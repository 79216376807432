import React, { useState } from "react";
import { MagnifyingGlass } from "phosphor-react";
import { Eye } from "phosphor-react";
import { useHistory } from "react-router";
import moment from "moment";
import { useStateValue } from "../context/StateProvider";
import { Hexagon } from "@phosphor-icons/react";
export default function Flicks() {
  const [{ flicks }] = useStateValue();

  const [search_text, setsearch_text] = useState("");

  const router = useHistory();

  return (
    <div className="p-5">
      <div className="flex justify-between items-center">
        <p className="text-white text-3xl tracking-wide font-medium leading-none">
          Flicks
        </p>
        <div className="h-11 w-80 relative bg-[#131418] rounded-full flex items-center px-3 group gap-3">
          <MagnifyingGlass
            size={22}
            className="group-focus-within:text-white text-[#8E8B8B]"
          />
          <input
            type="text"
            onChange={(event) => setsearch_text(event.target.value)}
            className="bg-transparent h-full w-full flex-1 appearance-none outline-none text-white placeholder:text-[#8E8B8B]"
            placeholder="Search by flicks title"
          />
        </div>
        <div className=""></div>
      </div>

      <div className="relative block mt-10 mb-10">
        <div className="relative">
          <div className="overflow-x-auto min-h-[0.01%]">
            <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
              <thead className="h-14 bg-[#131418]">
                <tr>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    #
                  </th>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    Title
                  </th>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    Product Id
                  </th>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    Flicks Tag
                  </th>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    Date Added
                  </th>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    Views
                  </th>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    Hearts
                  </th>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="table-row-group align-middle border-inherit">
                {flicks
                  ?.sort((a, b) => b.dateAdded - a.dateAdded)
                  ?.filter((val) => val?.name?.includes(search_text))
                  ?.map((review, index) => (
                    <React.Fragment key={index}>
                      <tr className="table-row-spacer"></tr>
                      <tr className="table-row align-[inherit]  bg-[#131418]">
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {index + 1}
                        </td>
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {review?.name}
                        </td>
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {review?.product?.id}
                        </td>
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {review?.flickTag}
                        </td>
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {moment(review?.dateAdded).format("DD/MM/YYYY")}
                        </td>
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {review?.views ?? 0}
                        </td>
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {review?.hearts}
                        </td>
                        <td
                          className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                          onClick={() =>
                            router.push("/admin/viewflick/" + review?.id)
                          }
                        >
                          <div className="flex items-center gap-x-3">
                            <div className="h-10 w-10 flex items-center justify-center relative">
                              <Hexagon size={40} weight="light" />
                              <Eye
                                size={20}
                                className="absolute"
                                weight="bold"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
            {flicks
              ?.sort((a, b) => b.dateAdded - a.dateAdded)
              ?.filter((val) => val?.name?.includes(search_text))?.length ===
              0 && (
              <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                No Reviews Available
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="fixed bottom-0 bg-background w-[calc(100vw-295px)] border-t-[2px] border-[#292929] flex items-center justify-between px-3 rounded-t-md">
        <div className="h-12 flex items-center justify-between  w-full text-[#808080]">
          {
            flicks
              ?.sort((a, b) => b.dateAdded - a.dateAdded)
              ?.filter((val) => val?.name?.includes(search_text))?.length
          }
        </div>
        <div className="text-[#808080] hover:text-white cursor-pointer">
          {flicks?.length}
        </div>
      </div>
    </div>
  );
}
