import React from 'react'
import GridCard from '../../pages/gallary/Cards/grid';
import { useRef } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Checks } from '@phosphor-icons/react';
import Modalcard from '../modalcard';
import FeedVideo from '../feedVideo';

export default function GallaryCardDesign({ design, selected, mode }) {
    const swiperRef = useRef(null);
    const handleSlideNext = () => {
        if (swiperRef.current) {
            swiperRef.current.slideNext();
        }
    };

    const handleSlidePrev = () => {
        if (swiperRef.current) {
            swiperRef.current.slidePrev();
        }
    };


    const containerRef = useRef(null)
    const scrollUp = () => {
        const container = containerRef.current;
        container.scrollTo({
            top: container.scrollTop - container.clientHeight,
            behavior: 'smooth',
        });
    };

    const scrollDown = () => {
        const container = containerRef.current;
        container.scrollTo({
            top: container.scrollTop + container.clientHeight,
            behavior: 'smooth',
        });
    };
    const [showModal, setShowModal] = useState(false);

    const router = useHistory();
    async function playVideos(index) {
        setShowModal(true);
    }
    async function copylink(link) {
        navigator.clipboard.writeText(link);
        toast.success("Link has been copied successfully", {
            position: "top-right",
            icon: <Checks size={32} color="green" weight="bold" />,
            bodyClassName: "text-sm tracking-wide font-work"
        });
    }
    return (
        <>
            {showModal === true && <Modalcard close={setShowModal}>
                <div className="h-[80vh] aspect-[9/16] bg-[#131417] border-[4px] border-black rounded-md" >
                    <div className="w-full h-full rounded overflow-y-auto  snap-y snap-mandatory scrollbar" ref={containerRef} id="zero-scrollbar">
                        <style>
                            {`#zero-scrollbar::-webkit-scrollbar {
                        display: none;
                      }
                        #zero-scrollbar {
                        scrollbar-width: none;
                        -ms-overflow-style: none;
                        }
                     `}
                        </style>
                        {selected?.map((review, index) => (
                            <FeedVideo key={index} data={review} scrollUp={scrollUp} scrollDown={scrollDown} from="gallery" />
                        ))}
                    </div>
                </div>
            </Modalcard>}
            {design === "Grid" && <div className="flex flex-wrap justify-center w-full mx-auto gap-10  overflow-y-auto h-fit my-5" id="thin-scrollbar">
                {selected?.map((rev, index) => (
                    <GridCard data={rev} key={index} mode={mode} playVideos={playVideos} scrollDown={scrollDown} scrollUp={scrollUp} />
                ))}
            </div>}
            {design === "Carousals" &&
                <div className="flex gap-10 justify-center h-[90vh] my-5">
                    <Swiper
                        spaceBetween={50}
                        // onSlideChange={() => console.log("slide change")}
                        // onSwiper={(swiper) => console.log(swiper)}
                        ref={swiperRef}
                        breakpoints={{
                            640: {
                                width: 640,
                                slidesPerView: 1,
                            },
                            768: {
                                width: 768,
                                slidesPerView: 2,
                            },
                            964: {
                                width: 964,
                                slidesPerView: 3,
                            },
                        }}
                        navigation={{
                            nextEl: ".next-button",
                            prevEl: ".prev-button",
                        }}
                        onBeforeInit={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                    >
                        {selected.map((rev, index) => (
                            <SwiperSlide key={index} className="relative cursor-pointer mx-10">
                                <GridCard data={rev} mode={mode} playVideos={playVideos} scrollDown={scrollDown} scrollUp={scrollUp} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            }
            {design === "SlideShow" && <div className="flex gap-10 justify-center  my-5 max-w-[1280px] mx-auto ">
                <button className="" onClick={handleSlidePrev}>
                    <img src={require("../../assets/icons/gallery/caretRight.svg").default} alt="right" className="rotate-180 transform" />
                </button>
                <div className="w-full flex-1 overflow-hidden">
                    <div className="">
                        <Swiper
                            spaceBetween={50}
                            // onSlideChange={() => console.log("slide change")}
                            // onSwiper={(swiper) => console.log(swiper)}
                            onSlideChange={(swiper) => {
                                // setActiveSlide(swiper.activeIndex);
                            }}
                            ref={swiperRef}
                            slidesPerGroup={1}
                            centeredSlides={true}
                            breakpoints={{
                                640: {
                                    width: 640,
                                    slidesPerView: 1,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 2,
                                },
                                964: {
                                    width: 964,
                                    slidesPerView: 3,
                                },
                            }}
                            navigation={{
                                nextEl: ".next-button",
                                prevEl: ".prev-button",
                            }}
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                            }}
                            initialSlide={0}
                        >
                            {selected.map((rev, index) => (
                                <SwiperSlide key={index} className={`relative cursor-pointer mx-10 flex-shrink-0 `}>
                                    <GridCard data={rev} mode={mode} playVideos={playVideos} scrollDown={scrollDown} scrollUp={scrollUp} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
                <button className="" onClick={handleSlideNext}>
                    <img src={require("../../assets/icons/gallery/caretRight.svg").default} alt="right" className="" />
                </button>
            </div>}
            {design === "List" && <div className={`flex flex-col space-y-10 max-w-[1020px] ${mode === "light" ? "bg-white" : "bg-[#232324]"} mx-auto p-10 rounded-3xl my-5`}>
                {selected.map((rev, index) => (
                    <div className={`${index % 2 === 0 ? "self-start" : "self-end"} text-black relative flex items-center`} key={index}>
                        <video
                            src={rev.reviewUrl}
                            // controls
                            controlsList="nodownload noplaybackrate"
                            disablePictureInPicture
                            className={`h-[606px] aspect-[9/16] object-cover mx-auto rounded-xl ${index % 2 === 0 ? "order-1" : "order-2"}`}
                            lazy="true"
                        ></video>
                        <div className={`min-h-[257px] w-[503px] rounded-3xl ${mode === "light" ? "bg-white border shadow-sm" : "bg-[#464646]"} p-5 flex flex-col justify-between ${index % 2 === 0 ? "order-2 -ml-10" : "order-1 -mr-10 z-10"}`}>
                            <div className="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="23" viewBox="0 0 30 23" fill="none">
                                    <path d="M24.618 0.451996L29.046 2.666C25.684 7.34 22.24 12.834 22.24 22.51H13.712C13.958 12.588 21.01 3.158 24.618 0.451996ZM11.252 0.451996L15.598 2.666C12.318 7.258 8.792 12.834 8.792 22.51H0.346C0.592 12.588 7.644 3.158 11.252 0.451996Z" fill={mode === "light" ? "#121212" : "#D9D9D9"} />
                                </svg>
                                <p className={`${mode === "light" ? "text-[#121212]" : "text-[#D9D9D9]"} text-3xl capitalize mt-4`}>{rev?.name}</p>
                            </div>
                            <div className="flex items-center gap-3 self-end">
                                <div className="h-16 w-16 border rounded-full">
                                    <img src={rev?.user?.imageUrl ?? `https://ui-avatars.com/api/?size=100&name=${rev?.user?.name}`} alt="profile" className='h-full w-full rounded-full object-cover' />
                                </div>
                                <div className="">
                                    <p className={`${mode === "light" ? "text-[#121212]" : "text-[#D9D9D9]"} truncate break-all first-letter:uppercase tracking-wide`}>
                                        {rev?.user?.name}
                                    </p>
                                    <p className={`${mode === "light" ? "text-[#121212]" : "text-[#D9D9D9]"} truncate break-all first-letter:uppercase tracking-wide`}>
                                        {rev?.user?.id}
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                ))}
            </div>}
            <div className="h-5"></div>
        </>
    )
}
