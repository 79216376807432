import React, { useEffect, useState } from "react";
import { MagnifyingGlass } from "phosphor-react";
import { Eye } from "phosphor-react";
import { useHistory } from "react-router";
import { sendAmplitudeData } from "../amplitude";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import moment from "moment";
import { useStateValue } from "../context/StateProvider";
import { Hexagon } from "@phosphor-icons/react";
export default function Reviews() {
  const [{ reviews, user }, dispatch] = useStateValue();

  const [search_text, setsearch_text] = useState("");
  const [tabs, settabs] = useState(0);
  const router = useHistory();
  const [text_reviews, settext_reviews] = useState([]);
  useEffect(() => {
    // console.log(user)
    const q = query(
      collection(db, "forms"),
      where("brandemail", "==", user?.email)
    );
    getDocs(q).then((res) => {
      var ids = [];
      var textreviews = [];
      res.docs.map((r) => ids.push(r.id));
      ids.forEach(async (formid) => {
        await getDocs(collection(db, `/forms/${formid}/submissions`)).then(
          (res) => {
            res.docs.map((doc) => {
              if (doc.data().reviewUrl === null) {
                textreviews.push({
                  ...doc.data(),
                  id: doc.id,
                  campaign_id: formid,
                });
              }
            });
          }
        );
      });
      settext_reviews(textreviews);
    });
    sendAmplitudeData("review list page opened");
  }, [user]);

  return (
    <div className="p-5">
      <div className="flex justify-between items-center">
        <p className="text-white text-3xl tracking-wide font-medium leading-none">
          Reviews
        </p>
        <div className="h-11 w-80 relative bg-[#131418] rounded-full flex items-center px-3 group gap-3">
          <MagnifyingGlass
            size={22}
            className="group-focus-within:text-white text-[#8E8B8B]"
          />
          <input
            type="text"
            onChange={(event) => setsearch_text(event.target.value)}
            className="bg-transparent h-full w-full flex-1 appearance-none outline-none text-white placeholder:text-[#8E8B8B]"
            placeholder="Search by product name"
          />
        </div>
        <div className=""></div>
      </div>
      <div className="grid w-full grid-cols-2 h-12 my-5 max-w-[748px] mx-auto bg-[#131418] rounded-full mt-10">
        <div
          onClick={() => settabs(0)}
          className={`cursor-pointer rounded-full ${
            tabs === 0 ? "bg-[#93A8F4]" : ""
          } tracking-wide text-white flex items-center justify-center transition-all`}
        >
          Video Reviews
        </div>
        <div
          onClick={() => settabs(1)}
          className={`cursor-pointer rounded-full ${
            tabs === 1 ? "bg-[#93A8F4]" : ""
          } tracking-wide text-white flex items-center justify-center transition-all`}
        >
          Text Reviews
        </div>
      </div>
      {tabs === 0 && (
        <>
          <div className="relative block mt-10 mb-10">
            <div className="relative">
              <div className="overflow-x-auto min-h-[0.01%]">
                <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                  <thead className="h-14 bg-[#131418]">
                    <tr>
                      <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                        #
                      </th>
                      <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                        Title
                      </th>
                      <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                        Status
                      </th>
                      <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                        Date Added
                      </th>
                      <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                        Views
                      </th>
                      <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                        Rating
                      </th>
                      <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-row-group align-middle border-inherit">
                    {reviews
                      ?.sort((a, b) => b.dateAdded - a.dateAdded)
                      ?.filter((val) =>
                        val?.product?.name?.includes(search_text)
                      )
                      ?.map((review, index) => (
                        <React.Fragment key={index}>
                          <tr className="table-row-spacer"></tr>
                          <tr
                            className="table-row align-[inherit]  bg-[#131418]"
                            key={index}
                          >
                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                              {index + 1}
                            </td>
                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                              {review?.name}
                            </td>
                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                              {review?.status === true ? (
                                <span className="flex gap-2 items-center">
                                  {" "}
                                  <div className="h-2 w-2 rounded-full bg-green-500"></div>{" "}
                                  Published
                                </span>
                              ) : (
                                <span className="flex gap-2 items-center">
                                  {" "}
                                  <div className="h-2 w-2 rounded-full bg-[#D88D63]"></div>{" "}
                                  Pending
                                </span>
                              )}
                            </td>
                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                              {moment(review?.dateAdded).format("DD/MM/YYYY")}
                            </td>
                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                              {review?.views ?? 0}
                            </td>
                            <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                              {review?.rating}
                            </td>
                            <td
                              className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                              onClick={() =>
                                router.push("/admin/viewreview/" + review?.id)
                              }
                            >
                              <div className="flex items-center gap-x-3">
                                <div className="h-10 w-10 flex items-center justify-center relative">
                                  <Hexagon size={40} weight="light" />
                                  <Eye
                                    size={20}
                                    className="absolute"
                                    weight="bold"
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
                {reviews
                  ?.filter((val) => {
                    if (search_text === "") {
                      return val;
                    } else if (
                      val?.name
                        ?.toLowerCase()
                        .includes(search_text.toLowerCase()) ||
                      val?.product?.name
                        .toLowerCase()
                        .includes(search_text.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  ?.filter((val) => {
                    if (val.status === true) {
                      return val;
                    }
                  })?.length === 0 && (
                  <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                    No Reviews Available
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="fixed bottom-0 bg-background w-[calc(100vw-295px)] border-t-[2px] border-[#292929] flex items-center justify-between px-3 rounded-t-md">
            <div className="h-12 flex items-center justify-between  w-full text-[#808080]">
              Showing 1 to{" "}
              {
                reviews?.filter((val) => {
                  if (search_text === "") {
                    return val;
                  } else if (
                    val?.name
                      ?.toLowerCase()
                      .includes(search_text.toLowerCase()) ||
                    val?.product?.name
                      .toLowerCase()
                      .includes(search_text.toLowerCase())
                  ) {
                    return val;
                  }
                }).length
              }{" "}
              of {reviews?.length}
            </div>
            <div className="text-[#808080] hover:text-white cursor-pointer">
              {reviews?.length}
            </div>
          </div>
        </>
      )}
      {tabs === 1 && (
        <div className="relative block mt-10 mb-10">
          <div className="relative">
            <div className="overflow-x-auto min-h-[0.01%]">
              <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                <thead className="h-14 bg-[#131418]">
                  <tr>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      #
                    </th>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Review
                    </th>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Campaign name
                    </th>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Date Added
                    </th>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Average Rating
                    </th>
                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody className="table-row-group align-middle border-inherit">
                  {text_reviews?.map((review, index) => (
                    <React.Fragment key={index}>
                      <tr className="table-row-spacer"></tr>
                      <tr
                        className="table-row align-[inherit]  bg-[#131418]"
                        key={index}
                      >
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {index + 1}
                        </td>
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {review?.review_info?.review_description}
                        </td>
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {review?.campaign_info?.campaign_name}
                        </td>
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {review?.dateAdded
                            ? moment(review?.dateAdded).format("DD/MM/YYYY")
                            : "Not Available"}
                        </td>
                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                          {review?.review_info?.average_rating}
                        </td>
                        <td
                          className="select-none max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                          onClick={() =>
                            router.push(
                              `/collector/view-submission/${review?.id}/${review?.campaign_id}`
                            )
                          }
                        >
                          <div className="flex items-center gap-x-3">
                            <div className="h-10 w-10 flex items-center justify-center relative">
                              <Hexagon size={40} weight="light" />
                              <Eye
                                size={20}
                                className="absolute"
                                weight="bold"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
              {text_reviews?.length === 0 && (
                <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                  No Reviews Available
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
