import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { useStateValue } from "../context/StateProvider";
import { Plus, ShieldSlash } from "@phosphor-icons/react";
import moment from "moment";
import { toast } from "react-toastify";

function Faqdashboard() {
  const [forms, setforms] = useState([]);
  const [loading, setloading] = useState(true);
  const [{ user }] = useStateValue();
  const [tabs, settabs] = useState(0);
  const { path } = useRouteMatch();
  const router = useHistory();

  useEffect(() => {
    const q = query(
      collection(db, "faqforms"),
      where("brandemail", "==", user?.email)
    );

    getDocs(q).then((res) => {
      var a = res.docs.map((r) => r.data());
      setforms(a);
      setloading(false);
    });
  }, []);

  async function changeRoute() {
    let limit = user?.limit?.faqLimit ?? 1;
    if ((user?.faqcampaigns ?? forms?.length) >= limit) {
      toast.warning("Campaign limit exceeded, Please upgrade your plan.", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="red" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    router.push("/engagement/createfaq");
  }

  if (loading)
    return (
      <div className=" min-h-screen -mt-16 relative flex items-center justify-center overflow-y-auto py-16 px-20">
        <p className="">Gettings your forms ready</p>
      </div>
    );

  if (forms.length === 0) {
    return (
      <div className=" min-h-screen -mt-16 relative overflow-y-auto py-16 px-20 ">
        <div className="">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Ffaq%20initial%20login%20image.png?alt=media&token=e461dbfd-68e5-44c8-aeea-69a539e20f54"
            alt="rightImage"
            className="absolute right-0 bottom-0 max-h-[80vh]"
          />
        </div>
        <div className="pt-28">
          <p className="text-6xl font-semibold">Welcome</p>
          <div className="mt-8">
            <p className="tracking-wider font-medium text-xl mb-1">
              What is FAQ Campaigns ?
            </p>
            <p className="tracking-wider font-light">
              Start by creating a faq campaign. This will let you remotely
              gather videos from any device just by sharing a link.
            </p>
          </div>
          <div className="my-8">
            <button
              className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
              onClick={() => changeRoute()}
            >
              <Plus size={22} color="white" /> Create a FAQ Campaign
            </button>
          </div>
          <div className="w-full flex flex-wrap items-center gap-5">
            <div className="w-fit text-center h-auto  p-3 my-2 border-2 rounded-xl border-white">
              <p className="mx-auto font-light tracking-wider text-sm">
                Faq's Campaigns used
              </p>
              <div className="mt-5 text-3xl">
                <span className="">
                  {user?.faqCampaigns ?? forms?.length} /{" "}
                  <span className="">{user?.limit?.faqLimit ?? 1}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className=" min-h-screen">
        <div className="w-full lg:max-w-[70%] mx-auto grid grid-cols-2 gap-5">
          <div className="w-full text-center h-auto py-5 p-3 my-2 border-2 rounded-xl border-white">
            <p className="mx-auto font-medium tracking-wider text-lg">
              Faq's Campaigns used
            </p>
            <div className="mt-5 text-3xl">
              <span className="">
                {user?.faqCampaigns ?? forms?.length} /{" "}
                <span className="">{user?.limit?.faqLimit ?? 1}</span>
              </span>
            </div>
          </div>
          <div className="w-full text-center h-auto py-5 p-3 my-2 border-2 rounded-xl border-white">
            <p className="mx-auto font-medium tracking-wider text-lg">
              Downloads used
            </p>
            <div className="mt-5  text-3xl">
              <span className="">
                {user?.downloads ?? 0} /
                <span className="">
                  {user?.downloadLimit === 5000
                    ? "Unlimited"
                    : user?.downloadLimit ?? 3}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between px-10 pt-10">
          <div className="">
            <p className="text-3xl font-medium">Your FAQ's Campaigns</p>
            <p className="tracking-wide">
              Use campaigns to collect testimonials from your users.
            </p>
          </div>
          <div className="">
            <button
              className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
              onClick={() => changeRoute()}
            >
              <Plus size={22} color="white" /> Create FAQ's Campaign
            </button>
          </div>
        </div>

        <div className="grid w-full grid-cols-2 h-12 my-5 max-w-[748px] mx-auto bg-[#131418] rounded-full">
          <div
            onClick={() => settabs(0)}
            className={`cursor-pointer rounded-full ${
              tabs === 0 ? "bg-[#93A8F4]" : ""
            } tracking-wide text-white flex items-center justify-center transition-all`}
          >
            Floating FAQ Campaigns
          </div>
          <div
            onClick={() => settabs(1)}
            className={`cursor-pointer rounded-full ${
              tabs === 1 ? "bg-[#93A8F4]" : ""
            } tracking-wide text-white flex items-center justify-center transition-all`}
          >
            Product FAQ Campaigns
          </div>
        </div>
        <div className="px-10 space-y-5 pb-10 mt-10">
          {forms
            ?.filter((val) => {
              if (tabs === 0) {
                return (
                  !val.hasOwnProperty("productimage") ||
                  val.productimage === null
                );
              } else if (tabs === 1) {
                return (
                  val.hasOwnProperty("productimage") &&
                  val.productimage !== null
                );
              }
              return true;
            })
            ?.map((form, index) => (
              <div
                className="h-20 bg-[#1E1F22] rounded-md flex items-center px-2 cursor-pointer"
                key={index}
                onClick={() =>
                  router.push("/engagement/view-faqcampaigns/" + form?.id)
                }
              >
                <div className="w-full h-full flex items-center justify-between">
                  <div className="flex-1 flex gap-3 w-full pl-3">
                    {form?.productimage ? (
                      <div className="h-16 w-16 rounded-lg">
                        <img
                          src={form?.productimage}
                          alt=""
                          className="h-full w-full rounded-lg"
                        />
                      </div>
                    ) : null}
                    <div className="flex-1 flex flex-col justify-center">
                      <p className=" text-[#dedede] tracking-wide font-medium">
                        Floating FAQ's Campaign Name : {form?.campaign_name}
                      </p>
                      <p className="text-[#707070] text-sm tracking-wide">
                        Spokesperson : {form?.name}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col text-right">
                    <p className="text-[#B7B7B7] text-lg font-medium tracking-wide">
                      {!form?.dateAdded
                        ? "Not available"
                        : moment(form?.dateAdded).format("DD/MM/YYYY")}
                    </p>
                    <p className=" text-[#dedede] tracking-wider text-sm">
                      Submissions : {form?.submissions}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
        {forms?.filter((val) => {
          if (tabs === 0) {
            return (
              !val.hasOwnProperty("productimage") || val.productimage === null
            );
          } else if (tabs === 1) {
            return (
              val.hasOwnProperty("productimage") && val.productimage !== null
            );
          }
          return true;
        })?.length === 0 ? (
          <div className="">
            <div className="flex-1 flex flex-col items-center justify-center">
              <p className="text-lg font-work tracking-wide">
                No camapaigns available
              </p>
              <p className="text-sm font-work tracking-wide text-[#e7e7e7]">
                Please select a product while creating a faq campaign.
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
}

export default Faqdashboard;
