import React from "react";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { MagnifyingGlass } from "phosphor-react";
import { useStateValue } from "../context/StateProvider";
import { Eye, Hexagon } from "@phosphor-icons/react";
export default function Search() {
  const { id } = useParams();
  const router = useHistory();
  const [querytext, setquerytext] = useState("");
  const [{ products, }, dispatch] = useStateValue();

  return (
    <div className="">
      <div className="h-16 my-5 flex items-center justify-center">
        <div className="relative h-10 w-96 flex items-center">
          <div className="absolute right-3">
            <MagnifyingGlass size={24} color="white" />
          </div>
          <form onSubmit={() => router.push(`/admin/search/${querytext}`)}>
            <input
              type="text"
              onChange={(e) => setquerytext(e.target.value)}
              className="bg-bg_highlight h-10 pr-12 rounded-full w-96 appearance-none outline-none pl-3 text-white placeholder:text-[#606165] font-light placeholder:font-normal text-sm tracking-wide"
              placeholder="Search products"
            />
          </form>
        </div>
      </div>
      <div className="my-3 px-5 text-lg text-white tracking-wide">
        <span className="text-opacity-60 font-light text-white">Search results for: </span> "{id}"
      </div>
      <div className="grid  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-full p-5 gap-x-6 gap-y-16 mt-10 pb-10">
        {products
          .filter((val) => {
            if (id === "") {
              return val;
            } else if (val.name.toLowerCase().includes(id.toLowerCase()) && val.status === true) {
              return val;
            }
          })
          .map((product, index) => (

            <div className=" cursor-pointer w-full bg-[#232323] h-[173px] rounded-3xl relative flex flex-col justify-between" key={index} onClick={() => router.push("/admin/viewproduct/" + product?.id)}>
              <div className="absolute top-[2px] right-[2px]">
                {product?.status === true && <div className="h-3 w-3 rounded-full bg-green-500"></div>}
                {product?.status === false && <div className="h-3 w-3 rounded-full bg-[#D88D63]"></div>}
              </div>
              <div className="">
                <div className="flex items-center p-3 gap-3">
                  <img src={product?.imageUrl} alt="thumbnail" className="h-[85px] aspect-square rounded-lg -mt-8" />
                  <div className="">
                    <div className="flex items-center gap-3">
                      <p className="text-sm text-[#D9D9D9] font-work tracking-wide">Rating</p>
                      <p className="font-work">{product?.rating?.toFixed(2)}</p>
                    </div>
                    <div className="flex items-center gap-3">
                      <p className="text-sm text-[#D9D9D9] font-work tracking-wide">Reviews</p>
                      <p className="font-work">{product?.reviews}</p>
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-center px-3">
                  <p className="text-sm text-[#D9D9D9] tracking-wide">{product?.id}</p>
                  <div className="flex flex-col items-center">
                    <div className="h-6 w-6 flex items-center justify-center relative">
                      <Hexagon size={22} weight="light" />
                      <Eye size={10} className="absolute" weight="bold" />
                    </div>
                    <p className="text-[8px] tracking-wide">View</p>
                  </div>
                </div>
              </div>
              <div className="h-auto text-sm font-light tracking-wide py-2 px-3 bg-[#2C2C2C] text-[#D9D9D9] rounded-b-3xl">
                {product?.name}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}