import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { sendAmplitudeData } from "../../amplitude";
import Modalcard from "../../components/modalcard";
import { useStateValue } from "../../context/StateProvider";
import AWN from "awesome-notifications";
import {
  Checks,
  Copy,
  DownloadSimple,
  FileCsv,
  Video,
} from "@phosphor-icons/react";
import { toast } from "react-toastify";
import HalfStarRating from "../../components/halfstarrating";
import QRCodeStyling from "qr-code-styling";
import ReactApexChart from "react-apexcharts";

function Viewcampaign() {
  const [{ user, reviews }, dispatch] = useStateValue();
  const [forms, setforms] = useState([]);
  const [form, setform] = useState({});
  const [words, setwords] = useState(null);
  const [product_details, setproduct_details] = useState({});
  const [showaddreviews, setshowaddreviews] = useState(false);
  const [product_attributes, setproduct_attributes] = useState([]);
  const [loading, setloading] = useState(true);
  const { id } = useParams();
  const [tab, settab] = useState(0);
  const [forms_search_text, setforms_search_text] = useState("");
  const [average_attributes, setaverage_attributes] = useState({});
  const [attributes_set, setattributes_set] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    const q = query(collection(db, "forms/" + id + "/submissions"));
    getDoc(doc(db, "forms/" + id)).then((res) => {
      setform(res.data());
      getDoc(doc(db, "product/" + res.data().prodid)).then((res) => {
        setproduct_details(res.data());
        setproduct_attributes(Object.keys(res.data()?.attributes));
      });
    });

    getDocs(q).then((res) => {
      var cmpns = res.docs.map((e) => ({ ...e.data(), id: e.id }));
      setforms(cmpns);
      extractWords(cmpns);
      const attribute_ratings = [];
      res.docs.map((doc) =>
        attribute_ratings.push(doc.data().review_info?.average_rating ?? 3)
      );
      const mixedSum = (arr = []) => {
        let sum = 0;
        for (let i = 0; i < arr.length; i++) {
          const el = arr[i];
          sum += +el;
        }
        return sum;
      };
      const campaign_average_rating = isNaN(
        (mixedSum(attribute_ratings) / attribute_ratings.length).toFixed(2)
      )
        ? 0
        : (mixedSum(attribute_ratings) / attribute_ratings.length).toFixed(2);
      // if (form?.campaign_average_rating === campaign_average_rating) {
      // console.log("not updated")
      // }
      if (form?.campaign_average_rating !== campaign_average_rating) {
        updateDoc(doc(db, "forms/" + id), {
          campaign_average_rating: campaign_average_rating,
        });
      }
      setloading(false);
      setattributes_set(
        res.docs.map((doc) => doc.data().review_info?.attributes)
      );
    });

    sendAmplitudeData("viewing campaign details", { campaign: id });
  }, []);

  useEffect(() => {
    if (attributes_set.length > 0) {
      let result = attributes_set?.reduce(
        (a, c) => (
          Object.keys(c)?.forEach((k) => (a[k] = (a[k] || 0) + c[k])), a
        ),
        {}
      );
      setaverage_attributes(result);
    }
  }, [attributes_set]);

  function extractWords(arr = []) {
    const filterWords =
      "a is the me you this are we am your its it will were if and he she i their there on in what but maybe might ";
    var wordsFiltered = {};
    for (var sentence of arr) {
      sentence = sentence.review_info.review_description
        .toLowerCase()
        .split(" ");
      sentence = sentence.filter((word) => !filterWords.includes(word));
      for (const word of sentence) {
        if (wordsFiltered[word]) {
          wordsFiltered += 1;
        } else {
          wordsFiltered[word] = 1;
        }
      }
    }
    setwords(wordsFiltered);
  }

  const copy = () => {
    navigator?.clipboard?.writeText(window.location.host + "/userView/" + id);
    showSuccessMsg("Link copied to clipboard");
  };
  const csvString = [
    [
      "Campaign Name",
      "Campaign Url",
      "Product Name",
      "Product ID",
      "Review Title",
      "User Name",
      "User ID",
      "User Email",
      "User Phone",
      form?.questions?.map((question, index) => `Q: ${question?.question}`),
      "Status",
      "Video url",
      "Average rating",
      "Purchase Mode",
      product_attributes?.map((product) => product),
    ],
    ...forms?.map((item, index) => [
      item.campaign_name ?? item?.campaign_info?.campaign_name,
      `https://brand.myrevue.app/userView/${item.id}`,
      item?.productname ?? item?.campaign_info?.productname,
      item?.prodid ?? item?.campaign_info?.prodid,
      item?.review_info?.review_name ?? item?.review_info?.review_description,
      item?.user_info?.name,
      item?.user_info?.id,
      item?.user_info?.email,
      item?.user_info?.phone,
      item?.questions?.map((question, index) => question?.answer),
      item.substatus,
      `https://myrevue.app/embed/submission/${id}/${item?.id}`,
      item?.review_info?.average_rating,
      item?.review_info?.purchaseMode,
      product_attributes?.map((p) => item?.review_info?.attributes?.[p]),
    ]),
  ]
    ?.map((e) => e.join(","))
    .join("\n");

  async function exportcsv() {
    const data = `data:,${csvString}`;
    const filename = `${form?.campaign_name || form?.productname}.csv`;
    const aTag = document.createElement("a");

    aTag.href = data;
    aTag.download = filename;
    aTag.click();
  }

  const calculateOptionPercent = (count, total) => {
    if (!count) return 0;

    count = count / total;
    count *= 1000;
    count = Math.round(count);
    return count / 10;
  };
  const handleSelectClick = (e) => {
    if (selected.includes(e.id)) {
      setSelected(selected.filter((r) => r !== e.id));
    } else {
      setSelected([...selected, e.id]);
    }
  };

  async function updateform() {
    if (selected.length === 0) {
      return new AWN().warning("Please select atleast one review");
    }

    await updateDoc(doc(db, "forms", form.id), { reviews: selected }).then(
      () => {
        new AWN().success("Reviews added in campaign");
        window.location.reload();
        return;
      }
    );
  }
  async function showSuccessMsg(msg) {
    toast.success(msg, {
      position: "top-right",
      icon: <Checks size={32} color="green" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
  }

  const qrCode = new QRCodeStyling({
    width: 1000,
    height: 1000,
    image:
      "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue%20only%20logo.png?alt=media&token=d1d344f7-ce12-44c1-826d-3963af17ad94",
    data: "http://brand.myrevue.app/userView/" + id,
    margin: 20,
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "Q" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: { type: "extra-rounded", color: "#000000" },
    backgroundOptions: { color: "#ffffff" },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0",
      },
    },
    cornersSquareOptions: { type: "extra-rounded", color: "#ff005c" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    cornersDotOptions: { type: "", color: "#ff005c" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
      },
    },
  });

  async function downloadQRCode() {
    qrCode.download({
      name: id,
      extension: "png",
    });
  }

  const pieData = {
    labels: ["Category 1", "Category 2", "Category 3"],
    series: [44, 55, 41],
  };

  // Define options for the pie chart
  const pieOptions = {
    chart: {
      type: "pie",
    },
    labels: pieData.labels,
  };

  return (
    <div className=" min-h-screen text-white scroll-smooth ">
      {showaddreviews === true && (
        <Modalcard close={setshowaddreviews}>
          <div className="h-[80vh] w-[90vw] bg-[#232326] overflow-y-auto p-5">
            <div className="pb-5">
              <p className="text-2xl">Reviews</p>
              <p className="">
                Selected reviews will be displayed on campaigns page.
              </p>
            </div>

            <div className=" flex flex-wrap gap-5">
              {reviews
                ?.filter((val) => val.bpsId === form?.prodid)
                ?.map((rev, index) => (
                  <div
                    className={`w-40 aspect-[9/16] ${
                      selected.includes(rev.id) &&
                      "border-[3px] border-blue-500 rounded-md"
                    }`}
                    key={index}
                    onClick={() => handleSelectClick(rev)}
                  >
                    <img
                      src={rev?.thumbnail}
                      alt=""
                      className="h-full w-full object-cover rounded-md"
                    />
                  </div>
                ))}
            </div>
            <div className="mt-5">
              <button
                className="bg-[#121212] px-8 appearance-none outline-none hover:bg-[#4611ea] py-2 mr-2"
                onClick={() => updateform()}
              >
                Add reviews
              </button>
            </div>
          </div>
        </Modalcard>
      )}
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <p className="">Gettings your submissions ready </p>
        </div>
      ) : (
        <div className="flex items-start justify-between p-10  gap-10">
          <div className="w-full flex-1">
            <div className="flex-1 flex items-center justify-between">
              <div className="rounded-md h-28 w-28">
                <img
                  src={form?.productimage}
                  alt="product_image"
                  className="rounded-md"
                />
              </div>
              <div className="bg-[#3B3D42] h-auto rounded-lg drop-shadow-lg w-full flex-1 flex flex-col justify-center items-center px-5 ml-10 gap-3 py-5">
                <p className="text-center text-[#D9D9D9] tracking-wide">
                  Shareable link:
                </p>
                <div className="flex items-center gap-5">
                  <div className="items-center text-xs bg-[#2E3036] w-fit flex gap-10 rounded-full pl-10">
                    <p>{window?.location?.host + "/userView/" + id}</p>
                    <button
                      className="hover:bg-green-500 bg-opacity-80 p-2 m-1 rounded-full outline-none appearance-none"
                      onClick={() => copy()}
                    >
                      <Copy size={28} color="white" />
                    </button>
                  </div>
                  <button
                    className="bg-[#2E3036] hover:bg-blue-500 bg-opacity-80 p-2 m-1 rounded-full outline-none appearance-none flex items-center gap-3 px-3"
                    onClick={() => downloadQRCode()}
                  >
                    <DownloadSimple size={28} color="white" />
                    Download QR Code
                  </button>
                </div>
                <div className="w-full text-left">
                  <p className="">Most used Words</p>
                  <div className="flex flex-wrap gap-2 my-1">
                    {words
                      ? Object.keys(words ?? {}).map((k) => (
                          <button
                            key={k}
                            className="rounded-full border border-violet-700 px-3 py-1 text-xs"
                          >
                            {k}-{words[k]}
                          </button>
                        ))
                      : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full  grid grid-cols-5  mt-10">
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${
                  tab === 0
                    ? "border-b border-[#D9D9D9] text-white"
                    : "bg-transparent text-[#808080]"
                }`}
                onClick={() => {
                  settab(0);
                  setforms_search_text("");
                }}
              >
                All ({forms.length})
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${
                  tab === 1
                    ? "border-b border-[#D9D9D9] text-white"
                    : "bg-transparent text-[#808080]"
                }`}
                onClick={() => {
                  settab(1);
                  setforms_search_text("pending");
                }}
              >
                Pending (
                {
                  forms.filter(
                    (val) => val.substatus === "pending" || !val?.substatus
                  ).length
                }
                )
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${
                  tab === 2
                    ? "border-b border-[#D9D9D9] text-white"
                    : "bg-transparent text-[#808080]"
                }`}
                onClick={() => {
                  settab(2);
                  setforms_search_text("In Review");
                }}
              >
                In Review (
                {forms.filter((val) => val.substatus === "In Review").length})
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${
                  tab === 3
                    ? "border-b border-[#D9D9D9] text-white"
                    : "bg-transparent text-[#808080]"
                }`}
                onClick={() => {
                  settab(3);
                  setforms_search_text("published");
                }}
              >
                Published (
                {forms.filter((val) => val.substatus === "published").length})
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${
                  tab === 4
                    ? "border-b border-[#D9D9D9] text-white"
                    : "bg-transparent text-[#808080]"
                }`}
                onClick={() => {
                  settab(4);
                  setforms_search_text("rejected");
                }}
              >
                Rejected (
                {forms.filter((val) => val.substatus === "rejected").length})
              </button>
            </div>
            <div className="flex flex-1  flex-wrap gap-8 mt-10">
              {forms
                .filter((val) => {
                  if (forms_search_text === "") {
                    return val;
                  } else if (
                    val?.substatus
                      ?.toLowerCase()
                      .includes(forms_search_text?.toLowerCase()) ||
                    !val?.substatus
                  ) {
                    return val;
                  } else return false;
                })
                .map((doc, index) => (
                  <Link
                    to={"/engagement/view-submission/" + doc.id + "/" + id}
                    key={index}
                  >
                    <div
                      key={index}
                      className="bg-gradient-to-b from-[#42454B] to-[#383A3F] aspect-[12/16] w-60  rounded-lg drop-shadow-lg relative flex  justify-center"
                    >
                      <div className="h-full w-full  bg-[#42454B] absolute rounded-lg  shadow-2xl bg-opacity-0">
                        <div className="right-0 float-right m-2 tracking-wide">
                          {(doc?.substatus === "pending" ||
                            !doc?.substatus) && (
                            <div className="bg-[#dea513]  px-5 py-1 rounded">
                              Pending
                            </div>
                          )}
                          {doc?.substatus === "In Review" && (
                            <div className="bg-orange-500  px-5 py-1 rounded">
                              {doc?.substatus}
                            </div>
                          )}
                          {doc?.substatus === "rejected" && (
                            <div className="bg-red-500  px-5 py-1 rounded">
                              {doc?.substatus}
                            </div>
                          )}
                          {doc?.substatus === "published" && (
                            <div className="bg-green-500  px-5 py- rounded">
                              {doc?.substatus}
                            </div>
                          )}
                        </div>
                        <div className="shadow-top bottom-0 absolute rounded-b-lg pb-2 bg-gradient-to-b from-transparent via-[#00000070] to-[#00000090] w-full">
                          <p className="p-1 text-xs tracking-wider ">
                            Campaign Name :{" "}
                            {doc?.campaign_info?.campaign_name ??
                              form?.campaign_name}
                          </p>
                          <p className="px-1 text-xs tracking-wider ">
                            Reviewer : {doc?.user_info?.name}
                          </p>
                          <p className="px-1 text-xs tracking-wider ">
                            Type : {doc?.reviewUrl !== null ? "Video" : "Text"}
                          </p>
                        </div>
                      </div>
                      <div className="aspect-[12/16] w-60">
                        {!doc?.thumbnail ? (
                          <video
                            src={doc?.reviewUrl}
                            className="object-cover h-full w-full rounded-lg"
                          ></video>
                        ) : (
                          <img
                            src={doc?.thumbnail}
                            className="object-cover h-full w-full rounded-lg"
                            alt="thumbnail"
                          ></img>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>

          <div className="bg-[#383A3F] w-[422px] min-h-full p-5 rounded-lg">
            <div className="flex flex-col items-center w-full gap-5">
              <button
                className="bg-[#2E3036] hover:bg-[#151516] flex items-center gap-8 h-14 rounded-full px-5 w-fit"
                onClick={() => setshowaddreviews(true)}
              >
                <Video size={28} color="#ffffff" />
                Add reviews in campaign form
              </button>
              <button
                className="bg-[#438B4E] hover:bg-[#25da40]  flex items-center gap-8 h-14 rounded-full px-5 w-fit"
                onClick={() => exportcsv()}
              >
                <FileCsv size={28} color="#ffffff" />
                Generate CSV
              </button>
            </div>
            <div className="w-full mt-5">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center">Campaign Details</p>
              </div>
              <div className="">
                <p className="mt-2  tracking-wider text-sm">
                  Campaign Name :{" "}
                  {doc?.campaign_info?.campaign_name ?? form?.campaign_name}
                </p>
                <p className="mt-2  tracking-wider text-sm">
                  Text Reviews :{" "}
                  {forms.filter((val) => val.reviewUrl === null).length}
                </p>
                <p className="mt-2  tracking-wider text-sm">
                  Video Reviews :{" "}
                  {forms.filter((val) => val.reviewUrl !== null).length}
                </p>
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center text-[#D9D9D9]">
                  Product Details
                </p>
              </div>
              <div className="">
                <p className="mt-2  tracking-wider text-sm text-[#D9D9D9]">
                  Product Name : {form?.productname}
                </p>
                <p className="mt-2  tracking-wider text-sm text-[#D9D9D9]">
                  Product ID : {form?.prodid}
                </p>
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center">Attributes</p>
              </div>
              <div className="">
                <div className="grid grid-cols-2">
                  {product_attributes?.map((attribute, index) => (
                    <div
                      className={`${
                        index % 2 === 0
                          ? "grid items-end justify-start my-2 select-none"
                          : "grid items-end justify-center my-2 select-none"
                      }`}
                      key={index}
                    >
                      <p className="text-center pb-2">{attribute}</p>
                      <HalfStarRating
                        sizeh={22}
                        rating={average_attributes?.[attribute] / forms.length}
                        colorcode="#E2BE45"
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full mt-5">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center text-[#D9D9D9]">
                  Questions Poll
                </p>
              </div>
              {form?.questions?.map((question, index) => (
                <div className="my-5" key={index}>
                  <p className="mt-2  tracking-wider text-sm text-[#D9D9D9]">
                    Question {question.id} : {question.question}
                  </p>
                  <div className="bg-[#2E3036] w-full h-12 rounded-full flex justify-between items-center px-5 mt-2">
                    <span className="">{question.opt1}</span>
                    <span className="">
                      {calculateOptionPercent(
                        question[question.opt1],
                        forms.length
                      )}
                      %
                    </span>
                  </div>
                  <div className="bg-[#2E3036] w-full h-12 rounded-full flex justify-between items-center px-5 mt-2">
                    <span className="">{question.opt2}</span>
                    <span className="">
                      {calculateOptionPercent(
                        question[question.opt2],
                        forms.length
                      )}
                      %
                    </span>
                  </div>
                  <div className="bg-[#2E3036] w-full h-12 rounded-full flex justify-between items-center px-5 mt-2">
                    <span className="">{question.opt3}</span>
                    <span className="">
                      {calculateOptionPercent(
                        question[question.opt3],
                        forms.length
                      )}
                      %
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Viewcampaign;
