import { collection, getDocs, query, where } from "firebase/firestore";
import { DownloadSimple, Eye } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import { db } from "../../firebase";
export default function Coupons() {
  const [showCatlog, setshowCatlog] = useState(false);
  const [coupons, setcoupons] = useState([]);
  const [search_text, setsearch_text] = useState("");
  const router = useHistory();
  const { path } = useRouteMatch();

  useEffect(() => {
    const couponss = query(
      collection(db, "rewardCoupan"),
      where("type", "==", "discount")
    );
    getDocs(couponss).then((res) =>
      setcoupons(res.docs.map((doc) => doc.data()))
    );
  }, []);

  return (
    <div className="p-5">
      <div className="flex items-center justify-between  text-white">
        <div className="flex">
          <p className="text-xl ">Coupons :</p>
          <p className="text-xl "> &nbsp; {coupons.length}</p>
        </div>
        <div className="flex gap-x-4 relative">
          <button
            className="bg-[#8ea9fa] h-10 px-8 cursor-pointer select-none text-white rounded"
            onClick={() => router.push(`/admin/createcoupon`)}
          >
            Create New
          </button>
          {/* <button onClick={() => setshowCatlog(!showCatlog)} className="border-[2px] border-[#707378] hover:border-white h-10 w-10 cursor-pointer select-none rounded text-[#707378] hover:text-white flex items-center justify-center">
                        <DotsThreeVertical size={24} weight="bold" />
                    </button> */}
          {showCatlog === true ? (
            <div className="z-30 bg-white rounded absolute mt-12 right-5 shadow-lg shadow-black">
              <button className="text-[15px] h-10 w-[19rem] hover:bg-[#efefef6e] text-[#121212] hover:text-[#8ea9fa] flex gap-x-3 items-center justify-center">
                <DownloadSimple size={22} />
                Download full catalog (CSV)
              </button>
              <button className="text-[15px] h-10 w-[19rem] hover:bg-[#efefef6e] text-[#121212] hover:text-[#8ea9fa] flex gap-x-3 items-center justify-center">
                <DownloadSimple size={22} />
                Download full catalog (XLSX)
              </button>
            </div>
          ) : null}
        </div>
      </div>

      <div className="relative block mt-10 mb-10">
        <div className="relative">
          <div className="overflow-x-auto min-h-[0.01%]">
            <table className="bg-[#131418] rounded-t-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
              <thead className="">
                <tr>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    #
                  </th>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    Coupon Name
                  </th>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    Available Coupons
                  </th>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    Quanity
                  </th>
                  <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="table-row-group align-middle border-inherit">
                {coupons
                  ?.filter((val) => {
                    if (search_text === "") {
                      return val;
                    } else if (
                      val.title
                        .toLowerCase()
                        .includes(search_text.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  .map((coupon, index) => (
                    <tr
                      className="table-row align-[inherit] border-inherit"
                      key={index}
                    >
                      <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                        {index + 1}
                      </td>
                      <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                        {coupon?.title}
                      </td>
                      <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                        {coupon?.quantity}
                      </td>
                      <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                        {coupon?.coupanValue}
                      </td>
                      <td
                        className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                        onClick={() =>
                          router.push("/admin/viewcoupon/" + coupon.id)
                        }
                      >
                        <div className="flex items-center gap-x-3">
                          View <Eye size={24} />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {coupons.length === 0 && (
              <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                No Coupons Available
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
