import React from "react";
import { useState } from "react";

function VideoResize({ formatType }) {
  const [size, setSize] = useState(null);

  const active =
    " flex h-36 w-36 cursor-pointer duration-75 transform scale-105 flex-col border-4 rounded-md bg-white bg-opacity-30 items-center justify-center";
  const passive =
    "flex h-36 w-36 cursor-pointer duration-75 transform hover:scale-105 flex-col border rounded-md bg-white bg-opacity-30 items-center justify-center";

  return (
    <div className="absolute  w-full h-full top-0 left-0 z-10 bg-black bg-opacity-70 flex items-center flex-col justify-center hideOut-normal">
      <div className="flex items-center gap-3">
        <div
          onClick={() => setSize("mobile")}
          className={size === "mobile" ? active : passive}
        >
          <div className="border h-12 w-7 m-2 rounded-md"></div>
          <p className="">Mobile</p>
        </div>
        <div
          onClick={() => setSize("tablet")}
          className={size === "tablet" ? active : passive}
        >
          <div className="border h-12 w-10 m-2 rounded-md"></div>
          <p className="">Tablet</p>
        </div>
        <div
          onClick={() => setSize("laptop")}
          className={size === "laptop" ? active : passive}
        >
          <div className="border h-12 w-16 m-2  rounded-md"></div>
          <p className="">Laptop</p>
        </div>
        <div
          onClick={() => setSize("square")}
          className={size === "square" ? active : passive}
        >
          <div className="border h-12 w-12 m-2  rounded-md"></div>
          <p className="">square</p>
        </div>
        <div
          onClick={() => setSize(null)}
          className={size === null ? active : passive}
        >
          <div className="border h-12 w-12 m-2 flex items-center justify-center rounded-md">
            <i className="fas fa fa-close "></i>
          </div>
          <p className="">Keep Current</p>
        </div>
      </div>
      <div className="h-20"></div>

      <div className="hideOut-normal text-center">
        <p className="text-xs text-gray-300">
          Clicking on <span className="font-bold">change Format</span> will
          change the format of this video. And new format will be done on the
          basis of center.
        </p>
        <p className="text-xs text-gray-300">
          <span className="font-bold text-red-500">Attention</span> : review
          will be formated on the basis of center.
        </p>
        <button
          onClick={() => formatType(size)}
          className="bg-[#8ea9fa] h-10 px-8 cursor-pointer mt-5  select-none text-white rounded"
        >
          Change Format
        </button>
      </div>
    </div>
  );
}

export default VideoResize;
