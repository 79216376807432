import { ArrowLeft } from 'phosphor-react'
import React from 'react'
import { useHistory } from 'react-router'

export default function Stickyheader({ title, back }) {
    const router = useHistory();
    return (
        <div className="sticky top-0 z-50 bg-background border-b-[1px] px-5 py-4  border-[#ececec1b] text-white flex items-center text-4xl font-extralight">
            {back === true ? <div className="h-12 w-12 flex items-center justify-center cursor-pointer" onClick={() => router.goBack()}>
                <ArrowLeft size={36} color="#ffffff" />
            </div> : null}
            <p className="pl-5 first-letter:uppercase">{title}</p>
        </div>
    )
}
