import React from 'react'

export default function Designs({ setdesign }) {
    return (
        <div className=''>
            <div className="mt-2">
                <p className="tracking-wide text-white text-opacity-60 font-light">Designs</p>
                <div className="grid grid-cols-2 gap-5 mt-3">
                    <div className="">
                        <button onClick={() => setdesign(1)} className="w-full aspect-[9/16] border border-[#3D3D3D] text-[#666666]">
                            1080x1920
                        </button>
                        <div className=" text-[#cacaca]">Default</div>
                    </div>
                    <div className="">
                        <button onClick={() => setdesign(2)} className="w-full aspect-[9/16] border border-[#3D3D3D] text-[#666666]">
                            1080x1920
                        </button>
                        <div className=" text-[#cacaca]">Instagram Story / Reel</div>
                    </div>
                    <div className="">
                        <button onClick={() => setdesign(3)} className="w-full aspect-square border border-[#3D3D3D] text-[#666666]">
                            1080x1080
                        </button>
                        <div className=" text-[#cacaca]">Instagram Post</div>
                    </div>
                    <div className="">
                        <button onClick={() => setdesign(4)} className="w-full aspect-[940/788] border border-[#3D3D3D] text-[#666666]">
                            940x788
                        </button>
                        <div className=" text-[#cacaca]">Facebook Post</div>
                    </div>
                    <div className="">
                        <button onClick={() => setdesign(5)} className="w-full aspect-video border border-[#3D3D3D] text-[#666666]">
                            1920x1080
                        </button>
                        <div className=" text-[#cacaca]">Video</div>
                    </div>

                </div>
            </div>
        </div>
    )
}
