import { doc, updateDoc } from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import React from "react";
import { db } from "../firebase";
import { nanoid } from "nanoid";
import Resizer from "react-image-file-resizer";
import { useState } from "react";
import AWN from "awesome-notifications";
import { useStateValue } from "../context/StateProvider";

function LogoSelectorForVideo({ logo, setLogo }) {
  const [{ user }, dispatch] = useStateValue();
  const [selected, setSelected] = useState(null);
  const [loading, setloading] = useState(false);

  var tempLogos = user?.logos ?? [];
  if (tempLogos.length === 0) {
    tempLogos.push(logo);
    tempLogos.push(user?.imageUrl);
  }

  const active =
    "h-12 bg-white p-2 rounded-md transform duration-200 cursor-pointer";
  const passive =
    "h-12 hover:bg-white p-2 rounded-md transform duration-200 cursor-pointer";

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        70,
        770,
        "JPEG",
        90,
        0,
        (uri) => {
          fetch(uri).then((res) => {
            resolve(res.blob());
          });
        },
        "base64",
        30,
        30
      );
    });

  const uploadBrandLogo = async (e) => {
    var file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }
    setloading(true);
    try {
      file = await resizeFile(file);
      const id = user?.brandId + nanoid(4);
      const type = file.type.split("/")[1];
      const storage = getStorage();
      const storageRef = ref(storage, `logos/${id}.${type}`);
      const uploadTask = await uploadBytesResumable(
        storageRef,
        e.target.files[0]
      )
        .then((res) => res.ref)
        .catch((e) => {
          setloading(false);
          return new AWN().warning(e);
        });
      const downloadURL = await getDownloadURL(uploadTask);

      tempLogos = tempLogos.filter((a) => a);
      const newArr = [...tempLogos, downloadURL];
      // console.log(newArr);
      await updateDoc(doc(db, "brandAdmin", user?.email), {
        logos: newArr,
      }).then((res) => {
        dispatch({ type: "SET_USER", user: { ...user, logos: newArr } })
        // setUser({ ...user, logos: newArr });
        setloading(false);
        return new AWN().success("Logo added");
      });
      setloading(false);
    } catch (e) {
      setloading(false);
      console.log(e);
      return new AWN().warning(e);
    }
  };

  if (loading) {
    return (
      <div className="absolute hideOut-normal w-full h-full top-0 left-0  bg-black bg-opacity-70 flex items-center flex-col justify-center">
        <i className="fas fa-spinner animate-spin"></i>
      </div>
    );
  } else
    return (
      <div className="absolute hideOut-normal w-full h-full top-0 left-0  bg-black bg-opacity-70 flex items-center flex-col justify-center">
        <div className="flex flex-wrap m-5 gap-5 bg-white bg-opacity-40 p-4 rounded-md">
          {tempLogos.map((logo, index) => (
            <img
              onClick={() => setSelected(logo)}
              src={logo}
              alt="img"
              key={"logo" + index}
              className={selected === logo ? active : passive}
            />
          ))}
          <input
            type="file"
            className="hidden"
            id="uploadLogo"
            onChange={uploadBrandLogo}
            accept="image/*"
          />
          <label htmlFor="uploadLogo">
            <div className="h-12  p-3 text-black rounded-md hover:bg-white  transform duration-200 cursor-pointer">
              <i className="fas fa fa-upload  fa-lg"></i>
              <span className="text-xs px-2">Upload New</span>
            </div>
          </label>
        </div>
        {selected ? (
          <button
            onClick={() => setLogo(selected)}
            className="hideOut-normal bg-[#8ea9fa] h-10 text-center cursor-pointer select-none text-white rounded m-1"
          >
            Add this
          </button>
        ) : null}
      </div>
    );
}

export default LogoSelectorForVideo;
