import { List, X } from "@phosphor-icons/react";
import React from "react";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";

function NotLoginHeaderNew() {
  const router = useHistory();
  const [menuopen, setmenuopen] = useState(false)
  return (
    <>
      {menuopen === true && <div className="absolute h-screen w-full bg-[#101010] z-[100] p-5 flex flex-col justify-between pb-10">
        <div className="flex justify-between items-center">
          <Link to="/">
            <img loading="lazy" src={require("../assets/logo/MyRevue Logo elite new.svg").default} alt="logo" className="h-[51px] w-auto" />
          </Link>
          <X size={32} color="white" onClick={() => setmenuopen(false)} />
        </div>
        <div className="flex flex-col items-center gap-5 ">
          <button className="font-medium uppercase px-5 tracking-wide" onClick={() => { router.push("/"); setmenuopen(false) }}>
            HOME
          </button>
          <button className="font-medium uppercase px-5 tracking-wide" onClick={() => { router.push("/whyvideoreviews"); setmenuopen(false) }}>
            Why US ?
          </button>
          <button className="font-medium uppercase px-5 tracking-wide" onClick={() => { router.push("/plans"); setmenuopen(false) }}>
            PRICING
          </button>
        </div>
        <div className="flex flex-col items-center space-y-5">
          <button className="font-medium uppercase px-5" onClick={() => { router.push("/login"); setmenuopen(false) }}>
            LOGIN
          </button>
          <button
            className="py-2 px-8 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
            onClick={() => { router.push("/signup"); setmenuopen(false) }}>
            Sign Up for Free
          </button>
        </div>
      </div>}
      <div className="w-full flex items-center justify-between py-5 px-5 md:px-10 ">
        <div className="">
          <Link to="/">
            <img loading="lazy" src={require("../assets/logo/MyRevue Logo elite new.svg").default} alt="logo" className="h-[51px] md:h-[82px] w-auto" />
          </Link>
        </div>
        <div className="md:flex items-center hidden">
          <button className="font-medium uppercase px-5" onClick={() => router.push("/")}>
            HOME
          </button>
          <button className="font-medium uppercase px-5" onClick={() => router.push("/whyvideoreviews")}>
            Why US ?
          </button>
          <button className="font-medium uppercase px-5" onClick={() => router.push("/plans")}>
            PRICING
          </button>
        </div>
        <div className="md:block hidden">
          <button className="font-medium uppercase px-5" onClick={() => router.push("/login")}>
            LOGIN
          </button>
          <button
            className="py-2 px-8 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
            onClick={() => router.push("/signup")}>
            Sign Up for Free
          </button>
        </div>
        <List size={28} color="white" className="md:hidden block" onClick={() => setmenuopen(true)} />
      </div >
    </>
  );
}

export default NotLoginHeaderNew;
