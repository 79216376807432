import axios from "axios";

async function logEngagementHook(data) {
  try {
    let reqOptions = {
      url: "https://adminapi-zscu3untuq-el.a.run.app/engagementHook",
      method: "POST",
      headers: {
        Accept: "*/*",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios.request(reqOptions);
    console.log("logged engagement analytics");

    return true;
  } catch (error) {
    return false;
  }
}

async function getEngagementAnalytics(brand, base) {
  try {
    let reqOptions = {
      url:
        "https://adminapi-zscu3untuq-el.a.run.app/getEngagementAnalytics/" +
        base +
        "/" +
        brand,
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        "Content-Type": "application/json",
      },
    };
    console.log(reqOptions);
    var response = await axios.request(reqOptions);
    console.log(response.data);
    return response.data;
  } catch (error) {
    return null;
  }
}

export { logEngagementHook, getEngagementAnalytics };
