import React from "react";
import { useHistory } from "react-router";
import { useStateValue } from "../context/StateProvider";
import { CalendarCheck, FirstAidKit, GraduationCap, HandHeart, Handshake, IconContext, Plus, Question, ReadCvLogo, UserFocus, UserSquare, Users } from "@phosphor-icons/react";

function ChooseTemplatePage() {
  const [{ point, question }, dispatch] = useStateValue();

  const router = useHistory();

  const handleStartScratch = () => {
    sessionStorage.setItem("points", JSON.stringify([]));
    dispatch({ type: "SET_POINT", point: [] })
    sessionStorage.setItem("questions", JSON.stringify([]));
    dispatch({ type: "SET_QUESTION", question: [] })
    router.push("createCampaign");
  };

  return (
    <>
      <div className="min-h-screen w-full  overflow-y-auto p-5 lg:p-10">
        <div className="bg-[#202020] p-5 lg:p-10 rounded-lg">
          <h1 className="text-center text-3xl font-work">Choose from a range of templates</h1>
          <div className="grid  md:grid-cols-2 lg:grid-cols-4 gap-10 mt-10">
            <div onClick={() => router.push("viewTemplate/customer-clients")} className="group h-[199px] w-[272px] border-2 border-white flex flex-col items-center rounded-lg justify-center gap-2 mx-auto hover:text-black text-white cursor-pointer hover:bg-[#D9D9D9]">
              <Users size={50} color="currentcolor" />
              <p className="text-xl font-work font-medium tracking-wide">Customers & clients</p>
              <p className="text-sm font-work tracking-wide">13 templates</p>
            </div>
            <div onClick={() => router.push("viewTemplate/employees")} className="group h-[199px] w-[272px] border-2 border-white flex flex-col items-center rounded-lg justify-center gap-2 mx-auto hover:text-black text-white cursor-pointer hover:bg-[#D9D9D9]">
              <UserSquare size={50} color="currentcolor" />
              <p className="text-xl font-work font-medium tracking-wide">Employees</p>
              <p className="text-sm font-work tracking-wide">12 templates</p>
            </div>
            <div onClick={() => router.push("viewTemplate/experts-influencers")} className="group h-[199px] w-[272px] border-2 border-white flex flex-col items-center rounded-lg justify-center gap-2 mx-auto hover:text-black text-white cursor-pointer hover:bg-[#D9D9D9]">
              <UserFocus size={50} color="currentcolor" />
              <p className="text-xl font-work font-medium tracking-wide">Experts & influencers</p>
              <p className="text-sm font-work tracking-wide">4 templates</p>
            </div>
            <div onClick={() => router.push("viewTemplate/partners-investors")} className="group h-[199px] w-[272px] border-2 border-white flex flex-col items-center rounded-lg justify-center gap-2 mx-auto hover:text-black text-white cursor-pointer hover:bg-[#D9D9D9]">
              <Handshake size={50} color="currentcolor" />
              <p className="text-xl font-work font-medium tracking-wide">Partners & investors</p>
              <p className="text-sm font-work tracking-wide">2 templates</p>
            </div>
            <div onClick={() => router.push("viewTemplate/education")} className="group h-[199px] w-[272px] border-2 border-white flex flex-col items-center rounded-lg justify-center gap-2 mx-auto hover:text-black text-white cursor-pointer hover:bg-[#D9D9D9]">
              <GraduationCap size={50} color="currentcolor" />
              <p className="text-xl font-work font-medium tracking-wide">Education</p>
              <p className="text-sm font-work tracking-wide">4 templates</p>
            </div>
            <div onClick={() => router.push("viewTemplate/events")} className="group h-[199px] w-[272px] border-2 border-white flex flex-col items-center rounded-lg justify-center gap-2 mx-auto hover:text-black text-white cursor-pointer hover:bg-[#D9D9D9]">
              <CalendarCheck size={50} color="currentcolor" />
              <p className="text-xl font-work font-medium tracking-wide">Events</p>
              <p className="text-sm font-work tracking-wide">4 templates</p>
            </div>
            <div onClick={() => router.push("viewTemplate/medical")} className="group h-[199px] w-[272px] border-2 border-white flex flex-col items-center rounded-lg justify-center gap-2 mx-auto hover:text-black text-white cursor-pointer hover:bg-[#D9D9D9]">
              <FirstAidKit size={50} color="currentcolor" />
              <p className="text-xl font-work font-medium tracking-wide">Medical</p>
              <p className="text-sm font-work tracking-wide">3 templates</p>
            </div>
            <div onClick={() => router.push("viewTemplate/nonprofits")} className="group h-[199px] w-[272px] border-2 border-white flex flex-col items-center rounded-lg justify-center gap-2 mx-auto hover:text-black text-white cursor-pointer hover:bg-[#D9D9D9]">
              <HandHeart size={50} color="currentcolor" />
              <p className="text-xl font-work font-medium tracking-wide">Non-profit</p>
              <p className="text-sm font-work tracking-wide">3 templates</p>
            </div>
          </div>

        </div>
        <div className="mt-10">
          <div onClick={() => handleStartScratch()} className="w-full  mx-auto max-w-[571px] h-[244px] border-2 border-white flex flex-col items-center rounded-lg justify-center gap-2 bg-[#202020]  hover:text-black text-white cursor-pointer hover:bg-[#D9D9D9]">
            <Plus size={80} color="currentcolor" />
            <p className="text-xl font-work">Start from scratch</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChooseTemplatePage;
