import React, { useState } from "react";
import { useRef } from "react";
import { db } from "../firebase";
import { addDoc, collection } from "firebase/firestore";

function ContactForm() {
  var name = useRef();
  var brand = useRef();
  var job = useRef();
  var email = useRef();
  var phone = useRef();
  var subject = useRef();
  var submitButton = useRef();
  var budgetRef = useRef();
  var websiteRef = useRef();

  const [submitted, setSubmitted] = useState(false);

  const submitForm = () => {
    if (submitted) return;
    if (!name.current.value) {
      name.current.style.border = "2px solid red";
      return;
    }
    name.current.style.border = "none";
    if (!brand.current.value) {
      brand.current.style.border = "2px solid red";
      return;
    }
    brand.current.style.border = "none";
    if (!job.current.value) {
      job.current.style.border = "2px solid red";
      return;
    }
    job.current.style.border = "none";
    if (!email.current.value?.includes("@")) {
      email.current.style.border = "2px solid red";
      return;
    }
    email.current.style.border = "none";

    if (!websiteRef.current.value?.includes("@")) {
      websiteRef.current.style.border = "2px solid red";
      return;
    }
    websiteRef.current.style.border = "none";

    if (!phone.current.value) {
      phone.current.style.border = "2px solid red";
      return;
    }
    phone.current.style.border = "none";
    if (budgetRef.current.value === "choose") {
      budgetRef.current.style.border = "2px solid red";
      return;
    }
    budgetRef.current.style.border = "none";

    var lead = {
      name: name.current.value,
      brand: brand.current.value,
      job: job.current.value,
      email: email.current.value,
      phone: phone.current.value,
      objective: subject.current.value,
      website: websiteRef.current.value,
      budget: budgetRef.current.value,
    };

    addDoc(collection(db, "brandLead"), lead).then((d) => {
      if (d.id) {
        name.current.value = "";
        brand.current.value = "";
        job.current.value = "";
        email.current.value = "";
        phone.current.value = "";
        subject.current.value = "";

        submitButton.current.innerText = "Submitted";
        setSubmitted(true);
      }
    });
  };

  return (
    <div className="w-[500px] rounded-xl bg-white h-full py-5 px-3 lg:px-8 flex flex-col items-center gap-4 justify-center">
      <p className="m-0 text-2xl text-center font-bold text-[#FF005C] ">
        Experience the Impact of MyRevue Elite Today.
      </p>
      <input
        type="text"
        ref={name}
        placeholder="Full Name"
        className="rounded-full p-2 px-3 appearance-none border-none bg-gray-200 text-black w-full h-14 "
      />
      <input
        type="text"
        ref={brand}
        placeholder="Brand Name"
        className="rounded-full p-2 px-3 appearance-none border-none bg-gray-200 text-black w-full h-14 "
      />
      <input
        type="text"
        ref={job}
        placeholder="Job Title"
        className="rounded-full p-2 px-3 appearance-none border-none bg-gray-200 text-black w-full h-14 "
      />
      <input
        type="text"
        ref={email}
        placeholder="Work Email Address"
        className="rounded-full p-2 px-3 appearance-none border-none bg-gray-200 text-black w-full h-14 "
      />
      <input
        type="number"
        maxLength={10}
        ref={phone}
        placeholder="phone number"
        className="rounded-full p-2 px-3 appearance-none border-none bg-gray-200 text-black w-full h-14 "
      />
      <input
        type="text"
        placeholder="Objective"
        ref={subject}
        className="rounded-full p-2 px-3 appearance-none border-none bg-gray-200 text-black w-full h-14 "
      />
      <input
        type="text"
        placeholder="website Url"
        ref={websiteRef}
        className="rounded-full p-2 px-3 appearance-none border-none bg-gray-200 text-black w-full h-14 "
      />
      <select
        className="rounded-full p-2 px-3 appearance-none border-none bg-gray-200 text-black w-full h-14 "
        name="budget"
        ref={budgetRef}
        id=""
      >
        <option value="choose">Campaign Budget</option>
        <option value="<25K"> under 25K</option>
        <option value="25K-50K"> 25K - 50K</option>
        <option value="50K-100K"> 50K-100K</option>
        <option value="100K <"> more than 100K </option>
      </select>
      <button
        onClick={submitForm}
        ref={submitButton}
        className="h-14 rounded-full bg-[#FF005C] w-32"
      >
        Submit
      </button>
    </div>
  );
}

export default ContactForm;
