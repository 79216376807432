import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AWN from "awesome-notifications";
import {
  Checks,
  Copy,
  DownloadSimple,
  FileCsv,
  ShieldSlash,
  Video,
} from "@phosphor-icons/react";
import { toast } from "react-toastify";
import QRCodeStyling from "qr-code-styling";
import { db } from "../firebase";
import { sendAmplitudeData } from "../amplitude";
import Modalcard from "../components/modalcard";
import { frequencies } from "./challengeConstants";
import { UserCircle } from "phosphor-react";
import BlobVideoPlayer from "../components/bloburlconverter";

function ViewChallenge() {
  const [challenges, setchallenges] = useState([]);
  const [unfilteredChallenge, setUnfiltered] = useState([]);
  const [form, setform] = useState({});
  const [showaddreviews, setshowaddreviews] = useState(false);
  const [usersub, setUsersub] = useState(null);
  const [loading, setloading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const submissionsRef = collection(db, `challenges/${id}/submissions`);
    const challengeDocRef = doc(db, `challenges/${id}`);

    Promise.all([
      getDoc(challengeDocRef),
      getDocs(submissionsRef)
    ]).then(([docSnapshot, submissionsSnapshot]) => {
      const challengeData = docSnapshot.data();
      const submissionsData = submissionsSnapshot.docs.map(sub => sub.data());

      const userResponsesMap = new Map();
      const unfiltered = [];

      submissionsData.forEach(sub => {
        unfiltered.push(sub);

        const userId = sub?.user_info?.id;
        const existingUser = userResponsesMap.get(userId);

        if (existingUser) {
          existingUser.responses += 1;
        } else {
          userResponsesMap.set(userId, { ...sub.user_info, responses: 1 });
        }
      });

      setform(challengeData);
      setchallenges(Array.from(userResponsesMap.values()));
      setUnfiltered(unfiltered);
      setloading(false);
    });

  }, []);


  const copy = () => {
    navigator?.clipboard?.writeText(
      window.location.host + "/submitChallenge/" + id
    );
    showSuccessMsg("Link copied to clipboard");
  };

  async function exportCsv() {
    var csvString = challenges.reduce((total, curr) => {
      var row = [
        curr.name,
        curr.email ?? "-",
        curr.phone ?? "-",
        curr.responses + "/" + form.videoPerUser,
        curr.responses === form.videoPerUser ? "Completed" : "Dropped",
      ].join(",");
      return total + "\n" + row;
    }, "name,email,phone,submissions,status");

    const data = `data:,${csvString}`;
    const filename = `${form?.campaign_name || form?.productname}.csv`;
    const aTag = document.createElement("a");

    aTag.href = data;
    aTag.download = filename;
    aTag.click();
  }

  async function showSuccessMsg(msg) {
    toast.success(msg, {
      position: "top-right",
      icon: <Checks size={32} color="green" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
  }
  async function showWarningMsg(msg) {
    toast.warning(msg, {
      position: "top-right",
      icon: <ShieldSlash size={32} color="red" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
  }

  const qrCode = new QRCodeStyling({
    width: 1000,
    height: 1000,
    image:
      "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue%20only%20logo.png?alt=media&token=d1d344f7-ce12-44c1-826d-3963af17ad94",
    data: "http://brand.myrevue.app/userView/" + id,
    margin: 20,
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "Q" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: { type: "extra-rounded", color: "#000000" },
    backgroundOptions: { color: "#ffffff" },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0",
      },
    },
    cornersSquareOptions: { type: "extra-rounded", color: "#ff005c" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    cornersDotOptions: { type: "", color: "#ff005c" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
      },
    },
  });

  async function downloadQRCode() {
    qrCode.download({
      name: id,
      extension: "png",
    });
  }

  async function startChallenge() {
    var templateCount =
      Number.parseInt(form.duration) / frequencies[form.frequency];
    templateCount = Math.floor(templateCount);

    const gap = 86400000 * [frequencies[form.frequency]];

    const today = Date.now();

    var openDates = Array.from(Array(templateCount)).map((val, index) =>
      new Date(today + gap * index).toDateString()
    );
    console.log(openDates);
    await updateDoc(doc(db, "challenges", form.id), {
      startDate: today,
      openDates: openDates,
    }).then(() => {
      setform({ ...form, startDate: today, openDates: openDates });
      new AWN().success("Challenge Started");
      setshowaddreviews(true);
      return;
    });
  }

  function showUserSubmission() {
    return (
      <Modalcard close={setUsersub}>
        <div className="h-[80vh] w-[90vw] bg-[#232326] overflow-y-auto p-5">
          <div className="pb-5 flex gap-5 items-center border-b">
            <img
              src={usersub.image}
              alt="noimage"
              className="h-20 w-20 object-cover rounded-lg"
            />
            <div className="">
              <p className="text-2xl">Submissions</p>
              <p className="">{usersub.name}</p>
            </div>
          </div>
          <div className="flex flex-wrap gap-3 py-3">
            {unfilteredChallenge
              .filter(
                (challenge) =>
                  challenge.user_info.email === usersub.email ||
                  challenge.user_info.phone === usersub.phone
              )
              .map((ch, index) => (
                <div key={"usersub" + index} className="">
                  <p className="">{new Date(ch.dateAdded).toDateString()}</p>
                  {/* <video
                    className="rounded h-[40vh] aspect-[9/16]"
                    src={ch.reviewUrl}
                    onClick={(e) =>
                      e.currentTarget.paused ? e.target.play() : e.target.pause()
                    }
                  ></video> */}
                  <BlobVideoPlayer videoUrl={ch.reviewUrl} width={301} />
                </div>
              ))}
          </div>
        </div>
      </Modalcard>
    );
  }

  function revealTemplate() {
    var today = new Date();
    today = today.toDateString();
    return (
      <Modalcard close={setshowaddreviews}>
        <div className="h-[80vh] w-[90vw] bg-[#232326] overflow-y-auto p-5">
          <div className="pb-5">
            <p className="text-2xl">Template</p>
            <p className="">message template {"(Click To Copy)"}</p>
          </div>

          <div className=" flex flex-wrap gap-5">
            {form.openDates.map((c, index) => (
              <div key={"template" + index}>
                <p className="my-1 text-xs">{c}</p>
                <div
                  className={
                    today !== c
                      ? "bg-[#29362e] rounded-md p-2"
                      : "bg-[#dcf8c6] rounded-md p-2"
                  }
                  style={{ maxWidth: "300px" }}
                >
                  <p
                    className="text-black"
                    onClick={(e) => {
                      if (today !== c) {
                        showWarningMsg(
                          "today's date isn't matched with template date."
                        );
                        return;
                      }
                      navigator.clipboard.writeText(e.target.innerText);
                      showSuccessMsg("Copied");
                    }}
                  >
                    Hey hie you recently bought product from us, would you like
                    to do a 30 days challenge by reviewing product benefits per{" "}
                    {form.frequency}.
                    <br />
                    <br />
                    If yes use this link to submit review :<br />
                    <a
                      href={window?.location?.host + "/submitChallenge/" + id}
                      className="text-blue-600 break-words"
                    >
                      {window?.location?.host + "/submitChallenge/" + id}
                    </a>
                    <br />
                    <br />
                    no worry... Rewards awaiting.
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modalcard>
    );
  }

  return (
    <div className=" min-h-screen text-white scroll-smooth ">
      {showaddreviews === true && revealTemplate()}
      {usersub ? showUserSubmission() : null}
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <p className="">Gettings your submissions ready </p>
        </div>
      ) : (
        <div className="flex items-start justify-between p-10  gap-10">
          <div className="w-full flex-1">
            <div className="flex-1 flex items-center justify-between">
              <div className="rounded-md h-28 w-28">
                <img
                  src={form?.productimage}
                  alt="product_image"
                  className="rounded-md"
                />
              </div>
              <div className="bg-[#3B3D42] h-auto rounded-lg drop-shadow-lg w-full flex-1 flex flex-col justify-center items-center px-5 ml-10 gap-3 py-5">
                <p className="text-center text-[#D9D9D9] tracking-wide">
                  Shareable link:
                </p>
                <div className="flex items-center gap-5">
                  <div className="items-center bg-[#2E3036] w-fit flex gap-10 rounded-full pl-10">
                    <p>{window?.location?.host + "/submitChallenge/" + id}</p>
                    <button
                      className="hover:bg-green-500 bg-opacity-80 p-2 m-1 rounded-full outline-none appearance-none"
                      onClick={() => copy()}
                    >
                      <Copy size={28} color="white" />
                    </button>
                  </div>
                  <button
                    className="bg-[#2E3036] hover:bg-blue-500 transform duration-100 bg-opacity-80 p-2 m-1 rounded-full outline-none appearance-none flex items-center gap-3 px-5"
                    onClick={() => downloadQRCode()}
                  >
                    <DownloadSimple size={28} color="white" />
                    Download QR Code
                  </button>
                </div>
              </div>
            </div>
            <div
              className={
                !form.startDate
                  ? "h-96 flex items-center flex-col justify-center gap-8  shadow-md rounded-md my-5  bg-[#2E3036] "
                  : "hidden"
              }
            >
              <p className="text-center px-5">
                Engage Your Audience with Daily Video Challenges: Empower your
                users to participate in a dynamic video campaign. Implement the
                'Start Challenge Today' button on your business dashboard and
                watch as daily uploads drive engagement, growth, and brand
                loyalty.
              </p>
              <button
                className="bg-[#438B4E] hover:bg-[#3baa4c]  transform duration-100 flex items-center gap-3 h-14 rounded-full px-5 w-fit"
                onClick={() => startChallenge()}
              >
                Start Challenge Today
              </button>
            </div>

            <div className="bg-[#383A3F] rounded min-h-full w-full my-3 p-2">
              <div
                className={
                  challenges.length === 0
                    ? "h-96 flex justify-center items-center"
                    : "hidden"
                }
              >
                <p className="text-center">Waiting for Response...</p>
              </div>
              <table
                className={
                  challenges.length === 0 ? "hidden" : "w-full table table-auto"
                }
              >
                <thead>
                  <tr className="h-12">
                    <th>SL NO.</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>submissions</th>
                    <th>status</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {challenges.map((user_info, index) => (
                    <tr key={"user_info" + index} className="">
                      <td>{index + 1}</td>
                      <td>{user_info.name}</td>
                      <td>{user_info.email ?? "-"}</td>
                      <td>{user_info.phone ?? "-"}</td>
                      <td className="flex justify-center items-center gap-2">
                        <p className="">
                          {user_info.responses}/{form.videoPerUser}
                        </p>
                        <button onClick={() => setUsersub(user_info)}>
                          <UserCircle size={22} />
                        </button>
                      </td>
                      <td>
                        {user_info.responses === form.videoPerUser ? (
                          <button>Send Reward</button>
                        ) : (
                          <p>waiting</p>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div className="bg-[#383A3F] w-[422px] min-h-full p-5 rounded-lg">
            <div className="flex  justify-between py-2 w-full items-center">
              <p className="">
                <span className="text-xs">Created</span> <br />
                <span> {new Date(form.dateAdded).toDateString()}</span>
              </p>
              <p className="text-right">
                <span className="text-xs">Started</span> <br />
                <span>
                  {form.startDate
                    ? new Date(form.startDate).toDateString()
                    : "Not Started yet"}
                </span>
              </p>
            </div>
            <p className="">
              <span className="text-xs">End Date</span> <br />
              <span>
                {form?.openDates
                  ? form?.openDates[form?.openDates?.length - 1]
                  : 0}
              </span>
            </p>
            <div className="flex flex-col items-center w-full gap-5">
              <button
                className="bg-[#2E3036] hover:bg-[#151516] transform duration-100 flex items-center gap-3 h-14 rounded-full px-5 w-fit"
                onClick={() => {
                  if (form.startDate) setshowaddreviews(true);
                  else {
                    showWarningMsg("Start challenge first");
                  }
                }}
              >
                <Video size={28} color="#ffffff" />
                Message Template
              </button>
            </div>
            <div className="w-full mt-5">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center">Challange Details</p>
              </div>
              <div className="">
                <p className="mt-2  tracking-wider text-sm">
                  Challange Title :
                  {doc?.campaign_info?.campaign_name ?? form?.campaign_name}
                </p>
                <p className="mt-2  tracking-wider text-sm">
                  Product Name : {form?.productname}
                </p>
                <p className="mt-2  tracking-wider text-sm">
                  Video Submissions :
                  {challenges.filter((val) => val.reviewUrl !== null).length}
                </p>
              </div>
            </div>
            <div className="mt-5 text-center ">
              <div className="border-b pb-3 border-[#D9D9D9]">
                <p className="tracking-wide text-center">Export Data</p>
              </div>
              <p className="flex justify-center">
                <button
                  onClick={exportCsv}
                  className="bg-[#438B4E] hover:bg-[#25da40] my-3 transform duration-100 flex items-center gap-3 h-14 rounded-full px-5 w-fit"
                >
                  <FileCsv size={28} color="#ffffff" />
                  Generate CSV
                </button>
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewChallenge;
