import { ArrowRight, Check, Eye, Hexagon, Pencil, ShieldSlash, Trash, UploadSimple } from '@phosphor-icons/react';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useStateValue } from '../context/StateProvider';
import { addDoc, collection, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import axios from 'axios';

export default function CreateEmail() {
    const router = useHistory();
    const [{ brand, user }, dispatch] = useStateValue();
    const [flowStep, setFlowStep] = useState(0);
    const [templatesTab, setTemplatesTab] = useState(false);
    const [templateSelected, setTemplateSelected] = useState(null);
    const [processedData, setProcessedData] = useState([]);
    const [processedDataHeader, setProcessedDataHeader] = useState([]);
    const [templates, setTemplates] = useState([]);

    const [campaignData, setCampaignData] = useState({
        campaignName: "",
        subject: "",
        senderName: "",
        senderEmailAddress: "",
        replyToEmailAddress: "",
    });

    useEffect(() => {
        getDocs(collection(db, "brandEmailTemplates"), where("email", "==", user.email)).then((res) => {
            const templatesWithIds = res.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setTemplates(templatesWithIds);
        })
    }, [])



    async function submitNextFromTemplate() {

        if (!templateSelected) {
            toast.warning("Please select a template", {
                position: "top-right",
                icon: <ShieldSlash size={32} color="red" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return
        }
        setFlowStep(step => step + 1);

    }
    const processData = async (csv) => {
        const lines = csv.trim().split('\n');
        const headers = lines[0].split(',');
        const rows = lines.slice(1);

        const result = [];

        for (const row of rows) {
            const values = row.split(',');
            const rowData = {};
            for (let i = 0; i < headers.length; i++) {
                rowData[headers[i]] = values[i];
            }
            result.push(rowData);
        }

        return result;
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = async (event) => {
            const csvData = event.target.result;
            const jsonData = await processData(csvData);
            setProcessedData(jsonData);
            setProcessedDataHeader(Object.keys(jsonData[0]))
            setFlowStep(step => step + 1)
        };

        reader.readAsText(file);
    };
    async function downloadFile(url, filename) {
        try {
            const response = await fetch(url);
            const blob = await response.blob();

            const anchor = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);

            anchor.href = objectUrl;
            anchor.download = filename || 'sample';

            document.body.appendChild(anchor);
            anchor.click();

            document.body.removeChild(anchor);
            URL.revokeObjectURL(objectUrl); // Clean up the object URL
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    }

    async function downloadSampleCSV() {
        const fileUrl = 'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fsample_files%2Fsample.csv?alt=media&token=6d2da210-0067-4af1-88ff-7a7c3afd5b76';
        const desiredFilename = 'sample.csv';
        downloadFile(fileUrl, desiredFilename);
    }

    async function downloadSampleJSON() {
        const fileUrl = 'https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fsample_files%2Fsample.json?alt=media&token=a908d9e5-267e-4189-a990-eab7a540f106';
        const desiredFilename = 'sample.json';
        downloadFile(fileUrl, desiredFilename);
    }
    async function submitNextFromTable() {
        if (processedData.length === 1) {
            toast.warning("Please select a template", {
                position: "top-right",
                icon: <ShieldSlash size={32} color="red" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return
        }
        setFlowStep(step => step + 1);
    }

    async function submitCampaign() {
        const document = {
            campaignName: campaignData.campaignName,
            subject: campaignData.subject,
            senderName: campaignData.senderName,
            senderEmailAddress: campaignData.senderEmailAddress,
            replyToEmailAddress: campaignData.replyToEmailAddress,
            createdBy: user?.email,
            emailList: processedData,
            email: user?.email,
            templateId: templateSelected,
            status: false
        }
        addDoc(collection(db, "brandEmailCampaigns"), document).then(async res => {
            let headersList = {
                "Accept": "*/*",
            }

            let reqOptions = {
                url: "https://massemailer-zscu3untuq-as.a.run.app/sendEmailsToUser/" + res.id,
                method: "GET",
                headers: headersList,
            }

            let response = axios.request(reqOptions);
            console.log(response.data);
            router.push("/admin/emails?tab=past")
        })
    }

    return (
        <div className="bg-[#2D3037]">
            {flowStep === 0 && <div className='h-screen overflow-y-auto relative pb-32'>
                <div className="fixed z-20 bottom-0  h-32 bg-gradient-to-b from-transparent via-[#12121290] to-[#121212] flex items-center justify-center w-[calc(100%-240px)]">
                    <button onClick={() => submitNextFromTemplate()} className="bg-[#93A8F4] hover:bg-[#4c67c5] h-11 px-6 min-w-[150px] rounded-md appearance-none outline-none">
                        Next
                    </button>
                </div>
                <p className="text-center text-2xl mt-10">Select a template</p>
                <div className='w-full px-5'>
                    <div className="relative block mt-10 mb-10 ">
                        <div className="relative">
                            <div className="overflow-x-auto min-h-[0.01%]">
                                <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                                    <thead className="h-14 bg-[#131418]">
                                        <tr>
                                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                #
                                            </th>
                                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                Title
                                            </th>
                                            <th className=" font-normal border-b-0 p-[15px]  text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                Status
                                            </th>
                                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-row-group align-middle border-inherit">
                                        {templates?.map((template, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="table-row-spacer"></tr>
                                                <tr
                                                    className={`table-row align-[inherit]  bg-[#131418] ${templateSelected === template.id ? " border-green-500 border" : ""}`}
                                                    onClick={() => setTemplateSelected(template.id)}
                                                >
                                                    <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                        {index + 1}
                                                    </td>
                                                    <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                                                        {template?.templateName}
                                                    </td>
                                                    <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                                                        {template?.status === true ? <span className="flex gap-2 items-center"> <div className="h-2 w-2 rounded-full bg-green-500"></div> Published</span> : <span className="flex gap-2 items-center"> <div className="h-2 w-2 rounded-full bg-[#D88D63]"></div> Pending</span>}
                                                    </td>
                                                    <td
                                                        className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                                                        onClick={() =>
                                                            setTemplateSelected(template.id)
                                                        }
                                                    >
                                                        <div className="flex items-center gap-x-3">
                                                            <div className="h-10 w-10 flex items-center justify-center relative">
                                                                <ArrowRight size={40} weight="light" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                {templates.length === 0 && <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                                    No templates Available
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="mt-10 mx-10">
                    <div className="h-[52px] w-fit mx-auto mt-10">
                        <div className="bg-[#D9D9D9] h-full p-[2px] rounded-full w-fit flex items-center text-black ">
                            <button onClick={() => setTemplatesTab(false)} className={`w-fit px-6 h-full flex items-center justify-center gap-2 tracking-wide transition-all  ${templatesTab === false ? "bg-[#131418] text-white" : null} rounded-full`}>
                                <p className="">Built in templates</p>
                            </button>
                            <button onClick={() => setTemplatesTab(true)} className={`w-fit px-6 h-full flex items-center justify-center gap-2 tracking-wide transition-all  ${templatesTab === true ? "bg-[#131418] text-white" : null} rounded-full`}>
                                <p className="">My Templates</p>
                            </button>
                        </div>
                    </div>
                </div> */}
                {/* <div className="mt-10 mx-10">
                    {templatesTab === false && <div className='flex gap-10 flex-wrap'>
                        <div className="relative  w-[278px] cursor-pointer group">
                            <div className={`h-[322px] w-[278px] border-[2px] border-transparent bg-white text-black text-5xl font-extralight flex items-center justify-center hover:border-blue-500 rounded-md`}>
                                +
                            </div>
                            <div className="mt-3">
                                <p className="truncate tracking-wide">Blank</p>
                            </div>
                        </div>
                        {builtInTemplates?.map((template, index) => (
                            <div className="relative  w-[278px] cursor-pointer group" onClick={() => template?.id === templateSelected?.id ? setTemplateSelected(null) : setTemplateSelected(template)} key={index}>
                                <div className={`h-[322px] w-[278px] border-[2px] border-transparent  hover:border-blue-500 rounded-md  ${templateSelected?.id === template?.id ? "border-blue-500" : ""}`}>
                                    <img src={template?.previewUrl} alt="preview" className={`rounded-md h-full w-full object-cover object-top group-hover:grayscale ${templateSelected?.id === template?.id ? "grayscale" : ""}`} />
                                </div>
                                <div className="mt-3">
                                    <p className="truncate tracking-wide">{template?.name}</p>
                                </div>
                            </div>
                        ))}
                    </div>}
                    {templatesTab === true && <div className='flex gap-10 flex-wrap'>
                        <div className="relative  w-[278px] cursor-pointer group">
                            <div className={`h-[322px] w-[278px] border-[2px] border-transparent bg-white text-black text-5xl font-extralight flex items-center justify-center hover:border-blue-500 rounded-md`}>
                                +
                            </div>
                            <div className="mt-3">
                                <p className="truncate tracking-wide">Blank</p>
                            </div>
                        </div>
                        {myTemplates?.map((template, index) => (
                            <div className="relative  w-[278px] cursor-pointer group" onClick={() => template?.id === templateSelected?.id ? setTemplateSelected(null) : setTemplateSelected(template)} key={index}>
                                <div className={`h-[322px] w-[278px] border-[2px] border-transparent  hover:border-blue-500 rounded-md  ${templateSelected?.id === template?.id ? "border-blue-500" : ""}`}>
                                    <img src={template?.previewUrl} alt="preview" className={`rounded-md h-full w-full object-cover object-top group-hover:grayscale ${templateSelected?.id === template?.id ? "grayscale" : ""}`} />
                                </div>
                                <div className="mt-3">
                                    <p className="truncate tracking-wide">{template?.name}</p>
                                </div>
                            </div>
                        ))}
                    </div>}
                </div> */}
            </div>}
            {flowStep === 1 && <div className='h-screen overflow-y-auto relative'>
                {/* <div className="fixed z-20 bottom-0  h-32 bg-gradient-to-b from-transparent via-[#12121290] to-[#121212] flex items-center justify-center w-[calc(100%-240px)]">
                    <button onClick={() => submitNextFromTemplate()} className="bg-[#93A8F4] hover:bg-[#4c67c5] h-11 px-6 min-w-[150px] rounded-md appearance-none outline-none">
                        Next
                    </button>
                </div> */}
                <div className="flex-1 h-full w-full flex flex-col items-center justify-center">
                    <p className="text-center text-2xl ">Upload Customer List</p>

                    <div className="h-64  bg-[#131416] max-w-[700px] w-full mx-auto mt-10 text-xl gap-5 tracking-wide relative font-light rounded-md flex flex-col items-center justify-center">
                        <input onChange={(event) => handleFileChange(event)} type="file" accept='.json,.csv' className="absolute h-full w-full rounded-md z-10 opacity-0" />
                        <UploadSimple size={48} color='white' />
                        Upload CSV or jSON
                        {/* <div className="px-5 w-full">
                            <div className="h-2 w-full bg-[#f6f6f6] rounded-full relative ">
                                <div className="absolute bg-[#93A8F4] h-full rounded-full" style={{ width: "50%" }}></div>
                            </div>
                        </div> */}
                    </div>
                    <div className="mt-5 tracking-wide">Sample files <span className="hover:underline hover:underline-offset-2 cursor-pointer" onClick={() => downloadSampleCSV()}>.csv</span> or <span className="hover:underline hover:underline-offset-2 cursor-pointer" onClick={() => downloadSampleJSON()}>.json</span></div>
                </div>
            </div>}
            {flowStep === 2 && <div className='h-screen overflow-y-auto relative'>
                <div className="fixed z-20 bottom-0  h-32 bg-gradient-to-b from-transparent via-[#12121290] to-[#121212] flex items-center justify-center w-[calc(100%-240px)]">
                    <button onClick={() => submitNextFromTable()} className="bg-[#93A8F4] hover:bg-[#4c67c5] h-11 px-6 min-w-[150px] rounded-md appearance-none outline-none">
                        Next
                    </button>
                </div>
                <div className="relative block mt-10 mb-10 px-5">
                    <div className="relative">
                        <div className="overflow-x-auto min-h-[0.01%]">
                            <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                                <thead className="h-14 bg-[#131418]">
                                    <tr>
                                        {processedDataHeader.map((header, index) => (
                                            <th key={index} className="first-letter:uppercase font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                {header}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="table-row-group align-middle border-inherit">
                                    {processedData.map((row, rowIndex) => (
                                        <React.Fragment key={rowIndex}>
                                            <tr className="table-row-spacer"></tr>
                                            <tr
                                                className="table-row align-[inherit]  bg-[#131418]"
                                            >
                                                {processedDataHeader.map((header, cellIndex) => (
                                                    <td key={cellIndex} className="first-letter:uppercase max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                        {row[header]}
                                                    </td>
                                                ))}
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                            {processedData.length === 0 && <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                                No fields Available
                            </div>}
                        </div>
                    </div>
                </div>
            </div>}
            {flowStep === 3 && <div className='h-screen overflow-y-auto relative p-10'>
                <h1 className='text-2xl '>Add Campaign Details</h1>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 w-full">
                    <div className="relative w-full ">
                        <input className="text-white tracking-wide w-full flex-1 bg-transparent appearance-none outline-none placeholder:text-[#808080]" placeholder='Email Campaign Name' value={campaignData.campaignName} onChange={(event) => setCampaignData({ ...campaignData, campaignName: event.target.value })} />
                    </div>
                </div>
                <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Audience</p>
                    <div className="relative w-full col-span-2">
                        <input className="text-white tracking-wide min-w-full flex-1 bg-transparent appearance-none outline-none" readOnly type='text' placeholder='Audience count' defaultValue={processedData.length} />
                    </div>
                </div>
                <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Subject</p>
                    <div className="relative w-full col-span-2">
                        <input className="text-white tracking-wide min-w-full flex-1 bg-transparent appearance-none outline-none" type='text' placeholder='Email subject' value={campaignData.subject} onChange={(event) => setCampaignData({ ...campaignData, subject: event.target.value })} />
                    </div>
                </div>
                <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Sender name</p>
                    <div className="relative w-full col-span-2">
                        <input className="text-white tracking-wide min-w-full flex-1 bg-transparent appearance-none outline-none" type='text' placeholder='Sender name' value={campaignData.senderName} onChange={(event) => setCampaignData({ ...campaignData, senderName: event.target.value })} />
                    </div>
                </div>
                <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Sender email address</p>
                    <div className="relative w-full col-span-2">
                        <input className="text-white tracking-wide min-w-full flex-1 bg-transparent appearance-none outline-none" type='text' placeholder='Sender email address' value={campaignData.senderEmailAddress} onChange={(event) => setCampaignData({ ...campaignData, senderEmailAddress: event.target.value })} />
                    </div>
                </div>
                <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Reply to email address (optional)</p>
                    <div className="relative w-full col-span-2">
                        <input className="text-white tracking-wide min-w-full flex-1 bg-transparent appearance-none outline-none" type='text' placeholder='Reply to email address' value={campaignData.replyToEmailAddress} onChange={(event) => setCampaignData({ ...campaignData, replyToEmailAddress: event.target.value })} />
                    </div>
                </div>
                <div className="border-b border-[#5C5C5D] mt-8 pb-2 grid grid-cols-3">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Created by</p>
                    <div className="relative w-full col-span-2">
                        <input className="text-white tracking-wide min-w-full flex-1 bg-transparent appearance-none outline-none" type='text' placeholder='Created by' readOnly defaultValue={user?.email} />
                    </div>
                </div>
                {/* <div className="mt-8">
                    <p className="font-medium text-[#747474] tracking-wide w-full">Content</p>
                    <div className="border border-[#5C5C5D] h-60 w-full rounded-2xl mt-2">
                    </div>
                </div> */}
                <div className="mt-8 flex justify-center">
                    <button onClick={() => submitCampaign()} className="bg-[#93A8F4] hover:bg-[#4c67c5] h-11 px-6 min-w-[150px] rounded-md appearance-none outline-none">
                        Send Emails
                    </button>
                </div>
            </div>}
        </div>
    )
}
