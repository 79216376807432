import { Eye, Hexagon, PlusCircle, ShieldSlash } from '@phosphor-icons/react';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useStateValue } from '../context/StateProvider';
import { useEffect } from 'react';
import { collection, getDocs, where } from 'firebase/firestore';
import { db } from '../firebase';
import { toast } from 'react-toastify';

export default function Emails() {
    const router = useHistory();
    const [{ brand, user }, dispatch] = useStateValue();
    const [campaigns, setCampaigns] = useState([]);
    const search = useLocation().search
    const searchParams = new URLSearchParams(search);
    const [templates, setTemplates] = useState([]);

    useEffect(() => {
        getDocs(collection(db, "brandEmailTemplates"), where("email", "==", user.email)).then((res) => {
            const templatesWithIds = res.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setTemplates(templatesWithIds);
        })
        getDocs(collection(db, "brandEmailCampaigns"), where("email", "==", user.email)).then((res) => {
            const campaignsWithIds = res.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));
            setCampaigns(campaignsWithIds);
        })
    }, [])

    async function checkSmtp() {
        if (!user.smtp) {
            toast.warning("Please add smtp settings to continue", {
                position: "top-right",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            router.push("/admin/settings?tab=settings")
            return
        }
        router.push("/admin/emails/create")
    }

    return (
        <div className="w-full pt-5 relative min-h-screen">
            <div className="px-5 flex justify-between items-center">
                <p className="text-white text-3xl tracking-wide font-medium">
                    Emails
                </p>
                <button onClick={() => checkSmtp()} className="bg-[#93A8F4] h-12 rounded-lg flex items-center gap-3 px-5 appearance-none outline-none">
                    <PlusCircle size={22} color='#f8f8f8' />
                    Create New Email Campaign
                </button>
            </div>
            <div className="mt-10">
                <div className="grid grid-cols-2 w-full">
                    <button onClick={() => router.push("/admin/emails?tab=past")} className={`pb-3 border-b ${(searchParams.get('tab') === null || searchParams.get('tab')?.includes("past")) ? "border-[#808080] font-medium" : "border-transparent"} text-center tracking-wide text-lg appearance-none outline-none`}>View Past Email Campaigns</button>
                    <button onClick={() => router.push("/admin/emails?tab=templates")} className={`pb-3 border-b ${searchParams.get('tab')?.includes("templates") ? "border-[#808080] font-medium" : "border-transparent"} text-center tracking-wide text-lg appearance-none outline-none`}>Templates</button>
                </div>
            </div>
            <div className="">
                {(searchParams.get('tab') === null || searchParams.get('tab')?.includes("past")) &&
                    <div className="relative block mt-10 mb-10 px-5">
                        <div className="relative">
                            <div className="overflow-x-auto min-h-[0.01%]">
                                <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                                    <thead className="h-14 bg-[#131418]">
                                        <tr>
                                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                #
                                            </th>
                                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                Title
                                            </th>
                                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                No. of participants
                                            </th>
                                            <th className=" font-normal border-b-0 p-[15px]  text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                Status
                                            </th>
                                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-row-group align-middle border-inherit">
                                        {campaigns?.map((campaign, index) => (
                                            <React.Fragment key={index}>
                                                <tr className="table-row-spacer"></tr>
                                                <tr
                                                    className="table-row align-[inherit]  bg-[#131418]"
                                                >
                                                    <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                        {index + 1}
                                                    </td>
                                                    <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                                                        {campaign?.campaignName}
                                                    </td>
                                                    <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                                                        {campaign?.emailList?.length}
                                                    </td>
                                                    <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                                                        {campaign?.status === true ? <span className="flex gap-2 items-center"> <div className="h-2 w-2 rounded-full bg-green-500"></div> Finished</span> : <span className="flex gap-2 items-center"> <div className="h-2 w-2 rounded-full bg-[#D88D63]"></div> In Progress</span>}
                                                    </td>
                                                    <td
                                                        className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                                                        onClick={() =>
                                                            router.push("/admin/emails/viewemailcampaign/" + campaign.id)
                                                        }
                                                    >
                                                        <div className="flex items-center gap-x-3">
                                                            <div className="h-10 w-10 flex items-center justify-center relative">
                                                                <Hexagon size={40} weight="light" />
                                                                <Eye size={20} className="absolute" weight="bold" />
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                                {campaigns.length === 0 && <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                                    No email campaigns Available
                                </div>}
                            </div>
                        </div>
                    </div>
                }
                {searchParams.get('tab')?.includes("templates") &&
                    <div className='w-full px-5'>
                        <div className="mt-10 flex items-center justify-between h-[52px] ">
                            {/* <div className="bg-[#D9D9D9] h-full p-[2px] rounded-full w-fit flex items-center text-black ">
                                <button onClick={() => setTemplatesTab(false)} className={`w-fit px-6 h-full flex items-center justify-center gap-2 tracking-wide transition-all  ${templatesTab === false ? "bg-[#131418] text-white" : null} rounded-full`}>
                                    <p className="">Built in templates</p>
                                </button>
                                <button onClick={() => setTemplatesTab(true)} className={`w-fit px-6 h-full flex items-center justify-center gap-2 tracking-wide transition-all  ${templatesTab === true ? "bg-[#131418] text-white" : null} rounded-full`}>
                                    <p className="">My Templates</p>
                                </button>
                            </div> */}
                            <div className=""></div>
                            <button onClick={() => router.push("/admin/emails/createemailtemplate")} className="bg-[#93A8F4] h-12 rounded-lg flex items-center gap-3 px-5 appearance-none outline-none">
                                <PlusCircle size={22} color='#f8f8f8' />
                                Create Email Template
                            </button>
                        </div>
                        <div className="relative block mt-10 mb-10 ">
                            <div className="relative">
                                <div className="overflow-x-auto min-h-[0.01%]">
                                    <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                                        <thead className="h-14 bg-[#131418]">
                                            <tr>
                                                <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                    #
                                                </th>
                                                <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                    Title
                                                </th>
                                                <th className=" font-normal border-b-0 p-[15px]  text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                    Status
                                                </th>
                                                <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-row-group align-middle border-inherit">
                                            {templates?.map((template, index) => (
                                                <React.Fragment key={index}>
                                                    <tr className="table-row-spacer"></tr>
                                                    <tr
                                                        className="table-row align-[inherit]  bg-[#131418]"
                                                    >
                                                        <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                                            {index + 1}
                                                        </td>
                                                        <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                                                            {template?.templateName}
                                                        </td>
                                                        <td className="max-w-[400px] truncate align-middle h-14 px-[15px] text-sm tracking-wider font-light text-white leading-[1.42857143] table-cell cursor-pointer">
                                                            {template?.status === true ? <span className="flex gap-2 items-center"> <div className="h-2 w-2 rounded-full bg-green-500"></div> Published</span> : <span className="flex gap-2 items-center"> <div className="h-2 w-2 rounded-full bg-[#D88D63]"></div> Pending</span>}
                                                        </td>
                                                        <td
                                                            className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                                                            onClick={() =>
                                                                router.push("/admin/emails/editemailtemplate/" + template.id)
                                                            }
                                                        >
                                                            <div className="flex items-center gap-x-3">
                                                                <div className="h-10 w-10 flex items-center justify-center relative">
                                                                    <Hexagon size={40} weight="light" />
                                                                    <Eye size={20} className="absolute" weight="bold" />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </React.Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                    {templates.length === 0 && <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                                        No templates Available
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
