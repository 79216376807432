import React, { useEffect, useRef, useState } from "react";

export default function GridCard({ data, index, playVideos, mode }) {
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreenChange = () => {
    setIsFullscreen(document.fullscreenElement !== null);
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);
  return (
    <div
      className={`w-[350px] min-h-[622px] ${
        mode === "light" ? "bg-white shadow-sm border" : "bg-[#202020]"
      } rounded-lg flex flex-col`}
      onClick={() => playVideos(index)}
    >
      <div className="h-[359px] w-[350px]">
        {/* <video
                    src={data?.reviewUrl}
                    className={`${isFullscreen ? "h-full w-full " : "h-full w-full object-cover"} rounded-t-lg `}
                    poster={data?.thumbnail}
                    // onClick={(event) => {
                    //     event.currentTarget.paused ? event.currentTarget.play() : event.currentTarget.pause()
                    // }}
                    // controls
                    controlsList='nodownload noplaybackrate'
                    disablePictureInPicture
                ></video> */}
        <img
          src={data?.thumbnail}
          className="h-full w-full object-cover rounded-t-lg"
          alt="thumbnail"
        />
      </div>
      <div className="flex-1 h-full p-5 space-y-4 flex flex-col justify-between">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="23"
            viewBox="0 0 30 23"
            fill="none"
          >
            <path
              d="M24.618 0.451996L29.046 2.666C25.684 7.34 22.24 12.834 22.24 22.51H13.712C13.958 12.588 21.01 3.158 24.618 0.451996ZM11.252 0.451996L15.598 2.666C12.318 7.258 8.792 12.834 8.792 22.51H0.346C0.592 12.588 7.644 3.158 11.252 0.451996Z"
              fill={mode === "light" ? "#121212" : "#D9D9D9"}
            />
          </svg>
          <p
            className={`${
              mode === "light" ? "text-[#121212]" : "text-[#D9D9D9]"
            } text-3xl capitalize mt-4`}
          >
            {data?.name}
          </p>
        </div>
        <div className="flex items-center gap-3 w-full">
          <div className="h-16 w-16 border rounded-full">
            <img
              src={
                data?.user?.imageUrl ??
                `https://ui-avatars.com/api/?size=100&name=${data?.user?.name}`
              }
              alt="profile"
              className="h-full w-full rounded-full object-cover"
            />
          </div>
          <div className="flex-1 w-[70%]">
            <p
              className={`${
                mode === "light" ? "text-[#121212]" : "text-[#D9D9D9]"
              } truncate break-all first-letter:uppercase tracking-wide`}
            >
              {data?.user?.name}
            </p>
            <p
              className={`${
                mode === "light" ? "text-[#121212]" : "text-[#D9D9D9]"
              } truncate break-all first-letter:uppercase tracking-wide`}
            >
              {data?.user?.id}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
