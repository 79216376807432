import { Link, useHistory } from "react-router-dom";
import "swiper/css";
import ReactVisibilitySensor from "react-visibility-sensor";
import NotLoginHeaderNew from "./layouts/NotLoginHeaderNew";
import NotloginfooterNew from "./layouts/NotloginfooterNew";
import { useEffect } from "react";
export default function Saas() {
  const router = useHistory();
  useEffect(() => {
    var title = `Saas | MyRevue - Elite`;
    var metaTitle = `Saas | MyRevue - Elite`;
    var metaDescription = `Take your elevator pitch to new heights by harnessing the power of interactive videos. Join forward-thinking brands as they achieve conversion increases of up to 330%. Easily obtain impressive video testimonials by sharing a simple link. MyRevue Elite offers the flexibility to record videos from any device, while ensuring they align perfectly with your brand through a delightful interface. Effortlessly create personalized video requests using our expertly designed templates. Enhance response rates by including video greetings and incentives. Enjoy the benefits of ad-free video embedding and hosting, and add a touch of customization with your own brand logo and stakeholder names. Elevate your pitch and maximize conversions with the captivating potential of interactive video content.`;
    var metaKeyword = `Brands - MyRevue, Video testimonials, Review collector, Senja, Vocal Video, testimonial tool,customer review,customer testimonial,get video testimonials, collect video testimonials, free video testimonial software,testimonial video content, questions for a testimonial, great testimonial questions, best testimonial commercials, testimonial ads examples india,testimonial survey , b2b testimonials`;

    var ogUrl = `https://brand.myrevue.app/sass`;
    var ogtype = `website`;
    var ogtitle = `Saas | MyRevue - Elite`;
    var ogdescription = `Take your elevator pitch to new heights by harnessing the power of interactive videos. Join forward-thinking brands as they achieve conversion increases of up to 330%. Easily obtain impressive video testimonials by sharing a simple link. MyRevue Elite offers the flexibility to record videos from any device, while ensuring they align perfectly with your brand through a delightful interface. Effortlessly create personalized video requests using our expertly designed templates. Enhance response rates by including video greetings and incentives. Enjoy the benefits of ad-free video embedding and hosting, and add a touch of customization with your own brand logo and stakeholder names. Elevate your pitch and maximize conversions with the captivating potential of interactive video content.`;
    var ogimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fbrandadminhome%2Fhomepage.webp?alt=media&token=f2817158-0d3f-4903-9745-ddcea3a195b7`;


    var twittercard = `summary_large_image`;
    var twitterdomain = `brand.myrevue.app`;
    var twitterurl = `https://brand.myrevue.app/sass`;
    var twittertitle = `Saas | MyRevue - Elite`;
    var twitterdescription = `Take your elevator pitch to new heights by harnessing the power of interactive videos. Join forward-thinking brands as they achieve conversion increases of up to 330%. Easily obtain impressive video testimonials by sharing a simple link. MyRevue Elite offers the flexibility to record videos from any device, while ensuring they align perfectly with your brand through a delightful interface. Effortlessly create personalized video requests using our expertly designed templates. Enhance response rates by including video greetings and incentives. Enjoy the benefits of ad-free video embedding and hosting, and add a touch of customization with your own brand logo and stakeholder names. Elevate your pitch and maximize conversions with the captivating potential of interactive video content.`;
    var twitterimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fbrandadminhome%2Fhomepage.webp?alt=media&token=f2817158-0d3f-4903-9745-ddcea3a195b7`;

    document.title = title
    document.getElementsByTagName('meta')["title"].content = metaTitle;
    document.getElementsByTagName('meta')["description"].content = metaDescription;
    document.getElementsByTagName('meta')["keywords"].content = metaKeyword;


    document.querySelector(`meta[property="og:url"]`).content = ogUrl;
    document.querySelector(`meta[property="og:type"]`).content = ogtype;
    document.querySelector(`meta[property="og:title"]`).content = ogtitle;
    document.querySelector(`meta[property="og:description"]`).content = ogdescription;
    // document.querySelector(`meta[property="og:image"]`).content = ogimage;

    document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
    document.querySelector(`meta[property="twitter:domain"]`).content = twitterdomain;
    document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
    document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
    document.querySelector(`meta[name="twitter:description"]`).content = twitterdescription;
    // document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
  }, [])

  return (
    <div className="h-screen overflow-y-auto bg-[#101010]">
      <NotLoginHeaderNew />
      <div className="md:my-10 grid lg:grid-cols-2 px-5 md:px-10 mx-auto xl:px-24 2xl:32">
        <div className="">
          <p className="text-[#F92147] md:mt-16 uppercase tracking-wide">Rise above the noise!</p>
          <h1 className="text-4xl md:text-[55px] font-semibold leading-tight ">Elevate your elevator pitch to new heights with the power of video.</h1>
          <p className="md:text-lg md:max-w-[70%] tracking-wide font-medium text-[#8E8B8B]">
            Join modern brands increasing conversions with interactive videos up to <span className="text-[#F92147]">330%</span>
          </p>
          <div className="flex items-center mt-5 gap-5 w-full">
            <button
              className=" transition-all duration-300 py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
              onClick={() => router.push("/signup")}>
              Sign Up
            </button>
            <div className="relative pl-5 md:pl-0">
              <button className="font-medium tracking-wide py-2 md:px-12 z-10" onClick={() => router.push("/signup")}>
                Try it for free
              </button>
              <img loading="lazy" src={require("./assets/icons/icons/hero section arrow.svg").default} alt="" className="absolute top-2 -ml-12" />
            </div>
          </div>
        </div>

        <div className="flex-1 w-full flex items-center justify-center mt-24 md:mt-0">
          <img loading="lazy" src={require("./assets/images/Sass/hero.svg").default} alt="" className="" />
        </div>
      </div>
      <div className="my-10 px-5 md:px-10 md:mt-20  mx-auto">
        <h2 className="text-[32px] text-center font-semibold leading-tight mt-16 pb-5">Trusted by...</h2>
        <div className="trusted-slider">
          <div className="trusted-slide-track flex gap-16">
            <div className="trusted-slide">
              <img
                loading="lazy"
                src={require("./assets/logo/brand logo/yatrikart (1).svg").default}
                height="100"
                width="250"
                alt=""
                className=""
              />
            </div>
            <div className="trusted-slide">
              <img
                loading="lazy"
                src={require("./assets/logo/brand logo/Stage.svg").default}
                height="100"
                width="250"
                alt=""
                className="transform scale-[85%]"
              />
            </div>
            <div className="trusted-slide">
              <img loading="lazy" src={require("./assets/logo/brand logo/recooty.svg").default} height="100" width="250" alt="" />
            </div>
            <div className="trusted-slide">
              <img
                loading="lazy"
                src={require("./assets/logo/brand logo/supersourcing.svg").default}
                height="100"
                width="250"
                alt=""
                className=""
              />
            </div>
            <div className="trusted-slide">
              <img
                loading="lazy"
                src={require("./assets/logo/brand logo/bingage.svg").default}
                height="100"
                width="250"
                alt=""
                className="invert"
              />
            </div>
            <div className="trusted-slide">
              <img
                loading="lazy"
                src={require("./assets/logo/brand logo/yatrikart (1).svg").default}
                height="100"
                width="250"
                alt=""
                className=""
              />
            </div>
            <div className="trusted-slide">
              <img
                loading="lazy"
                src={require("./assets/logo/brand logo/Stage.svg").default}
                height="100"
                width="250"
                alt=""
                className="transform scale-[85%]"
              />
            </div>
            <div className="trusted-slide">
              <img loading="lazy" src={require("./assets/logo/brand logo/recooty.svg").default} height="100" width="250" alt="" />
            </div>
            <div className="trusted-slide">
              <img
                loading="lazy"
                src={require("./assets/logo/brand logo/supersourcing.svg").default}
                height="100"
                width="250"
                alt=""
                className=""
              />
            </div>
            <div className="trusted-slide">
              <img
                loading="lazy"
                src={require("./assets/logo/brand logo/bingage.svg").default}
                height="100"
                width="250"
                alt=""
                className="invert"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#171717] mt-10">
        <div className=" w-full px-5 lg:px-10 py-10 pb-20 grid lg:grid-cols-2 gap-10 justify-between mx-auto ">
          <div className={` w-full flex-1 flex flex-col justify-center`}>
            <p className="text-3xl lg:text-5xl font-semibold leading-tight">Get great video <br />testimonials by sharing <span className="hidden lg:inline"><br /></span> a link</p>
            <p className="mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg:max-w-[80%]">
              Using MyRevue Elite, people can record from any device, Designed to match your brand, with a delightful interface.
            </p>
            <button
              className=" transition-all duration-300 w-fit mt-3 py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
              onClick={() => router.push("/signup")}>
              Sign Up
            </button>
          </div>
          <div className="relative">
            <div className="w-full aspect-[797/456] absolute z-10 transform scale-50 lg:scale-100">
              <button className="text-sm py-2 px-6 rounded-full transition-transform duration-500 mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-56 absolute bottom-[-4rem] right-[-12rem]  lg:left-auto lg:bottom-[1.7rem] lg:-right-5">
                Works on any Device
              </button>
              <button className="text-sm py-2 px-6 rounded-full transition-transform duration-500 mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-56 absolute bottom-[-7rem] right-[-10rem]  lg:left-auto lg:bottom-[-1rem] lg:right-5">
                Fully Remote
              </button>
              <button className="text-sm py-2 px-6 rounded-full transition-transform duration-500 mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-56 absolute bottom-[-10rem] right-[-8rem]  lg:left-auto lg:bottom-[-3.7rem] lg:right-16">
                Collect Video Resonses
              </button>
            </div>
            <div className="w-full aspect-[797/456]">
              <video src={"https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fsass%20page%2Fsass1%20(1).mp4?alt=media&token=ce46bca1-3868-4271-8012-781e27b7fe96&_gl=1*1l7cdyk*_ga*MTA3NzI2MjQ5OC4xNjczNTcxNzQw*_ga_CW55HF8NVT*MTY4NTcwNTQ1MS43Ni4xLjE2ODU3MDU3NDUuMC4wLjA."} autoPlay muted loop className="w-full h-full object-cover  rounded-lg"></video>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:mt-10">
        <div className=" w-full px-5 lg:px-10 py-10 lg:pb-20 grid lg:grid-cols-2 gap-10 justify-between mx-auto ">
          <div className="relative order-2 lg:order-1">
            {/* <div className="w-max transform scale-50 lg:scale-100 absolute bottom-0">
              <button className="text-sm py-2 px-6 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-64 absolute lg:bottom-[5rem] bottom-12 left-[7rem] lg:-right-36">
                Ask your pesonal questions
              </button>
              <button className="text-sm py-2 px-6 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-64 absolute lg:bottom-9 -bottom-2 left-10 lg:-right-24">
                Share Points to Cover a Video
              </button>
              <button className="text-sm py-2 px-6 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-64 absolute lg:-bottom-2  -bottom-16 -left-5 lg:-right-12">
                Fully Guided Setup Pages
              </button>
            </div> */}
            <div className="w-full aspect-[797/456] absolute z-10 transform scale-50 lg:scale-100">
              <button className="text-sm py-2 px-6 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-64 absolute bottom-[-4rem] right-[-12rem]  lg:left-auto lg:bottom-[5rem] lg:-right-36">
                Ask your personal questions
              </button>
              <button className="text-sm py-2 px-6 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-64 absolute bottom-[-7rem] right-[-10rem] lg:left-auto  lg:bottom-9 lg:-right-24">
                Share Points to Cover a Video
              </button>
              <button className="text-sm py-2 px-6 rounded-full mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-64 absolute bottom-[-10rem] right-[-8rem]  lg:left-auto lg:-bottom-2 lg:-right-12">
                Fully Guided Setup Pages
              </button>
            </div>
            <div className="w-full aspect-[797/456]">
              <video src={"https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fsass%20page%2Fsass2.mp4?alt=media&token=248f41d8-8d21-482c-9d15-e97a9b62a1d6&_gl=1*1y6ua35*_ga*MTA3NzI2MjQ5OC4xNjczNTcxNzQw*_ga_CW55HF8NVT*MTY4NTcwNTQ1MS43Ni4xLjE2ODU3MDY1MDEuMC4wLjA."} autoPlay muted loop className="w-full h-full object-cover  rounded-lg"></video>
            </div>
          </div>
          <div className={`w-full flex-1 flex flex-col lg:items-end justify-center order-1 lg:order-2`}>
            <p className="lg:text-right text-3xl lg:text-5xl font-semibold leading-tight">Templates crafted by experts!</p>
            <p className="lg:text-right mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg:max-w-[80%]">
              Create your video request in seconds with one of our templates. If you want to increase response rates, you can even include a video greeting & incentive.
            </p>
            <button
              className=" transition-all duration-300 w-fit mt-3 py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
              onClick={() => router.push("/signup")}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
      <div className="bg-[#171717] mt-10">
        <div className=" w-full px-5 lg:px-10 py-10 pb-20 grid lg:grid-cols-2 gap-10 justify-between mx-auto ">
          <div className={`w-full flex-1 flex flex-col justify-center`}>
            <p className="text-3xl lg:text-5xl font-semibold leading-tight">Embedding & hosting <br /> are ad-free</p>
            <p className="mt-5 text-[#ABA3A3] lg:text-[18px] tracking-wide lg:max-w-[80%]">
              When you get a response, You can trim, add brand logo add stakeholder name.
            </p>
            <button
              className=" transition-all duration-300 w-fit mt-3 py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
              onClick={() => router.push("/signup")}>
              Sign Up
            </button>
          </div>
          <div className="relative">
            <div className="w-max transform scale-50 lg:scale-100 absolute bottom-0">
              <button className="text-sm py-2 px-6 rounded-full  mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-72 absolute lg:bottom-[5rem] bottom-[2.5rem] left-16 lg:-left-36 ">
                Trim responses to the best parts
              </button>
              <button className="text-sm py-2 px-6 rounded-full  mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-72 absolute lg:bottom-9  -bottom-2 left-5 lg:-left-24">
                Add brand logo Instantly
              </button>
              <button className="text-sm py-2 px-6 rounded-full  mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] w-72 absolute lg:-bottom-2 -bottom-14 -left-5 lg:-left-12">
                Give It Personality with a name
              </button>
            </div>
            <div className="w-full aspect-[797/456]">
              <video src={"https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Fsass%20page%2Fsass3.mp4?alt=media&token=1eb08cc6-671b-4c14-a2cc-b18bc837e1cc&_gl=1*10hw0zn*_ga*MTA3NzI2MjQ5OC4xNjczNTcxNzQw*_ga_CW55HF8NVT*MTY4NTcwNTQ1MS43Ni4xLjE2ODU3MDY3NDkuMC4wLjA."} autoPlay muted loop className="w-full h-full object-cover  rounded-lg"></video>
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="my-10 w-full px-5 lg:px-10 lg:pt-20 lg:pb-20 max-w-[1400px] mx-auto">
          <div className="flex flex-col items-center">
            <h3 className="lg:w-[50%] text-center text-[32px] font-semibold leading-tight">
              Gain a Clear Insight into Collected Reviews and Testimonials!
            </h3>
            <p className="lg:w-[50%] text-center text-[#ABA3A3] mt-3 tracking-wide ">
              A video testimonial or review converts 25% more buyers than a written testimonial. Creating video testimonials and reviews is
              dramatically faster and easier with MyRevue Elite.
            </p>
          </div>
          <div className="grid lg:grid-cols-3 gap-5 lg:gap-20 lg:px-20 mt-10">
            <ReactVisibilitySensor partialVisibility once>
              {({ isVisible }) => (
                <div className={`growthCard ${isVisible ? "fade-slide-up" : ""}`}>
                  <div className="w-full p-4 transition-all duration-300 border-2 hover:border-[#F92147] rounded-lg">
                    <div className="flex items-end">
                      <p className="text-[24px]">Up to</p>
                      <p className="text-5xl pl-3 font-semibold text-[#F92147]">25x</p>
                    </div>
                    <div className="flex justify-between items-end">
                      <p className="text-2xl font-light">Increase in ROI</p>
                      <img src={require("./assets/images/ROI.svg").default} alt="" className="h-32" />
                    </div>
                  </div>
                </div>
              )}
            </ReactVisibilitySensor>
            <ReactVisibilitySensor partialVisibility once>
              {({ isVisible }) => (
                <div className={`growthCard ${isVisible ? "fade-slide-up" : ""}`}>
                  <div className="w-full p-4 transition-all duration-300 border-2 hover:border-[#F92147] rounded-lg">
                    <div className="flex items-end">
                      <p className="text-[24px]">Up to</p>
                      <p className="text-5xl pl-3 font-semibold text-[#F0DE7D]">330%</p>
                    </div>
                    <div className="flex justify-between items-end">
                      <p className="text-2xl font-light">Increase in conversion rate</p>
                      <img src={require("./assets/images/conversion.svg").default} alt="" className="h-32" />
                    </div>
                  </div>
                </div>
              )}
            </ReactVisibilitySensor>
            <ReactVisibilitySensor partialVisibility once>
              {({ isVisible }) => (
                <div className={`growthCard ${isVisible ? "fade-slide-up" : ""}`}>
                  <div className="w-full p-4 transition-all duration-300 border-2 hover:border-[#F92147] rounded-lg">
                    <div className="flex items-end">
                      <p className="text-[24px]">Up to</p>
                      <p className="text-5xl pl-3 font-semibold text-[#4611EA]">5x</p>
                    </div>
                    <div className="flex justify-between items-end">
                      <p className="text-2xl font-light">Increased time on site</p>
                      <img src={require("./assets/images/timeonsite.svg").default} alt="" className="h-32" />
                    </div>
                  </div>
                </div>
              )}
            </ReactVisibilitySensor>
          </div>
          <div className="flex items-center justify-center mt-5">
            <button
              className=" transition-all duration-300 py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
              onClick={() => router.push("/signup")}>
              Sign Up
            </button>
          </div>
        </div>
      </div>
      <NotloginfooterNew />
    </div>
  );
}
