import { CaretUp, ShareFat, SpeakerX } from '@phosphor-icons/react';
import { CaretDown, SpeakerHigh } from 'phosphor-react';
import React, { useRef, useState } from 'react';
import ReactVisibilitySensor from 'react-visibility-sensor';

export default function PlaylistFeed({ data, scrollDown, scrollUp, keyid, displayscroll, showproduct }) {
    const [isMuted, setIsMuted] = useState(true);
    const videoRef = useRef(null);
    const [progress, setprogress] = useState(0)
    const [duration, setduration] = useState('0:00')
    const [totalDuration, settotalDuration] = useState('0:00');
    async function onChange(isVisible) {
        setTimeout(async () => {
            if (isVisible) {
                await videoRef.current.play();
                setIsMuted(false);
                return
            } else {
                await videoRef.current.pause();
                setIsMuted(true);
            }
        }, 100);
    }
    const getMinutesfromsecond = (time) => {
        const min = Math.floor((time / 1000 / 60) << 0);
        const sec = Math.floor((time / 1000) % 60);
        return `${min >= 10 ? min : min}:${sec >= 10 ? sec : "0" + sec}`;
    }
    const handleOnTimeUpdate = () => {
        const video = videoRef.current;
        const vidprogress = (videoRef.current.currentTime / videoRef.current.duration) * 100;
        setprogress((isNaN(Math.trunc(vidprogress)) ? 0 : Math.trunc(vidprogress)));

        setduration(getMinutesfromsecond(video.currentTime * 1000))
        settotalDuration(getMinutesfromsecond(video.duration * 1000))
    };
    const progressStyle = {
        width: `${progress}%`,
    };

    async function sharereview() {
        navigator.share({
            url: `https://brand.myrevue.app`,
            title: "MyRevue Elite",
            // text: `Hey! Check out this ${data?.type === "review" ? "review" : "flick"} on MyRevue app. Get the best ${data?.type === "review" ? "review" : "inspirational videos"} before buying anything on MyRevue! download the app.`
        })
    }

    return (
        <ReactVisibilitySensor onChange={onChange}>
            <div className="snap-start h-full w-full relative bg-black" id={keyid}>
                {/* {displayscroll === true && <div className="absolute right-0 top-1/3 z-50 p-2 space-y-3">
                    <button className="rounded-md hJsDPI_max flex items-center justify-center appearance-none outline-none" onClick={() => scrollUp()}>
                        <CaretUp color='white' size={28} />
                    </button>
                    <button className="rounded-md hJsDPI_max flex items-center justify-center appearance-none outline-none" onClick={() => scrollDown()}>
                        <CaretDown color='white' size={28} />
                    </button>
                </div>
                } */}
                <div className="absolute bottom-0 w-full z-40 ">
                    <div className="w-full flex flex-col gap-3 pb-5 items-end p-2">
                        <button className="hJsDPI_max flex items-center justify-center rounded-md appearance-none outline-none" onClick={() => sharereview()}>
                            <ShareFat color='white' size={24} />
                        </button>
                        <button className="hJsDPI_max flex items-center justify-center rounded-md appearance-none outline-none" onClick={() => { setIsMuted(!isMuted); videoRef.current.muted = !isMuted }}>
                            {isMuted === false ?
                                <SpeakerHigh color='white' size={24} />
                                :
                                <SpeakerX color='white' size={24} />
                            }
                        </button>
                    </div>
                    {showproduct === true &&
                        <div className="p-2 w-full">
                            <div className="hJsDPI p-2 flex gap-3">
                                <div className="">
                                    <img src={data?.product?.imageUrl} alt="product_image" className="h-full aspect-square rounded-md" />
                                </div>
                                <div className="w-full flex-1 flex-col flex justify-between">
                                    <p className="font-work text-lg leading-tight text-black">{data?.product?.name}</p>
                                    {data?.product?.affiliateLink && (
                                        <div className="">
                                            <a href={data?.product?.affiliateLink} rel="noreferrer noopener" target="_blank" className="">
                                                <button className="bg-[#090A0B] py-[5px] w-fit px-5 font-work rounded-md">Buy Now</button>
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                    <div className="bg-gradient-to-b from-transparent via-[#12121250]  to-[#121212] h-10 w-full flex items-center justify-center gap-3 md:-mt-3 font-work rounded-b">
                        Powered by
                        <a href="https://brand.myrevue.app" target='_blank' className="" rel="noreferrer">
                            <img loading="lazy" src={require("../assets/logo/MyRevue Logo elite new.svg").default} alt="logo" className="h-[42px] w-auto" />
                        </a>
                    </div>
                </div>

                <div className="absolute h-2 w-full z-20">
                    <div className={`h-2 bg-white absolute z-30`} style={progressStyle}></div>
                    <div className="h-2 bg-white bg-opacity-20 w-full"></div>
                </div>
                <video
                    ref={videoRef}
                    src={data?.reviewUrl ?? data?.video}
                    muted={isMuted}
                    autoPlay={true}
                    onTimeUpdate={handleOnTimeUpdate}
                    poster={data?.thumbnail ?? data?.poster}
                    onClick={(e) => {
                        e.currentTarget.paused
                            ? e.currentTarget.play()
                            : e.currentTarget.pause();
                    }}
                    className="h-full w-full object-cover  mx-auto "
                ></video>
            </div>
        </ReactVisibilitySensor>
    );
}
