import AWN from "awesome-notifications";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useStateValue } from "../context/StateProvider";
import { db } from "../firebase";

function ExportVideo({ src, formId, submissionId }) {
  const [{ user, }, dispatch] = useStateValue();
  const exportNow = async () => {
    const downloads = await getDoc(doc(db, "brandAdmin", user?.email))
      .then((res) => {
        let d = res.data();
        return d.downloads ?? 0;
      })
      .catch((e) => {
        console.log(e);
        return -1;
      });

    if (downloads === -1) {
      return new AWN().warning(
        "failed to export review right now. Please try again later"
      );
    }

    if (downloads > (user?.limit ?? 3)) {
      return new AWN().warning(
        "download Limit Exceeded. Please contact admin or upgrade your plan."
      );
    }

    const subref = "forms/" + formId + "/submission/" + submissionId;
    await updateDoc(doc(db, "brandAdmin/" + user?.email), {
      downloads: user?.downloads ? user?.downloads + 1 : 1,
    });
    dispatch({ type: "SET_USER", user: { ...user, downloads: user?.downloads ? user?.downloads + 1 : 1 } })
    // setUser({ ...user, downloads: user?.downloads ? user?.downloads + 1 : 1 });
    await updateDoc(doc(db, subref), {
      downloadable: true,
    });
    var a = document.createElement("a");
    a.href = src;
    a.target = "_blank";
    a.click();
    return;
  };
  return (
    <div className="absolute hideOut-normal w-full h-full top-0 left-0  bg-black bg-opacity-70 flex items-center flex-col justify-center">
      <p className="">Export Video Now</p>
      <button
        className="bg-[#8ea9fa] h-10 px-8 cursor-pointer select-none text-white rounded m-1"
        onClick={exportNow}
      >
        Export
      </button>
    </div>
  );
}

export default ExportVideo;
