import React from "react";
import { useStateValue } from "../../context/StateProvider";

function Cstats() {
  const [{ user }, dispatch] = useStateValue();

  return (
    <div className="w-full flex flex-wrap items-center gap-5">
      <div className="w-fit text-center h-auto  p-3 my-2 border-2 rounded-xl border-white">
        <p className="mx-auto font-light tracking-wider text-sm">Campaigns used</p>
        <div className="mt-5 text-3xl">
          <span className="">
            {user?.campaigns ?? 0} / <span className="">{user?.limit?.campaignLimit ?? 1}</span>
          </span>
        </div>
      </div>
      <div className="w-fit text-center h-auto  p-3 my-2 border-2 rounded-xl border-white">
        <p className="mx-auto font-light tracking-wider text-sm">Downloads used</p>
        <div className="mt-5  text-3xl">
          <span className="">
            {user?.downloads ?? 0} / <span className=""> {user?.limit?.downloadLimit === 5000 ? "Unlimited" : user?.limit?.downloadLimit ?? 3}</span>
          </span>
        </div>
      </div>
      <div className="w-fit text-center h-auto p-3 my-2 border-2 rounded-xl border-white">
        <p className="mx-auto font-light tracking-wider text-sm">Comments used</p>
        <div className="mt-5  text-3xl">
          <span className="">
            {user?.comments ?? 0}/ <span className="">{user?.limit?.commentLimit ?? 0}</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Cstats;
