import moment from 'moment';
import { FunnelSimple, X } from 'phosphor-react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from '../../../firebase';
import { useStateValue } from '../../../context/StateProvider';

export default function Reviews({ reviewss, setreview }) {
    const [search_product, setsearch_product] = useState("");
    const [filters_modal, setfilters_modal] = useState(false)
    const [{ brand, reviews }, dispatch] = useStateValue();

    const [filters, setfilters] = useState({
        dateFrom: "",
        dateTo: "",
        rating: "",
        product: "",
        sorting: ""
    });
    const [products, setproducts] = useState([])

    console.log(reviews)
    function filtersdate(days) {
        if (!days) {
            setfilters({ ...filters, dateFrom: "", dateTo: "" })
            return
        }
        let endDate = moment(); // The current date
        let startDate = moment().subtract(days, 'days');
        setfilters({ ...filters, dateFrom: startDate.format('ddd MMM DD YYYY'), dateTo: endDate.format('ddd MMM DD YYYY') });
    }
    function resetfilters() {
        setfilters({
            dateFrom: "",
            dateTo: "",
            rating: "",
            product: "",
            sorting: ""
        });
    }
    return (
        <div className='h-full w-full relative'>
            {filters_modal === true && <div className="bg-[#1f1f1f] h-screen w-full fixed top-0 right-0 max-w-96 z-40">
                <div className="p-2 flex items-center justify-end" >
                    <button onClick={() => setfilters_modal(false)}><X size={28} color="white" /></button>
                </div>
                <div className="h-12 w-full flex items-center justify-between px-5 border-b border-[#80808040]">
                    Filters

                    <button className="bg-[#121212] py-1 px-8 rounded-md" onClick={() => resetfilters()}>Reset</button>
                </div>
                <div className="mt-5 px-5 space-y-5">
                    <div className="">
                        <p className="">Date added</p>
                        <select className="text-white w-full h-8 mt-2 px-2 text-sm tracking-wide rounded-md appearance-none outline-none bg-[#494949]" onChange={(event) => filtersdate(event.target.value)}>
                            <option value="">Please Select</option>
                            <option value="7">Last 7 days</option>
                            <option value="14">Last 14 days</option>
                            <option value="31">Last 1 Month</option>
                        </select>
                    </div>
                    <div className="">
                        <p className="">Rating</p>
                        <select className="text-white w-full h-8 mt-2 px-2 text-sm tracking-wide rounded-md appearance-none outline-none bg-[#494949]" onChange={(event) => setfilters({ ...filters, rating: event.target.value })}>
                            <option value="">Please Select</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    <div className="">
                        <p className="">Product</p>
                        <select className="text-white w-full h-8 mt-2 px-2 text-sm tracking-wide rounded-md appearance-none outline-none bg-[#494949]" onChange={(event) => setfilters({ ...filters, product: event.target.value })}>
                            <option value="" >Please Select</option>
                            {products.map((product, index) => (
                                <option value={product?.id} key={index}>{product?.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="">
                        <p className="">Sorting</p>
                        <select className="text-white w-full h-8 mt-2 px-2 text-sm tracking-wide rounded-md appearance-none outline-none bg-[#494949]" onChange={(event) => setfilters({ ...filters, sorting: event.target.value })}>
                            <option value="">Please Select</option>
                            <option value="desc">Oldtest to newest</option>
                            <option value="asc">Newest to oldtest</option>
                        </select>
                    </div>
                </div>
            </div>}
            <div className="flex justify-between items-center">
                <input type="text" onChange={(event) => setsearch_product(event.target.value)} className="h-8 rounded-md bg-[#3d3d3d] placeholder:text-sm px-3 appearance-none outline-none" placeholder='Search for products..' />
                <button className="" onClick={() => setfilters_modal(true)}><FunnelSimple size={32} color="#ffffff" /></button>
            </div>
            <div className='grid grid-cols-3 gap-3 mt-10'>
                {reviews?.map((review, index) => (
                    <div className="w-full aspect-[9/16] cursor-pointer" onClick={() => setreview(review)} key={index}>
                        <img src={review.thumbnail} alt="" loading='lazy' className="object-cover h-full w-full" />
                    </div>
                ))}
            </div>
        </div>
    )
}
