import React from 'react'
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom'
import { sendAmplitudeData } from './amplitude';
import NotLoginHeaderNew from './layouts/NotLoginHeaderNew';
import NotloginfooterNew from './layouts/NotloginfooterNew';

export default function Whyvideoreviews() {
    const router = useHistory();
    useEffect(() => {
        sendAmplitudeData("whyvideoreviews page opened");

        var title = `How does your MyRevue Elite platform work?`;
        var metaTitle = `How does your MyRevue Elite platform work?`;
        var metaDescription = `Get Authentic Video Reviews Or Testimonials,Authentic video reviews & testimonial will tell your story and drive sales for your brand.They are also more interesting to watch, making them more likely to drive sales.`;
        var metaKeyword = `Why Video Reviews | MyRevue  , Video testimonials, Review collector, video testimonial app,video testimonial platform,video testimonial service,twitter testimonials,video testimonial website,video testimonial tool,testimonials on social media, trustpilot similar sites,sites like trust pilot,websites like trustpilot,trustpilot alternative,platform for reviews,testimonial video template, testimonial video company,testimonial video editing,video testimonial script examples,collect testimonials online,script for testimonial video`;

        var ogUrl = `https://brand.myrevue.app/whyvideoreviews`;
        var ogtype = `website`;
        var ogtitle = `How does your MyRevue Elite platform work?`;
        var ogdescription = `Get Authentic Video Reviews Or Testimonials,Authentic video reviews & testimonial will tell your story and drive sales for your brand.They are also more interesting to watch, making them more likely to drive sales.`;
        var ogimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fbrandadminhome%2Fwhyvideoreviews.webp?alt=media&token=31bf01f9-36da-4837-847a-82d8ecef9b50`;


        var twittercard = `summary_large_image`;
        var twitterdomain = `brand.myrevue.app`;
        var twitterurl = `https://brand.myrevue.app/whyvideoreviews`;
        var twittertitle = `How does your MyRevue Elite platform work?`;
        var twitterdescription = `Get Authentic Video Reviews Or Testimonials,Authentic video reviews & testimonial will tell your story and drive sales for your brand.They are also more interesting to watch, making them more likely to drive sales.`;
        var twitterimage = `https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fbrandadminhome%2Fwhyvideoreviews.webp?alt=media&token=31bf01f9-36da-4837-847a-82d8ecef9b50`;

        document.title = title
        document.getElementsByTagName('meta')["title"].content = metaTitle;
        document.getElementsByTagName('meta')["description"].content = metaDescription;
        document.getElementsByTagName('meta')["keywords"].content = metaKeyword;


        document.querySelector(`meta[property="og:url"]`).content = ogUrl;
        document.querySelector(`meta[property="og:type"]`).content = ogtype;
        document.querySelector(`meta[property="og:title"]`).content = ogtitle;
        document.querySelector(`meta[property="og:description"]`).content = ogdescription;
        document.querySelector(`meta[property="og:image"]`).content = ogimage;

        document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
        document.querySelector(`meta[property="twitter:domain"]`).content = twitterdomain;
        document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
        document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
        document.querySelector(`meta[name="twitter:description"]`).content = twitterdescription;
        document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;



    }, []);

    return (
        <div className="h-screen overflow-y-auto bg-[#101010]">
            <NotLoginHeaderNew />
            <div className="md:my-10  px-5 md:px-10 mx-auto xl:px-24 2xl:px-32">
                <p className="text-center font-medium tracking-wide text-[#F92147]">WHY VIDEO REVIEWS</p>
                <h1 className='text-[28px] md:text-[36px] lg:text-[40px] text-center leading-tight font-semibold tracking-wide'>Harness the Impact of Genuine Video Reviews and Testimonials - Engaging and Trustworthy!</h1>
                <p className="text-[#8E8B8B] text-center tracking-wide mt-3">Boost sales and establish credibility with authentic video reviews and testimonials. </p>
                <p className="text-[#8E8B8B] text-center tracking-wide">Watch engaging video content that tells your brand story and drives conversions.</p>
                <div className="flex items-center mt-5 gap-5 w-full justify-center mb-28">
                    <button
                        className="py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                        onClick={() => router.push("/signup")}>
                        Sign Up
                    </button>
                    <div className="relative pl-5 md:pl-0">
                        <button className="font-medium tracking-wide py-2 md:px-12 z-10" onClick={() => router.push("/signup")}>
                            Try it for free
                        </button>
                        <img loading="lazy" src={require("./assets/icons/icons/hero section arrow.svg").default} alt="" className="absolute top-2 -ml-12" />
                    </div>
                </div>
            </div>
            <div className="px-5 lg:px-10 ">
                <div className="bg-[#171717] my-10 w-full px-5 md:px-10 py-10 mx-auto rounded-lg grid md:grid-cols-2 gap-10">
                    <div className="w-full flex flex-col justify-center gap-3">
                        <h2 className='text-[28px] lg:text-[36px] font-semibold leading-tight'>Leverage Video Testimonials & Reviews for Trust and Conversions!</h2>
                        <p className="text-[#8E8B8B] tracking-wide">Elevate customer trust through video testimonials and reviews with MyRevue Elite. Effortlessly gather and share authentic video testimonials from customers, partners, employees, and experts. No longer time-consuming or expensive, MyRevue Elite streamlines the process, enhancing your brand's credibility.</p>
                        <div className="">
                            <button
                                className="py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                                onClick={() => router.push("/signup")}>
                                Sign Up
                            </button>
                        </div>
                    </div>
                    <div className="w-full flex items-start justify-end ">
                        <div className="w-full max-w-[480px] aspect-square">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fwhyvideoreviews%2F1%20(1)-min.png?alt=media&token=5e875af4-e2a4-449b-8377-cb03528e781f" alt="" className="h-full w-full object-cover transform -scale-x-100 rounded-lg" />
                        </div>
                    </div>

                </div>
            </div>
            <div className="px-5 lg:px-10 ">
                <div className="my-10 w-full px-5 md:px-10 py-10 mx-auto rounded-lg grid md:grid-cols-2 gap-10">
                    <div className="w-full flex items-start justify-start order-2 md:order-1">
                        <div className="w-full max-w-[480px] aspect-square">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fwhyvideoreviews%2F1%20(1)-min.png?alt=media&token=5e875af4-e2a4-449b-8377-cb03528e781f" alt="" className="h-full w-full object-cover transform -scale-x-100 rounded-lg" />
                        </div>
                    </div>

                    <div className="w-full flex flex-col justify-center md:items-end gap-3 order-1 md:order-2">
                        <h2 className='md:text-right text-[28px] lg:text-[36px] font-semibold leading-tight'>How does your MyRevue Elite platform work?</h2>
                        <p className="md:text-right text-[#8E8B8B] tracking-wide">Capture Compelling Videos Anywhere, Anytime with MyRevue Elite. Automate Production, Edit Easily, and Engage Your Audience with Professional-Looking Content. Enjoy Ad-Free Hosting and Customizable Video Galleries.</p>
                        <div className="">
                            <button
                                className="py-2 px-12 rounded-full bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                                onClick={() => router.push("/signup")}>
                                Sign Up
                            </button>
                        </div>
                    </div>

                </div>
            </div>
            <div className="px-5 lg:px-10 ">
                <h1 className='text-3xl md:text-[36px] lg:text-[40px] text-center leading-tight font-semibold tracking-wide'>7 Types of video Testimonials & Reviews:</h1>
                <p className="text-[#8E8B8B] text-center tracking-wide mt-3">Capture a wide range of authentic video stories with our flexible platform</p>
                <div className="bg-[#171717] my-10 w-full px-5 md:px-10 py-10 mx-auto rounded-lg grid md:grid-cols-2 gap-10">
                    <div className="w-full flex flex-col justify-center gap-3">
                        <p className="font-black text-[110px] text-[#FFFFFF36] leading-none">01</p>
                        <p className="-mt-12 text-4xl font-semibold">Customer Testimonial Videos</p>
                        <p className="mt-2 text-[#ABA3A3] md:text-[18px] tracking-wide">
                            With genuine customer videos, you can boost marketing performance and conversions on your website, social media, and more.
                        </p>
                        <button
                            className="py-2 px-12 rounded-full mt-5 w-fit transition-all bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                            onClick={() => router.push("/signup")}>
                            Sign Up
                        </button>
                    </div>
                    <div className="w-full flex items-start justify-end ">
                        <div className="w-full max-w-[480px] aspect-square">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fwhyus%2F1.svg?alt=media&token=7a7b145c-81f3-42d7-bdd6-04641bba5efe" alt="" className="h-full w-full object-cover transform -scale-x-100 rounded-lg" />
                        </div>
                    </div>

                </div>
            </div>
            <div className="px-5 lg:px-10 ">
                <div className="my-10 w-full px-5 md:px-10 py-10 mx-auto rounded-lg gap-10 grid md:grid-cols-2">
                    <div className="w-full flex items-start justify-start order-2 md:order-1">
                        <div className="w-full max-w-[480px] aspect-square">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fwhyus%2F2.svg?alt=media&token=ed4f26fe-3948-41e5-b042-a5e294207b71" alt="" className="h-full w-full object-cover transform -scale-x-100 rounded-lg" />
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-center md:items-end gap-3 order-1 md:order-2">
                        <p className="md:text-right font-black text-[110px] text-[#FFFFFF36] leading-none">02</p>
                        <p className="md:text-right -mt-12 text-4xl font-semibold">Employee Testimonial Videos</p>
                        <p className="md:text-right mt-2 text-[#ABA3A3] md:text-[18px] tracking-wide">
                            Recruit the best talent by creating and sharing videos about your company culture straight from the source: your employees.
                        </p>
                        <button
                            className="py-2 px-12 rounded-full mt-5 w-fit transition-all bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                            onClick={() => router.push("/signup")}>
                            Sign Up
                        </button>
                    </div>


                </div>
            </div>
            <div className="px-5 lg:px-10 ">
                <div className="bg-[#171717] my-10 w-full px-5 md:px-10 py-10 mx-auto rounded-lg grid md:grid-cols-2 gap-10">
                    <div className="w-full flex flex-col justify-center gap-3">
                        <p className="font-black text-[110px] text-[#FFFFFF36] leading-none">03</p>
                        <p className="-mt-12 text-4xl font-semibold">Videos featuring influencers and experts</p>
                        <p className="mt-2 text-[#ABA3A3] md:text-[18px] tracking-wide">
                            You can engage (and convince) your audience with short video commentary from industry experts or influencers.
                            These people are busy, so streamlined collections that are only a few minutes long are essential.                        </p>
                        <button
                            className="py-2 px-12 rounded-full mt-5 w-fit transition-all bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                            onClick={() => router.push("/signup")}>
                            Sign Up
                        </button>
                    </div>
                    <div className="w-full flex items-start justify-end ">
                        <div className="w-full max-w-[480px] aspect-square">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fwhyus%2F3.svg?alt=media&token=1f01cc35-e2d2-4ed6-8320-343e4912e41c" alt="" className="h-full w-full object-cover transform -scale-x-100 rounded-lg" />
                        </div>
                    </div>

                </div>
            </div>
            <div className="px-5 lg:px-10 ">
                <div className="my-10 w-full px-5 md:px-10 py-10 mx-auto rounded-lg gap-10 grid md:grid-cols-2">
                    <div className="w-full flex items-start justify-start order-2 md:order-1">
                        <div className="w-full max-w-[480px] aspect-square">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fwhyus%2F4.svg?alt=media&token=9305c07e-1750-4b43-80da-fbd7f021b269" alt="" className="h-full w-full object-cover transform -scale-x-100 rounded-lg" />
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-center md:items-end gap-3 order-1 md:order-2">
                        <p className="md:text-right font-black text-[110px] text-[#FFFFFF36] leading-none">04</p>
                        <p className="md:text-right -mt-12 text-4xl font-semibold">Real Estate Videos</p>
                        <p className="md:text-right mt-2 text-[#ABA3A3] md:text-[18px] tracking-wide">
                            Video testimonials from home buyers and sellers capitalize on this phenomenon of social proof - but at a greater speed than ever before.
                        </p>
                        <button
                            className="py-2 px-12 rounded-full mt-5 w-fit transition-all bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                            onClick={() => router.push("/signup")}>
                            Sign Up
                        </button>
                    </div>


                </div>
            </div>
            <div className="px-5 lg:px-10 ">
                <div className="bg-[#171717] my-10 w-full px-5 md:px-10 py-10 mx-auto rounded-lg grid md:grid-cols-2 gap-10">
                    <div className="w-full flex flex-col justify-center gap-3">
                        <p className="font-black text-[110px] text-[#FFFFFF36] leading-none">05</p>
                        <p className="-mt-12 text-4xl font-semibold">Product Unboxing videos</p>
                        <p className="mt-2 text-[#ABA3A3] md:text-[18px] tracking-wide">
                            You can engage (and convince) your audience with short video commentary from industry experts or influencers.
                            These people are busy, so streamlined collections that are only a few minutes long are essential.
                        </p>
                        <button
                            className="py-2 px-12 rounded-full mt-5 w-fit transition-all bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                            onClick={() => router.push("/signup")}>
                            Sign Up
                        </button>
                    </div>
                    <div className="w-full flex items-start justify-end ">
                        <div className="w-full max-w-[480px] aspect-square">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fwhyus%2F5.svg?alt=media&token=1b625c60-d2a4-4bf3-9f0d-d3a4aec14796" alt="" className="h-full w-full object-cover transform -scale-x-100 rounded-lg" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-5 lg:px-10 ">
                <div className="my-10 w-full px-5 md:px-10 py-10 mx-auto rounded-lg gap-10 grid md:grid-cols-2">
                    <div className="w-full flex items-start justify-start order-2 md:order-1">
                        <div className="w-full max-w-[480px] aspect-square">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fwhyus%2F6.svg?alt=media&token=4528bcb6-46a7-479e-962b-fbdd49793dbd" alt="" className="h-full w-full object-cover transform -scale-x-100 rounded-lg" />
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-center md:items-end gap-3 order-1 md:order-2">
                        <p className="md:text-right font-black text-[110px] text-[#FFFFFF36] leading-none">06</p>
                        <p className="md:text-right -mt-12 text-4xl font-semibold">Videos for review sites</p>
                        <p className="md:text-right mt-2 text-[#ABA3A3] md:text-[18px] tracking-wide">
                            By curating a selection of authentic video reviews, you can maximize conversions from review sites like G2 and Google.
                        </p>
                        <button
                            className="py-2 px-12 rounded-full mt-5 w-fit transition-all bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                            onClick={() => router.push("/signup")}>
                            Sign Up
                        </button>
                    </div>
                </div>
            </div>
            <div className="px-5 lg:px-10 ">
                <div className="bg-[#171717] my-10 w-full px-5 md:px-10 py-10 mx-auto rounded-lg grid md:grid-cols-2 gap-10">
                    <div className="w-full flex flex-col justify-center gap-3">
                        <p className="font-black text-[110px] text-[#FFFFFF36] leading-none">07</p>
                        <p className="-mt-12 text-4xl font-semibold">video Reviews</p>
                        <p className="mt-2 text-[#ABA3A3] md:text-[18px] tracking-wide">
                            There is no doubt that customer reviews are essential to any ecommerce success story, however they have become a tricky topic in recent years due to the ease of bribery and outright fabrication.
                        </p>
                        <button
                            className="py-2 px-12 rounded-full mt-5 w-fit transition-all bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                            onClick={() => router.push("/signup")}>
                            Sign Up
                        </button>
                    </div>
                    <div className="w-full flex items-start justify-end ">
                        <div className="w-full max-w-[480px] aspect-square">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fwhyus%2F7.svg?alt=media&token=8fd88c66-e68d-46f2-9d2e-6550916f5562" alt="" className="h-full w-full object-cover transform -scale-x-100 rounded-lg" />
                        </div>
                    </div>
                </div>
            </div>
            <NotloginfooterNew />
        </div>
    )
}
