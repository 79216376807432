import AWN from "awesome-notifications";
import { } from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { db } from "../firebase";
import { sendAmplitudeData } from "../amplitude";
import { useStateValue } from "../context/StateProvider";
import axios from "axios";
import { nanoid } from "nanoid";

function MasterLogin() {
  const [{ user }, dispatch] = useStateValue();
  const [newUserId, setNewUserId] = useState(nanoid(12));

  useEffect(() => {
    sendAmplitudeData("login page opened");
    var title = `Login - MyRevue`;
    var metaTitle = `Login - MyRevue`;
    var metaDescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var metaKeyword = `Brands - MyRevue, video testimonials software ,best video testimonial software, video testimonials, get video testimonials, collect video testimonials, free video testimonial software, video testimonials app, video customer testimonials, video testimonial platform, video testimonial tool`;

    var ogUrl = `https://brand.myrevue.app/login`;
    var ogtype = `website`;
    var ogtitle = `Login - MyRevue`;
    var ogdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var ogimage = ``;

    var twittercard = `summary_large_image`;
    var twitterdomain = `brand.myrevue.app`;
    var twitterurl = `https://brand.myrevue.app/login`;
    var twittertitle = `Login - MyRevue`;
    var twitterdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var twitterimage = ``;

    document.title = title;
    document.getElementsByTagName("meta")["title"].content = metaTitle;
    document.getElementsByTagName("meta")["description"].content =
      metaDescription;
    document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

    document.querySelector(`meta[property="og:url"]`).content = ogUrl;
    document.querySelector(`meta[property="og:type"]`).content = ogtype;
    document.querySelector(`meta[property="og:title"]`).content = ogtitle;
    document.querySelector(`meta[property="og:description"]`).content =
      ogdescription;
    document.querySelector(`meta[property="og:image"]`).content = ogimage;

    document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
    document.querySelector(`meta[property="twitter:domain"]`).content =
      twitterdomain;
    document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
    document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
    document.querySelector(`meta[name="twitter:description"]`).content =
      twitterdescription;
    document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
  }, []);

  let brandIdref = useRef();
  let emailRef = useRef();
  const router = useHistory();

  const changeBrandId = async () => {
    let brandId = brandIdref.current.value;
    if (!brandId) {
      return new AWN().warning("Please enter brand id");
    }
    brandId = brandId.trim();

    let headersList = {
      Accept: "*/*",
      Authorization: "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g",
    };

    let reqOptions = {
      url: `https://brandapi-zscu3untuq-el.a.run.app/getBrand/${brandId}`,
      method: "GET",
      headers: headersList,
    };

    let getbrand = await axios
      .request(reqOptions)
      .then((res) => res?.data?.brand)
      .catch((err) => console.log(err));

    // const getbrand = await getDoc(doc(db, "brand", brandId)).then(res => res.data())
    const getbrandadmin = await getDoc(
      doc(db, "brandAdmin", emailRef.current.value.trim())
    ).then((res) => res.data());

    if (!getbrandadmin.appUserId) {
      const appUserId = await GetUserId(getbrandadmin);
      getbrandadmin.appUserId = appUserId;
    }
    if (!getbrandadmin?.limit) {
      const result = await updateLimit(getbrandadmin);
      getbrandadmin.limit = result;
    }
    if (getbrand && getbrandadmin) {
      sessionStorage.setItem("brand", JSON.stringify(getbrand));
      dispatch({ type: "SET_BRAND", brand: getbrand });
      sessionStorage.setItem(
        "user",
        JSON.stringify({ ...getbrandadmin, access: "admin" })
      );
      dispatch({
        type: "SET_USER",
        user: { ...getbrandadmin, access: "admin" },
      });
      router.replace("/admin");
    } else {
      return new AWN().warning("No brand found");
    }
  };
  async function updateLimit(data) {
    try {
      const limitDoc = {
        campaignLimit: data?.campaignLimit ?? 1,
        commentLimit: data?.commentLimit ?? 0,
        downloadLimit: data?.downloadLimit ?? 3,
        faqLimit: 1,
        galleryLimit: 1,
        challangeLimit: 1,
        quizLimit: 1
      }
      await updateDoc(doc(db, "brandAdmin", data.email), { limit: limitDoc });
      return limitDoc
    } catch (error) {
      console.log(error);
      return null
    }
  }

  async function GetUserId(data) {
    let headersList = {
      Accept: "*/*",
      Authorization:
        "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
      "Content-Type": "application/json",
    };

    let bodyContent = {
      rating: 3,
      followers: 0,
      following: 0,
      reviews: 0,
      views: 0,
      flicks: 0,
      languages: ["English"],
      name: data?.companyName ?? data?.name,
      categories: ["cat_kdik4uvKPxQPc1iG8Nmn4S"],
      refCode: null,
      weight: -1,
      rank: null,
      socialMedia: null,
      token: null,
      qrCodeUrl: null,
      imageUrl:
        "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fdefault_dp.png?alt=media&token=12a37164-51dc-48a8-9bf3-f008fa6a5113",
      refBy: null,
      joinDate: Date.now(),
      about: "",
      dob: null,
      verified: null,
      address: null,
      favorite: null,
      savedReviews: null,
      phone: null,
      id: "user_" + newUserId,
      lastLogin: Date.now(),
      email: data?.email,
      achievement: null,
      totalReviews: 0,
      gender: "male",
      tag: "@user_" + newUserId,
      coins: 0,
      totalCoins: 0,
    };

    let reqOptions = {
      url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
      method: "PUT",
      headers: headersList,
      data: bodyContent,
    };

    let response = await axios.request(reqOptions);

    if (response.data?.newUser === false) {
      if (!data?.appUserId) {
        await updateDoc(doc(db, "brandAdmin", data.email), {
          appUserId: response.data.user.id,
        });
        return response.data.user?.id;
      }
    }
    if (response.data?.newUser === true) {
      await updateDoc(doc(db, "brandAdmin", data.email), {
        appUserId: response.data.user.id,
      });
      return response.data.user?.id;
    }
  }

  return (
    // <div className="flex flex-col h-screen">
    //   <NotLoginHeader />
    //   <div className="flex-1 flex justify-center">
    //     <div className=" h-full  flex flex-col items-center justify-center p-5 w-full lg:px-7">
    //       <div className=" hideOut w-full sm:w-96" id="page1">
    //         <h1 className="text-4xl my-3">Enter Brand ID</h1>
    //         <input
    //           type="email"
    //           ref={brandIdref}
    //           className="w-full my-6 h-12 rounded-md focus:border-white bg-transparent border-[1px] border-[#9E9E9E] px-2 outline-none placeholder:text-white placeholder:text-opacity-70 tracking-wide"
    //           placeholder="Enter brand id"
    //         />
    //         <input
    //           type="email"
    //           ref={emailRef}
    //           className="w-full my-6 h-12 rounded-md focus:border-white bg-transparent border-[1px] border-[#9E9E9E] px-2 outline-none placeholder:text-white placeholder:text-opacity-70 tracking-wide"
    //           placeholder="Enter brand email"
    //         />
    //         <button
    //           className="h-12 w-full rounded-md  bg-transparent mb-2 py-2 px-4 gradient-border"
    //           onClick={changeBrandId}
    //         >
    //           Continue
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div className="bg-[#1A1A1A] h-screen">
      {/* <div className="grid grid-cols-2 bg-cover bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fpattern-min.png?alt=media&token=bc04c455-0afe-4295-9562-044cb7cf2ef0')] h-full w-full"> */}
      <div className="grid lg:grid-cols-2 h-full w-full">
        <div className="h-full w-full relative hidden lg:flex items-center justify-center">
          <img
            src={require("../assets/logo/MyRevue Logo elite new.svg").default}
            alt="logo"
            className="h-[50px]  transform scale-150 absolute left-24 top-10"
          />
          <video
            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/gifs%2Flogin.mp4?alt=media&token=52fec1d6-7ce1-4ff8-9bf8-0e12640e053e"
            className="max-h-[380px]"
            loop
            muted
            autoPlay
          ></video>
        </div>
        <div className="h-full w-full bg-[#2B2F37] rounded-l-xl flex lg:items-center justify-center p-5">
          <div className="md:w-96 w-full">
            <img
              src={require("../assets/logo/MyRevue Logo elite new.svg").default}
              alt="logo"
              className="h-[60px]   mx-auto mb-20 lg:hidden"
            />
            <p className="text-4xl">Brand Login </p>
            <div className="w-full mt-5 ">
              <input
                type="text"
                ref={brandIdref}
                className="bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                placeholder="Brand ID"
              />
            </div>
            <div className="w-full mt-5">
              <input
                type="text"
                ref={emailRef}
                className="bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                placeholder="Brand Email Address"
              />
            </div>
            <div className="w-full mt-3">
              <button
                onClick={changeBrandId}
                className="bg-gradient-to-r from-[#F92147] via-[#C535A8] to-[#9747FF] font-medium w-full h-12 rounded-md"
              >
                Log in
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MasterLogin;
