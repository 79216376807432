import { CircleWavyCheck, UploadSimple } from "phosphor-react";
import React, { useState } from "react";
import Stickyheader from "../components/stickyheader";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import strings from "../assets/Strings";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  getDownloadURL,
} from "firebase/storage";
import { Link } from "react-router-dom";
import { useStateValue } from "../context/StateProvider";
import AWN from "awesome-notifications";
import axios from "axios";
import { toast } from "react-toastify";
import {
  Checks,
  CircleNotch,
  Copy,
  FloppyDiskBack,
  ShieldSlash,
} from "@phosphor-icons/react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function Settings() {
  const [{ user, brand }, dispatch] = useStateValue();
  const [show_verification, setshow_verification] = useState(false);
  const [verification_details, setverification_details] = useState({});
  const [uploading, setuploading] = useState(false);
  const [progress, setprogress] = useState(0);
  const [tokenLoading, setTokenLoading] = useState(false);
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const router = useHistory();

  const [smtpSettings, setSmtpSettings] = useState({
    username: user?.smtp?.username ?? "",
    password: user?.smtp?.password ?? "",
    host: user?.smtp?.host ?? "",
    port: user?.smtp?.port ?? "",
    secure: user?.smtp?.secure ?? "true",
  });

  const uploadFile = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }

    const storage = getStorage();
    const storageRef = ref(
      storage,
      `brand_verification/${brand?.id}/` + brand?.id + ".pdf"
    );
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setuploading(true);
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setverification_details({
            ...verification_details,
            documentUrl: downloadURL,
          });
          setuploading(false);
        });
      }
    );
  };

  const uploadBrandImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }

    const storage = getStorage();
    const storageRef = ref(storage, `brand/${brand?.id}.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          async function updateBrand() {
            let headersList = {
              Accept: "*/*",
              Authorization: "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g",
            };

            let bodyContent = {
              brandId: brand?.id,
              updates: {
                imageUrl: downloadURL,
              },
            };
            let reqOptions = {
              url: `https://brandapi-zscu3untuq-el.a.run.app/updateBrand`,
              method: "PUT",
              headers: headersList,
              data: bodyContent,
            };

            let response = await axios.request(reqOptions);
            await updateUserImage(user?.appUserId, downloadURL);
            if (response.data.success === false) {
              return;
            }
            if (response.data.success === true) {
              sessionStorage.setItem(
                "brand",
                JSON.stringify({ ...brand, imageUrl: downloadURL })
              );
              dispatch({
                type: "SET_BRAND",
                brand: { ...brand, imageUrl: downloadURL },
              });
            }
          }
          updateBrand();

          // setbrand({ ...brand, imageUrl: downloadURL });
          // updateDoc(doc(db, strings.testBrand, brand?.id), {
          //   imageUrl: downloadURL,
          // });
        });
      }
    );
  };
  const uploadBrandCoverImage = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }

    const storage = getStorage();
    const storageRef = ref(storage, `coverImage/${brand?.id}-coverImage.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          // setbrand({ ...brand, imageUrl: downloadURL });
          async function updateBrand() {
            let headersList = {
              Accept: "*/*",
              Authorization: "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g",
            };

            let bodyContent = {
              brandId: brand?.id,
              updates: {
                coverImage: downloadURL,
              },
            };
            let reqOptions = {
              url: `https://brandapi-zscu3untuq-el.a.run.app/updateBrand`,
              method: "PUT",
              headers: headersList,
              data: bodyContent,
            };

            let response = await axios.request(reqOptions);
            if (response.data.success === false) {
              return;
            }
            if (response.data.success === true) {
              sessionStorage.setItem(
                "brand",
                JSON.stringify({ ...brand, coverImage: downloadURL })
              );
              dispatch({
                type: "SET_BRAND",
                brand: { ...brand, coverImage: downloadURL },
              });
            }
          }
          updateBrand();
        });
      }
    );
  };

  const submit = async () => {
    if (!verification_details?.address) {
      toast.warning("Please add office address", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!verification_details?.description) {
      toast.warning("Please add the description", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!verification_details?.info?.fullName) {
      toast.warning("Please fill out the details", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!verification_details?.info?.designation) {
      toast.warning("Please fill out the details", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!verification_details?.info?.workEmail) {
      toast.warning("Please fill out the details", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!verification_details?.info?.contactNumber) {
      toast.warning("Please fill out the details", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!verification_details?.info?.handle) {
      toast.warning("Please fill out the details", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!verification_details?.documentUrl) {
      toast.warning("Please upload the document", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }

    var verification = {
      ...verification_details,
      appliedAt: Date.now(),
      verified: false,
    };
    await updateDoc(doc(db, strings.adminCollection, user?.email), {
      verification,
    }).then((res) => {
      toast.success("Successfully applied for verification.", {
        position: "top-right",
        icon: <Checks size={32} color="green" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      sessionStorage.setItem("user", JSON.stringify({ ...user, verification }));
      dispatch({ type: "SET_USER", user: { ...user, verification } });
    });
  };

  async function copyText(text) {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard", {
      position: "top-right",
      icon: <Checks size={32} color="green" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
    return;
  }

  async function showSuccessMsg(msg) {
    toast.success(msg, {
      position: "top-right",
      icon: <Checks size={32} color="green" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
  }
  async function showWarningMsg(msg) {
    toast.warning(msg, {
      position: "top-right",
      icon: <ShieldSlash size={32} color="red" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
  }

  async function createToken() {
    if (!brand?.id) {
      showWarningMsg("Please refresh or relogin to continue");
      return;
    }

    try {
      setTokenLoading(true);
      let headersList = {
        Accept: "*/*",
      };

      let reqOptions = {
        url: "https://api.myrevue.app/generateToken/" + brand?.id,
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);

      await updateDoc(doc(db, strings.adminCollection, user?.email), {
        clientSecret: response?.data?.token,
      });
      sessionStorage.setItem(
        "user",
        JSON.stringify({ ...user, clientSecret: response?.data?.token })
      );
      dispatch({
        type: "SET_USER",
        brand: { ...user, clientSecret: response?.data?.token },
      });
      setTokenLoading(false);
      window.location.reload();
    } catch (error) {
      showWarningMsg(error);
      setTokenLoading(false);
    }
  }

  async function updateUserImage(userId, imageUrl) {
    let headersList = {
      Accept: "*/*",
      Authorization:
        "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
    };
    let bodyContent = {
      userId: userId,
      imageUrl: imageUrl,
    };

    let reqOptions = {
      url: "https://userapi-zscu3untuq-el.a.run.app/userImageUpdate",
      method: "PUT",
      headers: headersList,
      data: bodyContent,
    };

    await axios.request(reqOptions);
  }

  async function submitSMTP() {
    if (!smtpSettings.host) {
      toast.warning("Please add host", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!smtpSettings.host) {
      toast.warning("Please add host", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!smtpSettings.port) {
      toast.warning("Please add port", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!smtpSettings.secure) {
      toast.warning("Please select secure", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!smtpSettings.username) {
      toast.warning("Please add username", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!smtpSettings.password) {
      toast.warning("Please add password", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    await updateDoc(doc(db, "brandAdmin", user?.email), { smtp: smtpSettings });
    sessionStorage.setItem(
      "user",
      JSON.stringify({ ...user, smtp: smtpSettings })
    );
    dispatch({ type: "SET_USER", user: { ...user, smtp: smtpSettings } });
    toast.success("SMTP settings updated successfully", {
      position: "top-right",
      icon: <Checks size={32} color="green" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
  }
  return (
    <div className="flex flex-col overflow-y-auto h-full">
      <div className="px-5 py-4  border-[#ececec1b] text-white flex items-center text-3xl font-medium">
        <p className="pl-5 first-letter:uppercase ">Settings</p>
      </div>
      <div className="mt-10">
        <div className="grid grid-cols-2 w-full">
          <button
            onClick={() => router.replace(`?tab=profile`)}
            className={`pb-3 border-b ${
              searchParams.get("tab") === null ||
              searchParams.get("tab")?.includes("profile")
                ? "border-[#808080] font-medium"
                : "border-transparent"
            } text-center tracking-wide text-lg appearance-none outline-none`}
          >
            Brand Profile
          </button>
          <button
            onClick={() => router.replace(`?tab=settings`)}
            className={`pb-3 border-b ${
              searchParams.get("tab")?.includes("settings")
                ? "border-[#808080] font-medium"
                : "border-transparent"
            } text-center tracking-wide text-lg appearance-none outline-none`}
          >
            Settings
          </button>
        </div>
      </div>
      {(searchParams.get("tab") === null ||
        searchParams.get("tab")?.includes("profile")) && (
        <div className="p-5 relative">
          <div className="max-w-[860px] w-full rounded-xl p-8 bg-[#131418] mx-auto">
            <div className="flex items-center justify-between mb-5">
              <img
                crossOrigin="anonymous"
                src={
                  require("../assets/logo/MyRevue Logo elite new.svg").default
                }
                alt=""
                className="h-10"
              />
              <button
                onClick={() =>
                  !user?.verification
                    ? setshow_verification(!show_verification)
                    : user?.verification?.verified === true
                    ? showSuccessMsg("Brand is already verified")
                    : showSuccessMsg(
                        "Verification request has already been sent"
                      )
                }
                className=" w-fit hover:bg-[#1f2027] rounded-lg h-10 pb-1 px-5 text-white text-sm tracking-wider flex items-center"
              >
                {!user?.verification && (
                  <p className=" font-work pt-1 mr-3">Apply for verification</p>
                )}
                {user?.verification?.verified === false && (
                  <p className=" font-work pt-1 mr-3">
                    Applied for verification
                  </p>
                )}
                {user?.verification?.verified === true && (
                  <p className=" font-work pt-1 mr-3">Verified</p>
                )}
                <CircleWavyCheck
                  size={24}
                  color={
                    user?.verification?.verified === true ? "green" : "#ffffff"
                  }
                />
              </button>
            </div>
            <div className="w-32 h-32 rounded-full mx-auto relative">
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                className="h-full w-full rounded-full absolute opacity-0"
                onChange={(e) => uploadBrandImage(e)}
              />
              <img
                src={brand?.imageUrl}
                alt="brand_image"
                className="h-full w-full rounded-full"
              />
            </div>
            <div className="w-full mx-auto max-w-[650px] mt-10 relative">
              <input
                type="file"
                accept=".png,.jpg,.jpeg"
                className="h-full w-full rounded-3xl absolute opacity-0"
                onChange={(e) => uploadBrandCoverImage(e)}
              />
              <img
                src={brand?.coverImage}
                alt="brandCoverImage"
                className="w-full h-40 bg-black border-2 border-white rounded-3xl object-cover"
              />
            </div>
            <div className="max-w-[708px] w-full mt-20 mx-auto">
              <div className="pl-3">
                <h3 className="text-xl font-medium tracking-wide">
                  Brand Information
                </h3>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Brand Name
                </p>
                <div className="relative w-full col-span-2 flex items-center gap-5">
                  <input
                    readOnly
                    className="text-white tracking-wide min-w-fit flex-1 bg-transparent appearance-none outline-none"
                    defaultValue={brand?.name}
                  />
                  <button
                    onClick={() => copyText(brand?.name)}
                    className="hover:text-white text-[#5C5C5D]"
                  >
                    <Copy size={28} color="currentcolor" />
                  </button>
                </div>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Brand ID
                </p>
                <div className="relative w-full col-span-2 flex items-center gap-5">
                  <input
                    readOnly
                    className="text-white tracking-wide min-w-fit flex-1 bg-transparent appearance-none outline-none"
                    defaultValue={brand?.id}
                  />
                  <button
                    onClick={() => copyText(brand?.id)}
                    className="hover:text-white text-[#5C5C5D]"
                  >
                    <Copy size={28} color="currentcolor" />
                  </button>
                </div>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Brand Email
                </p>
                <div className="relative w-full col-span-2 flex items-center gap-5">
                  <input
                    readOnly
                    className="text-white tracking-wide min-w-fit flex-1 bg-transparent appearance-none outline-none"
                    defaultValue={user?.email}
                  />
                  <button
                    onClick={() => copyText(user?.email)}
                    className="hover:text-white text-[#5C5C5D]"
                  >
                    <Copy size={28} color="currentcolor" />
                  </button>
                </div>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <div className="flex h-full items-center">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Authorization Token
                  </p>
                </div>
                {user?.clientSecret ? (
                  <div className="relative w-full col-span-2 flex items-center gap-5">
                    <input
                      readOnly
                      className="text-white tracking-wide min-w-fit flex-1 bg-transparent appearance-none outline-none"
                      defaultValue={user?.clientSecret}
                    />
                    <button
                      onClick={() => copyText(user?.clientSecret)}
                      className="hover:text-white text-[#5C5C5D]"
                    >
                      <Copy size={28} color="currentcolor" />
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={() => createToken()}
                    className="bg-[#4611ea] px-4 py-2 text-sm tracking-wide font-work rounded-md flex items-center justify-center"
                  >
                    {tokenLoading === true ? (
                      <CircleNotch size={24} color="white" />
                    ) : (
                      "Generate Client Secret"
                    )}
                  </button>
                )}
              </div>
            </div>
            <div className="max-w-[708px] w-full mt-8 mx-auto">
              <div className="pl-3">
                <h3 className="text-xl font-medium tracking-wide">
                  Display language
                </h3>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Language
                </p>
                <div className="relative w-full col-span-2 flex items-center gap-5">
                  <input
                    readOnly
                    className="text-white tracking-wide w-fit bg-transparent appearance-none outline-none"
                    defaultValue={"English"}
                  />
                </div>
              </div>
            </div>
          </div>
          {!user?.verification && show_verification === true && (
            <div
              className="max-w-[860px] w-full rounded-xl p-8 bg-[#131418] mx-auto mt-10"
              id="verification"
            >
              <div className="flex items-center justify-between mb-5">
                <img
                  crossOrigin="anonymous"
                  src={
                    require("../assets/logo/MyRevue Logo elite new.svg").default
                  }
                  alt=""
                  className="h-10"
                />
              </div>
              <div className="w-32 h-32 rounded-full mx-auto relative">
                <input
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  className="h-full w-full rounded-full absolute opacity-0"
                  onChange={(e) => uploadBrandImage(e)}
                />
                <img
                  src={brand?.imageUrl}
                  alt="brand_image"
                  className="h-full w-full rounded-full"
                />
              </div>
              <div className="max-w-[708px] w-full mt-20 mx-auto">
                <div className="pl-3">
                  <h3 className="text-xl font-medium tracking-wide">
                    Brand Information
                  </h3>
                </div>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Brand Name
                  </p>
                  <div className="relative w-full col-span-2 flex items-center gap-5">
                    <input
                      readOnly
                      className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                      defaultValue={brand?.name}
                    />
                  </div>
                </div>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Brand Address <span className="text-red-500">*</span>
                  </p>
                  <div className="relative w-full col-span-2 flex items-center gap-5">
                    <input
                      className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                      placeholder="ex: Indore, MP, PIN:XXXXXX"
                      defaultValue={user?.verification?.address}
                      onChange={(event) =>
                        setverification_details({
                          ...verification_details,
                          address: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Brand Description <span className="text-red-500">*</span>
                  </p>
                  <div className="relative w-full col-span-2 flex items-center gap-5">
                    <textarea
                      className="text-white tracking-wide w-full bg-transparent appearance-none outline-none h-28"
                      placeholder="ex: brand description"
                      defaultValue={user?.verification?.description}
                      onChange={(event) =>
                        setverification_details({
                          ...verification_details,
                          description: event.target.value,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="max-w-[708px] w-full mt-8 mx-auto">
                <div className="pl-3">
                  <h3 className="text-xl font-medium tracking-wide">
                    Contact information
                  </h3>
                </div>
                <div className="grid grid-cols-2 gap-10 mt-5">
                  <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                    <p className="font-semibold text-[#747474] tracking-wide w-full">
                      Full Name <span className="text-red-500">*</span>
                    </p>
                    <div className="relative w-full col-span-2 flex items-center gap-5 mt-2">
                      <input
                        className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                        onChange={(event) =>
                          setverification_details({
                            ...verification_details,
                            info: {
                              ...verification_details.info,
                              fullName: event.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                    <p className="font-semibold text-[#747474] tracking-wide w-full">
                      Designation <span className="text-red-500">*</span>
                    </p>
                    <div className="relative w-full col-span-2 flex items-center gap-5 mt-2">
                      <input
                        className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                        onChange={(event) =>
                          setverification_details({
                            ...verification_details,
                            info: {
                              ...verification_details.info,
                              designation: event.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-10 mt-5">
                  <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                    <p className="font-semibold text-[#747474] tracking-wide w-full">
                      Work Email <span className="text-red-500">*</span>
                    </p>
                    <div className="relative w-full col-span-2 flex items-center gap-5 mt-2">
                      <input
                        className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                        onChange={(event) =>
                          setverification_details({
                            ...verification_details,
                            info: {
                              ...verification_details.info,
                              workEmail: event.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                    <p className="font-semibold text-[#747474] tracking-wide w-full">
                      Secondary email (optional)
                    </p>
                    <div className="relative w-full col-span-2 flex items-center gap-5 mt-2">
                      <input
                        className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                        onChange={(event) =>
                          setverification_details({
                            ...verification_details,
                            info: {
                              ...verification_details.info,
                              secondaryEmail: event.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-10 mt-5">
                  <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                    <p className="font-semibold text-[#747474] tracking-wide w-full">
                      Contact Number <span className="text-red-500">*</span>
                    </p>
                    <div className="relative w-full col-span-2 flex items-center gap-5 mt-2">
                      <input
                        className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                        onChange={(event) =>
                          setverification_details({
                            ...verification_details,
                            info: {
                              ...verification_details.info,
                              contactNumber: event.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="border-b border-[#5C5C5D] pl-3 pb-2">
                    <p className="font-semibold text-[#747474] tracking-wide w-full">
                      Website/Social Handle{" "}
                      <span className="text-red-500">*</span>
                    </p>
                    <div className="relative w-full col-span-2 flex items-center gap-5 mt-2">
                      <input
                        className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                        onChange={(event) =>
                          setverification_details({
                            ...verification_details,
                            info: {
                              ...verification_details.info,
                              handle: event.target.value,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-5">
                  <p className="font-semibold text-[#747474] tracking-wide w-full">
                    Buisness Document <span className="text-red-500">*</span>
                  </p>
                  <div className="relative w-full col-span-2 flex items-center gap-5 mt-4 bg-[#2B2C31] h-12 rounded-lg p-1">
                    <input
                      type="file"
                      accept=".pdf"
                      className="w-full h-full absolute opacity-0"
                      onChange={(e) => uploadFile(e)}
                    />
                    <div className="h-full aspect-square bg-[#666666] rounded-md flex items-center justify-center">
                      <UploadSimple size={24} color="white" />
                    </div>
                    <div className="w-full flex-1 flex items-center justify-center">
                      <p className="text-[#A5A5A5] text-center tracking-wide">
                        {uploading === false && progress < 99 ? (
                          <span className="flex items-center gap-x-3">
                            <UploadSimple size={20} />
                            Upload .pdf format only
                          </span>
                        ) : (
                          <span>
                            {progress > 99
                              ? "Uploaded."
                              : `Uploading ${progress} %`}
                          </span>
                        )}
                      </p>
                    </div>
                    {verification_details?.documentUrl ? (
                      <div className="z-50 mr-5">
                        <Link
                          to={{ pathname: verification_details?.documentUrl }}
                          target="_blank"
                        >
                          <p className=" font-work text-[#d1d1d1] underline underline-offset-2 cursor-pointer">
                            View
                          </p>
                        </Link>
                      </div>
                    ) : null}
                  </div>
                  <p className="text-xs tracking-wide text-[#454545] text-center mt-2">
                    Buisness legal name would be present in document
                  </p>
                </div>
                <div className="mt-8 flex justify-center">
                  <button
                    onClick={() => submit()}
                    className="bg-[#63D876] hover:bg-green-500 h-12 w-fit px-8 flex items-center  justify-center gap-4 rounded-full"
                  >
                    <FloppyDiskBack size={24} color="white" />
                    Save
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {searchParams.get("tab")?.includes("settings") && (
        <div className="p-5 relative">
          <div className="max-w-[860px] w-full rounded-xl p-8 bg-[#131418] mx-auto">
            <div className="flex items-center justify-between mb-5">
              <img
                crossOrigin="anonymous"
                src={
                  require("../assets/logo/MyRevue Logo elite new.svg").default
                }
                alt=""
                className="h-10"
              />
              <button className=" w-fit hover:bg-[#1f2027] rounded-lg h-10 pb-1 px-5 text-white text-sm tracking-wider flex items-center">
                Send Test Mail
              </button>
            </div>
            <div className="max-w-[708px] w-full mt-20 mx-auto">
              <div className="pl-3">
                <h3 className="text-xl font-medium tracking-wide">
                  SMTP Settings
                </h3>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Host
                </p>
                <div className="relative w-full col-span-2 flex items-center gap-5">
                  <input
                    className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                    placeholder="ex: smtp.example.com"
                    value={smtpSettings.host}
                    onChange={(event) =>
                      setSmtpSettings({
                        ...smtpSettings,
                        host: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Port
                </p>
                <div className="relative w-full col-span-2 flex items-center gap-5">
                  <input
                    type="number"
                    className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                    placeholder="ex: 465"
                    value={smtpSettings.port}
                    onChange={(event) =>
                      setSmtpSettings({
                        ...smtpSettings,
                        port: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Secure
                </p>
                <div className="relative w-full col-span-2 flex items-center gap-5">
                  <select
                    className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                    onChange={(event) =>
                      setSmtpSettings({
                        ...smtpSettings,
                        secure: event.target.value,
                      })
                    }
                  >
                    <option value="true" className="text-black">
                      true
                    </option>
                    <option value="false" className="text-black">
                      false
                    </option>
                  </select>{" "}
                </div>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Username
                </p>
                <div className="relative w-full col-span-2 flex items-center gap-5">
                  <input
                    className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                    placeholder="username"
                    value={smtpSettings.username}
                    onChange={(event) =>
                      setSmtpSettings({
                        ...smtpSettings,
                        username: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="border-b border-[#5C5C5D] pl-3 mt-8 pb-2 grid grid-cols-3">
                <p className="font-semibold text-[#747474] tracking-wide w-full">
                  Password
                </p>
                <div className="relative w-full col-span-2 flex items-center gap-5">
                  <input
                    className="text-white tracking-wide w-full bg-transparent appearance-none outline-none"
                    placeholder="password"
                    value={smtpSettings.password}
                    onChange={(event) =>
                      setSmtpSettings({
                        ...smtpSettings,
                        password: event.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <button
              onClick={() => submitSMTP()}
              className="bg-[#93A8F4] h-10 mt-5 mx-auto rounded-lg flex items-center gap-3 px-10 appearance-none outline-none"
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
