import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useStateValue } from "../context/StateProvider";
function Cheader() {
  const [{ brand }, dispatch] = useStateValue();

  const location = useLocation();
  return (
    <div className="h-16 px-8 bg-[#3F434D] text-white  flex items-center justify-between drop-shadow-md">
      <div className="">
        <img
          src={require("../assets/logo/MyRevue Logo elite new.svg").default}
          alt=""
          className="h-10 transform scale-150 ml-10"
        />
      </div>
      <div className="space-x-5">
        <Link to="/admin/home">
          <button className="bg-[#93A8F4] bg-opacity-0 hover: appearance-none outline-none px-3 py-2 rounded">
            <i className="fas fa-home"></i>
          </button>
        </Link>
        <Link to="/engagement">
          <button
            className={
              location.pathname === "/engagement"
                ? "bg-[#93A8F4]   appearance-none outline-none px-3 py-2 rounded  font-light tracking-wider"
                : "bg-[#93A8F4] bg-opacity-0 hover: appearance-none outline-none px-3 py-2 rounded font-light tracking-wider"
            }
          >
            Campaigns
          </button>
        </Link>
        {/* <Link to="/engagement/inhouserequest">
          <button
            className={
              document.URL.includes("inhouserequest")
                ? "bg-[#93A8F4]   appearance-none outline-none px-3 py-2 rounded "
                : "bg-[#93A8F4] bg-opacity-0 hover: appearance-none outline-none px-3 py-2 rounded"
            }
          >
            InHouse Request
          </button>
        </Link> */}
        <Link to="/engagement/viewfaqs">
          <button
            className={
              document.URL.includes("viewfaqs")
                ? "bg-[#93A8F4]   appearance-none outline-none px-3 py-2 rounded font-light tracking-wider"
                : "bg-[#93A8F4] bg-opacity-0 hover: appearance-none outline-none px-3 py-2 rounded font-light tracking-wider"
            }
          >
            FAQ's
          </button>
        </Link>

        <Link to="/engagement/challenges">
          <button
            className={
              document.URL.includes("challenges")
                ? "bg-[#93A8F4]   appearance-none outline-none px-3 py-2 rounded font-light tracking-wider"
                : "bg-[#93A8F4] bg-opacity-0 hover: appearance-none outline-none px-3 py-2 rounded font-light tracking-wider"
            }
          >
            Challenge
          </button>
        </Link>
        <Link to="/engagement/quiz">
          <button
            className={
              document.URL.includes("quiz")
                ? "bg-[#93A8F4]   appearance-none outline-none px-3 py-2 rounded font-light tracking-wider"
                : "bg-[#93A8F4] bg-opacity-0 hover: appearance-none outline-none px-3 py-2 rounded font-light tracking-wider"
            }
          >
            Quiz
          </button>
        </Link>
        <Link to="/engagement/analytics">
          <button
            className={
              document.URL.includes("analytics")
                ? "bg-[#93A8F4]   appearance-none outline-none px-3 py-2 rounded font-light tracking-wider"
                : "bg-[#93A8F4] bg-opacity-0 hover: appearance-none outline-none px-3 py-2 rounded font-light tracking-wider"
            }
          >
            Analytics
          </button>
        </Link>
        {/* <Link to="/collector/feed">
          <button
            className={
              document.URL.includes("feed")
                ? "bg-[#93A8F4]   appearance-none outline-none px-3 py-2 rounded"
                : "bg-[#93A8F4] bg-opacity-0 hover: appearance-none outline-none px-3 py-2 rounded"
            }
          >
            Feed
          </button>
        </Link> */}
      </div>
      <div className="">
        <Link to="/admin/settings">
          <button className="hover:bg-[#93A8F4] p-2 rounded-full transform duration-75">
            <img src={brand?.imageUrl} alt="" className="h-10 rounded-full" />
          </button>
        </Link>
      </div>
    </div>
  );
}

export default Cheader;
