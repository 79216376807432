import React, { useState } from "react";
import { useEffect } from "react";
import { useStateValue } from "../context/StateProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ProductAddedToCartList() {
  const router = useHistory();

  const [{ user }, dispatch] = useStateValue();
  const [productList, setproductList] = useState([]);

  useEffect(() => {
    async function productListCartAnalytics() {
      try {
        let headersList = {
          Accept: "*/*",
          Authorization: user?.clientSecret,
        };

        let reqOptions = {
          url: `https://api.myrevue.app/getProductListByAddToCart/7`,
          method: "GET",
          headers: headersList,
        };

        let response = sessionStorage.getItem("getProductListByAddToCart");

        if (!response) {
          response = await axios.request(reqOptions);
          sessionStorage.setItem(
            "getProductListByAddToCart",
            JSON.stringify(response.data.data)
          );
          setproductList(response.data.data);
        } else {
          setproductList(JSON.parse(response));
        }
      } catch (error) {
        toast.warning(error, {
          position: "top-right",
          icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
          bodyClassName: "text-sm tracking-wide font-work",
        });
      }
    }

    productListCartAnalytics();
  }, []);

  return (
    <div
      className="w-full bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600"
      id="thin-scrollbar"
    >
      <p className="sticky top-0 bg-[#131418] text-sm tracking-wide border-b border-[#939393] px-5 py-3 mb-3 text-[#939393]">
        Top products ordered from add to cart
      </p>
      <ul className="">
        {productList?.map((product, index) => (
          <li
            onClick={() =>
              router.push("/admin/viewproduct/" + product?.item?.id)
            }
            className="flex items-start gap-x-5 my-3 hover:bg-background px-5 py-2 cursor-pointer"
            key={index}
          >
            <div className="flex items-center gap-x-3">
              <p className="text-lg w-5">{index + 1}:</p>
              <img
                src={product?.item?.imageUrl}
                crossOrigin="anonymous"
                alt=""
                className="h-12 w-12 rounded"
              />
            </div>
            <p className="text-base text-left tracking-wide  flex-1">
              {product?.item?.name}
            </p>
          </li>
        ))}
        {productList?.length === 0 && (
          <li className="flex items-start gap-x-5 my-3 hover:bg-background px-5 py-2 cursor-pointer">
            <p className="text-base tracking-wide text-center flex-1">
              No data available for this week
            </p>
          </li>
        )}
      </ul>
    </div>
  );
}

export default ProductAddedToCartList;
