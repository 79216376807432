import {
  Binoculars,
  CaretDown,
  CaretLeft,
  CaretUp,
  Checks,
  CircleNotch,
  ClipboardText,
  FilePlus,
  Heart,
  PenNib,
  ShieldSlash,
  X,
} from "@phosphor-icons/react";
import React from "react";
import { useState } from "react";
import { useStateValue } from "../context/StateProvider";
import { toast } from "react-toastify";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
import { nanoid } from "nanoid";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { db, storage } from "../firebase";
import Modalcard from "../components/modalcard";
import { useHistory } from "react-router-dom";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import strings from "../assets/Strings";
import { Timer } from "phosphor-react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import QuizQuestion from "./QuizQuestion";

export default function CreateQuiz() {
  const router = useHistory();
  const [{ user, brand, products }, dispatch] = useStateValue();
  const [tab, settab] = useState(null);
  const [incentiveType, setInType] = useState(null);
  const [questions, setQuestions] = useState([{}, {}, {}, {}, {}]);
  const [camapignId, setcamapignId] = useState(nanoid(20));
  const [design, setDesign] = useState({
    logo: user?.imageUrl ?? null,
    primaryColor: "#0097A7",
    secondaryColor: "#02638F",
  });
  const [overview, setOverview] = useState({
    camapaignName: null,
    spokespersonlogo: null,
    welcomeMessage: null,
    spokespersonDesignation: null,
    spokespersonName: null,
    incentive: 0,
  });
  const [productInfo, setProductInfo] = useState({
    productName: null,
    productImage: null,
    productId: null,
    sku: null,
    brandName: brand?.name,
    brandId: brand?.id,
    catId: null,
    subcategory: null,
  });
  const [thankYou, setthankYou] = useState({
    headline: null,
    thankyouMessage: null,
  });
  const [productSearchText, setProductSearchText] = useState("");
  const [duration, setDuration] = useState("7");
  const [freq, setFrequency] = useState("Daily");
  const [showProductModal, setshowProductModal] = useState(false);
  const [productAdding, setproductAdding] = useState(false);
  const [finalSubmissionLoading, setfinalSubmissionLoading] = useState(false);
  const [link, setlink] = useState("");

  async function getBase64(image) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  }

  async function updateLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      showWarning("Please select an image to upload");
      return;
    }
    if (file) {
      getBase64(file)
        .then((base64String) => {
          setDesign({ ...design, logo: base64String });
          return;
        })
        .catch((error) => {
          toast.warning(error, {
            position: "top-right",
            icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
            bodyClassName: "text-sm tracking-wide font-work",
          });
          return;
        });
    }
  }

  async function updateSpokespersonLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      showWarning("Please select an image to upload");
      return;
    }
    if (file) {
      getBase64(file)
        .then((base64String) => {
          setOverview({ ...overview, spokespersonlogo: base64String });
          return;
        })
        .catch((error) => {
          showWarning(error);
          return;
        });
    }
  }

  function updateQuest(item, index) {
    var temp = Array.from(questions);
    temp.splice(index, 1, item);
    console.log(temp);
    setQuestions(temp);
  }

  async function showWarning(text) {
    toast.warning(text, {
      position: "top-right",
      icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
    return;
  }
  async function setProduct(prod) {
    if (!prod) {
      showWarning("Please select a product");
      return;
    }
    try {
      setproductAdding(true);
      let headersList = {
        Accept: "*/*",
        Authorization:
          "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD",
      };

      let reqOptions = {
        url: `https://productapi-zscu3untuq-el.a.run.app/getProduct/${prod?.id}`,
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      if (response?.data?.success === false) {
        showWarning("Something went wrong");
        return;
      }
      let data = response.data?.product;
      setProductInfo({
        productName: data?.name,
        productImage: data?.imageUrl,
        productId: data?.id,
        sku: data?.sku ?? null,
        brandName: brand?.name,
        brandId: brand?.id,
        catId: data?.catId,
        subcategory: data?.subcategory,
      });
      setproductAdding(false);
      setshowProductModal(false);
    } catch (error) {
      if (error.request) {
        showWarning(error.request);
      } else {
        showWarning(error.message);
      }
    }
  }

  async function finalSubmit() {
    setfinalSubmissionLoading(true);
    const data = {
      LogoLink: design?.logo,
      bpsId: productInfo?.brandId,
      incentive: {
        type: incentiveType,
        freeProduct: overview.freeProduct,
        cashback: overview.cashback,
        discount: overview.discount,
      },
      questions: questions,
      brandemail: user?.email,
      campaign_name: overview?.camapaignName,
      catId: productInfo?.catId,
      dateAdded: Date.now(),
      designation: overview?.spokespersonDesignation,
      headLine: thankYou.headline,
      id: camapignId,
      name: overview.spokespersonName,
      parentName: brand?.name,
      prodid: productInfo?.productId,
      productimage: productInfo?.productImage,
      productname: productInfo?.productName,
      productsku: productInfo?.sku ?? null,
      spokeLink: overview.spokespersonlogo,
      subcategory: productInfo?.subcategory ?? "",
      submissions: 0,
      textReviews: 0,
      videoReviews: 0,
      thankYouMessageRef: thankYou.thankyouMessage,
      welcomeMessage: overview.welcomeMessage,
      customizations: {
        primaryColor: design.primaryColor,
        secondaryColor: design.secondaryColor,
      },
    };
    if (!data.LogoLink) {
      showWarning("Please upload brand logo");
      setfinalSubmissionLoading(false);
      return;
    }
    if (data.incentive < 5) {
      showWarning("must be more than 5 Rupees.");
      setfinalSubmissionLoading(false);
      return;
    }

    if (!data.spokeLink) {
      showWarning("Please add spokesperson Image");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.name) {
      showWarning("Please add spokesperson Name");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data.designation) {
      showWarning("Please add spokesperson designation");
      setfinalSubmissionLoading(false);
      return;
    }

    if (!data.prodid) {
      showWarning("Please select a product");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data?.headLine) {
      showWarning("Please add a thank you headline");
      setfinalSubmissionLoading(false);
      return;
    }
    if (!data?.thankYouMessageRef) {
      showWarning("Please add a thank you message");
      setfinalSubmissionLoading(false);
      return;
    }
    if (data.LogoLink.includes("data:image") === true) {
      const contentType = data.LogoLink.split(";")[0].split(":")[1]; // Extract content type from data URL
      const fileName = `quizs/${camapignId}/logo.png`;
      const downloadURL = await uploadFile(
        data.LogoLink.split(",")[1],
        fileName,
        contentType
      );
      data.LogoLink = downloadURL;
      design.logo = downloadURL;
    }
    if (data.spokeLink.includes("data:image") === true) {
      const contentType = data.spokeLink.split(";")[0].split(":")[1]; // Extract content type from data URL
      const fileName = `quizs/${camapignId}/spokeLogo.png`;
      const downloadURL = await uploadFile(
        data.spokeLink.split(",")[1],
        fileName,
        contentType
      );
      data.spokeLink = downloadURL;
      overview.spokespersonlogo = downloadURL;
    }
    console.log(data);
    await setDoc(doc(db, "quizs/" + camapignId), data);
    toast.success("quiz created successfully", {
      position: "top-right",
      icon: <Checks size={32} color="green" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
    await updateDoc(doc(db, strings.adminCollection + "/" + user?.email), {
      quizs: user?.quizs ? user?.quizs + 1 : 1,
    });
    dispatch({
      type: "SET_USER",
      user: {
        ...user,
        quizs: user?.quizs ? user?.quizs + 1 : 1,
      },
    });
    setlink(`${window?.location?.host + "/submitquiz/" + camapignId}`);
    setfinalSubmissionLoading(null);
  }

  async function uploadFile(base64Data, fileName, contentType) {
    try {
      const storageRef = ref(storage, fileName);
      await uploadString(storageRef, base64Data, "base64", {
        contentType: contentType,
      }); // Change the contentType as needed
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.log(error);
      showWarning("Error in uploading image");
      return null;
    }
  }

  async function goBack() {
    router.push("/collector/view-quiz/" + camapignId);
  }
  async function copylink() {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard", {
      position: "top-right",
      icon: <Checks size={32} color="green" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
    return;
  }

  return (
    <div className="h-[calc(100vh-64px)] flex overflow-y-auto">
      {finalSubmissionLoading === null && (
        <>
          <Modalcard close={goBack}>
            <div className="h-[70vh] aspect-video rounded-xl bg-[#202020] p-10 space-y-2">
              <p className="text-3xl font-medium tracking-wide">
                Collect Responses
              </p>
              <p className="font-work">
                Almost done! To collect responses, you'll need to share a link
                with your target respondents. You can do this via email, SMS,
                social media, or on the web.
              </p>
              <div className="relative bg-[#2E3036] h-14 w-full rounded-xl flex items-center justify-between p-1">
                <div className="flex-1 break-words pl-3">
                  <p className="font-work">{link}</p>
                </div>
                <div
                  onClick={() => copylink()}
                  className="cursor-pointer bg-[#D9D9D9] h-full w-fit px-8 flex flex-col items-center justify-center rounded-lg text-black"
                >
                  <ClipboardText size={18} color="currentcolor" />
                  <p className="text-xs tracking-wide font-work">Copy</p>
                </div>
              </div>
              <p className="">QUICK TIPS</p>
              <ul className="pl-5">
                <li className="list-disc font-work">
                  Each time you get a response, we'll autogenerate a draft video
                  for you to review & publish.
                </li>
                <li className="list-disc font-work">
                  Ready to invite respondents but not sure what to say ? Adapt
                  our sample invitaion messages.
                </li>
                <li className="list-disc font-work">
                  Check out our11 tips for higher response rates.
                </li>
              </ul>
              <div className="w-full text-center p-5">
                <Link to={"/collector/quizs/view-quiz/" + camapignId}>
                  <button className="px-3 py-2 rounded-md hover:bg-green-600 bg-green-700 transform duration-100">
                    View quiz
                  </button>
                </Link>
              </div>
            </div>
          </Modalcard>
        </>
      )}
      <div className="w-full max-w-[576px] h-full bg-[#202020] p-8 overflow-y-auto">
        <div className="mb-3 rounded-lg">
          {(tab === null || tab === 2 || tab === 4 || tab === 3) && (
            <p className="text-[#D9D9D9] tracking-wide ">
              Welcome to <span className="text-[#EA335F]">MyRevue</span>! Create a custom quiz to engage with your customers and boost Customer Lifetime Value.
            </p>
          )}
          {tab === 0 && (
            <p className="text-[#D9D9D9] tracking-wide ">
              Configure your quiz
              <span className="text-[#EA335F]">look & feel here.</span>& add
              your <span className="text-[#EA335F]">logo</span>
            </p>
          )}
          {tab === 1 && (
            <p className="text-[#D9D9D9] tracking-wide ">
              This page greets respondents, explains how it{" "}
              <span className="text-[#EA335F]">works</span>, and{" "}
              <span className="text-[#EA335F]">
                offers optional incentives.
              </span>
              This can be different from you collector's internal name.
            </p>
          )}
          {tab === 5 && (
            <p className="text-[#D9D9D9] tracking-wide ">
              Say <span className="text-[#EA335F]">thanks</span> for
              participating and let people know you'll follow up about any
              custom incentives they've earned.
            </p>
          )}
        </div>

        <div className="">
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 0 ? settab(null) : settab(0))}
              className={`${tab === 0
                ? "border-b border-[#808080] text-white"
                : "text-[#acacac]"
                }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 0 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <PenNib size={22} color="currentcolor" />
              <p className="">Design</p>
              <div className="flex-grow"></div>
            </button>

            {tab === 0 && (
              <div className="w-full py-4">
                <div className="">
                  <p className="">Logo</p>
                  <div className="h-16  min-w-[120px] max-w-fit bg-[#ffffff] mt-2 rounded-md flex items-center justify-center p-1 text-xl relative">
                    {design?.logo === null ? (
                      <p className="">+</p>
                    ) : (
                      <img src={design?.logo} alt="" className="h-full" />
                    )}
                    <input
                      type="file"
                      onChange={(event) => updateLogo(event)}
                      className="h-full w-full absolute opacity-0"
                      accept=".png, .jpg,.jpeg"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-5 mt-3">
                  <div className="w-full">
                    <p className="">Primary Color</p>
                    <div className="mt-2 flex items-center gap-3 h-9 rounded-md border w-full focus-within:border-[#D1D1D1] focus-within:ring-[#D1D1D1] bg-transparent px-2 duration-100 focus-within:ring-1">
                      <div className="relative h-6 w-6 self-center">
                        <div className="absolute z-10 h-full w-full">
                          <input
                            type="color"
                            onChange={(event) =>
                              setDesign({
                                ...design,
                                primaryColor: event.target.value,
                              })
                            }
                            className="h-full w-full rounded-full opacity-0"
                            name=""
                            id=""
                          />
                        </div>
                        <button
                          type="button"
                          className="h-full w-full rounded-full border"
                          style={{ backgroundColor: design?.primaryColor }}
                        ></button>
                      </div>
                      <input
                        type="text"
                        value={design?.primaryColor}
                        onChange={(event) =>
                          setDesign({
                            ...design,
                            primaryColor: event.target.value,
                          })
                        }
                        className="appearance-none outline-none flex-1 border-none uppercase focus:ring-0 w-full bg-transparent placeholder:text-[#acacac]"
                        placeholder="#4E46E5"
                        name=""
                        id=""
                      ></input>
                    </div>
                  </div>
                  <div className="w-full">
                    <p className="">Background Color</p>
                    <div className="mt-2 flex items-center gap-3 h-9 rounded-md border w-full focus-within:border-[#D1D1D1] focus-within:ring-[#D1D1D1] bg-transparent px-2 duration-100 focus-within:ring-1">
                      <div className="relative h-6 w-6 self-center">
                        <div className="absolute z-10 h-full w-full">
                          <input
                            type="color"
                            onChange={(event) =>
                              setDesign({
                                ...design,
                                secondaryColor: event.target.value,
                              })
                            }
                            className="h-full w-full rounded-full opacity-0"
                            name=""
                            id=""
                          />
                        </div>
                        <button
                          type="button"
                          className="h-full w-full rounded-full border"
                          style={{ backgroundColor: design?.secondaryColor }}
                        ></button>
                      </div>
                      <input
                        type="text"
                        value={design?.secondaryColor}
                        onChange={(event) =>
                          setDesign({
                            ...design,
                            secondaryColor: event.target.value,
                          })
                        }
                        className="appearance-none outline-none flex-1 border-none uppercase focus:ring-0 w-full bg-transparent placeholder:text-[#acacac]"
                        placeholder="#4E46E5"
                        name=""
                        id=""
                      ></input>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 1 ? settab(null) : settab(1))}
              className={`${tab === 1
                ? "border-b border-[#808080] text-white"
                : "text-[#acacac]"
                }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 1 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <Binoculars size={22} color="currentcolor" />
              <p className="">Overview</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 1 && (
              <div className="w-full py-4 space-y-3">
                <div className="">
                  <p className="text-[#F8F8F8]">quiz Name</p>
                  <input
                    type="text"
                    className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                    placeholder="quiz name"
                    value={overview?.camapaignName}
                    onChange={(event) =>
                      setOverview({
                        ...overview,
                        camapaignName: event.target.value,
                      })
                    }
                    onBlur={(event) =>
                      event.currentTarget.value.length < 5
                        ? showWarning("Campaign Name must be atleast 5 letters")
                        : null
                    }
                  />
                </div>
                <div className="w-full">
                  <p className="text-[#F8F8F8] text-sm tracking-wide">
                    <span className="text-[#EA335F] text-base">
                      Spokesperson
                    </span>
                    <br />
                    Make it feel personal with a friendly face from your
                    company.
                  </p>
                  <div className="w-full flex mt-2 gap-8 items-center">
                    <div className="h-20 w-20 bg-[#434343] rounded-full flex items-center justify-center p-1 text-xl relative">
                      {overview?.spokespersonlogo === null ? (
                        <p className="">+</p>
                      ) : (
                        <img
                          src={overview?.spokespersonlogo}
                          alt=""
                          className="h-full w-full object-cover rounded-full"
                        />
                      )}
                      <input
                        type="file"
                        onChange={(event) => updateSpokespersonLogo(event)}
                        className="h-full w-full absolute opacity-0"
                        accept=".png, .jpg,.jpeg"
                      />
                    </div>
                    <div className="w-full flex-1 space-y-3">
                      <div className="">
                        <input
                          type="text"
                          className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                          placeholder="Full Name"
                          value={overview?.spokespersonName}
                          onChange={(event) =>
                            setOverview({
                              ...overview,
                              spokespersonName: event.target.value,
                            })
                          }
                          onBlur={(event) =>
                            event.currentTarget.value.length < 3
                              ? showWarning(
                                "Please enter full name of spokesperson"
                              )
                              : null
                          }
                        />
                      </div>
                      <div className="">
                        <input
                          type="text"
                          className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                          placeholder="Designation"
                          value={overview?.spokespersonDesignation}
                          onChange={(event) =>
                            setOverview({
                              ...overview,
                              spokespersonDesignation: event.target.value,
                            })
                          }
                          onBlur={(event) =>
                            event.currentTarget.value.length < 2
                              ? showWarning(
                                "Please enter designation of spokesperson"
                              )
                              : null
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <p className="text-[#F8F8F8]">Welcome Message</p>
                  <textarea
                    onChange={(event) =>
                      setOverview({
                        ...overview,
                        welcomeMessage: event.target.value,
                      })
                    }
                    value={overview?.welcomeMessage}
                    type="text"
                    className="appearance-none outline-none h-24 rounded-md p-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                    placeholder="Welcome Message"
                    onBlur={(event) =>
                      event.currentTarget.value.length < 5 &&
                      showWarning("Please add a longer welcome message")
                    }
                  />
                </div>
              </div>
            )}
          </div>

          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 2 ? settab(null) : settab(2))}
              className={`${tab === 2
                ? "border-b border-[#808080] text-white"
                : "text-[#acacac]"
                }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 2 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <FilePlus size={22} color="currentcolor" />
              <p className="">Product</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 2 && (
              <div className="w-full py-4 space-y-3 relative group">
                <div className="w-full">
                  <div className="w-full flex mt-2 gap-8 items-center">
                    <div className="h-16 w-16 rounded-full">
                      <div
                        onClick={() => setshowProductModal(!showProductModal)}
                        className=" cursor-pointer h-full w-full bg-[#434343] rounded-full text-xl flex items-center justify-center"
                      >
                        {productAdding === true ? (
                          <div className="animate-spin">
                            <CircleNotch size={22} color="white" />
                          </div>
                        ) : productInfo?.productImage === null ? (
                          <p className="">+</p>
                        ) : (
                          <img
                            src={productInfo?.productImage}
                            alt=""
                            className="h-full w-full rounded-full"
                          />
                        )}
                      </div>
                    </div>
                    <div className="w-full flex-1">
                      <div className="my-3">
                        <input
                          type="text"
                          defaultValue={productInfo?.productName}
                          readOnly
                          onFocus={() => setshowProductModal(!showProductModal)}
                          className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                          placeholder="Product Name"
                        />
                      </div>
                    </div>
                  </div>
                  {showProductModal === true && (
                    <div className="absolute mt-3 py-3 w-full h-80 overflow-y-auto bg-[#202020] z-50 border border-[#808080] rounded-md">
                      <div className="my-3 px-3 flex gap-3">
                        <input
                          type="text"
                          onChange={(event) =>
                            setProductSearchText(event.target.value)
                          }
                          className="flex-1 w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                          placeholder="Search by product name"
                        />
                        <button
                          className=""
                          onClick={() => setshowProductModal(false)}
                        >
                          <X size={32} color="white" />
                        </button>
                      </div>
                      {products
                        ?.filter((val) =>
                          val?.name
                            ?.toLowerCase()
                            ?.includes(productSearchText?.toLowerCase())
                        )
                        ?.map((prod, index) => (
                          <div
                            className="w-full flex h-16 my-3 items-center justify-between cursor-pointer hover:bg-[#2E3036] px-3"
                            key={index}
                            onClick={() => setProduct(prod)}
                          >
                            <div className="flex-1 w-full flex gap-3 items-center">
                              <div className="w-12 h-12 rounded-full">
                                <img
                                  src={prod?.imageUrl}
                                  alt="productimage"
                                  className="h-full w-full rounded-full"
                                />
                              </div>
                              <div className="flex-1 w-full">
                                <p className="w-full text-sm tracking-wide">
                                  {prod?.name}
                                </p>
                              </div>
                            </div>
                            <div className="">
                              <ReactStars
                                size={25}
                                count={5}
                                isHalf={false}
                                value={prod?.rating}
                                color="white"
                                activeColor="#FF7D58"
                                edit={false}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 3 ? settab(null) : settab(3))}
              className={`${tab === 3
                ? "border-b border-[#808080] text-white"
                : "text-[#acacac]"
                }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 3 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <Timer size={22} color="currentcolor" />
              <p className="">Questionaire</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 3 && (
              <div className="w-full py-4 space-y-3">
                {questions.map((q, index) => (
                  <QuizQuestion
                    key={"question" + index}
                    position={index}
                    quest={q}
                    quizId={camapignId}
                    updateFun={updateQuest}
                  />
                ))}
              </div>
            )}
          </div>

          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 4 ? settab(null) : settab(4))}
              className={`${tab === 4
                ? "border-b border-[#808080] text-white"
                : "text-[#acacac]"
                }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 4 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <Heart size={22} color="currentcolor" />
              <p className="">Incentives</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 4 && (
              <div className="w-full py-4 space-y-3">
                <div className=" flex flex-wrap gap-3 items-center">
                  <input
                    type="radio"
                    value="cashback"
                    name="incentives"
                    id="incentivecashback"
                    defaultChecked={incentiveType === "cashback"}
                    onClick={() => setInType("cashback")}
                  />
                  <label htmlFor="incentivecashback">Cashback</label>
                  <input
                    type="radio"
                    value="discount"
                    name="incentives"
                    id="incentivediscount"
                    defaultChecked={incentiveType === "discount"}
                    onClick={() => setInType("discount")}
                  />
                  <label htmlFor="incentivediscount">Exclusive Discount</label>
                  <input
                    type="radio"
                    value="freeProduct"
                    name="incentives"
                    id="incentivefreeProduct"
                    defaultChecked={incentiveType === "freeProduct"}
                    onClick={() => setInType("freeProduct")}
                  />
                  <label htmlFor="incentivefreeProduct">Free Product</label>
                </div>

                {incentiveType === "cashback" && (
                  <div className="">
                    <p className="text-[#F8F8F8]">Incentives per video</p>
                    <input
                      type="number"
                      className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                      placeholder="incentives in rupee"
                      defaultValue={overview.cashback ?? 0}
                      onChange={(event) =>
                        setOverview({
                          ...overview,
                          cashback: Number.parseInt(event.target.value),
                        })
                      }
                    />
                  </div>
                )}
                {incentiveType === "cashback" && (
                  <p className="w-full text-right">
                    <span className="text-3xl">
                      {(questions.length * overview.cashback).toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: "INR",
                        }
                      )}
                    </span>{" "}
                    <span className="text-sm">per User</span>
                  </p>
                )}

                {incentiveType === "discount" && (
                  <div>
                    <p className="text-[#F8F8F8]">% Discount on Order value</p>
                    <input
                      type="number"
                      className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                      placeholder="% Discount on Order value"
                      defaultValue={overview.discount ?? 0}
                      onChange={(event) =>
                        setOverview({
                          ...overview,
                          discount: Number.parseInt(event.target.value),
                        })
                      }
                    />
                  </div>
                )}
                {incentiveType === "freeProduct" && (
                  <div>
                    <p className="text-[#F8F8F8]">Free Product name</p>
                    <input
                      type="text"
                      className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                      placeholder="Product Name"
                      defaultValue={overview.freeProduct ?? ""}
                      onChange={(event) =>
                        setOverview({
                          ...overview,
                          freeProduct: event.target.value,
                        })
                      }
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="border-b border-[#808080]">
            <button
              onClick={() => (tab === 6 ? settab(null) : settab(6))}
              className={`${tab === 6
                ? "border-b border-[#808080] text-white"
                : "text-[#acacac]"
                }  tracking-wide flex items-center gap-4 py-4  transition-all duration-100 w-full`}
            >
              {tab === 6 ? (
                <CaretUp size={16} color="currentcolor" />
              ) : (
                <CaretDown size={16} color="currentcolor" />
              )}
              <Heart size={22} color="currentcolor" />
              <p className="">Thank you page</p>
              <div className="flex-grow"></div>
            </button>
            {tab === 6 && (
              <div className="w-full py-4 space-y-3">
                <div className="">
                  <p className="text-[#F8F8F8]">Headline</p>
                  <input
                    type="text"
                    className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                    placeholder="Headline"
                    value={thankYou.headline}
                    onChange={(event) =>
                      setthankYou({ ...thankYou, headline: event.target.value })
                    }
                    onBlur={(event) =>
                      event.currentTarget.value.length < 1
                        ? showWarning("Please add a headline")
                        : null
                    }
                  />
                </div>
                <div className="">
                  <p className="text-[#F8F8F8]">Message</p>
                  <textarea
                    type="text"
                    className="appearance-none outline-none h-28 rounded-md p-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
                    placeholder="Message"
                    value={thankYou.thankyouMessage}
                    onChange={(event) =>
                      setthankYou({
                        ...thankYou,
                        thankyouMessage: event.target.value,
                      })
                    }
                    onBlur={(event) =>
                      event.currentTarget.value.length < 1
                        ? showWarning("Please add a thank you message")
                        : null
                    }
                  />
                </div>
              </div>
            )}
          </div>
          <div className="mt-8">
            {finalSubmissionLoading === null ? (
              <button
                className={`bg-[#585858] hover:bg-[#121212] w-fit px-12 tracking-wide flex items-center gap-4 py-2 rounded-lg  transition-all duration-100 mx-auto    `}
              >
                <p className="">Finished</p>
              </button>
            ) : (
              <button
                onClick={() => finalSubmit()}
                className={`bg-[#585858] hover:bg-[#121212] w-fit px-12 tracking-wide flex items-center gap-4 py-2 rounded-lg  transition-all duration-100 mx-auto    `}
              >
                {finalSubmissionLoading === true ? (
                  <span className="animate-spin">
                    <CircleNotch size={22} color="white" />
                  </span>
                ) : (
                  <p className="">Finish</p>
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="w-full flex-1 h-full flex items-center justify-center px-10">
        <div className="relative flex flex-none flex-col overflow-hidden bg-[#20202060] shadow-xl ring-4 ring-white duration-300 w-full rounded-md h-[90%] p-5">
          {[0, 1, 2, 3, 4, 5, null].includes(tab) && (
            <div
              className={`h-full aspect-[9/17] xl:aspect-[9/18] relative rounded-lg mx-auto `}
              style={{ backgroundColor: design.secondaryColor }}
            >
              <div
                className="overflow-y-auto h-full  relative"
                id="thin-scrollbar"
              >
                <div className="pt-10 px-5   flex items-center justify-center">
                  <div className="bg-white h-16 py-2 overflow-hidden px-8 rounded-xl flex items-center justify-center ">
                    <img
                      src={
                        design.logo ??
                        "https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                      }
                      alt=""
                      className="h-full mx-auto "
                    />
                  </div>
                </div>
                <div className="px-5 mt-5">
                  <div className="">
                    <p className="font-medium tracking-wider text-center">
                      {overview?.camapaignName ?? "Quiz title"}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center my-5">
                    <div>
                      {overview?.spokespersonlogo ? (
                        <img
                          src={overview?.spokespersonlogo}
                          alt=""
                          className="h-16 w-16 mx-auto rounded-full object-cover my-2"
                        />
                      ) : (
                        <div className="bg-white h-16 w-16 rounded-full text-pink-500 flex items-center justify-center text-3xl">
                          {overview?.spokespersonlogo
                            ?.split(" ")[0]
                            .substring(0, 2)
                            .toUpperCase() ?? "NA"}
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <p className="text-center tracking-wide ">
                        {overview?.spokespersonName ?? "Spokesperson Name"}
                      </p>
                      <p className="text-center tracking-wide font-light  opacity-50">
                        {overview?.spokespersonDesignation ??
                          "Spokesperson Designation"}
                      </p>
                    </div>
                    <div className="border-l-[3px] border-red-500 mt-5">
                      <p className="px-2 tracking-wide">
                        {overview?.welcomeMessage ?? "Welcome to MyRevue"}
                      </p>
                    </div>
                    <div className="flex flex-col items-center mt-12">
                      <p className="text-lg font-medium">How it Works</p>
                      <div className="flex gap-x-2 mt-5 w-full">
                        <img
                          src="https://vocalvideo.com/assets/icons/record-blurb-mobile-ddb01ba6be7d6a634b3af138233feacb91bcdaa39f496d14eede0627ab2c573b.svg"
                          alt=""
                          className=""
                        />
                        <p className="flex-1 w-full tracking-wide text-sm text-center">
                          Select the correct answer and win {isNaN(questions.length * overview.incentive) === false ? (questions.length * overview.incentive).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: "INR",
                            }
                          ) : "____"} cashback incentive.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="absolute w-full flex flex-col pb-5 justify-end px-5 h-32 bg-gradient-to-b from-transparent via-transparent bottom-0 rounded-b-lg"
                style={{ "--tw-gradient-to": "#00000069" }}
              >
                <button
                  className="py-3 rounded-lg appearance-none outline-none w-full"
                  style={{ backgroundColor: design?.primaryColor }}
                >
                  Let's Go
                </button>
              </div>
            </div>
          )}
          {tab === 6 && (
            <div
              className={`h-full aspect-[9/17] xl:aspect-[9/18] relative rounded-lg mx-auto `}
              style={{ backgroundColor: design.secondaryColor }}
            >
              <div
                className="overflow-y-auto h-full px-5 py-5 relative"
                id="thin-scrollbar"
              >
                <div className="bg-white bg-opacity-10 h-14 w-14 rounded-lg flex items-center justify-center">
                  <CaretLeft size={22} color="white" />
                </div>
                <div className="mt-8 ">
                  <div className="mt-12 flex flex-col justify-center">
                    <div className="border-l-[3px] border-red-500 ">
                      <p className="px-2 text-3xl font-medium">
                        🎉 {thankYou?.headline ?? "HeadLine"}
                      </p>
                      <p className="px-2 text-xl font-light font-sans tracking-wide mt-3">
                        {thankYou?.thankyouMessage ?? "Thank you message"}
                      </p>
                    </div>
                    <div className="mt-8 flex items-center gap-x-3">
                      <div>
                        {overview?.spokespersonlogo ? (
                          <img
                            src={overview?.spokespersonlogo}
                            alt=""
                            className="h-16 w-16 mx-auto rounded-full object-cover my-2"
                          />
                        ) : (
                          <div className="bg-white h-16 w-16 rounded-full text-pink-500 flex items-center justify-center text-3xl">
                            {overview?.spokespersonName
                              ?.split(" ")[0]
                              .substring(0, 2)
                              .toUpperCase() ?? "NA"}
                          </div>
                        )}
                      </div>
                      <div className="">
                        <p className="tracking-wide ">
                          {overview?.spokespersonName ?? "Spokesperson Name"}
                        </p>
                        <p className="tracking-wide font-light  opacity-50">
                          {overview?.spokespersonDesignation ??
                            "Spokesperson Designation"}
                        </p>
                      </div>
                    </div>
                    <div className=" flex items-center justify-end mt-16">
                      <div className="w-full bg-white  flex flex-col rounded-2xl p-10 items-center justify-center">
                        <p className="text-lg font-sans tracking-widest uppercase text-black ">
                          POWERED BY
                        </p>
                        <img
                          src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FMyRevue%20Logo%20(1)%201.svg?alt=media&token=f3878494-f182-43c4-bbfb-6d1f7247af27"
                          alt=""
                          className="h-14 my-3"
                        />
                        <p className="text-black mt-3 italic text-center">
                          <span className="text-pink-500 font-medium  underline underline-offset-2 not-italic">
                            MyRevue
                          </span>
                          is India's First Product Discovery Platform To Get 360 overview about any product and brand.
                          Create and post your videos on the MyRevue App to receive guaranteed cashbacks.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
