import React from 'react'
import NotLoginHeaderNew from '../layouts/NotLoginHeaderNew'
import NotloginfooterNew from '../layouts/NotloginfooterNew'

export default function Refundpolicy() {
    return (
        <div className="bg-[#101010] ">
            <NotLoginHeaderNew />
            <div className='p-10'>
                <h1 className='text-center text-xl uppercase pb-5'>Refund Policy</h1>
                <p className="py-2 font-work tracking-wide">At MyRevue, we strive to provide our customers with the best experience possible. If you are not satisfied with your purchase, we offer a 14-day refund policy.</p>

                <p className="py-2 font-work tracking-wide">To be eligible for a refund, you must submit a refund request within 14 days of the date of purchase. You can submit a refund request by emailing our customer support team at connect@myrevue.app</p>

                <p className="py-2 font-work tracking-wide">Please include your order number and the reason for your refund request. We may require additional information to process your request.</p>

                <p className="py-2 font-work tracking-wide">Once your refund request is received, we will review it and determine whether a refund is appropriate. If your request is approved, we will issue a refund within 7-10 business days of approval.</p>

                <p className="py-2 font-work tracking-wide">Please note that we reserve the right to refuse refunds in cases where we believe the refund policy is being abused or misused.</p>

                <p className="py-2 font-work tracking-wide">Thank you for choosing MyRevue, and we apologize for any inconvenience you may have experienced. If you have any questions or concerns, please do not hesitate to contact us at connect@myrevue.app</p>
            </div>
            <NotloginfooterNew />
        </div>
    )
}