import { async } from '@firebase/util'
import React from 'react'
import { useStateValue } from '../context/StateProvider'
import { useState } from 'react';
import { Checks, ShieldSlash } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import { addDoc, collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useEffect } from 'react';
import Modalcard from '../components/modalcard';
import { useLocation } from 'react-router-dom';

export default function Badges() {
    const [selected, setSelected] = useState([]);
    const [added, setAdded] = useState(false);

    const [{ brand }, dispatch] = useStateValue();
    const [modal, setModal] = useState(false);
    const location = useLocation();

    useEffect(() => {
        getDoc(doc(db, "badges", brand.id), document).then((res) => {
            setSelected(res.data().badges);
            setAdded(true)
        })
    }, [])


    async function selectBadge(value) {
        const index = selected.indexOf(value);

        if (index !== -1) {
            const updatedSelected = [...selected.slice(0, index), ...selected.slice(index + 1)];
            setSelected(updatedSelected);
        } else {

            if (selected.length >= 2) {
                toast.warning("You can select maxiumum 2 badges.", {
                    position: "top-right",
                    icon: <ShieldSlash size={32} color="red" weight="bold" />,
                    bodyClassName: "text-sm tracking-wide font-work"
                });
                return
            }
            setSelected([...selected, value]);
        }
    }


    async function submitBadged() {
        const document = {
            brandId: brand.id,
            badges: selected
        }
        await setDoc(doc(db, "badges", brand.id), document).then((res) => {
            toast.success(`Badges enabled for ${brand?.name}`, {
                position: "top-right",
                icon: <Checks size={32} color="green" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            setModal(true)
        })
    }

    async function copyCode(value) {
        navigator.clipboard.writeText(value)
        toast.success("Link copied to clipboard", {
            position: "top-right",
            icon: <Checks size={32} color="green" weight="bold" />,
            bodyClassName: "text-sm tracking-wide font-work"
        });
        return
    }
    function getBaseUrl(url) {
        const parsedUrl = new URL(url);
        return `${parsedUrl.protocol}//${parsedUrl.host}/`;
    }

    const baseUrl = getBaseUrl(window.location.href);

    return (
        <div className=''>
            {modal && <Modalcard close={setModal}>
                <div className="h-[70vh] aspect-video rounded-xl bg-[#202020] p-10 space-y-2 z-100">
                    <p className="text-3xl font-medium tracking-wide">Embed</p>
                    <p className="font-work">
                        Almost done! To add badge on your site follow below steps.
                    </p>
                    <div className="grid grid-cols-2 w-full gap-5 my-5">
                        {selected.map((badge, index) => (
                            <div key={index} className="h-40 w-full rounded-md bg-[#2d2e30] flex gap-3">
                                <div className="h-full aspect-square rounded-l-md flex items-center justify-center bg-white">
                                    <img src={require(`../assets/images/badges/${badge}.png`)} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                                </div>
                                <div className="flex-1 w-full h-full overflow-y-auto py-2 cursor-text select-text break-words" id='thin-scrollbar' onClick={() => copyCode(`<iframe src="${baseUrl}badges/${brand?.id}/${badge}" height="250" width="250" frameborder="0"></iframe>`)}>
                                    {`<iframe src="${baseUrl}badges/${brand?.id}/${badge}" height="250" width="250" frameborder="0"></iframe>`}
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className="">
                        Integration
                    </p>
                    <ul className='pl-5'>
                        <li className='list-disc font-work'>
                            Copy the emebed code for particular badge.
                        </li>
                        <li className='list-disc font-work'>
                            Add this code in your website.
                        </li>
                    </ul>
                </div>
            </Modalcard>}
            <div className="px-10 py-5">
                <p className="text-white text-3xl tracking-wide font-medium">
                    Badges
                </p>
            </div>
            <p className="text-white text-lg text-center tracking-wide">
                Select maximum of 2 badges you want to use on your site
            </p>
            <div className="px-10 my-5 flex flex-wrap gap-8">
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(1) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(1)}>
                    <img src={require("../assets/images/badges/1.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(2) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(2)}>
                    <img src={require("../assets/images/badges/2.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(3) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(3)}>
                    <img src={require("../assets/images/badges/3.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(4) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(4)}>
                    <img src={require("../assets/images/badges/4.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(5) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(5)}>
                    <img src={require("../assets/images/badges/5.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(6) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(6)}>
                    <img src={require("../assets/images/badges/6.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(7) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(7)}>
                    <img src={require("../assets/images/badges/7.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(8) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(8)}>
                    <img src={require("../assets/images/badges/8.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(9) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(9)}>
                    <img src={require("../assets/images/badges/9.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(10) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(10)}>
                    <img src={require("../assets/images/badges/10.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(11) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(11)}>
                    <img src={require("../assets/images/badges/11.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(12) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(12)}>
                    <img src={require("../assets/images/badges/12.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(13) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(13)}>
                    <img src={require("../assets/images/badges/13.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
                <div className={`h-40 aspect-square bg-[#131418] rounded-md flex items-center justify-center cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.includes(14) ? "border border-[#63D876]" : ""}`} onClick={() => selectBadge(14)}>
                    <img src={require("../assets/images/badges/14.png")} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                </div>
            </div>
            <div className="">
                <button onClick={() => submitBadged()} className="bg-[#585858] hover:bg-[#121212] w-fit px-12 tracking-wide flex items-center gap-4 py-2 rounded-lg  transition-all duration-100 mx-auto  ">Submit</button>
            </div>

            {added &&
                <div className="h-auto m-10 rounded-xl bg-[#202020] p-10 space-y-2 z-100">
                    <p className="text-3xl font-medium tracking-wide">Embed</p>
                    <p className="font-work">
                        Almost done! To add badge on your site follow below steps.
                    </p>
                    <div className="grid grid-cols-2 w-full gap-5 my-5">
                        {selected.map((badge, index) => (
                            <div key={index} className="h-40 w-full rounded-md bg-[#2d2e30] flex gap-3">
                                <div className="h-full aspect-square rounded-l-md flex items-center justify-center bg-white">
                                    <img src={require(`../assets/images/badges/${badge}.png`)} alt="" className="h-auto w-auto max-w-[80%] max-h-[80%]" />
                                </div>
                                <div className="flex-1 w-full h-full overflow-y-auto py-2 cursor-text select-text break-words" id='thin-scrollbar' onClick={() => copyCode(`<iframe src="${baseUrl}badges/${brand?.id}/${badge}" height="250" width="250" frameborder="0"></iframe>`)}>
                                    {`<iframe src="${baseUrl}badges/${brand?.id}/${badge}" height="250" width="250" frameborder="0"></iframe>`}
                                </div>
                            </div>
                        ))}
                    </div>
                    <p className="">
                        Integration
                    </p>
                    <ul className='pl-5'>
                        <li className='list-disc font-work'>
                            Copy the emebed code for particular badge.
                        </li>
                        <li className='list-disc font-work'>
                            Add this code in your website.
                        </li>
                    </ul>
                </div>
            }
        </div>
    )
}
