import React, { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { sendAmplitudeData } from "../amplitude";
import { Eye, Hexagon, Plus, ShieldSlash } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import { useStateValue } from "../context/StateProvider";
import Cstats from "../pages/view/Cstats";

function Challenge() {
  const [challenges, setchallenges] = useState([]);
  const [loading, setloading] = useState(true);
  const [{ user }, dispatch] = useStateValue();

  const { path } = useRouteMatch();
  const router = useHistory();

  useEffect(() => {
    sendAmplitudeData("challenge dashboard page opened");

    const q = query(
      collection(db, "challenges"),
      where("brandemail", "==", user?.email)
    );

    getDocs(q).then((res) => {
      const challengesData = res.docs.map((doc) => doc.data());
      setchallenges(challengesData);
      setloading(false);
    });
  }, []);

  function statusChallenge(form) {
    if (!form.startDate)
      return <p className="bg-gray-700 rounded-md py-1 px-2">Draft</p>;
    let isExpired = Date.now() > form.startDate + 86400000 * form.duration;
    if (isExpired)
      return <p className="bg-red-700 rounded-md py-1 px-2">Expired</p>;
    return <p className="bg-green-700 rounded-md py-1 px-2">Live</p>;
  }

  async function changeRoute() {
    let limit = user?.limit?.challangeLimit ?? 1;
    if (challenges.length >= limit) {
      toast.warning("challenge limit exceeded, Please upgrade your plan.", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="red" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    router.push(path + "/create");
  }
  async function showWarning(text) {
    toast.warning(text, {
      position: "top-right",
      icon: <ShieldSlash size={32} color="red" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
    return
  }
  if (loading)
    return (
      <div className=" min-h-screen -mt-16 relative flex items-center justify-center overflow-y-auto bg-[#323336] py-16 px-20">
        <p className="">Gettings your challenges ready</p>
      </div>
    );

  if (challenges.length === 0) {
    return (
      <div className=" min-h-screen -mt-16 relative overflow-y-auto py-16 px-20 ">
        <div className="">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Ffaq%20initial%20login%20image.png?alt=media&token=e461dbfd-68e5-44c8-aeea-69a539e20f54"
            alt="rightImage"
            className="absolute right-0 bottom-0 max-h-[80vh]"
          />
        </div>
        <div className="pt-28">
          <p className="text-6xl font-semibold">Welcome</p>
          <div className="mt-8">
            <p className="tracking-wider font-medium text-xl mb-1">
              What is challenge ?
            </p>
            <p className="tracking-wider font-light">
              Start by creating a challange. This will let you remotely
              gather videos from any device just by sharing a link.
            </p>
          </div>
          <div className="my-8">
            <button
              className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
              onClick={() => changeRoute()}
            >
              <Plus size={22} color="white" /> Create challenge
            </button>
          </div>
        </div>
        <div className="w-fit text-center h-auto py-5 p-3 my-2 border-2 rounded-xl border-white">
          <p className="mx-auto font-medium tracking-wider text-lg">
            Challenges used
          </p>
          <div className="mt-5 text-3xl">
            <span className="">
              {user?.challenges ?? challenges?.length} / <span className="">{user?.limit?.challangeLimit ?? 1}</span>
            </span>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className=" min-h-screen">
        <div className="px-10 py-5">
          <div className="w-full lg:max-w-[70%] mx-auto grid grid-cols-2 gap-5">
            <div className="w-full text-center h-auto py-5 p-3 my-2 border-2 rounded-xl border-white">
              <p className="mx-auto font-medium tracking-wider text-lg">
                Challenges used
              </p>
              <div className="mt-5 text-3xl">
                <span className="">
                  {user?.challenges ?? challenges?.length}/ <span className="">{user?.limit?.challangeLimit ?? 1}</span>
                </span>
              </div>
            </div>
            <div className="w-full text-center h-auto py-5 p-3 my-2 border-2 rounded-xl border-white">
              <p className="mx-auto font-medium tracking-wider text-lg">
                Downloads used
              </p>
              <div className="mt-5  text-3xl">
                <span className="">
                  {user?.downloads ?? 0} /
                  <span className="">
                    {user?.downloadLimit === 5000
                      ? "Unlimited"
                      : user?.downloadLimit ?? 3}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between p-10">
          <div className="">
            <p className="text-3xl font-medium">Your challenges</p>
            <p className="tracking-wide">
              Use challenges to collect testimonials from your users.
            </p>
          </div>
          <div className="">
            <button
              className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
              onClick={() => user?.challenges >= 10 ? showWarning('Challnages limit exceeded, Please upgrade to continue') : changeRoute()}
            >
              <Plus size={22} color="white" /> Create challenge
            </button>
          </div>
        </div>

        <div className="grid  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full p-5 gap-x-6 gap-y-16 pb-10 ">
          {challenges?.map((form, index) => (
            <div
              className=" cursor-pointer w-full bg-[#232323] h-[173px] rounded-3xl relative flex flex-col justify-between"
              key={index}
              onClick={() => router.push(path + "/view-challenge/" + form?.id)}
            >
              <div className="">
                <div className="flex items-center p-3 gap-3">
                  <img
                    src={form?.productimage}
                    alt="thumbnail"
                    className="h-[85px] aspect-square rounded-lg -mt-8"
                  />
                  <div className="">
                    <p className="">{statusChallenge(form)}</p>
                  </div>
                </div>
                <div className="flex justify-between items-center px-3">
                  <div className="">
                    <div className="flex items-center gap-3">
                      <p className="font-work">{form?.campaign_name}</p>
                    </div>
                    <div className="flex items-center justify-between gap-3">
                      <p className="font-work">{form.duration ?? 0} Days</p>
                      <p className="font-work">{form.frequency ?? 0} </p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="h-6 w-6 flex items-center justify-center relative">
                      <Hexagon size={22} weight="light" />
                      <Eye size={10} className="absolute" weight="bold" />
                    </div>
                    <p className="text-[8px] tracking-wide">View</p>
                  </div>
                </div>
              </div>
              <div className="h-auto text-sm font-light tracking-wide py-2 px-3 bg-[#2C2C2C] text-[#D9D9D9] rounded-b-3xl">
                {form?.productname}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}

export default Challenge;
