import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./awn.css";
import "./App.css";
import Layout from "./layouts/layout";
import Login from "./pages/login";
import ScrollToTop from "./components/scrolltotop";
import SignUp from "./pages/SignUp";
import VideoEditorPage from "./pages/VideoEditorPage";
import ChoosePlan from "./pages/ChoosePlan";
import LandingPage from "./LandingPage";
import CollectorLayout from "./layouts/CollectorLayout";
import MUserView from "./pages/view/UserView";
import Test from "./Test";
import Whyvideoreviews from "./whyvideoreviews";
import Studio from "./pages/studio";
import Forgotpassword from "./forgotpassword";
import MasterLogin from "./pages/masterlogin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Refundpolicy from "./pages/refundpolicy";
import Cancellationpolicy from "./cancellationpolicy";
import Terms from "./terms";
import Privacy from "./privacy";
import Becomepartner from "./becomepartner";
import Servicereview from "./servicereview";
import Feedback from "./feedback";
import Feed from "./pages/feed";
import Faqplayer from "./pages/faqplayer";
import Saas from "./Saas";
import GetAVideo from "./getAvideo";
import Showcase from "./showcase";
import Userviewsfaq from "./pages/view/userviewsfaq";
import Influencer from "./influencer";
import InfluencerApplyNow from "./influencerApplyNow";
import ChallangeUserView from "./pages/view/ChallangeUserView";
import Badgesembed from "./pages/badgesembed";
import QuizUserView from "./quiz/QuizUserView";
import FourZeroFour from "./pages/404";
import QuizUserPage from "./quiz/QuizUserPage";

export default function App() {
  useEffect(() => {
    function handleContextMenu(e) {
      if (e.target.tagName === "VIDEO") {
        e.preventDefault(); // Prevent the default context menu for video elements
      }
    }

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);
  return (
    <div className=" text-white  font-Poppins" id="main-background">
      <Router>
        <title>MyRevue Brand Admin</title>
        <ToastContainer
          toastStyle={{ backgroundColor: "#2a2a2a", color: "white" }}
        />
        <ScrollToTop />
        <Switch>
          <Route path="/admin" component={Layout} />
          <Route path="/badges/:brandId/:badge" component={Badgesembed} />
          <Route path="/embed/feed/:id" component={Feed} />
          <Route
            path="/embed/faqplayer/:campaignid/:id"
            component={Faqplayer}
          />
          <Route path="/plans" component={ChoosePlan} />
          {/* <Route path="/userView/:form" component={ChallangeUserView} /> */}
          <Route path="/userView/:form" component={MUserView} />
          <Route path="/submitChallenge/:form" component={ChallangeUserView} />
          <Route path="/submitQuiz/:form" component={QuizUserPage} />
          <Route path="/faquserview/:form" component={Userviewsfaq} />
          <Route path="/engagement" component={CollectorLayout} />
          <Route path="/studio" component={Studio} />
          <Route path="/editVideo/:reviewId" component={VideoEditorPage} />
          <Route path="/login" exact component={Login} />
          <Route path="/applynow" exact component={InfluencerApplyNow} />
          <Route path="/influencer" exact component={Influencer} />
          <Route path="/masterlogin" exact component={MasterLogin} />
          <Route path="/test" exact component={Test} />
          <Route path="/signup" exact component={SignUp} />
          <Route path="/whyvideoreviews" exact component={Whyvideoreviews} />
          <Route path="/refundpolicy" exact component={Refundpolicy} />
          <Route path="/privacy-policy" exact component={Privacy} />
          <Route path="/become_partner" exact component={Becomepartner} />
          <Route path="/serviceReview" exact component={Servicereview} />
          <Route path="/feedback" exact component={Feedback} />
          <Route path="/terms-and-conditions" exact component={Terms} />
          <Route
            path="/cancellationpolicy"
            exact
            component={Cancellationpolicy}
          />
          <Route path="/forgotpassword" exact component={Forgotpassword} />
          <Route path="/saas" exact component={Saas} />
          <Route path="/getavideo" exact component={GetAVideo} />
          <Route path="/showcase" exact component={Showcase} />
          <Route path="/" exact component={LandingPage} />
          <Route path={`/*`} component={FourZeroFour} />
        </Switch>
      </Router>
    </div>
  );
}
