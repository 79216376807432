import { collection, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { db } from '../firebase'
import { useStateValue } from '../context/StateProvider';
import AWN from 'awesome-notifications';
import { Eye } from 'phosphor-react';
import { useHistory } from 'react-router';
import moment from 'moment';
import axios from 'axios';
import { Plus } from '@phosphor-icons/react';
import { useLocation } from 'react-router-dom';

export default function InHouseRequest() {
    const [{ brand, user }, dispatch] = useStateValue();
    const router = useHistory();
    const [login, setlogin] = useState(true);
    const [campaigns, setcampaigns] = useState([]);
    const [draftCampaigns, setDraftCampaigns] = useState([]);
    const search = useLocation().search
    const searchParams = new URLSearchParams(search);
    useEffect(() => {
        async function getInhouseRequests() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
            }

            let reqOptions = {
                url: `https://brandadminapi-zscu3untuq-el.a.run.app/getAllHouseRequest/${brand?.id}`,
                method: "GET",
                headers: headersList,
            }

            const response = await axios.request(reqOptions).then((res) => res?.data?.data);
            setcampaigns(response);
        }
        async function getDraftInhouseRequest() {
            await getDocs(collection(db, "brandAdmin", user?.email, "draftGetaVideo")).then((snapshot) => {
                setDraftCampaigns(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
            })
        }
        getDraftInhouseRequest();
        getInhouseRequests()
    }, [])

    return (
        <div className="">
            <div className="flex items-center justify-between mx-10 mt-10">
                <div className="">
                </div>
                <div className="">
                    <button className="bg-[#1e1f22] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center gap-3" onClick={() => router.push("/admin/requestcampaign")}> <Plus size={22} color='white' />Create</button>
                </div>
            </div>
            <div className="relative block m-10">
                <div className="relative">
                    <div className="overflow-x-auto min-h-[0.01%]">
                        <table className="bg-[#131418] rounded-t-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                            <thead className="">
                                <tr>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        #
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Id
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Campaign
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Status
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Date Added
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Videos
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Price
                                    </th>
                                    <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="table-row-group align-middle border-inherit">
                                {draftCampaigns?.sort((a, b) => b.dateAdded - a.dateAdded)?.map((campaign, index) => (
                                    <tr className="table-row align-[inherit] border-inherit" key={index}>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            {index + 1}
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            {campaign?.id}
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            {campaign?.productDetails?.productName && `${campaign?.productDetails?.productName} ,`} {campaign?.videoType}
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            Draft
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            {moment(campaign?.dateAdded).format("DD/MM/YYYY")}
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle text-center h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            {campaign?.approved ?? 0}/{campaign?.videos}
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            $ {parseInt(campaign?.paymentToken?.amount ?? 0)}
                                        </td>
                                        <td
                                            className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                                            onClick={() => {
                                                // sessionStorage.setItem('inhouserequest', JSON.stringify(campaign))
                                                router.push("/admin/requestcampaign/draft/" + campaign?.id)
                                            }}
                                        >
                                            <div className="flex items-center gap-x-3">
                                                View <Eye size={24} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                                {campaigns?.sort((a, b) => b.dateAdded - a.dateAdded)?.map((campaign, index) => (
                                    <tr className="table-row align-[inherit] border-inherit" key={index}>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            {index + 1}
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            {campaign?._id}
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            {campaign?.productDetails?.productName}, {campaign?.videoType}
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            {campaign?.status}
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            {moment(campaign?.dateAdded).format("DD/MM/YYYY")}
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle text-center h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            {campaign?.approved ?? 0}/{campaign?.videos}
                                        </td>
                                        <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                            $ {parseInt(campaign?.paymentToken?.amount ?? 0)}
                                        </td>
                                        <td
                                            className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide border-t-[1px] border-[#ececec1b] font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                                            onClick={() => {
                                                sessionStorage.setItem('inhouserequest', JSON.stringify(campaign))
                                                router.push("/collector/requestcampaign/" + campaign?._id)
                                            }}
                                        >
                                            <div className="flex items-center gap-x-3">
                                                View <Eye size={24} />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {(draftCampaigns.length + campaigns.length) === 0 && <div className="h-14 bg-[#121212] rounded-b-md flex items-center justify-center">
                            <p className="tracking-wide ">No campaigns available</p>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}
