import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom";
import { db } from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
import { sendAmplitudeData } from "../amplitude";
import { useStateValue } from "../context/StateProvider";
import moment from "moment";
import { Plus } from "@phosphor-icons/react";

function ViewProductCampaigns() {
  const [forms, setforms] = useState([]);
  const [loading, setloading] = useState(true);
  const [{ user }, dispatch] = useStateValue();

  const { path } = useRouteMatch();
  const { prodId } = useParams();

  const router = useHistory();

  useEffect(() => {
    const q = query(
      collection(db, "forms"),
      where("brandemail", "==", user?.email),
      where("prodid", "==", prodId)
    );

    getDocs(q).then((res) => {
      var a = res.docs.map((r) => ({ ...r.data(), id: r.id }));
      setforms(a);
      setloading(false);
    });

    sendAmplitudeData("viewing product campaigns");
  }, []);

  return (
    <div className=" min-h-screen">
      {loading ? (
        <div className=" flex items-center justify-center h-96">
          <p className="">Gettings your forms ready</p>
        </div>
      ) : (
        <>
          <div className="flex items-center justify-between p-10">
            <div className="">
              <p className="text-2xl">Product Campaigns</p>
              <p className="">
                Use campaigns to collect testimonials from your users.
              </p>
            </div>
            <div className="">
              <button
                className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
                onClick={() => router.push("/engagement/chooseTemplate")}
              >
                <Plus size={22} color="white" /> Create Campaign
              </button>
            </div>
          </div>

          <div className="px-10 pb-10 space-y-5">
            {forms?.map((form, index) => (
              <div
                className="h-20 bg-[#1e1f22] rounded-md flex items-center px-2 cursor-pointer"
                key={index}
                onClick={() =>
                  router.push("/engagement/view-campaign/" + form.id)
                }
              >
                <div className="w-full h-full flex items-center justify-between py-2">
                  <div className="flex-1 flex h-full gap-3 w-full items-center">
                    <div className="h-full aspect-square">
                      <img
                        src={form?.productimage}
                        alt=""
                        className="rounded-md h-full w-full object-cover"
                      />
                    </div>
                    <div className="flex-1 w-full">
                      <p className=" text-[#dedede] tracking-wide">
                        Camapaign Name : {form?.campaign_name}
                      </p>
                      <p className="text-[#707070] text-sm tracking-wide">
                        Spokesperson : {form?.name}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col text-right">
                    <p className="text-[#B7B7B7] text-lg font-medium tracking-wide">
                      {!form?.dateAdded
                        ? "Not available"
                        : moment(form?.dateAdded).format("DD/MM/YYYY")}
                    </p>
                    <p className=" text-[#dedede] tracking-wider text-sm">
                      Submissions : {form?.submissions}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
        // <div className=" flex flex-wrap mt-10">
        //   {forms.map((form, index) => (
        //     <Link
        //       key={"campaign" + index}
        //       to={"/engagement/view-campaign/" + form.id}
        //     >
        //       <div
        //         className="m-2 bg-[#3E4046] w-64 h-[350px] flex items-center pt-2 rounded-lg  flex-col"
        //         key={index}
        //       >
        //         <img
        //           src={form.productimage}
        //           alt=""
        //           className="h-60 w-60 object-cover rounded-lg cursor-pointer"
        //         />
        //         <p className="pt-2 px-2 pb-1 text-sm tracking-wide text-center">
        //           Product Name : {form?.productname}
        //         </p>
        //         <p className="py-1 px-2 text-sm tracking-wide text-center">
        //           Spokesperson : {form?.name}
        //         </p>
        //       </div>
        //     </Link>
        //   ))}
        // </div>
      )}
    </div>
  );
}

export default ViewProductCampaigns;
