import React, { useEffect } from "react";
import { useStateValue } from "../context/StateProvider";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";
import WebTotalViews from "../analytics/WebTotalViews";
import VideoGuideViews from "../analytics/VideoGuideViews";
import MasterFaqVideoes from "../analytics/MasterFaqVideoes";
import VideoShowCaseViews from "../analytics/VideoShowCaseViews";
import ProductFaqViews from "../analytics/ProductFaqViews";
import ProductAddedToCartList from "../analytics/ProductAddedToCartList";
import ProductListByViews from "../analytics/ProductListByViews";

export default function Analytics() {
  const [{ user }, dispatch] = useStateValue();
  const [brandTotalAnalytics, setBrandTotalAnalytics] = useState([]);

  useEffect(() => {
    async function getBrandTotalAnalytics() {
      try {
        let headersList = {
          Accept: "*/*",
          Authorization: user?.clientSecret,
        };

        let reqOptions = {
          url: `https://api.myrevue.app/getBrandPluginsAnalyticsCount`,
          method: "GET",
          headers: headersList,
        };
        let response = sessionStorage.getItem("getBrandPluginsAnalyticsCount");

        if (!response) {
          console.log("--------FETCHING DB----------");
          response = await axios.request(reqOptions);
          sessionStorage.setItem(
            "getBrandPluginsAnalyticsCount",
            JSON.stringify(response.data.data)
          );
          setBrandTotalAnalytics(response.data.data);
        } else {
          response = JSON.parse(response);
          setBrandTotalAnalytics(response);
        }
      } catch (error) {
        toast.warning(error, {
          position: "top-right",
          icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
          bodyClassName: "text-sm tracking-wide font-work",
        });
      }
    }
    getBrandTotalAnalytics();
  }, [user]);
  console.log(brandTotalAnalytics);

  const totalViews =
    brandTotalAnalytics?.masterFaq +
    brandTotalAnalytics?.views +
    brandTotalAnalytics?.productFaq +
    brandTotalAnalytics?.homeGuide;
  if (totalViews)
    return (
      <div className="">
        <div className="px-10 py-5">
          <p className="text-white text-3xl tracking-wide font-medium">
            Website Analytics
          </p>
        </div>
        <div className="grid grid-cols-5 px-10 gap-5 my-5">
          <div className="p-5 bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600">
            <p className=""> Website Total Views</p>
            <p className=" text-3xl my-5">{totalViews}</p>
          </div>
          <div className="p-5 bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600">
            <p className=""> Video Guide Total Views</p>
            <p className=" text-3xl my-5">
              {brandTotalAnalytics?.homeGuide ?? 0}
            </p>
          </div>
          <div className="p-5 bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600">
            <p className=""> Master FAQ's Total Views</p>
            <p className=" text-3xl my-5">
              {brandTotalAnalytics?.masterFaq ?? 0}
            </p>
          </div>
          <div className="p-5 bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600">
            <p className=""> Product FAQ's Total Views</p>
            <p className=" text-3xl my-5">
              {brandTotalAnalytics?.productFaq ?? 0}
            </p>
          </div>
          <div className="p-5 bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600">
            <p className=""> Product Added To Cart </p>
            <p className=" text-3xl my-5">
              {brandTotalAnalytics?.addTocart ?? 0}
            </p>
          </div>
        </div>
        <div className="w-full grid grid-cols-2 px-10 gap-10">
          <div className="">
            <ProductListByViews />
          </div>
          <div className="w-full">
            <WebTotalViews />
          </div>
        </div>
      </div>
    );
  else
    return (
      <div className="h-screen">
        <div className="px-10 py-5">
          <p className="text-white text-3xl tracking-wide font-medium">
            Website Analytics
          </p>
        </div>
        <div className="h-full w-full flex flex-col items-center justify-center">
          <p className="">
            Widget is not Integrated. Analytical data will be shown after
            integration of MyRevue widgets.
          </p>
          <a
            href="https://myrevue.gitbook.io/"
            target="_blank"
            className="my-2  bg-purple-600 text-white px-3 py-2 rounded hover:bg-purple-500 duration-200"
          >
            Integrate Now
          </a>
        </div>
      </div>
    );
}
