import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useStateValue } from "../../context/StateProvider";
import { doc, updateDoc } from "firebase/firestore";
import { db, storage } from "../../firebase";
import Modalcard from "../../components/modalcard";
import { CheckCircle, CircleNotch, PlayCircle, ShieldSlash, Trash, X } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import HalfStarRating from "../../components/halfstarrating";
import { nanoid } from "nanoid";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { useHistory } from "react-router-dom";

export default function Instagram() {
    const [{ user, products, brand }, dispatch] = useStateValue();
    const router = useHistory();
    const graphUrl = "https://graph.facebook.com/v17.0/";
    const queryString = window.location.search;
    const params = new URLSearchParams(queryString);
    const [videos, setVideos] = useState([]);
    const [previewOn, setPreviewOn] = useState(false);
    const [previewLink, setpreviewLink] = useState(null);

    const [selected, setSelected] = useState([]);
    const [selectedvideos, setSelectedvideos] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);

    const [productSearchText, setProductSearchText] = useState("");

    useEffect(() => {
        if (user?.fbAccess?.access_token) {
            fetchMedia(user?.fbAccess?.access_token);
            return;
        }
        const codeValue = params.get("code");
        if (codeValue) {
            verifyToken(codeValue);
        }
    }, [user?.fbAccess?.access_token]);

    async function fetchMedia(token) {
        const media = await axios.get(`${graphUrl}/${user?.instaDetails?.id}?fields=media&access_token=${token}`);
        const medias = media.data.media.data;

        const videoPromises = medias.map(async (mediaId) => {
            const video = await axios.get(
                `${graphUrl}/${mediaId.id}?fields=media_url,media_type,shortcode,thumbnail_url,is_shared_to_feed&access_token=${token}`
            );
            return video.data;
        });

        const videos = await Promise.all(videoPromises);
        // const filteredVideos = videos.filter(val => val?.media_type === "VIDEO");
        const filteredVideos = videos.filter((val) => val?.media_type === "VIDEO" && val?.media_url);
        setVideos(filteredVideos);
    }

    async function handleFBLogin() {
        window.location.href =
            "https://www.facebook.com/dialog/oauth?client_id=318975980599685&redirect_uri=http://localhost:3000/admin/import/instagram&scope=pages_show_list,business_management,instagram_basic,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_ads,pages_manage_posts,pages_manage_engagement,public_profile";
    }

    async function verifyToken(code) {
        try {
            const response = await fetch(
                `https://graph.facebook.com/oauth/access_token?code=${code}&client_id=318975980599685&client_secret=09919f910c9666715b95171432e7a8e0&redirect_uri=http://localhost:3000/admin/import/instagram`
            );
            const data = await response.json();
            const accountId = await getMyAccounts(data.access_token);
            await getInstagramAccount(accountId, data);
            window.location.href = "/admin/import/instagram";
        } catch (error) {
            console.error("Error fetching user information:", error);
        }
    }
    async function getMyAccounts(token) {
        try {
            const response = await fetch(`https://graph.facebook.com/v17.0/me?fields=accounts&access_token=${token}`);
            const data = await response.json();
            return data.accounts.data[0].id;
        } catch (error) {
            console.error("Error fetching user information:", error);
        }
    }

    async function getInstagramAccount(buisnessId, data) {
        const instaResponse = await axios.get(`${graphUrl}/${buisnessId}?fields=connected_instagram_account&access_token=${data.access_token}`);
        const instagramDetails = await axios.get(
            `${graphUrl}/${instaResponse.data.connected_instagram_account.id}?fields=username,profile_picture_url,media_count&access_token=${data.access_token}`
        );
        const instaDetails = {
            username: instagramDetails.data.username,
            dp: instagramDetails.data.profile_picture_url,
            id: instagramDetails.data.id,
        };
        sessionStorage.setItem("user", JSON.stringify({ ...user, instaDetails: instaDetails, fbAccess: data }));
        dispatch({ type: "SET_USER", user: { ...user, instaDetails: instaDetails, fbAccess: data } });
        await updateDoc(doc(db, "brandAdmin", user.email), { instaDetails: instaDetails, fbAccess: data });
    }

    async function previewOpen(data) {
        setPreviewOn(true);
        setpreviewLink(data?.media_url);
    }
    async function previewClose() {
        setPreviewOn(false);
        setpreviewLink(null);
    }
    async function handleSelectClick(e) {
        if (selected.some((item) => item.id === e.id)) {
            setSelected(selected.filter((r) => r.id !== e.id));
        } else {
            const object = {
                id: e.id,
            };
            setSelected([...selected, object]);
        }
    }

    async function handleNext() {
        if (selected.length < 1) {
            toast.warning("Please select atleast 1 video.", {
                position: "top-right",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work",
            });
            return;
        }
        let selectedmedias = [];
        selected.forEach((media, index) => {
            videos
                .filter((val) => val.id === media.id)
                .map((video) =>
                    selectedmedias.push({
                        thumbnail: video?.thumbnail_url,
                        reviewUrl: video?.media_url,
                        instaMediaId: video?.id,
                    })
                );
        });
        console.log(selectedmedias);
        setSelectedvideos(selectedmedias);
    }
    // Function to add a new input field
    const addInput = (index) => {
        // setImportLinks([...importLinks, '']);

        if (selectedvideos.length >= 3) {
            toast.warning("Only 3 Videos can be uploaded at a time.", {
                position: "top-right",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work",
            });
            return;
        }
        setSelectedvideos((links) => [...selectedvideos, {}]);
    };

    // Function to delete an input field by its index
    const deleteInput = (index) => {
        const updatedselectedvideos = [...selectedvideos];
        if (updatedselectedvideos.length === 1) {
            toast.warning("Atleast 1 video must be there to import.", {
                position: "top-right",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work",
            });
            return;
        }
        updatedselectedvideos.splice(index, 1);
        setSelectedvideos(updatedselectedvideos);
    };
    function addFieldToObjects(objects, index, fieldName, fieldValue) {
        if (index < 0 || index >= objects.length) {
            return objects; // Index out of bounds, return the original array
        }

        const updatedObjects = objects.map((obj, i) => {
            if (i === index) {
                return {
                    ...obj,
                    [fieldName]: fieldValue,
                };
            }
            return obj;
        });

        return updatedObjects;
    }
    const handleAddFields = (index, field, value) => {
        const updatedArray = addFieldToObjects(selectedvideos, index, field, value);
        setSelectedvideos(updatedArray);
    };

    async function submitFlicks() {
        if (selectedvideos.length === 0) {
            toast.warning("There must atleast 1 video", {
                position: "top-right",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work",
            });
            return;
        }
        setSubmitLoading(true);
        const updatedLinks = [];
        const newLinks = [...selectedvideos];

        newLinks.forEach((flick) => {
            updatedLinks.push({
                id: "review_" + nanoid(16),
                title: flick?.name,
                name: flick?.name,
                description: flick?.name,
                bpsId: flick?.prodId,
                flickTag: flick?.flickTag,
                uploadedBy: user?.appUserId,
                thumbnail: flick?.thumbnail,
                reviewUrl: flick?.reviewUrl,
                subFlicksTag: flick?.subFlicksTag,
                brand: brand?.id,
                views: 0,
                hearts: 0,
                rating: 0,
                type: "flicks",
                processed: false,
                subcategory: null,
                catId: null,
                reviewLanguage: flick?.language,
                purchaseMode: null,
                purchaseTime: null,
                orderId: null,
            });
        });
        async function validateUpdatedLinks(updatedLinks) {
            const isValidLinks = updatedLinks.every((flick, index) => {
                const requiredFields = ["id", "title", "name", "description", "bpsId", "flickTag", "uploadedBy", "thumbnail", "reviewUrl", "subFlicksTag"];

                return requiredFields.every((field) => flick[field] !== undefined);
            });

            if (!isValidLinks) {
                toast.warning("Please fill all details.", {
                    position: "top-right",
                    icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                    bodyClassName: "text-sm tracking-wider font-work font-light",
                });
                setSubmitLoading(false);
                return false;
            }

            const hasMinLengthTitle = updatedLinks.every((flick, index) => {
                if (flick.title.length < 20) {
                    toast.warning(`import video ${index + 1} has less than 20 characters`, {
                        position: "top-right",
                        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                        bodyClassName: "text-sm tracking-wider font-work font-light",
                    });
                    return false;
                }
                return true;
            });

            if (!hasMinLengthTitle) {
                setSubmitLoading(false);
                return false;
            }

            return true;
        }
        const isValidLinks = await validateUpdatedLinks(updatedLinks);
        if (isValidLinks === false) {
            // toast.warning('Please fill all details.', {
            //     position: "top-right",
            //     icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
            //     bodyClassName: "text-sm tracking-wide font-work"
            // });
            setSubmitLoading(false);
            return;
        }
        console.log(updatedLinks);
        const processObjects = async (objects) => {
            try {
                for (const obj of objects) {
                    const newThumbnailUrl = await handleThumbnailUpload(obj.thumbnail);
                    const newReviewUrl = await handleVideoUpload(obj.reviewUrl);

                    // Update the object with new URLs
                    obj.thumbnail = newThumbnailUrl;
                    obj.reviewUrl = newReviewUrl;

                    // You can now update this object in your database or perform any other actions needed
                    // For example, you can use Firebase Firestore to store the updated object.
                }
            } catch (error) {
                console.error("Error processing objects:", error);
            }
        };

        await processObjects(updatedLinks);
        console.log(updatedLinks);
        async function sendApiRequestsSequentially() {
            try {
                const headersList = {
                    Accept: "*/*",
                    Authorization: "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf",
                };

                for (const obj of updatedLinks) {
                    const reqOptions = {
                        url: `https://flicksapi-zscu3untuq-el.a.run.app/createFlicks`,
                        method: "PUT",
                        headers: headersList,
                        data: obj,
                    };

                    const response = await axios.request(reqOptions);
                    console.log(response.data);
                    console.log("done: " + obj?.id);
                }
            } catch (error) {
                console.error("Error creating documents:", error);
            }
        }
        async function getBrandFlicks() {
            let headersList = {
                Accept: "*/*",
                Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
            };

            let reqOptions = {
                url: "https://adminapi-zscu3untuq-el.a.run.app/getBrandFlicks/" + brand?.id,
                method: "GET",
                headers: headersList,
            };

            let response = await axios.request(reqOptions);
            sessionStorage.setItem("flicks", JSON.stringify(response.data));
            dispatch({ type: "SET_FLICKS", flicks: response.data });
        }
        await sendApiRequestsSequentially();
        await getBrandFlicks();
        setSubmitLoading(false);
        router.push("/admin/flicks");
    }

    const handleVideoUpload = async (videoUrl) => {
        try {
            const videoName = `importVideosNew/${Date.now()}.mp4`;
            const response = await fetch(videoUrl);
            const blob = await response.blob();
            const videoStorageRef = ref(storage, videoName);
            const uploadTask = uploadBytesResumable(videoStorageRef, blob);

            return new Promise((resolve, reject) => {
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log(`Upload is ${progress}% complete`);
                    },
                    (error) => {
                        console.error("Upload error:", error);
                        reject(error);
                    },
                    async () => {
                        console.log("Upload complete");
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        resolve(downloadURL);
                    }
                );
            });
        } catch (error) {
            console.error("Error uploading video:", error);
            throw error;
        }
    };

    const handleThumbnailUpload = async (imageUrl) => {
        try {
            const thumbnailName = `importVideosNew/${Date.now()}.jpg`;
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const thumbnailStorageRef = ref(storage, thumbnailName);
            const uploadTask = uploadBytesResumable(thumbnailStorageRef, blob);

            return new Promise((resolve, reject) => {
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log(`Upload is ${progress}% complete`);
                    },
                    (error) => {
                        console.error("Upload error:", error);
                        reject(error);
                    },
                    async () => {
                        console.log("Upload complete");
                        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                        resolve(downloadURL);
                    }
                );
            });
        } catch (error) {
            console.error("Error uploading thumbnail:", error);
            throw error;
        }
    };

    return (
        <>
            {previewOn === true && (
                <Modalcard close={previewClose}>
                    <div className="h-[80vh] aspect-[9/16] bg-background rounded-xl">
                        <video
                            src={previewLink}
                            onClick={(e) => (e.currentTarget.paused ? e.target.play() : e.target.pause())}
                            controls
                            controlsList="nodownload noplaybackrate nofullscreen"
                            disablePictureInPicture
                            className="cursor-pointer h-full w-full rounded-xl"></video>
                    </div>
                </Modalcard>
            )}
            <div className="h-16 bg-[#131418] w-full py-4 px-6 flex justify-between">
                <div className=""></div>
                {!user?.instaDetails || !user?.fbAccess ? (
                    <button
                        onClick={() => handleFBLogin()}
                        className="bg-[#4611ea] text-white max-w-[200px] overflow-hidden text-sm tracking-wide py-[9px] px-[12px] leading-none font-light rounded-md">
                        Connect
                    </button>
                ) : (
                    <div className="bg-[#1e1f25] text-white justify-center overflow-hidden text-sm tracking-wide py-[9px] px-[12px] leading-none font-light rounded-md flex items-center gap-2">
                        {/* <img src={user?.instaDetails?.dp} alt="" className="h-6 w-6 rounded-full" /> */}
                        <span className="">@{user?.instaDetails?.username}</span>
                    </div>
                )}
            </div>
            {!user?.instaDetails ? null :
                <>
                    {selectedvideos.length === 0 ? (
                        <>
                            <div className="py-5 ">
                                <p className="text-center tracking-wider">Select the videos you want to import as flick.</p>
                            </div>
                            <div className="flex flex-wrap gap-10 p-5">
                                {videos?.map((video, index) => (
                                    // <div className="h-64 aspect-[9/16] rounded-md" onClick={() => previewOpen(video)} key={index}>
                                    //     <img src={video?.thumbnail_url} alt={video?.id} className="h-64 aspect-[9/16] rounded-md object-cover" />
                                    // </div>
                                    <div
                                        key={"video" + index}
                                        className={`w-[170px] aspect-[9/16] object-cover rounded-lg relative cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.some((item) => item.id === video.id) ? "border border-[#63D876]" : ""
                                            }`}>
                                        <div className="absolute bottom-2 right-2 z-30">
                                            <button
                                                onClick={() => previewOpen(video)}
                                                className="h-[20px] w-fit flex items-center leading-none gap-[3px] text-[8px] bg-[#2E354B] rounded-full p-[2px] pr-2">
                                                {" "}
                                                <PlayCircle size={16} color="white" /> Preview
                                            </button>
                                        </div>
                                        {selected.some((item) => item.id === video.id) && (
                                            <div className="absolute top-2 left-2 z-20">
                                                <div className="">
                                                    {" "}
                                                    <CheckCircle size={20} color="#63D876" weight="bold" />{" "}
                                                </div>
                                            </div>
                                        )}
                                        {video?.thumbnail_url ? (
                                            <img
                                                src={video?.thumbnail_url ?? "https://i.pravatar.cc/150"}
                                                onClick={() => handleSelectClick(video)}
                                                alt="nopreview_available"
                                                className={`${selected.some((item) => item.id === video.id) ? "saturate-0" : "saturate-100"
                                                    } w-full h-full rounded-lg object-cover`}
                                            />
                                        ) : (
                                            <video
                                                src={video.media_url}
                                                onClick={() => handleSelectClick(video)}
                                                className={`${selected.some((item) => item.id === video.id) ? "saturate-0" : "saturate-100"
                                                    } w-full h-full rounded-lg`}></video>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="flex items-center justify-center">
                                <button className="bg-[#8ea9fa] h-11 w-[180px] text-lg rounded shadow-sm" onClick={() => handleNext()}>
                                    Next
                                </button>
                            </div>
                        </>
                    ) : (
                        <div className="bg-bg_highlight p-5 m-5 rounded-lg">
                            {selectedvideos?.map((link, index) => (
                                <div key={index}>
                                    <div className="h-11 flex bg-[#2E3036] w-full items-center px-1 rounded-lg gap-3 mt-5 mb-2">
                                        <div className="h-9 w-9 rounded-md bg-[#93A8F4] flex items-center justify-center">{index + 1}</div>
                                        <div className="flex-1 w-full h-full relative">
                                            <button className="px-1 absolute right-1 h-full flex items-center" onClick={() => deleteInput(index)}>
                                                <Trash size={22} color="red" />
                                            </button>
                                            <input
                                                type="text"
                                                defaultValue={`import video ${index + 1}`}
                                                readOnly
                                                className="w-full h-full rounded-md appearance-none outline-none bg-transparent caret-[#93A8F4] tracking-wide"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-start w-full gap-5">
                                        <div className="flex gap-5">
                                            <div className="">
                                                <p className="font-work text-sm tracking-wide">Video</p>
                                                <div className="w-48 aspect-[9/16] bg-[#2E3036] rounded-lg">
                                                    <video
                                                        src={link.reviewUrl}
                                                        controls
                                                        className="w-full h-full object-cover rounded-lg"
                                                        controlsList="nodownload noplaybackrate nofullscreen"
                                                        disablePictureInPicture></video>
                                                </div>
                                            </div>
                                            <div className="">
                                                <p className="font-work text-sm tracking-wide">Thumbnail</p>
                                                <div className="w-48 aspect-[9/16] bg-[#2E3036] rounded-lg">
                                                    <img src={link.thumbnail} className="w-full h-full object-cover rounded-lg" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-1 w-full">
                                            <div className=" bg-[#2E3036] h-[116px] rounded-xl w-full">
                                                <textarea
                                                    onChange={(event) => handleAddFields(index, "name", event.target.value)}
                                                    className="w-full h-full p-3 bg-transparent placeholder:text-[#8E8B8B] appearance-none outline-none"
                                                    placeholder="write a caption"></textarea>
                                            </div>
                                            <div className="w-full mt-2 relative group">
                                                <div className=" mt-16 absolute w-full h-72 overflow-y-auto bg-[#202020] z-50 border border-[#808080] rounded-md hidden group-hover:flex flex-col">
                                                    <div className="my-3 px-3 flex gap-3 items-center h-12 w-full">
                                                        <input
                                                            type="text"
                                                            value={productSearchText}
                                                            onChange={(event) => setProductSearchText(event.target.value)}
                                                            className="flex-1 w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                                                            placeholder="Search by product name"
                                                        />
                                                        <button className="" onClick={() => setProductSearchText("")}>
                                                            <X size={32} color="white" />
                                                        </button>
                                                    </div>
                                                    {products
                                                        ?.filter((val) => val?.name?.toLowerCase()?.includes(productSearchText?.toLowerCase()))
                                                        ?.map((prod, pindex) => (
                                                            <div
                                                                className="w-full flex h-16 items-center justify-between cursor-pointer hover:bg-[#2E3036] px-3 py-2"
                                                                key={pindex}
                                                                onClick={() => handleAddFields(index, "prodId", prod.id)}>
                                                                <div className="flex-1 w-full flex gap-3 items-center">
                                                                    <div className="w-12 h-12 rounded-full">
                                                                        <img src={prod?.imageUrl} alt="productimage" className="h-full w-full rounded-full" />
                                                                    </div>
                                                                    <div className="flex-1 w-full">
                                                                        <p className="w-full text-sm tracking-wide">{prod?.name}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="">
                                                                    <HalfStarRating sizeh={25} colorcode={"#E2BE45"} rating={parseFloat(prod?.rating)} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                </div>
                                                <div className="w-full flex items-center gap-8">
                                                    <div className="h-16 w-16 rounded-full">
                                                        <div className=" cursor-pointer h-full w-full bg-[#434343] rounded-full text-xl flex items-center justify-center">
                                                            {link?.prodId === null ? (
                                                                <p className="">+</p>
                                                            ) : (
                                                                <img
                                                                    src={products?.filter((val) => val?.id === link?.prodId)[0]?.imageUrl}
                                                                    alt=""
                                                                    className="h-full w-full rounded-full"
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="w-full flex-1">
                                                        <div className="my-3">
                                                            <input
                                                                type="text"
                                                                readOnly
                                                                defaultValue={products?.filter((val) => val?.id === link?.prodId)[0]?.name}
                                                                className="w-full h-9 px-3 bg-[#2C2C2C] rounded-lg appearance-none outline-none placeholder:text-sm placeholder:text-[#acacac] placeholder:tracking-wide"
                                                                placeholder="Product Name"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="mt-5">
                                            <button className={`bg-[#2E3036] relative hover:bg-[#93A8F4] h-11 w-52 rounded-full appearance-none outline-none tracking-wide`} >
                                                <input type="file" className="absolute h-full w-full bg-white rounded-full top-0 left-0 opacity-0" onChange={(event) => uploadCoverImage(index, event)} />
                                                Upload Cover Image
                                            </button>
                                        </div> */}
                                            <div className="mt-3">
                                                <p className="text-lg tracking-wide">Language</p>
                                                <div className="flex flex-wrap gap-3 mt-1">
                                                    <button
                                                        className={`bg-[#2E3036] ${link.language === "English" && "bg-[#93A8F4]"
                                                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                        onClick={() => handleAddFields(index, "language", "English")}>
                                                        English
                                                    </button>
                                                    <button
                                                        className={`bg-[#2E3036] ${link.language === "Hindi" && "bg-[#93A8F4]"
                                                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                        onClick={() => handleAddFields(index, "language", "Hindi")}>
                                                        Hindi
                                                    </button>
                                                    <button
                                                        className={`bg-[#2E3036] ${link.language === "Telugu" && "bg-[#93A8F4]"
                                                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                        onClick={() => handleAddFields(index, "language", "Telugu")}>
                                                        Telugu
                                                    </button>
                                                    <button
                                                        className={`bg-[#2E3036] ${link.language === "Tamil" && "bg-[#93A8F4]"
                                                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                        onClick={() => handleAddFields(index, "language", "Tamil")}>
                                                        Tamil
                                                    </button>
                                                    <button
                                                        className={`bg-[#2E3036] ${link.language === "Marathi" && "bg-[#93A8F4]"
                                                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                        onClick={() => handleAddFields(index, "language", "Marathi")}>
                                                        Marathi
                                                    </button>
                                                    <button
                                                        className={`bg-[#2E3036] ${link.language === "Bengali" && "bg-[#93A8F4]"
                                                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                        onClick={() => handleAddFields(index, "language", "Bengali")}>
                                                        Bengali
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <p className="text-lg tracking-wide">
                                                    What is this flick about <span className="text-[#8E8B8B]">(choose any one)</span>
                                                </p>
                                                <div className="flex flex-wrap gap-3 mt-1">
                                                    <button
                                                        className={`bg-[#2E3036] ${link.flickTag === "Get The Looks" && "bg-[#93A8F4]"
                                                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                        onClick={() => handleAddFields(index, "flickTag", "Get The Looks")}>
                                                        #Get The Looks
                                                    </button>
                                                    <button
                                                        className={`bg-[#2E3036] ${link.flickTag === "Haul Videos" && "bg-[#93A8F4]"
                                                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                        onClick={() => handleAddFields(index, "flickTag", "Haul Videos")}>
                                                        #Haul Videos
                                                    </button>
                                                    <button
                                                        className={`bg-[#2E3036] ${link.flickTag === "Tutorial Videos" && "bg-[#93A8F4]"
                                                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                        onClick={() => handleAddFields(index, "flickTag", "Tutorial Videos")}>
                                                        #Tutorial Videos
                                                    </button>
                                                    <button
                                                        className={`bg-[#2E3036] ${link.flickTag === "Unboxing Videos" && "bg-[#93A8F4]"
                                                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                        onClick={() => handleAddFields(index, "flickTag", "Unboxing Videos")}>
                                                        #Unboxing Videos
                                                    </button>
                                                    <button
                                                        className={`bg-[#2E3036] ${link.flickTag === "Store Visit Videos" && "bg-[#93A8F4]"
                                                            } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                        onClick={() => handleAddFields(index, "flickTag", "Store Visit Videos")}>
                                                        #Store Visit Videos
                                                    </button>
                                                </div>
                                            </div>
                                            {!link?.flickTag ? null : (
                                                <div className="mt-3">
                                                    <p className="text-lg tracking-wide">More Specifically..</p>
                                                    {link.flickTag === "Get The Looks" && (
                                                        <div className="flex flex-wrap gap-3 mt-1">
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Ethnic Wear Tips" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Ethnic Wear Tips")}>
                                                                #Ethnic Wear Tips
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Wedding Wear" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Wedding Wear")}>
                                                                #Wedding Wear
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "SkinCare Routine Tips" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "SkinCare Routine Tips")}>
                                                                #SkinCare Routine Tips
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Cocktail Wear" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Cocktail Wear")}>
                                                                #Cocktail Wear
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Vintage & Retro Styling" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Vintage & Retro Styling")}>
                                                                #Vintage & Retro Styling
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Fitness & Health Tips" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Fitness & Health Tips")}>
                                                                #Fitness & Health Tips
                                                            </button>
                                                        </div>
                                                    )}
                                                    {link.flickTag === "Tutorial Videos" && (
                                                        <div className="flex flex-wrap gap-3 mt-1">
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Makeup Tutorial" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Makeup Tutorial")}>
                                                                #Makeup Tutorial
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "SkinCare Tutorial" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "SkinCare Tutorial")}>
                                                                #SkinCare Tutorial
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Smart Devices Tutorial" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Smart Devices Tutorial")}>
                                                                #Smart Devices Tutorial
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "App-Web Tools Tutorial" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "App-Web Tools Tutorial")}>
                                                                #App-Web Tools Tutorial
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Nail Art Tutorial" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Nail Art Tutorial")}>
                                                                #Nail Art Tutorial
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Healthy Cooking Tutorial" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Healthy Cooking Tutorial")}>
                                                                #Healthy Cooking Tutorial
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Hair Styling Tutorial" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Hair Styling Tutorial")}>
                                                                #Hair Styling Tutorial
                                                            </button>
                                                        </div>
                                                    )}
                                                    {link.flickTag === "Haul Videos" && (
                                                        <div className="flex flex-wrap gap-3 mt-1">
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Food Haul" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Food Haul")}>
                                                                #Food Haul
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Tech Haul" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Tech Haul")}>
                                                                #Tech Haul
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Beauty Haul" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Beauty Haul")}>
                                                                #Beauty Haul
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Fashion Haul" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Fashion Haul")}>
                                                                #Fashion Haul
                                                            </button>
                                                        </div>
                                                    )}
                                                    {link.flickTag === "Unboxing Videos" && (
                                                        <div className="flex flex-wrap gap-3 mt-1">
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Food & Drink Unboxing" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Food & Drink Unboxing")}>
                                                                #Food & Drink Unboxing
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Beauty Unboxing" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Beauty Unboxing")}>
                                                                #Beauty Unboxing
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Gadget Unboxing" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Gadget Unboxing")}>
                                                                #Gadget Unboxing
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Fashion Unboxing" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Fashion Unboxing")}>
                                                                #Fashion Unboxing
                                                            </button>
                                                        </div>
                                                    )}
                                                    {link.flickTag === "Store Visit Videos" && (
                                                        <div className="flex flex-wrap gap-3 mt-1">
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Offline Store Visit" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Offline Store Visit")}>
                                                                #Offline Store Visit
                                                            </button>
                                                            <button
                                                                className={`bg-[#2E3036] ${link.subFlicksTag === "Online Store Visit" && "bg-[#93A8F4]"
                                                                    } hover:bg-[#93A8F4] h-10 px-6 rounded-full appearance-none outline-none tracking-wide`}
                                                                onClick={() => handleAddFields(index, "subFlicksTag", "Online Store Visit")}>
                                                                #Online Store Visit
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className="my-5 flex items-center justify-center gap-5">
                                <button className="bg-[#2E3036] w-fit px-6 rounded-full h-11 flex items-center gap-2" onClick={() => window.location.reload()}>
                                    Discard
                                </button>
                                <button className="bg-[#93A8F4] w-fit px-12 rounded-full h-11 flex items-center gap-2" onClick={() => submitFlicks()}>
                                    {submitLoading ? (
                                        <span className="animate-spin">
                                            <CircleNotch size={22} color="white" />
                                        </span>
                                    ) : (
                                        "Submit"
                                    )}
                                </button>
                            </div>
                        </div>
                    )}
                </>
            }
        </>
    );
}
