import React from 'react'
import NotLoginHeader from './layouts/NotLoginHeader'
import Notloginfooter from './layouts/Notloginfooter'
import { useState } from 'react'
import { addDoc, collection } from 'firebase/firestore';
import { db } from './firebase';
import { toast } from 'react-toastify';
import { ShieldSlash } from 'phosphor-react';
import NotLoginHeaderNew from './layouts/NotLoginHeaderNew';
import NotloginfooterNew from './layouts/NotloginfooterNew';

export default function Feedback() {

    const [text, settext] = useState(null);
    const [name, setname] = useState(null);


    async function submit() {

        if (!name || name?.length < 3) {
            toast.warning("Please add your name", {
                position: "top-right",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return;
        }
        if (!text || text?.length < 20) {
            toast.warning("Please add a longer feedback", {
                position: "top-right",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return;
        }
        const document = {
            feedback: text,
            id: null,
            name: name,
            date: Date.now()
        }

        await addDoc(collection(db, "feedback"), document).then(res => {
            // console.log(res?.id)
        })

    }
    return (
        <div className="bg-[#101010] ">
            <div className="bg-cover bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fpattern-min.png?alt=media&token=bc04c455-0afe-4295-9562-044cb7cf2ef0')] h-screen overflow-y-auto w-full">
                <NotLoginHeaderNew />
                <div className="h-fit w-full flex-1 p-5 flex justify-center ">
                    <div className="w-full">
                        <div className="">
                            <h1 className="text-center text-white text-3xl font-semibold">Your feedback matters!</h1>
                            <p className="text-center text-[#8E8B8B]">Share your thoughts and help us serve you better.</p>
                        </div>
                        <div className="p-5 md:p-10 text-white bg-[#191919] w-full max-w-[540px] mx-auto mt-5 rounded-xl">
                            <div className="">
                                <input placeholder='Full Name' onChange={(event) => setname(event.target.value)} className="bg-[#101010] rounded-lg placeholder:font-medium placeholder:text-[#8E8B8B] placeholder:tracking-wide  h-12 w-full appearance-none outline-none p-3 text-sm tracking-wide font-light" />
                            </div>
                            <div className="mt-5">
                                <textarea placeholder='Feedback' onChange={(event) => settext(event.target.value)} className="bg-[#101010] rounded-lg placeholder:font-medium placeholder:text-[#8E8B8B] placeholder:tracking-wide  h-40 w-full appearance-none outline-none p-3 text-sm tracking-wide font-light"></textarea>
                            </div>
                            <div className="flex items-center justify-center">
                                <button onClick={() => submit()} className=" mt-3 py-[10px] text-lg tracking-wide px-12 rounded-lg w-fit bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
                <NotloginfooterNew />
            </div>
        </div>
    )
}
