import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import { CheckCircle, ShieldSlash } from "phosphor-react";
import React, { useRef, useState } from "react";
import { toast } from "react-toastify";

function QuizQuestion({ position, quest, quizId, updateFun }) {
  const questionRef = useRef();
  const answerA = useRef();
  const answerB = useRef();
  const answerC = useRef();
  const answerD = useRef();

  const [video, setVideo] = useState({
    correctAnswer: quest.correctAnswer,
    questionVideoUrl: quest.questionVideoUrl,
    answers: quest.answers,
    question: quest.question,
    index: position,
  });

  const [uploading, setUploading] = useState(false);
  const [progress, setprogress] = useState(0);

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        video.remove();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };
  console.log(position, "quiz position");
  const handleFileUpload = async (e) => {
    const thumbnail_snap = await generateVideoThumbnail(e.target.files[0]);
    const storage = getStorage();
    const storageRef = ref(
      storage,
      "quizQuestions/" + quizId + (position + 1) + ".jpg"
    );

    uploadString(storageRef, thumbnail_snap, "data_url").then((snapshot) => {
      getDownloadURL(storageRef).then((downloadURL) => {
        setVideo({ ...video, thumbnail: downloadURL });
      });
    });
  };

  const video_upload = async (e) => {
    var image = e.target.files[0];
    var type = image.type.split("/")[1];
    const formats = ["mp4", "mkv", "mov", "avi"];

    if (!formats.includes(type)) {
      alert("Please upload a video file");
      return;
    }
    await handleFileUpload(e);
    const storage = getStorage();
    const storageRef = ref(
      storage,
      "quizQuestions/" + quizId + (position + 1) + "." + type
    );
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setUploading(true);
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // console.log(progress)
        setprogress(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setVideo({
            ...video,
            questionVideoUrl: downloadURL,
          });
          updateQuestion();
          setUploading(false);
        });
      }
    );
  };

  async function showWarning(text) {
    toast.warning(text, {
      position: "top-right",
      icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
    return;
  }

  const updateQuestion = () => {
    const options = [questionRef, answerA, answerB, answerC, answerD];
    var fault = false;
    for (const opt of options) {
      if (!opt.current.value || opt.current.value.length === 0) {
        fault = true;
        opt.current.classList.remove("border-[#808080]");
        opt.current.classList.add("border-red-500");
      } else {
        opt.current.classList.remove("border-red-500");
        opt.current.classList.add("border-[#808080]");
      }
    }

    if (fault) {
      showWarning("some fields are blank");
      return;
    }

    var quest = {
      ...video,
      question: questionRef.current.value,
      answers: {
        a: answerA.current.value,
        b: answerB.current.value,
        c: answerC.current.value,
        d: answerD.current.value,
      },
    };
    console.log(quest);

    updateFun(quest, position);
  };

  return (
    <div onMouseLeave={updateQuestion}>
      <div className="flex items-center justify-start gap-3">
        <span>{position + 1}. </span>
        <input
          ref={questionRef}
          type="text"
          defaultValue={quest.question}
          placeholder="type question here.."
          className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
        />
      </div>
      <br />
      <div className="flex items-center justify-start gap-3">
        <div className="h-[200px] w-[170px] text-center border rounded-md shadow-sm flex items-center justify-center">
          <label htmlFor={"questionVideo" + position}>
            {video.questionVideoUrl ? (
              <video
                src={video.questionVideoUrl}
                className="w-full h-full rounded-md"
                onMouseLeave={(e) => e.currentTarget.pause()}
                onMouseEnter={(e) => e.currentTarget.play()}
              />
            ) : uploading ? (
              <p className="text-xs text-gray-500 cursor-pointer">
                {progress} %
              </p>
            ) : (
              <p className="text-xs text-gray-500 cursor-pointer">
                click to Upload Video of question {position + 1}.
              </p>
            )}
          </label>
          <input
            type="file"
            accept=".mp4"
            className="hidden"
            id={"questionVideo" + position}
            onChange={(e) => video_upload(e)}
          />
        </div>
        <div className="flex flex-col h-full w-full justify-evenly items-center">
          <div className="flex w-full gap-1 items-center">
            <input
              ref={answerA}
              type="text"
              defaultValue={quest.answers?.a}
              placeholder="answer A"
              className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
            />
            <span
              className=" cursor-pointer"
              onClick={() => setVideo({ ...video, correctAnswer: "a" })}
            >
              <CheckCircle
                size={30}
                color={video.correctAnswer === "a" ? "green" : "gray"}
              />
            </span>
          </div>
          <div className="flex w-full gap-1 items-center">
            <input
              ref={answerB}
              type="text"
              defaultValue={quest.answers?.b}
              placeholder="answer A"
              className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
            />
            <span
              className=" cursor-pointer"
              onClick={() => setVideo({ ...video, correctAnswer: "b" })}
            >
              <CheckCircle
                size={30}
                color={video.correctAnswer === "b" ? "green" : "gray"}
              />
            </span>
          </div>
          <div className="flex w-full gap-1 items-center">
            <input
              ref={answerC}
              type="text"
              defaultValue={quest.answers?.c}
              placeholder="answer A"
              className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
            />
            <span
              className=" cursor-pointer"
              onClick={() => setVideo({ ...video, correctAnswer: "c" })}
            >
              <CheckCircle
                size={30}
                color={video.correctAnswer === "c" ? "green" : "gray"}
              />
            </span>
          </div>
          <div className="flex w-full gap-1 items-center">
            <input
              ref={answerD}
              type="text"
              defaultValue={quest.answers?.d}
              placeholder="answer A"
              className="appearance-none outline-none h-10 rounded-md px-3 mt-1 placeholder:text-sm placeholder:tracking-wide placeholder:text-[#7E7E7E] bg-transparent border border-[#808080] focus:border-white hover:border-white w-full"
            />
            <span
              className=" cursor-pointer"
              onClick={() => setVideo({ ...video, correctAnswer: "d" })}
            >
              <CheckCircle
                size={30}
                color={video.correctAnswer === "d" ? "green" : "gray"}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuizQuestion;
