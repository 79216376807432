import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { PuzzlePiece } from "phosphor-react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import Loadingdot from "../../components/loadingdot";
import Stickyheader from "../../components/stickyheader";
import { db } from "../../firebase";
import { sendAmplitudeData } from "../../amplitude";
export default function Viewcoupon() {
  const [loading, setloading] = useState(false);
  const [showcoupons, setshowcoupons] = useState(false);
  const [coupon, setcoupon] = useState({});
  const [couponscodes, setcouponscodes] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    async function fetchdata() {
      setloading(true);
      await getDoc(doc(db, "rewardCoupan", id)).then((res) =>
        setcoupon(res.data())
      );
      await getDocs(collection(db, "rewardCoupan", id, "coupanCodes")).then(
        (res) => setcouponscodes(res.docs.map((doc) => doc.data()))
      );
      setloading(false);
    }
    fetchdata();

    sendAmplitudeData("view coupon page opened", { coupon: id });
  }, []);

  const fireBaseTime = new Date(
    coupon?.dateAdded?.seconds * 1000 + coupon?.dateAdded?.nanoseconds / 1000000
  );
  const date = fireBaseTime.toDateString();

  return (
    <div className="flex flex-col h-full">
      <Stickyheader title={coupon?.title} back={true} />

      <div className="">
        {loading ? (
          <Loadingdot />
        ) : (
          <div className="flex-1 bg-bg_highlight p-4 grid grid-cols-3 gap-x-5 pb-10 m-5">
            <div className="h-full  mt-5 border-r pr-5 border-[#ececec30]">
              <div className="flex flex-col items-center justify-start">
                <div className="h-80 w-80 rounded-xl border border-dashed border-[#ececec50] hover:border-white">
                  <img
                    crossOrigin="anonymous"
                    src={coupon?.imageUrl}
                    alt=""
                    className="h-full w-full rounded-xl"
                  />
                </div>
                <div className="flex flex-col items-center mt-10 border-t border-[#ececec30] w-full">
                  <div className="h-10 pb-1 text-white text-sm tracking-wider flex items-center mt-5">
                    <p className="pt-1 mr-3">
                      {
                        couponscodes?.filter((val) => {
                          if (val?.alloted) {
                            return val;
                          }
                        }).length
                      }{" "}
                      out {couponscodes?.length} coupons used
                    </p>
                  </div>
                  <button
                    onClick={() => setshowcoupons(!showcoupons)}
                    className=" bg-background hover:bg-[#4611ea] h-10 pb-1 px-5 text-white text-sm tracking-wider flex items-center"
                  >
                    <p className="pt-1 mr-3">Show Coupons</p>{" "}
                    <PuzzlePiece size={24} color="#ffffff" />
                  </button>
                </div>
              </div>
              {showcoupons === true && (
                <div className="mt-5">
                  {couponscodes?.map((code, index) => (
                    <div
                      className="flex items-center justify-between hover:bg-background p-2"
                      key={index}
                    >
                      <p className="text-[#808080]">{code?.id}</p>
                      <p className="text-[#808080]">
                        {code?.alloted === null ? "not used" : "used"}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="col-span-2 flex flex-col items-end w-full  ">
              <div className="grid grid-cols-2 grid-flow-col gap-x-5 mt-5 w-full">
                <div className="w-full">
                  <p className="text-[#9c9c9c] text-sm tracking-wide">
                    Contact title
                  </p>
                  <input
                    disabled
                    defaultValue={coupon?.title}
                    placeholder="ex: Black friday offer"
                    type="text"
                    className="text-sm bg-transparent border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2"
                  />
                </div>
                {/* <div className="w-full">
                                    <p className="text-[#9c9c9c] text-sm tracking-wide">Coupon code</p>
                                    <input disabled defaultValue={coupon?.title} placeholder='ex: MRLAYT2039' type="text" className='text-sm bg-transparent border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2' />
                                </div> */}
              </div>
              <div className="grid grid-cols-2 grid-flow-col gap-x-5 mt-5 w-full">
                <div className="w-full">
                  <p className="text-[#9c9c9c] text-sm tracking-wide">
                    Created At
                  </p>
                  <input
                    disabled
                    defaultValue={date}
                    placeholder="ex: 19 Dec 2022"
                    type="text"
                    className="text-sm bg-transparent border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2"
                  />
                </div>
                <div className="w-full">
                  <p className="text-[#9c9c9c] text-sm tracking-wide">
                    Quantity
                  </p>
                  <input
                    disabled
                    defaultValue={coupon?.quantity}
                    placeholder="ex: 10"
                    type="text"
                    className="text-sm bg-transparent border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 grid-flow-col gap-x-5 mt-5 w-full">
                <div className="w-full">
                  <p className="text-[#9c9c9c] text-sm tracking-wide">Value</p>
                  <input
                    disabled
                    defaultValue={coupon?.coupanValue}
                    placeholder="ex: 3000"
                    type="text"
                    className="text-sm bg-transparent border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2"
                  />
                </div>
                <div className="w-full">
                  <p className="text-[#9c9c9c] text-sm tracking-wide">Type</p>
                  <input
                    disabled
                    defaultValue={coupon?.type}
                    placeholder="ex: free product"
                    type="text"
                    className="text-sm bg-transparent border-b border-[#505050] focus:border-white hover:border-white appearance-none outline-none w-full mt-2 caret-white text-white font-normal tracking-wide pb-2"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 grid-flow-col gap-x-5 mt-5 w-full">
                <div className="w-full col-span-2">
                  <p className="text-[#9c9c9c] text-sm tracking-wide pb-4">
                    Terms and conditions
                  </p>
                  {coupon?.rules?.map((rule, index) => (
                    <p className="text-[#9c9c9c] text-sm tracking-wide">
                      {index + 1}: {rule}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
