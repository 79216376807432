import AWN from "awesome-notifications";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import strings from "../assets/Strings";

function VideoTrimSelector({ duration, handleChange }) {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(duration);

  const diff = 30;

  const scrubFirst = (first) => {
    first = first.target.value;
    first = Number.parseInt(first);
    if (first >= end - diff) return;
    setStart(first);
    handleChange(first, end);
  };

  const scrublast = (last) => {
    last = last.target.value;
    last = Number.parseInt(last);
    if (last < start + diff) return;
    setEnd(last);
    handleChange(start, last);
  };
  // const trimNowFinal = async () => {
  //   if (start === 0 && end === Number.parseInt(duration)) {
  //     return new AWN().warning("trim length should be not the actual duration");
  //   }
  //   console.log(strings.apiLink);
  //   let headers = new Headers();

  //   headers.append("Content-Type", "application/json");
  //   headers.append("Accept", "application/json");
  //   headers.append("Origin", "http://localhost:3000");
  //   const response = await axios
  //     .post(
  //       strings.apiLink + "trim",
  //       {
  //         url: src,
  //         start: start,
  //         end: end,
  //       },
  //       {
  //         headers,
  //       }
  //     )
  //     .then((res) => res)
  //     .catch((e) => {
  //       return e;
  //     });

  //   running(false);

  //   if (response.status === 200) {
  //     if (response.data.success) {
  //       register([
  //         {
  //           type: "trim",
  //           added: true,
  //           skip: false,
  //           time: Date.now(),
  //         },
  //       ]);
  //       return new AWN().success("review trimmed.");
  //     } else {
  //       return new AWN().warning(response.data.message);
  //     }
  //   } else {
  //     return new AWN().warning(response.message);
  //   }
  // };

  return (
    <div className="w-full  h-20 z-10 py-5 hideOut-normal">
      <p className="text-xs text-gray-300 mx-auto text-center my-3">
        Slide this two slider to make the required clip.
      </p>
      <div className="flex w-full">
        <div className="multi-range relative w-full">
          <div className="slider-track"></div>
          <input
            type="range"
            value={start}
            min="0"
            onChange={scrubFirst}
            max={duration}
            id="range1"
          />
          <input
            type="range"
            value={end}
            onChange={scrublast}
            min="0"
            max={duration}
            id="range2"
          />
        </div>
      </div>
    </div>
  );
}

export default VideoTrimSelector;
