import { ShieldSlash, UploadSimple, X } from "phosphor-react";
import React, { useEffect, useState } from "react";
import Stickyheader from "../../components/stickyheader";
import { getDoc, getDocs, doc, setDoc, collection } from "firebase/firestore";
import { db } from "../../firebase";
import {
  getStorage,
  uploadBytesResumable,
  ref,
  getDownloadURL,
} from "firebase/storage";
import { nanoid } from "nanoid";
import { useRef } from "react";
import Loadingdot from "../../components/loadingdot";
import { useHistory } from "react-router";
import { sendAmplitudeData } from "../../amplitude";
import { useStateValue } from "../../context/StateProvider";
import axios from "axios";
import { toast } from "react-toastify";

export default function Createproduct() {
  const [{ products, brand }, dispatch] = useStateValue();

  const [progress, setprogress] = useState(0);
  const [product_details, setproduct_details] = useState({});
  const [imageUrl, setimageUrl] = useState("");
  const [id, setid] = useState(nanoid(8));
  const [categories, setcategories] = useState([]);
  const [tags, settags] = useState([]);
  const [loading, setloading] = useState(false);
  const router = useHistory();
  const tagref = useRef();
  useEffect(() => {
    setloading(true);
    console.log(brand)
    getDocs(collection(db, "category")).then((res) =>
      setcategories(res.docs.map((doc) => doc.data()))
    );
    async function checkid() {
      const docRef = doc(db, "product", `pro_${id}`);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        console.log("already have document");
        setid(nanoid(8));
      } else {
        setloading(false);
        console.log("No such document!");
      }
    }
    checkid();
  }, [id]);

  useEffect(() => {
    sendAmplitudeData("creating a product ");
  }, []);

  async function submit() {
    if (!imageUrl) {
      toast.warning(`Please upload a product image.`, {
        position: "top-right",
        // theme: "dark",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work"
      });
      return;
    }
    if (!product_details?.name) {
      toast.warning(`Please fill all details for this product.`, {
        position: "top-right",
        // theme: "dark",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work"
      });
      return;
    }
    if (!product_details?.description || product_details?.description?.length < 50) {
      toast.warning(`Description must be more than 50 characters`, {
        position: "top-right",
        // theme: "dark",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work"
      });
      return;
    }
    if (!product_details?.category) {
      toast.warning(`Please fill all details for this product.`, {
        position: "top-right",
        // theme: "dark",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work"
      });
      return;
    }
    if (!product_details?.subcategory) {
      toast.warning(`Please fill all details for this product.`, {
        position: "top-right",
        // theme: "dark",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work"
      });
      return;
    }

    let attr;

    try {
      attr = categories
        .find((cat) => cat.id === product_details?.category)
        .images.find(
          (subc) => subc.name === product_details?.subcategory
        ).attributes;
      // console.log(attr);
    } catch (error) {
      alert("No attributes found for this subcategory.");
      return;
    }
    var filterName = product_details?.name.toLowerCase().split(" ");

    const productdetails = {
      id: `pro_${id}`,
      subscribes: 0,
      isverified: false,
      status: false,
      experiences: 0,
      reviews: 0,
      attributes: attr,
      totalExps: 0,
      totalReviews: 0,
      views: 0,
      rating: 3,
      name: product_details.name,
      description: product_details.description,
      catId: product_details.category,
      parentName: brand?.name,
      affliateLink: null,
      subcategory: product_details.subcategory,
      coverImage: null,
      parent: brand?.id,
      tag: `@pro_${id}`,
      createdAt: Date.now(),
      addedBy: {
        id: brand?.id,
        name: brand?.name,
        tag: brand?.tag,
        image: brand?.imageUrl,
      },
      filter: [...tags, ...filterName],
      imageUrl: imageUrl,
    };

    let headersList = {
      "Accept": "*/*",
      "Content-Type": "application/json",
      "Authorization": "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD"
    }

    let reqOptions = {
      url: "https://productapi-zscu3untuq-el.a.run.app/createProduct",
      method: "PUT",
      headers: headersList,
      data: productdetails,
    }

    try {
      const response = await axios.request(reqOptions);
      const pro_data = response.data;
      console.log(pro_data)
      dispatch({
        type: "SET_PRODUCTS",
        products: [...products, productdetails],
      });
      router.push("/admin/products");
      window.location.reload();
    } catch (error) {
      console.log(error)
    }
  }

  const uploadFile = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }

    const storage = getStorage();
    const storageRef = ref(storage, `product/pro_${id}.jpg`);
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setimageUrl(downloadURL);
        });
      }
    );
  };
  const uploadCoverFile = async (e) => {
    const file = e.target.files[0];

    if (!file) {
      return alert("No files selected");
    }

    const storage = getStorage();
    const storageRef = ref(
      storage,
      `test/${brand?.id}/` + `pro_${id}cover.jpg`
    );
    const uploadTask = uploadBytesResumable(storageRef, e.target.files[0]);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setprogress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setimageUrl(downloadURL);
        });
      }
    );
  };

  const removeTag = (e) => {
    // console.log(e);
    settags(tags.filter((word) => e !== word));
  };
  const addtags = (e) => {
    e.preventDefault();

    if (tags?.includes(tagref.current.value)) {
      return;
    }
    settags([...tags, tagref.current.value.trim()]);
    tagref.current.value = "";
  };
  return (
    <div className="flex flex-col h-full">
      <Stickyheader title={"Create New Product"} back={true} />
      <div className="p-5">
        {loading ? (
          <Loadingdot />
        ) : (
          <div className="h-full flex-1 bg-bg_highlight p-4 grid grid-cols-2 pb-10">
            <div className="h-full flex items-center justify-center">
              <div className="h-96 w-96 bg-background bg-opacity-50 rounded-2xl border border-dashed border-[#ececec50] hover:border-white hover:bg-opacity-100 relative">
                <input
                  type="file"
                  onChange={(e) => uploadFile(e)}
                  className="h-96 w-96 rounded-2xl opacity-0 absolute"
                  accept=".jpg, .jpeg, .png"
                />
                {progress < 99 ? (
                  <div className="h-full w-full flex flex-col items-center justify-center">
                    <UploadSimple size={50} color="#ffffff" />
                    <div className="text-sm text-white font-light tracking-wide text-center mt-5">
                      {progress <= 0 ? (
                        <>
                          <p className="">Browse or drag and drop image file</p>
                          <p className="">We support: JPG, JPEG, PNG</p>
                        </>
                      ) : (
                        <p className="">
                          {progress <= 99 ? (
                            <span className="">Uploading {progress} %</span>
                          ) : (
                            <span>Uploaded</span>
                          )}
                        </p>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="h-full w-full flex flex-col items-center justify-center">
                    <img
                      src={imageUrl}
                      alt=""
                      crossOrigin="anonymous"
                      className="h-full w-full rounded-2xl"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div className="text-white">
                <p className="">Name</p>
                <input
                  type="text"
                  onChange={(event) =>
                    setproduct_details({
                      ...product_details,
                      name: event.target.value,
                    })
                  }
                  placeholder="Enter product name"
                  className="text-sm tracking-wide font-normal appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-2"
                />
              </div>
              <div className="text-white my-3">
                <p className="">Description</p>
                <textarea
                  type="text"
                  onChange={(event) =>
                    setproduct_details({
                      ...product_details,
                      description: event.target.value,
                    })
                  }
                  placeholder="Enter product description"
                  className="text-sm tracking-wide font-normal appearance-none outline-none h-40 w-full bg-background p-3 truncate mt-2"
                />
              </div>
              <div className="text-white my-3">
                <p className="">Category</p>
                <select
                  onChange={(e) =>
                    setproduct_details({
                      ...product_details,
                      category: e.target.value,
                    })
                  }
                  className="text-sm tracking-wide font-normal appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-2"
                >
                  <option value="" hidden>
                    Please select
                  </option>
                  {categories.map((e, index) => (
                    <option value={e.id} key={index}>
                      {e.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="text-white my-3">
                <p className="">Sub Category</p>
                <select
                  onChange={(e) =>
                    setproduct_details({
                      ...product_details,
                      subcategory: e.target.value,
                    })
                  }
                  className="text-sm tracking-wide font-normal appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-2"
                >
                  <option value="" className="" hidden>
                    Please select category
                  </option>
                  {categories
                    .find((ele) => ele.id === product_details?.category)
                    ?.subcategory.map((e, index) => (
                      <option value={e} key={index}>
                        {e}
                      </option>
                    ))}
                </select>
              </div>
              <div className="text-white my-3">
                <p className="">Tags</p>
                <div className="">
                  <div className="flex flex-wrap mt-2 bg-input items-center bg-background">
                    {tags?.map((e) => (
                      <span className="bg-black text-white m-2   cursor-pointer">
                        <span className="flex items-center py-1">
                          <p className="px-2">{e}</p>
                          <div
                            className="cursor-pointer pr-1"
                            onClick={() => removeTag(e)}
                          >
                            <X size={20} color="white" />
                          </div>
                        </span>
                      </span>
                    ))}
                    <form onSubmit={(e) => addtags(e)}>
                      <input
                        type="text"
                        ref={tagref}
                        className="px-5 w-full bg-transparent appearance-none outline-none h-12 text-sm tracking-wide"
                        placeholder="Enter Tag(s)"
                      />
                    </form>
                  </div>
                </div>
                {/* <input className="text-sm tracking-wide font-normal appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-2" /> */}
              </div>
              <div className="mt-5">
                <button
                  onClick={() => submit()}
                  className="h-10 w-40 appearance-none outline-none bg-background hover:bg-[#4611ea] text-white text-sm tracking-wider"
                >
                  Add product
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
