import AWN from "awesome-notifications";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { auth, db } from "../firebase";
import SignUpStepCompledted from "../components/SignUpStepCompleted";
import { sendAmplitudeData } from "../amplitude";

import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import NotLoginHeader from "../layouts/NotLoginHeader";
import strings from "../assets/Strings";
import { nanoid } from "nanoid";
import { useEffect } from "react";
import { useStateValue } from "../context/StateProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";

function SignUp() {
  const [stepCompleted, setstepCompleted] = useState(0);
  const [accountDetails, setacDetails] = useState({});
  const [useCases, setUseCases] = useState([]);
  const [{ user, brand }, dispatch] = useStateValue();


  const provider = new GoogleAuthProvider();

  let phoneRef = useRef();
  let passwordRef = useRef();
  let emailRef = useRef();
  let nameRef = useRef();
  let businessNameRef = useRef();
  let websiteUrlRef = useRef();
  let descriptionRef = useRef();

  const router = useHistory();

  useEffect(() => {
    sendAmplitudeData("signUp page opened");
  }, []);

  const signInPopup = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result?.user;

        const getaccount = await getDoc(doc(db, strings.adminCollection, user.email)).then((res) => res.exists())
        if (getaccount) {
          return new AWN().warning("Account already exists, please login")
        }
        setacDetails({
          accessToken: user?.accessToken,
          name: user?.displayName,
          email: user?.email,
          emailVerfied: user?.emailVerified,
          phone: user?.phoneNumber,
          imageUrl: user?.photoURL,
        });
        setstepCompleted(1);
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error);
        toast.warning(error, {
          position: "top-right",
          icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
          bodyClassName: "text-sm tracking-wide font-work"
        });
      });
  };

  const safeSetUseCase = (e) => {
    if (useCases.includes(e)) {
      setUseCases(useCases.filter((f) => f !== e));
    } else {
      setUseCases([...useCases, e]);
    }
  };

  const signupOneComplete = async () => {
    switch (stepCompleted) {
      case 0: {
        const phone = phoneRef.current.value;
        const email = emailRef.current.value;
        const password = passwordRef.current.value;

        let regex = /^[6-9][0-9]{9}/i;

        if (!regex.test(phone)) {
          return new AWN().warning("Please enter valid phone number");
        }
        if (!email.includes("@")) {
          return new AWN().warning("Please enter valid email address");
        }
        if (password.length < 8) {
          return new AWN().warning("Password should be more than 8 letters");
        }


        const getaccount = await getDoc(doc(db, strings.adminCollection, email)).then((res) => res.exists())
        if (getaccount) {
          return new AWN().warning("Account already exists, please login")
        }

        setacDetails({
          ...accountDetails,
          phone: phone,
          email: email,
          pass: password,
        });
        setstepCompleted(1);
        break;
      }
      case 1: {
        const name = nameRef.current.value;
        const companyName = businessNameRef.current.value;
        const description = descriptionRef.current.value;
        if (companyName.length <= 2) {
          return new AWN().warning("Please enter valid company name");
        }
        if (name.length < 6) {
          return new AWN().warning("Please enter full Name");
        }
        if (!accountDetails.employeeNumber)
          return new AWN().warning("Please select number of Employees");
        if (description.length < 50) {
          return new AWN().warning("Description should be more than 50 letters");
        }
        setacDetails({
          ...accountDetails,
          name: name,
          companyName: companyName,
          description: description
        });
        checkBrandExistance();
        break;
      }
      case 2: {
        if (useCases.length === 0) {
          return new AWN().warning("Please select atleast 1 use case");
        }
        setstepCompleted(3);
        break;
      }
      case 3: {
        createAccount();
        break;
      }
      case 4: {
        break;
      }

      default: {
        console.log("this is default case");
      }
    }
  };

  const checkBrandExistance = async () => {
    const company = businessNameRef.current.value?.trim();
    const q = query(
      collection(db, "brand"),
      where("name", "==", company),
      limit(1)
    );

    const exist = await getDocs(q)
      .then((res) => res.length === 1)
      .catch((e) => {
        console.log(e);
        return true;
      });
    console.log(exist);
    if (exist) {
      new AWN().warning("this company name already exist.");
    } else {
      setstepCompleted(2);
    }
  };

  const timer = (ms) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(true);
      }, ms);
    });

  const createBrand = async () => {
    const id = nanoid(10);
    let data = {
      addedBy: accountDetails.email,
      dateAdded: Date.now(),
      description: accountDetails?.description,
      coverImage: null,
      catId: null,
      experiences: 0,
      reviews: 0,
      flicks: 0,
      status: false,
      subscribes: 0,
      subcategory: null,
      name: accountDetails.companyName,
      filter: accountDetails.companyName.split(" ").map((r) => r.toLowerCase()),
      id: "brand_" + id,
      tag: "@brand_" + id,
      totalReviews: 0,
      views: 0,
      imageUrl: "https://ui-avatars.com/api/?name=" + accountDetails.companyName,
      rating: 3,
    };

    let headersList = {
      "Accept": "*/*",
      "Authorization": "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g"
    }

    let reqOptions = {
      url: "https://brandapi-zscu3untuq-el.a.run.app/createBrand",
      method: "PUT",
      headers: headersList,
      data: data,
    }

    let response = await axios.request(reqOptions);
    if (response.data.success === true) {
      return response.data.id;
    }
    else return null;
  };

  const createAccount = async () => {
    const websiteUrl = websiteUrlRef.current.value;
    if (websiteUrl) {
      if (!websiteUrl.includes(".")) {
        return new AWN().warning("Please enter valid website Url");
      }
    }

    setstepCompleted(4);

    const brandResponse = await createBrand();

    if (!brandResponse) {
      new AWN().warning("unable to create brand. Please try again.");
      return;
    }

    var data = accountDetails;
    data = {
      ...data,
      brandId: brandResponse,
      websiteUrl: websiteUrl,
      plan: "freemium",
      campaigns: 0,
      downloads: 0,
      comments: 0,
      // campaignLimit: 1,
      // downloadLimit: 3,
      // commentLimit: 0,
      limit: {
        campaignLimit: 1,
        commentLimit: 0,
        downloadLimit: 3,
        faqLimit: 1,
        galleryLimit: 1,
        challangeLimit: 1,
        quizLimit: 1
      },
      useCase: useCases,
      dateJoined: Date.now(),
      role: "brandAdmin",
    };

    if (!data.emailVerfied) {
      var userCreatewithEmail = false;

      userCreatewithEmail = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.pass
      )
        .then((res) => {
          data = { ...data, accessToken: res.user.accessToken };
          return true;
        })
        .catch((e) => {
          if (e.code === "auth/email-already-in-use") {
            return true;
          } else {
            console.log(e);
            new AWN().warning(e.toString());
            setTimeout(() => window.location.reload(), 8000);
            return false;
          }
        });

      if (!userCreatewithEmail) return;
    }

    setacDetails(data);

    const existAccount = await getDoc(
      doc(db, strings.adminCollection, accountDetails.email)
    ).then((res) => res.exists());

    if (existAccount) {
      return new AWN().warning("Already account exist for this email");
    }
    const success = await setDoc(
      doc(db, strings.adminCollection, accountDetails.email),
      data
    )
      .then((res) => true)
      .catch((e) => false);

    const wait = await timer(2000);
    if (wait) {
      console.log("wait successfull");
    }
    if (success) {
      setstepCompleted(5);
    } else {
      setstepCompleted(1);
      return new AWN().alert("Something went wrong.. Please try again later");
    }
  };

  const togglePass = () => {
    var pass = document.getElementById("password");
    var eye = document.getElementById("eye");
    if (pass.type === "text") {
      pass.type = "password";
      eye.classList.remove("fa-eye");
      eye.classList.add("fa-eye-slash");
    } else {
      pass.type = "text";
      eye.classList.remove("fa-eye-slash");
      eye.classList.add("fa-eye");
    }
  };

  if (brand !== null && user !== null) {
    return router.replace("/admin");
  } else
    return (
      <div className="bg-[#1A1A1A] h-screen relative">
        <div className="">
          <img
            src={require("../assets/logo/MyRevue Logo elite new.svg").default}
            alt="logo"
            className="h-[50px]  transform scale-150 absolute left-24 top-10"
          />
        </div>
        <div className="p-5 flex items-center justify-center bg-cover bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fpattern-min.png?alt=media&token=bc04c455-0afe-4295-9562-044cb7cf2ef0')] h-full w-full">
          <div className="w-full max-w-[450px] ">
            {/* <SignUpStepCompledted stepCompleted={stepCompleted} /> */}
            <div
              className={stepCompleted === 0 ? "space-y-4 hideOut" : "hideIn"}
              id="page1"
            >
              <h1 className="text-3xl lg:text-4xl my-3 font-work">Create your account</h1>


              <div className="flex w-full items-center justify-center">
                <span className="font-work bg-transparent flex items-center justify-center placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-12">
                  +91
                </span>
                <input
                  type="text"
                  defaultValue={accountDetails?.phone}
                  ref={phoneRef}
                  maxLength="10"
                  className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                  placeholder="Phone Number "
                />
              </div>
              <input
                type="email"
                defaultValue={accountDetails?.email}
                ref={emailRef}
                className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                placeholder="Business Email Address"
              />
              <div className="flex w-full items-center justify-center">
                <input
                  type="password"
                  id="password"
                  defaultValue={accountDetails?.pass}
                  ref={passwordRef}
                  maxLength="20"
                  className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                  placeholder="Password"
                />
                <span className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-12 flex items-center justify-center ">
                  <i
                    className="fas fa-eye px-2 cursor-pointer"
                    id="eye"
                    onClick={togglePass}
                  ></i>
                </span>
              </div>
              <p className="text-gray-400 text-center my-3 tracking-wide ">
                By continuing, you agree to
                <span className="text-bold text-[#C4C4C4] px-1">
                  MyRevue's Terms
                </span>
                of
                <span className="text-bold  text-[#C4C4C4] px-1  ">
                  Service & Privacy
                </span>
                Policy
              </p>
              <button
                className="font-work bg-gradient-to-r from-[#F92147] via-[#C535A8] to-[#9747FF] font-medium w-full h-12 rounded-md"
                onClick={signupOneComplete}
              >
                Continue
              </button>
              <p className="font-work">OR</p>
              <button
                className="bg-white text-black px-5 rounded-md w-full flex items-center gap-x-4 py-[10px] justify-center "
                onClick={signInPopup}
              >
                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FGoogle__G__Logo.svg.png?alt=media&token=693d692a-9a33-4720-8a94-6e3a35810d8d" alt="" className="h-6 w-6" />
                <p className="text-[17px] font-work">Continue with Google</p>
              </button>
              <p className="text-[#C4C4C4] text-center my-3 tracking-wide">
                Already have an account?
                <Link to="/login" className="px-2 text-white">
                  Click here
                </Link>
              </p>
            </div>
            <div
              className={stepCompleted === 1 ? " hideOut w-full sm:w-96" : "hideIn"}
              id="page1"
            >
              <h1 className="text-4xl  font-work leading-none">Welcome!</h1>
              <p className="font-work">Let's set Up your account.</p>
              <div className="space-y-4">
                <input
                  type="email"
                  defaultValue={accountDetails?.name}
                  ref={nameRef}
                  className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                  placeholder="Full name"
                />
                <input
                  type="email"
                  defaultValue={accountDetails?.description}
                  ref={descriptionRef}
                  className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                  placeholder="Brand description"
                />
                <input
                  type="email"
                  defaultValue={accountDetails?.companyName}
                  ref={businessNameRef}
                  className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                  placeholder="Company name"
                />

                <p className="w-full text-start text-gray-200">
                  Number of employees
                </p>
                <div className="flex  text-xs w-full items-center justify-between">
                  <button
                    className={
                      "border-2 border-[#8E8B8B]  rounded-md p-2 h-10 m-1 " +
                      (accountDetails.employeeNumber === "<10"
                        ? "bg-white text-black"
                        : null)
                    }
                    onClick={() =>
                      setacDetails({ ...accountDetails, employeeNumber: "<10" })
                    }
                  >
                    {"<10"}
                  </button>
                  <button
                    className={
                      "border-2 border-[#8E8B8B] rounded-md p-2 h-10 m-1 " +
                      (accountDetails.employeeNumber === "10-49"
                        ? "bg-white text-black"
                        : null)
                    }
                    onClick={() =>
                      setacDetails({ ...accountDetails, employeeNumber: "10-49" })
                    }
                  >
                    {" "}
                    10-49
                  </button>
                  <button
                    className={
                      "border-2 border-[#8E8B8B] rounded-md p-2 h-10 m-1 " +
                      (accountDetails.employeeNumber === "50-99"
                        ? "bg-white text-black"
                        : null)
                    }
                    onClick={() =>
                      setacDetails({ ...accountDetails, employeeNumber: "50-99" })
                    }
                  >
                    50-99
                  </button>
                  <button
                    className={
                      "border-2 border-[#8E8B8B] rounded-md p-2 h-10 m-1 " +
                      (accountDetails.employeeNumber === "100-999"
                        ? "bg-white text-black"
                        : null)
                    }
                    onClick={() =>
                      setacDetails({
                        ...accountDetails,
                        employeeNumber: "100-999",
                      })
                    }
                  >
                    100-999
                  </button>
                  <button
                    className={
                      "border-2 border-[#8E8B8B] rounded-md p-2 h-10 m-1 " +
                      (accountDetails.employeeNumber === "1000+"
                        ? "bg-white text-black"
                        : null)
                    }
                    onClick={() =>
                      setacDetails({ ...accountDetails, employeeNumber: "1000+" })
                    }
                  >
                    1000+
                  </button>
                </div>
              </div>

              <button
                className="font-work bg-gradient-to-r from-[#F92147] via-[#C535A8] to-[#9747FF] font-medium w-full h-12 rounded-md my-3"
                onClick={signupOneComplete}
              >
                Continue
              </button>

              <p
                className="text-[#BEBEBE] tracking-wide font-work text-center my-1 text-sm cursor-pointer"
                onClick={() => setstepCompleted(stepCompleted - 1)}
              >
                Go Back
              </p>
            </div>
            <div
              className={stepCompleted === 2 ? " hideOut w-full sm:w-96" : "hideIn"}
              id="page1"
            >
              <h1 className="text-3xl lg:text-4xl mb-1 font-work text-center">How we can help you with?</h1>
              <p className="text-sm text-[#BEBEBE]  mb-3 font-work">
                Please select all that apply
              </p>

              <button
                className={
                  "font-work  tracking-wide w-full rounded-md p-2 h-10 m-1 " +
                  (useCases.includes("Video Testimonials/Reviews")
                    ? "border-2 border-white text-white"
                    : "border-2 border-[#808080] text-[#808080] ")
                }
                onClick={(e) => safeSetUseCase(e.target.innerHTML)}
              >
                Video Testimonials/Reviews
              </button>
              <button
                className={
                  "font-work  tracking-wide w-full rounded-md p-2 h-10 m-1 " +
                  (useCases.includes("Video Advertising and Social Media")
                    ? "border-2 border-white text-white"
                    : "border-2 border-[#808080] text-[#808080] ")
                }
                onClick={(e) => safeSetUseCase(e.target.innerHTML)}
              >
                Video Advertising and Social Media
              </button>
              <button
                className={
                  "font-work  tracking-wide w-full rounded-md p-2 h-10 m-1 " +
                  (useCases.includes("Internal communication")
                    ? "border-2 border-white text-white"
                    : "border-2 border-[#808080] text-[#808080] ")
                }
                onClick={(e) => safeSetUseCase(e.target.innerHTML)}
              >
                Internal communication
              </button>
              <button
                className={
                  "font-work  tracking-wide w-full rounded-md p-2 h-10 m-1 " +
                  (useCases.includes("Hiring and recruiting")
                    ? "border-2 border-white text-white"
                    : "border-2 border-[#808080] text-[#808080] ")
                }
                onClick={(e) => safeSetUseCase(e.target.innerHTML)}
              >
                Hiring and recruiting
              </button>
              <button
                className={
                  "font-work  tracking-wide w-full rounded-md p-2 h-10 m-1 " +
                  (useCases.includes("Sales enablement and training")
                    ? "border-2 border-white text-white"
                    : "border-2 border-[#808080] text-[#808080] ")
                }
                onClick={(e) => safeSetUseCase(e.target.innerHTML)}
              >
                Sales enablement and training
              </button>
              <button
                className={
                  "font-work  tracking-wide w-full rounded-md p-2 h-10 m-1 " +
                  (useCases.includes("Event videoes and promotion")
                    ? "border-2 border-white text-white"
                    : "border-2 border-[#808080] text-[#808080] ")
                }
                onClick={(e) => safeSetUseCase(e.target.innerHTML)}
              >
                Event videoes and promotion
              </button>
              <button
                className={
                  "font-work  tracking-wide w-full rounded-md p-2 h-10 m-1 " +
                  (useCases.includes("Feedback") ? "border-2 border-white text-white"
                    : "border-2 border-[#808080] text-[#808080] ")
                }
                onClick={(e) => safeSetUseCase(e.target.innerHTML)}
              >
                Feedback
              </button>
              <button
                className={
                  "font-work  tracking-wide w-full rounded-md p-2 h-10 m-1 " +
                  (useCases.includes("Other") ? "border-2 border-white text-white"
                    : "border-2 border-[#808080] text-[#808080] ")
                }
                onClick={(e) => safeSetUseCase(e.target.innerHTML)}
              >
                Other
              </button>
              <button
                className="font-work bg-gradient-to-r from-[#F92147] via-[#C535A8] to-[#9747FF] font-medium w-full h-12 rounded-md my-3"
                onClick={signupOneComplete}
              >
                Continue
              </button>

              <p
                className="text-[#BEBEBE] tracking-wide font-work text-center my-1 text-sm cursor-pointer"
                onClick={() => setstepCompleted(stepCompleted - 1)}
              >
                Go Back
              </p>
            </div>
            <div
              className={stepCompleted === 3 ? " hideOut w-full sm:w-96" : "hideIn"}
              id="page1"
            >
              <h1 className="text-3xl lg:text-4xl font-work text-center mb-5">The final step, Branding</h1>
              {/* <p className="my-3 text-xs text-gray-300">
                Please enter your website URL
              </p> */}
              <input
                type="text"
                defaultValue={accountDetails?.websiteUrl}
                ref={websiteUrlRef}
                className="font-work bg-transparent placeholder:text-[#8E8B8B] text-white border-b border-white h-10 appearance-none outline-none w-full"
                placeholder="Please enter your website URL"
              />
              <button
                className="mt-4 font-work bg-gradient-to-r from-[#F92147] via-[#C535A8] to-[#9747FF] font-medium w-full h-12 rounded-md my-3"
                onClick={signupOneComplete}
              >
                Continue
              </button>
              <p className="font-work tracking-wide">OR</p>
              <button
                onClick={createAccount}
                className="text-[#BEBEBE] tracking-wide font-work text-center my-1 text-sm cursor-pointer"
              >
                Skip this step
              </button>
            </div>
            <div
              className={stepCompleted === 4 ? " hideOut w-full sm:w-96" : "hideIn"}
              id="page1"
            >
              <h1 className="text-4xl my-3 text-center font-work">Creating Account now</h1>

              <i className="fas my-5 fa-spinner animate-spin fa-5x"></i>
            </div>
            <div
              className={stepCompleted === 5 ? " hideOut w-full sm:w-96" : "hideIn"}
              id="page1"
            >
              <h1 className="text-4xl my-3 font-work">All Done!</h1>

              <i className="fas fa-check fa-7x bg-green-500 p-5 rounded-full my-5"></i>
              <button onClick={() => router.push("/login")} className="font-work bg-gradient-to-r from-[#F92147] via-[#C535A8] to-[#9747FF] font-medium w-full h-12 rounded-md my-3">
                Continue to Login
              </button>
            </div>
          </div>
        </div>
      </div>
      // <div className="bg-[#1A1A1A] h-screen relative">
      //   <div className="">
      //     <img
      //       src={require("../assets/logo/MyRevue Logo elite new.svg").default}
      //       alt="logo"
      //       className="h-[60px]  transform scale-150 absolute left-24 top-10"
      //     />
      //   </div>
      //   <div className="p-5 flex items-center justify-center bg-cover bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fpattern-min.png?alt=media&token=bc04c455-0afe-4295-9562-044cb7cf2ef0')] h-full w-full">
      //     <div className="w-full max-w-[520px] ">
      //       {/* <div className="flex items-center justify-center">
      //         <SignUpStepCompledted stepCompleted={stepCompleted} />
      //       </div> */}
      //       <div className="">
      //         <h1 className="text-4xl text-center font-work">Create Account</h1>
      //         <p className=""></p>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
}

export default SignUp;
