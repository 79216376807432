import { ChartDonut } from "@phosphor-icons/react";
import React from "react";
import { useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useStateValue } from "../context/StateProvider";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";

function WebTotalViews() {
  const [{ user }, dispatch] = useStateValue();
  const [siteTotalViews, setSiteTotalViews] = useState([]);

  useEffect(() => {
    async function getSiteTotalViews() {
      try {
        let headersList = {
          Accept: "*/*",
          Authorization: user?.clientSecret,
        };

        let reqOptions = {
          url: `https://api.myrevue.app/getViewsGraphData/7`,
          method: "GET",
          headers: headersList,
        };
        let cachedData = sessionStorage.getItem("webtotalviews");
        let response;
        if (!cachedData) {
          console.log("----FETCHED API-------");

          response = await axios.request(reqOptions);
          response = response.data;
          sessionStorage.setItem("webtotalviews", JSON.stringify(response));
        } else {
          response = JSON.parse(cachedData);
        }
        // Sort the data array in ascending order based on the "_id" (date) field
        let sortedData = response.data.sort(
          (a, b) => new Date(a._id) - new Date(b._id)
        );
        let counts = [];
        sortedData.forEach((data) => {
          counts.push(data.count);
        });
        setSiteTotalViews(sortedData);
      } catch (error) {
        toast.warning(error, {
          position: "top-right",
          icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
          bodyClassName: "text-sm tracking-wide font-work",
        });
      }
    }
    getSiteTotalViews();
  }, []);

  var siteViewsOptions = {
    series: [
      {
        name: "Website Total Views",
        data: siteTotalViews.map((views) => views.count),
      },
    ],

    options: {
      chart: {
        type: "area",
        toolbar: {
          show: false, // Hide the toolbar (which includes zoom in, zoom out, etc.)
        },
      },
      colors: ["#5b478f"],
      stroke: {
        curve: "smooth", // Use 'straight' curve instead of 'smooth'
        width: 3, // Set the line width to 2
        show: true, // Show the stroke (border) above the graph points
        colors: ["#855CF8"],
      },
      xaxis: {
        categories: siteTotalViews.map((views) => views._id),
      },
      markers: {
        show: false, // Hide the data point markers
      },
      legend: {
        show: false, // Hide the legend
      },
      tooltip: {
        theme: "dark", // Set the tooltip theme to dark
        style: {
          background: "#000000", // Change the background color to black
          color: "#ffffff", // Change the text color to white
        },
      },
      dataLabels: {
        enabled: false, // Disable the data labels
      },
      grid: {
        show: false,
      },
    },
  };

  return (
    <div
      className={`bg-[#131418] shadow-sm  shadow-gray-600 h-96 w-full rounded-md p-5 text-white col-span-2`}
    >
      <div className="flex justify-between items-center">
        <p className="font-light text-sm tracking-wider">Views on Website</p>
        <p className="text-3xl text-[#9a9999]">
          {siteTotalViews.reduce(
            (sum, item) =>
              typeof item.count === "number" ? item.count + sum : sum,
            0
          )}
        </p>
        <p className="flex gap-2 text-xs items-center">
          <ChartDonut size={22} color="#ffffff" /> Weekly
        </p>
      </div>
      {siteViewsOptions?.options?.xaxis?.categories.length === 0 ? (
        <div className="w-full flex items-center justify-center h-full">
          <p className="font-work">No data available for this week</p>
        </div>
      ) : (
        <div className="w-full">
          <ReactApexChart
            options={siteViewsOptions.options}
            series={siteViewsOptions.series}
            type="area"
            height={240}
          />
        </div>
      )}
    </div>
  );
}

export default WebTotalViews;
