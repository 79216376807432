import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import {
  GoogleAuthProvider,
  RecaptchaVerifier,
  getAuth,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import { logEngagementHook } from "../common/functions";
import axios from "axios";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";
import { nanoid } from "nanoid";
import AWN from "awesome-notifications";

function QuizUserPage() {
  let quizdiv = useRef();
  const [page, setPage] = useState(0);
  const [current, setCurrent] = useState(0);
  const [quiz, setQuiz] = useState(null);
  const [userResponse, setResponse] = useState([]);
  const [user, setUser] = useState(null);
  const [device, setDevice] = useState("");

  const { form } = useParams();
  let videoRef = useRef();
  let phoneRef = useRef();
  let verifyButtonRef = useRef();
  const user_id = "user_" + nanoid(15);

  const delay = (time) =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, time);
    });

  useEffect(() => {
    const inputs = document.getElementById("otpinputs");

    inputs?.addEventListener("input", function (e) {
      const target = e.target;
      const val = target.value;

      if (isNaN(val)) {
        target.value = "";
        return;
      }

      if (val !== "") {
        const next = target.nextElementSibling;
        if (next) {
          next.focus();
        }
      }
    });

    inputs?.addEventListener("keyup", function (e) {
      const target = e.target;
      const key = e.key.toLowerCase();

      if (key === "backspace" || key === "delete") {
        target.value = "";
        const prev = target.previousElementSibling;
        if (prev) {
          prev.focus();
        }
        return;
      }
    });
  }, [page]);

  useEffect(() => {
    if (window.innerWidth > 450) {
      const height = Math.ceil(window.innerHeight * 0.9);
      const width = Math.floor(height / 2);
      setDevice("Desktop");
      quizdiv.current.style.width = width + "px";
      quizdiv.current.style.height = height + "px";
    } else {
      setDevice("mobile");

      window.scrollTo(0, document.body.scrollHeight);
    }
    if (!quiz)
      getDoc(doc(db, "quizs", form)).then((d) => {
        if (!d.exists()) {
          setPage(7);
        }
        var quizData = d.data();
        setQuiz(quizData);
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: "Desktop",
          brand: quizData.bpsId,
          action: "open_quiz",
        });
      });
  }, []);

  const signinByGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: device,
          brand: quiz.bpsId,
          action: "quiz_login_success",
        });
        setUser({
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        });

        var isSubmited = await getDocs(
          query(
            collection(db, "quizs/" + form + "/submissions"),
            where("user_info.email", "==", user.email)
          )
        ).then((res) => res.docs.length !== 0);
        if (isSubmited) {
          setPage(6);
          return;
        }

        let headersList = {
          Accept: "*/*",
          Authorization:
            "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
          "Content-Type": "application/json",
        };

        let bodyContent = {
          name: user.displayName,
          id: user_id,
          tag: `@${user_id}`,
          email: user.email,
          followers: 0,
          following: 0,
          reviews: 0,
          views: 0,
          flicks: 0,
        };

        let reqOptions = {
          url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
          method: "PUT",
          headers: headersList,
          data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        if (response.data?.success === false) {
          toast.warning("Something went wrong", {
            position: "top-right",
            icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
            bodyClassName: "text-sm tracking-wide font-work",
          });
          return;
        }
        setUser(response?.data?.user);
        setPage(4);
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function getphoneotp(e) {
    const phone_number = phoneRef.current.value;
    let regex = /^[6-9][0-9]{9}/i;
    if (!regex.test(phone_number)) {
      return new AWN().warning("Please enter valid phone number");
    }
    e.currentTarget.classList.remove("fa-arrow-right");
    e.currentTarget.classList.add("fa-spinner");
    e.currentTarget.style.boxShadow = "none";
    e.currentTarget.classList.add("animate-spin");

    const auth = getAuth();

    const phonenumber = "+91" + phone_number;

    var isSubmited = await getDocs(
      query(
        collection(db, "quizs/" + form + "/submissions"),
        where("user_info.phone", "==", phone_number)
      )
    ).then((res) => res.docs.length !== 0);

    if (isSubmited) {
      setPage(6);
      return;
    }
    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // onSignInSubmit();
        },
      },
      auth
    );
    let appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phonenumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setPage(3);
      })
      .catch((error) => {
        alert(error.message);
      });
  }

  const verifyOTP = (otp) => {
    let regex = /\d{6}/i;
    if (!regex.test(otp)) {
      verifyButtonRef.current.innerHTML = "Verify";
      return new AWN().alert("Please enter valid otp");
    }
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then(async (result) => {
        const user = result.user;
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: device,
          brand: quiz.bpsId,
          action: "quiz_login_success",
        });
        let headersList = {
          Accept: "*/*",
          Authorization:
            "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
          "Content-Type": "application/json",
        };

        let bodyContent = {
          name: user.displayName?.toLowerCase() ?? "Change User Name",
          id: user_id,
          tag: `@${user_id}`,
          email: user.email,
          phone: user.phoneNumber,
          followers: 0,
          following: 0,
          reviews: 0,
          views: 0,
          flicks: 0,
          joinDate: Date.now(),
          categories: [],
        };

        let reqOptions = {
          url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
          method: "PUT",
          headers: headersList,
          data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        if (response.data?.success === false) {
          toast.warning("Something went wrong", {
            position: "top-right",
            icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
            bodyClassName: "text-sm tracking-wide font-work",
          });
          return;
        }
        setUser(response?.data?.user);
        gotoPageAfterLoading(4);
      })
      .catch((error) => {
        new AWN().alert("Sign In error. Please try again.");
      });
  };

  const gotoPageAfterLoading = (pageNumber) => {
    setPage(1);
    setTimeout(() => {
      setPage(pageNumber);
    }, 1500);
  };

  const nextPage = (jump = 1) => {
    // 1.loading
    // 2.login
    // 3.otp verification page
    // 4.begin page
    // 5.question page
    // 6.thank you page
    // 7.already submitted page
    // 8.invalid form page
    if (jump !== 1) {
      setPage(jump);
      return;
    }
    if (page + 1 === 3) {
      getphoneotp();
      return;
    }
    if (page === 3) {
      verifyButtonRef.current.innerHTML = `<i class='fas fa fa-spinner animate-spin'></i>`;
      const inputs = document.getElementById("otpinputs");
      var otp = "";
      inputs.childNodes.forEach((otpfields) => {
        otp += otpfields.value;
      });
      verifyOTP(otp);
      return;
    }
    setPage(page + 1);
  };

  const nextCurrent = () => {
    if (current >= 4) {
      setPage(6);
    } else setCurrent(current + 1);
  };

  const renderPages = () => {
    switch (page) {
      case 6:
        const answerCorrected =
          userResponse.filter((d) => d.isRight).length - 1;

        return (
          <div
            key={page + "page"}
            className=" fade h-full flex flex-col items-center gap-6 justify-center text-white"
          >
            <p className="text-5xl">thankyou</p>
            <div className="flex gap-2 text-xl">
              {Array.from(Array(5)).map((s, index) => {
                if (index <= answerCorrected)
                  return (
                    <i key={"star" + index} className="fa fas fa-star "></i>
                  );
                else
                  return (
                    <i
                      key={"star" + index}
                      className="fa fas fa-star opacity-20"
                    ></i>
                  );
              })}
            </div>
          </div>
        );
      case 5:
        var question = quiz.questions[current];
        return (
          <div
            key={page + "page"}
            className="fade h-full flex flex-col items-center p-3 text-white"
          >
            <div className="h-3"></div>
            <div className="flex gap-2">
              {Array.from(Array(quiz.questions.length ?? 5)).map((d, index) => {
                if (index === current)
                  return (
                    <div
                      key={"progress" + index}
                      className="h-2 w-4 rounded-full transform duration-300 scale-110 bg-green-300"
                    ></div>
                  );
                else if (index < current)
                  return (
                    <div
                      key={"progress" + index}
                      className="h-2 w-4 rounded-full bg-white"
                    ></div>
                  );
                else
                  return (
                    <div
                      key={"progress" + index}
                      className="h-2 w-4 rounded-full bg-white bg-opacity-30"
                    ></div>
                  );
              })}
            </div>
            <div
              className=" rounded-lg overflow-hidden w-4/5 my-3 aspect-[9/16] relative"
              style={{ border: "5px solid #ff005c" }}
            >
              <video
                autoPlay
                ref={videoRef}
                className="h-full w-full object-cover transform duration-300"
                src={question.questionVideoUrl}
              ></video>
              <button
                onClick={() => {
                  videoRef.current.currentTime = 0;
                  videoRef.current.play();
                }}
                className=" w-min mx-auto text-xs absolute left-0 right-0 bottom-1 rounded-full p-2 px-3 bg-pink-700"
              >
                Replay
              </button>
            </div>
            <div className="grid grid-cols-2 grid-row-2 w-full flex-1 gap-2">
              {Object.entries(question.answers).map(([key, value]) => {
                return (
                  <button
                    key={"option" + key + current}
                    onClick={async (e) => {
                      if (userResponse[current]) return;
                      var resp = question;
                      resp["userAnswer"] = value;
                      resp["userOption"] = key;
                      resp["isRight"] = question.correctAnswer === key;
                      setResponse([...userResponse, resp]);
                      if (resp.isRight) {
                        e.currentTarget.style.backgroundColor = "green";
                      } else {
                        e.currentTarget.style.backgroundColor = "red";
                      }
                      await delay(2000);
                      nextCurrent();
                    }}
                    className="bg-[#ffffff50] rounded-lg border border-white "
                  >
                    {value}
                  </button>
                );
              })}
            </div>
            <div className="h-12"></div>
          </div>
        );
      case 4:
        return (
          <div
            key={page + "page"}
            className="fade h-full flex flex-col p-3 justify-center text-white"
          >
            <div className="h-40"></div>
            <p className="">Why wait more?</p>
            <p className="text-5xl">Let's Begin</p>
            <button
              onClick={() => nextPage(1)}
              className="h-10 my-12 rounded-full text-[#ff005c] self-center bg-white w-3/5"
            >
              Sure!!
            </button>
          </div>
        );
      case 3:
        return (
          <div
            key={page + "page"}
            className="fade h-full flex flex-col items-center text-white p-3"
          >
            <p className="text-3xl font-semibold my-8">
              Please Verify and You are ready!
            </p>
            <p className="text-xs text-center my-8">
              For additional security, we’ve send an OTP to your registered
              Mobile Number and Email Id
            </p>
            <div id="otpinputs" className="flex gap-2">
              <input
                type="text"
                inputMode="numeric"
                className="h-10 w-8 text-center rounded-md outline-none duration-200  focus:border-pink-700   border-white bg-transparent border-2"
                maxLength={1}
              />
              <input
                type="text"
                inputMode="numeric"
                className="h-10 w-8 text-center rounded-md   outline-none duration-200  focus:border-pink-700 border-white bg-transparent border-2"
                maxLength={1}
              />
              <input
                type="text"
                inputMode="numeric"
                className="h-10 w-8 text-center rounded-md outline-none duration-200  focus:border-pink-700  border-white bg-transparent border-2"
                maxLength={1}
              />
              <input
                type="text"
                inputMode="numeric"
                className="h-10 w-8 text-center rounded-md  outline-none  duration-200 focus:border-pink-700 border-white bg-transparent border-2"
                maxLength={1}
              />
              <input
                type="text"
                inputMode="numeric"
                className="h-10 w-8 text-center rounded-md  outline-none  duration-200 focus:border-pink-700 border-white bg-transparent border-2"
                maxLength={1}
              />
              <input
                type="text"
                inputMode="numeric"
                className="h-10 w-8 text-center rounded-md  outline-none  duration-200 focus:border-pink-700 border-white bg-transparent border-2"
                maxLength={1}
              />
            </div>
            <button
              ref={verifyButtonRef}
              onClick={() => nextPage(1)}
              className="fade h-10 my-12 rounded-full text-center text-[#ff005c] bg-white w-3/5"
            >
              Verify
            </button>
          </div>
        );
      case 2:
        return (
          <div
            key={page + "page"}
            className="fade h-full flex flex-col text-white  justify-start items-center"
          >
            <img src={require("../assets/quiz/qui2.png")} alt="" />
            <p className="font-semibold my-2">Sign Up Or Login</p>
            <button
              onClick={signinByGoogle}
              className="h-10 text-center  flex items-center bg-white rounded-full text-black px-3 text-sm my-5 w-3/4"
            >
              <img
                className="h-6 mr-2"
                src={require("../assets/icons/google.png")}
                alt=""
              />{" "}
              <p className="m-0 p-0 text-center flex-1 ">
                Continue With Google
              </p>
            </button>
            <p className="">OR</p>
            <div className="" id="sign-in-button"></div>
            <input
              className="my-5 text-black appearance-none h-10 rounded-full px-2 text-xs w-3/4"
              type="number"
              ref={phoneRef}
              maxLength={12}
              placeholder="Continue with phone number"
            />
            <i
              onClick={(e) => {
                getphoneotp(e);
              }}
              className="fas hover:bg-pink-500 duration-150 cursor-pointer font-bold fa-arrow-right p-5 rounded-full bg-pink-600 text-white -mb-7"
              style={{ boxShadow: "3px 4px 8px -2px aliceblue" }}
            ></i>
          </div>
        );
      case 1:
        return (
          <div
            key={"page" + page}
            className="fade h-full w-full flex items-center justify-center"
          >
            <i className="fas fa-spinner animate-spin text-white"></i>
          </div>
        );
      default:
        return (
          <div className=" fade h-full">
            <img
              className=" w-full object-cover"
              src={require("../assets/quiz/quiz1.png")}
              alt=""
            />
            <img
              src={require("../assets/logo/brand logo/gyk.png")}
              className="h-16 bg-white rounded-lg -mb-8 z-20 mx-auto"
              alt="logo"
            />
            <div
              style={{ boxShadow: "0px 5px 40px 0px #a9d7ff" }}
              className="flex z-10  flex-col pt-8 items-center justify-start rounded-2xl bg-white mx-auto h-2/6 w-4/5 px-3"
            >
              <p className="text-2xl text-center">
                Answer quiz and earn rewards
              </p>
              <br />
              <p className="text-center text-xs flex-1">
                this is offer line just put an extra line here
              </p>
              <i
                onClick={() => nextPage(2)}
                className="fas hover:bg-pink-500 duration-150 cursor-pointer font-bold fa-arrow-right p-5 rounded-full bg-pink-600 text-white -mb-7"
                style={{ boxShadow: "3px 4px 8px -2px aliceblue" }}
              ></i>
            </div>
            <div className="flex-1"></div>
          </div>
        );
    }
  };

  return (
    <div className="text-black md:flex items-center justify-center h-screen w-full bg-black">
      <div
        ref={quizdiv}
        className="quiz-background h-full w-full rounded relative "
      >
        {renderPages()}
        <div className="flex items-center justify-center h-10 absolute left-0 right-0 bottom-2">
          <p className="flex items-center p-1 text-xs bg-white rounded-full bg-opacity-60 px-2">
            Powered By &nbsp;
            <img
              className="h-5"
              src={require("../assets/logo/myrevue_elite_black.svg").default}
              alt=""
            />
          </p>
        </div>
      </div>
    </div>
  );
}

export default QuizUserPage;
