import React from "react";
import { useEffect } from "react";

function Test() {
  const processor = {};

  processor.doLoad = function doLoad() {
    const video = document.getElementById("video");
    this.video = video;

    this.c1 = document.getElementById("c1");
    this.ctx1 = this.c1.getContext("2d");

    this.c2 = document.getElementById("c2");
    this.ctx2 = this.c2.getContext("2d");

    video.addEventListener(
      "play",
      () => {
        this.width = video.videoWidth / 2;
        this.height = video.videoHeight / 2;
        this.timerCallback();
      },
      false
    );
  };

  processor.timerCallback = function timerCallback() {
    if (this.video.paused || this.video.ended) {
      return;
    }
    this.computeFrame();
    setTimeout(() => {
      this.timerCallback();
    }, 0);
  };

  processor.computeFrame = function () {
    this.ctx1.drawImage(this.video, 0, 0, this.width, this.height);
    const frame = this.ctx1.getImageData(0, 0, this.width, this.height);
    const data = frame.data;

    for (let i = 0; i < data.length; i += 4) {
      const red = data[i + 0];
      const green = data[i + 1];
      const blue = data[i + 2];
      if (green > 100 && red > 100 && blue < 43) {
        data[i + 3] = 0;
      }
    }
    this.ctx2.putImageData(frame, 0, 0);
  };

  return (
    <div className="min-h-screen ">
      <div className="flex items-center justify-center">
        <video
          id="video"
          src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/test%2FKaka%20WRLD%20-%20Bholenath%20(A%20Love%20Story)%20-%20Official%20Video%20-%20Arvindr%20Khaira%20-%20Main%20Bhola%20Parvat%20Ka.mp4?alt=media&token=f3110f69-2f66-4e8e-9c47-724fe92dff54"
          controls
          crossOrigin="anonymous"
          className="h-[50vh]"
        />
        <button onClick={() => processor.doLoad()}>play this </button>
      </div>
      <div className="flex">
        <canvas id="c1" width="600" height="400"></canvas>
        <canvas id="c2" width="600" height="400"></canvas>
      </div>
    </div>
  );
}

export default Test;
