import { DeviceMobileCamera, Eye } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Loadingdot from "../../components/loadingdot";
import axios from "axios";
import { useStateValue } from "../../context/StateProvider";
import { toast } from "react-toastify";
import { ArrowLeft, CheckCircle, Checks, CircleNotch, Desktop, Hexagon, PlayCircle, Plus, ShieldSlash, Star, StarHalf, Trash } from "@phosphor-icons/react";
import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { db } from "../../firebase";
import HalfStarRating from "../../components/halfstarrating";
import Modalcard from "../../components/modalcard";
import moment from "moment";

export default function Viewproduct() {
  const { id } = useParams();
  const router = useHistory();
  const [loading, setloading] = useState(false);
  const [product, setproduct] = useState({});
  const [attributes, setattributes] = useState({});
  const [overall_rating, setoverall_rating] = useState(0);
  const [newReviews, setnewReviews] = useState([]);
  const [tab, settab] = useState(0);
  const [{ reviews, brand }] = useStateValue()
  const [selected, setSelected] = useState([]);
  const [exist, setexist] = useState(false);
  const [editable, seteditable] = useState(false);
  const [deviceType, setdeviceType] = useState('desktop');
  const [previewOn, setpreviewOn] = useState(false);
  const [previewLink, setpreviewLink] = useState(null);
  const [importedVideos, setImportedVideos] = useState([]);
  const [showReviews, setShowReviews] = useState(true);
  const [flicks, setFlicks] = useState([]);

  useEffect(() => {
    Promise.all([fetchproduct(), fetchnewReviews(), fetchShopableVideos(), fetchImportedVideos(), fetchFlicks()])
  }, [id]);

  async function fetchproduct() {
    setloading(true);
    let headersList = {
      Accept: "*/*",
      Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
    };

    let reqOptions = {
      url: "https://adminapi-zscu3untuq-el.a.run.app/getProduct/" + id,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    let data = response.data;
    setproduct(data);
    setloading(false);
    setattributes({
      keys: Object.keys(data?.attributes ?? {}),
      values: Object.values(data?.attributes ?? {}),
    });
    overall_rat(Object.values(data?.attributes));
  }
  async function fetchFlicks() {
    let headersList = {
      "Accept": "*/*",
      "Authorization": "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD"
    }

    let reqOptions = {
      url: `https://productapi-zscu3untuq-el.a.run.app/getProductFlicks/${id}/0`,
      method: "GET",
      headers: headersList,
    }

    let response = await axios.request(reqOptions);
    response.data.data.forEach((flick) => {
      flick.type = "flicks"
    })
    setFlicks(response.data.data)
  }
  async function fetchnewReviews() {
    const filtered = reviews?.filter(val => val?.product?.id === id)
    setnewReviews(filtered);
  }
  async function fetchImportedVideos() {
    const importquery = query(collection(db, "importVideos"), where("prodId", "==", id));
    const docs = await getDocs(importquery).then(documents => documents.docs.map(doc => doc.data()));
    setImportedVideos(docs)
  }
  async function fetchShopableVideos() {
    try {
      const res = await getDoc(doc(db, "shoppableVideos", id));
      if (res.exists()) {
        setSelected(res.data()?.selected ?? []);
        seteditable(false);
        setexist(true);
      } else {
        seteditable(true);
        setexist(false);
      }
    } catch (err) {
      console.log(err);
    }
  }

  function overall_rat(array) {
    const mixedSum = (arr = []) => {
      let sum = 0;
      for (let i = 0; i < arr.length; i++) {
        const el = arr[i];
        sum += +el;
      }
      return sum;
    };
    setoverall_rating((mixedSum(array) / 5).toFixed(2));
  }


  const handleSelectClick = (e) => {
    if (selected.some(item => item.id === e.id)) {
      setSelected(selected.filter((r) => r.id !== e.id));
    } else {
      const object = {
        id: e.id,
        type: e.type
      }
      setSelected([...selected, object]);
    }
  };

  async function createShoppableVideos() {
    if (selected.length < 3) {
      toast.warning("Please select atleast 3 reviews/flicks", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work"
      });
      return
    }

    const document = {
      productId: id,
      brandId: brand?.id,
      selected: selected
    }
    try {
      if (exist === true) {
        await updateDoc(
          doc(db, `shoppableVideos/${id}`),
          { selected: selected }
        ).then((res) => {
          toast.success("Shoppable Videos for product has been successfully updated.", {
            position: "top-right",
            icon: <Checks size={32} color="green" weight="bold" />,
            bodyClassName: "text-sm tracking-wide font-work"
          });
          window.location.reload()
        }).catch(err => console.log(err))
        return
      }
      else {
        await setDoc(
          doc(db, `shoppableVideos/${id}`),
          document
        ).then((res) => {
          toast.success("Shoppable Videos for product has been successfully created.", {
            position: "top-right",
            icon: <Checks size={32} color="green" weight="bold" />,
            bodyClassName: "text-sm tracking-wide font-work"
          });
          window.location.reload()
        });
      }
    } catch (e) {
      return toast.warning(e, {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work"
      });
    }
  }

  async function previewOpen(data) {
    setpreviewOn(true);
    setpreviewLink(data?.reviewUrl)
  }
  async function previewClose() {
    setpreviewOn(false);
    setpreviewLink(null)
  }



  return (
    <div className="flex flex-col h-full">
      <div className="px-5 py-4  border-[#ececec1b] text-white flex items-center text-2xl tracking-wide font-medium">
        <div className="h-12 w-12 flex items-center justify-center cursor-pointer" onClick={() => router.goBack()}>
          <ArrowLeft size={36} color="#ffffff" />
        </div>
        <p className="pl-5 first-letter:uppercase ">{product?.name}</p>
      </div>
      <div className="grid w-full grid-cols-2 h-12 my-5 max-w-[848px] mx-auto bg-[#131418] rounded-full">
        <div onClick={() => settab(0)} className={`cursor-pointer h-12 rounded-full ${tab === 0 ? "bg-[#93A8F4]" : ""} tracking-wide text-white flex items-center justify-center transition-all`}>Details</div>
        <div onClick={() => settab(1)} className={`cursor-pointer h-12 rounded-full ${tab === 1 ? "bg-[#93A8F4]" : ""} tracking-wide text-white flex items-center justify-center transition-all`}>Shoppable Videos</div>
        {/* <div onClick={() => settab(2)} className={`cursor-pointer h-12 rounded-full ${tab === 2 ? "bg-[#93A8F4]" : ""} tracking-wide text-white flex items-center justify-center transition-all`}>Import Videos</div> */}
      </div>
      {loading ? (
        <Loadingdot />
      ) : (
        <>
          {tab === 0 &&
            <div className="p-5">
              <div className="flex w-full items-start gap-10 rounded-lg bg-bg_highlight p-8">
                <div className="w-80 flex flex-col items-center">
                  <div className="w-full aspect-square">
                    <img src={product?.imageUrl} crossOrigin="anonymous" alt="" className="h-full w-full object-cover rounded-lg" />
                  </div>
                  <div className="my-4 tracking-wide ">
                    <p className="font-work text-sm">{product?.description}</p>
                  </div>
                </div>
                <div className="w-full flex-1">
                  <div className="">
                    <div className="text-white mb-3 flex justify-end -mr-8">
                      <div className="appearance-none outline-none h-10 w-32 bg-background px-3 truncate mt-2 rounded-l-full  font-work tracking-wide flex items-center justify-center gap-5">
                        <Eye size={24} color="white" />
                        {product?.views ?? 0}
                      </div>
                    </div>
                    <div className="text-white mb-3">
                      <p className="font-work">ID</p>
                      <input
                        type="text"
                        disabled
                        defaultValue={product?.id}
                        className="appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-2 rounded-md text-sm font-work tracking-wide"
                      />
                    </div>
                    <div className="text-white my-3">
                      <p className="font-work">Name</p>
                      <input
                        type="text"
                        disabled
                        defaultValue={product?.name}
                        className="appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-2 rounded-md text-sm font-work tracking-wide"
                      />
                    </div>
                    <div className="text-white my-3">
                      <p className="font-work">Sub Category</p>
                      <input
                        type="text"
                        disabled
                        defaultValue={product?.subcategory}
                        className="appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-2 rounded-md text-sm font-work tracking-wide"
                      />
                    </div>
                  </div>
                  <div className="bg-background w-full p-5 rounded-lg mt-10">
                    <div className="pb-5">
                      <p className="text-center text-white font-work tracking-wide">Overall Rating</p>
                      <div className="flex items-center justify-center">
                        <HalfStarRating
                          sizeh={30}
                          colorcode={"#E2BE45"}
                          rating={parseFloat(overall_rating)}
                        />
                      </div>
                    </div>
                    <div className="flex flex-wrap gap-10">
                      {attributes?.keys?.map((attribute, index) => (
                        <div
                          className="w-fit flex flex-col items-center"
                          key={index}>
                          <p className={`text-center text-white font-work tracking-wide`}>{attribute}</p>
                          <HalfStarRating
                            sizeh={30}
                            colorcode={"#E2BE45"}
                            rating={parseFloat(Math.floor(Math.ceil(product?.attributes?.[attribute] * 2) / 2))}
                          />
                        </div>
                      ))}
                    </div>
                  </div>

                </div>
              </div>
              <div className="bg-bg_highlight w-full p-5 rounded-lg mt-10 grid grid-cols-3 gap-3">
                <div className="h-auto flex items-center justify-center gap-3 w-full text-white">
                  <p className="tracking-wide font-work">Views on product newReviews :</p>
                  <p className="tracking-wide font-work">{product?.views ?? 0}</p>
                </div>
                <div className="h-auto flex items-center justify-center gap-3 w-full text-white">
                  <p className="tracking-wide font-work">newReviews on product :</p>
                  <p className="tracking-wide font-work">{product?.reviews ?? 0}</p>
                </div>
                <div className="h-auto flex items-center justify-center gap-3 w-full text-white">
                  <p className="tracking-wide font-work">Experiences on product :</p>
                  <p className="tracking-wide font-work">{product?.experiences ?? 0}</p>
                </div>
                <div className="h-auto flex items-center justify-center gap-3 w-full text-white">
                  <p className="tracking-wide font-work">Save for later clicks :</p>
                  <p className="tracking-wide font-work">{product?.saveForLater ?? 0}</p>
                </div>
                <div className="h-auto flex items-center justify-center gap-3 w-full text-white">
                  <p className="tracking-wide font-work">Buy now clicks :</p>
                  <p className="tracking-wide font-work">{product?.buyNowClicks ?? 0}</p>
                </div>
                <div className="h-auto flex items-center justify-center gap-3 w-full text-white">
                  <p className="tracking-wide font-work">Intrested in product :</p>
                  <p className="tracking-wide font-work">{product?.intrested_clicks ?? 0}</p>
                </div>
              </div>
              <div className="grid w-full grid-cols-2 h-12 my-5 max-w-[648px] mx-auto bg-[#131418] rounded-full">
                <div onClick={() => setShowReviews(true)} className={`cursor-pointer h-12 rounded-full ${showReviews === true ? "bg-[#93A8F4]" : ""} tracking-wide text-white flex items-center justify-center transition-all`}>Reviews</div>
                <div onClick={() => setShowReviews(false)} className={`cursor-pointer h-12 rounded-full ${showReviews === false ? "bg-[#93A8F4]" : ""} tracking-wide text-white flex items-center justify-center transition-all`}>Flicks</div>
                {/* <div onClick={() => settab(2)} className={`cursor-pointer h-12 rounded-full ${tab === 2 ? "bg-[#93A8F4]" : ""} tracking-wide text-white flex items-center justify-center transition-all`}>Import Videos</div> */}
              </div>
              {showReviews === true ?
                <div className="relative block mt-10 mb-10">
                  <div className="relative">
                    <div className="overflow-x-auto min-h-[0.01%]">
                      <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                        <thead className="h-14 bg-[#131418]">
                          <tr>
                            <th className="  font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              #
                            </th>
                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              Title
                            </th>
                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              Status
                            </th>
                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              Views
                            </th>
                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              Rating
                            </th>
                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="table-row-group align-middle border-inherit">
                          {newReviews.map((review, index) => (
                            <>
                              <tr className="table-row-spacer"></tr>
                              <tr className="table-row align-[inherit]  bg-[#131418]" key={index}>
                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                  {index + 1}
                                </td>
                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                  {review?.name}
                                </td>
                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                  {review?.status === true ? <span className="flex gap-2 items-center"> <div className="h-2 w-2 rounded-full bg-green-500"></div> Published</span> : <span className="flex gap-2 items-center"> <div className="h-2 w-2 rounded-full bg-[#D88D63]"></div> Pending</span>}
                                </td>
                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                  {review?.views ?? 0}
                                </td>
                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                  {review?.rating}
                                </td>
                                <td
                                  className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide  font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                                  onClick={() => router.push("/admin/viewreview/" + review.id)}>
                                  <div className="flex items-center gap-x-3">
                                    <div className="h-10 w-10 flex items-center justify-center relative">
                                      <Hexagon size={40} weight="light" />
                                      <Eye size={20} className="absolute" weight="bold" />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                      {newReviews?.length === 0 && (
                        <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                          No Reviews Available
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                :
                <div className="relative block mt-10 mb-10">
                  <div className="relative">
                    <div className="overflow-x-auto min-h-[0.01%]">
                      <table className=" rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                        <thead className="h-14 bg-[#131418]">
                          <tr>
                            <th className="  font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              #
                            </th>
                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              Title
                            </th>
                            {/* <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              Status
                            </th> */}
                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              Date Added
                            </th>
                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              Views
                            </th>
                            <th className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody className="table-row-group align-middle border-inherit">
                          {flicks.map((review, index) => (
                            <>
                              <tr className="table-row-spacer"></tr>
                              <tr className="table-row align-[inherit]  bg-[#131418]" key={index}>
                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                  {index + 1}
                                </td>
                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                  {review?.name}
                                </td>
                                {/* <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                  {review?.status === true ? <span className="flex gap-2 items-center"> <div className="h-2 w-2 rounded-full bg-green-500"></div> Published</span> : <span className="flex gap-2 items-center"> <div className="h-2 w-2 rounded-full bg-[#D88D63]"></div> Pending</span>}
                                </td> */}
                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                  {moment(review?.dateAdded).format("DD/MM/YYYY")}
                                </td>
                                <td className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide font-normal text-white leading-[1.42857143] table-cell cursor-pointer">
                                  {review?.views ?? 0}
                                </td>
                                <td
                                  className="max-w-[400px] truncate align-middle h-12 px-[15px] text-sm tracking-wide  font-normal text-[#606060] hover:text-white leading-[1.42857143] table-cell cursor-pointer"
                                  onClick={() => router.push("/admin/viewflick/" + review.id)}>
                                  <div className="flex items-center gap-x-3">
                                    <div className="h-10 w-10 flex items-center justify-center relative">
                                      <Hexagon size={40} weight="light" />
                                      <Eye size={20} className="absolute" weight="bold" />
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                      {flicks?.length === 0 && (
                        <div className="h-64 bg-[#131418] border-t-[1px] border-[#ececec1b] flex items-center justify-center w-full text-white text-sm tracking-wide">
                          No Flicks Available
                        </div>
                      )}
                    </div>
                  </div>
                </div>}
            </div>}
          {previewOn === true && <Modalcard close={previewClose}>
            <div className="h-[80vh] aspect-[9/16] bg-background rounded-xl">
              <video
                src={previewLink}
                onClick={(e) =>
                  e.currentTarget.paused ? e.target.play() : e.target.pause()
                }
                className="cursor-pointer h-full w-full rounded-xl"
              ></video>
            </div>
          </Modalcard>}
          {tab === 1 && (
            newReviews?.length + flicks?.length >= 3 ? <>
              {((exist === false) || (editable === true)) && <div className="p-5">
                <div className="">
                  <p className="font-work text-xl text-center">Select reviews / flicks to create Shoppable Videos</p>
                </div>
                <div className="flex flex-wrap bg-[#131418] mt-5  p-5 gap-5 rounded-xl">
                  {newReviews.map((webr, index) => (
                    <div
                      key={"webreview" + index}
                      className={`w-[170px] aspect-[9/16] object-cover rounded-lg relative cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.some(item => item.id === webr.id) ? "border border-[#63D876]" : ""}`}>
                      <div className="absolute bottom-2 right-2 z-30">
                        <button onClick={() => previewOpen(webr)} className="h-[20px] w-fit flex items-center leading-none gap-[3px] text-[8px] bg-[#2E354B] rounded-full p-[2px] pr-2"> <PlayCircle size={16} color="white" /> Preview</button>
                      </div>
                      {selected.some(item => item.id === webr.id) && <div className="absolute top-2 left-2 z-20">
                        <div className=""> <CheckCircle size={20} color="#63D876" weight="bold" /> </div>
                      </div>}
                      <img
                        src={webr.thumbnail ?? "https://i.pravatar.cc/150"}
                        onClick={() => handleSelectClick(webr)}
                        alt="nopreview_available"
                        className={`${selected.some(item => item.id === webr.id) ? "saturate-0" : "saturate-100"} w-full h-full rounded-lg`}
                      />
                    </div>
                  ))}
                  {flicks?.map((webr, index) => (
                    <div
                      key={"webreview" + index}
                      className={`w-[170px] aspect-[9/16] object-cover rounded-lg relative cursor-pointer hover:scale-105 transform duration-100 transition-all ${selected.some(item => item.id === webr.id) ? "border border-[#63D876]" : ""}`}>
                      <div className="absolute bottom-2 right-2 z-30">
                        <button onClick={() => previewOpen(webr)} className="h-[20px] w-fit flex items-center leading-none gap-[3px] text-[8px] bg-[#2E354B] rounded-full p-[2px] pr-2"> <PlayCircle size={16} color="white" /> Preview</button>
                      </div>
                      {selected.some(item => item.id === webr.id) && <div className="absolute top-2 left-2 z-20">
                        <div className=""> <CheckCircle size={20} color="#63D876" weight="bold" /> </div>
                      </div>}
                      {webr?.thumbnail
                        ?
                        <img
                          src={webr?.thumbnail ?? "https://i.pravatar.cc/150"}
                          onClick={() => handleSelectClick(webr)}
                          alt="nopreview_available"
                          className={`${selected.some(item => item.id === webr.id) ? "saturate-0" : "saturate-100"} w-full h-full rounded-lg object-cover`}
                        />
                        :
                        <video src={webr.reviewUrl} onClick={() => handleSelectClick(webr)} className={`${selected.some(item => item.id === webr.id) ? "saturate-0" : "saturate-100"} w-full h-full rounded-lg`}></video>
                      }
                    </div>
                  ))}
                </div>
                <div className="mt-4">
                  <button className="bg-[#131418] py-2 px-5 rounded-md" onClick={() => createShoppableVideos()}>{exist === true ? "Update" : "Create"} Shoppable Videos</button>
                </div>
              </div>
              }
              {((exist === true) && (editable === false)) && <div className="m-5">
                <div className="flex justify-end">
                  <button className="bg-[#131418] py-2 px-5 rounded-md" onClick={() => seteditable(true)}>Edit Reviews</button>
                </div>
                <div className="mx-auto w-fit py-2 px-3 gap-3 flex items-center justify-center bg-[#131418] rounded-md">
                  <button onClick={() => setdeviceType("mobile")} className={` flex items-center justify-center h-9 w-9 rounded-md ${deviceType === "mobile" ? "bg-white text-black" : "bg-transparent text-white"}`}>
                    <DeviceMobileCamera size={24} color="currentcolor" />
                  </button>
                  <button onClick={() => setdeviceType("desktop")} className={` flex items-center justify-center h-9 w-9 rounded-md ${deviceType === "desktop" ? "bg-white text-black" : "bg-transparent text-white"}`}>
                    <Desktop size={24} color="currentcolor" />
                  </button>
                </div>
                <div className={`mt-5 bg-[#131418] ${deviceType === "mobile" ? "w-fit" : "w-full"}  border-[4px] border-white rounded-md p-5 mx-auto`}>
                  <iframe src={`/embed/feed/${id}`} title="feed" className={` ${deviceType === "mobile" ? "h-[628px] w-[330px] rounded-md" : "h-[577px] w-full rounded-md"}`}></iframe>
                </div>
                <div className="">
                  <p className="my-5 font-work text-xl text-center">Integration Code</p>
                  {deviceType === "mobile" && <iframe
                    src={`https://carbon.now.sh/embed?bg=rgba%2874%2C144%2C226%2C1%29&t=night-owl&wt=none&l=htmlmixed&width=1200&ds=false&dsyoff=20px&dsblur=68px&wc=true&wa=false&pv=0px&ph=0px&ln=true&fl=1&fm=Fira+Code&fs=17px&lh=189%25&si=false&es=2x&wm=false&code=%253C%21DOCTYPE%2520html%253E%250A%253Chtml%2520lang%253D%2522en%2522%253E%250A%253Chead%253E%250A%2520%2520%253Cmeta%2520charset%253D%2522UTF-8%2522%2520%252F%253E%250A%2520%2520%253Cmeta%2520http-equiv%253D%2522X-UA-Compatible%2522%2520content%253D%2522IE%253Dedge%2522%2520%252F%253E%250A%2520%2520%253Cmeta%2520name%253D%2522viewport%2522%2520content%253D%2522width%253Ddevice-width%252C%2520initial-scale%253D1.0%2522%2520%252F%253E%250A%2520%2520%253Ctitle%253EShoppable%2520Videos%2520Feed%253C%252Ftitle%253E%250A%253C%252Fhead%253E%250A%253Cbody%253E%250A%2520%2520%253Ciframe%2520%250A%2520%2520%2520%2520src%253D%2522https%253A%252F%252F${window?.location.host}%252Fembed%252Ffeed%252F${id}%2522%2520title%253D%2522feed%2522%2520%250A%2520%2520%2520%2520height%253D%2522628%2522%250A%2520%2520%2520%2520width%253D%2522330%2522%250A%2520%2520%2520%253E%2509%250A%2520%2520%253C%252Fiframe%253E%250A%253C%252Fbody%253E%250A%253C%252Fhtml%253E%250A`}
                    style={{ width: 1200, height: 639, border: 0, margin: "auto" }}
                    title="feedCode"
                    sandbox="allow-scripts allow-same-origin">
                  </iframe>
                  }
                  {deviceType === "desktop" && <iframe
                    src={`https://carbon.now.sh/embed?bg=rgba%2874%2C144%2C226%2C1%29&t=night-owl&wt=none&l=htmlmixed&width=1200&ds=false&dsyoff=20px&dsblur=68px&wc=true&wa=false&pv=0px&ph=0px&ln=true&fl=1&fm=Fira+Code&fs=17px&lh=189%25&si=false&es=2x&wm=false&code=%253C%21DOCTYPE%2520html%253E%250A%253Chtml%2520lang%253D%2522en%2522%253E%250A%253Chead%253E%250A%2520%2520%253Cmeta%2520charset%253D%2522UTF-8%2522%2520%252F%253E%250A%2520%2520%253Cmeta%2520http-equiv%253D%2522X-UA-Compatible%2522%2520content%253D%2522IE%253Dedge%2522%2520%252F%253E%250A%2520%2520%253Cmeta%2520name%253D%2522viewport%2522%2520content%253D%2522width%253Ddevice-width%252C%2520initial-scale%253D1.0%2522%2520%252F%253E%250A%2520%2520%253Ctitle%253EShoppable%2520Videos%2520Feed%253C%252Ftitle%253E%250A%253C%252Fhead%253E%250A%253Cbody%253E%250A%2520%2520%253Ciframe%2520%250A%2520%2520%2520%2520src%253D%2522https%253A%252F%252F${window?.location.host}%252Fembed%252Ffeed%252F${id}%2522%2520title%253D%2522feed%2522%2520%250A%2520%2520%2520%2520height%253D%2522720%2522%250A%2520%2520%2520%2520width%253D%25221280%2522%250A%2520%2520%2520%253E%2509%250A%2520%2520%253C%252Fiframe%253E%250A%253C%252Fbody%253E%250A%253C%252Fhtml%253E%250A`}
                    style={{ width: 1200, height: 639, border: 0, margin: "auto" }}
                    title="feedCode"
                    sandbox="allow-scripts allow-same-origin">
                  </iframe>
                  }
                </div>
                <div className="pb-5"></div>
              </div>}
            </>
              : <>
                <div className="flex-1 flex flex-col items-center justify-center">
                  <p className="text-lg font-work tracking-wide">Not enough reviews/flicks available to create a shoppable videos</p>
                  <p className="text-sm font-work tracking-wide text-[#e7e7e7]">There must be minimum 3 reviews/flicks in a product to create a shoppable videos.</p>
                </div>
              </>
          )}
        </>
      )}
    </div>
  );
}


