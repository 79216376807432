const strings = {
  local: "https://localhost:8080/",
  apiLink: "https://34.131.171.176/",
  inputStyle:
    "w-full h-12 px-3 rounded-sm appearance-none border bg-transparent my-1",
  TestHost: "testchannelrevue",
  adminCollection: "brandAdmin",
  testBrand: "brand",
  storagetype: "localStorage",
  testUser: [
    "sumankuma.1997@gmail.com",
    "myrevueappofficial@gmail.com",
    "myrevueapp@gmail.com",
  ],
};

export default strings;
