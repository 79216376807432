import { doc, getDoc } from 'firebase/firestore';
import React from 'react'
import { useEffect } from 'react';
import { db } from '../firebase';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { ArrowLeft } from '@phosphor-icons/react';
import { useHistory } from 'react-router-dom';

export default function ViewEmaIlCampaign() {
    const [campaign, setCampaign] = useState({});
    const router = useHistory();
    const { id } = useParams();
    useEffect(() => {
        async function fetchData() {
            const campaignDoc = await getDoc(doc(db, "brandEmailCampaigns", id)).then((doc) => ({ id: doc.id, ...doc.data() }))
            console.log(campaignDoc)
            setCampaign(campaignDoc)
        }
        fetchData()
    }, [])
    return (
        <div className="w-full pt-5 relative  min-h-screen">
            <div className="px-5 py-4  border-[#ececec1b] text-white flex items-center text-2xl tracking-wide font-medium">
                <div className="h-12 w-12 flex items-center justify-center cursor-pointer" onClick={() => router.goBack()}>
                    <ArrowLeft size={36} color="#ffffff" />
                </div>
                <p className="pl-5 first-letter:uppercase ">{campaign.campaignName}</p>
            </div>
            <div className="m-10 grid grid-cols-3">
                <div className="w-full flex items-center justify-center">
                    <div className="w-full max-w-[350px] h-52 border border-white rounded-lg flex flex-col items-center justify-center">
                        <p className="text-5xl text-gray-300 font-semibold">{campaign?.emailList?.length}</p>
                        <p className="text-xl font-medium mt-4">Total Sent</p>
                    </div>
                </div>
                <div className="w-full flex items-center justify-center">
                    <div className="w-full max-w-[350px] h-52 border border-white rounded-lg flex flex-col items-center justify-center">
                        <p className="text-5xl text-green-500 font-semibold">{campaign?.count?.delivered ?? 0}</p>
                        <p className="text-xl font-medium mt-4">Total Delivered</p>
                    </div>
                </div>
                <div className="w-full flex items-center justify-center">
                    <div className="w-full max-w-[350px] h-52 border border-white rounded-lg flex flex-col items-center justify-center">
                        <p className="text-5xl text-red-500 font-semibold">{campaign?.count?.bounced ?? 0}</p>
                        <p className="text-xl font-medium mt-4">Total Bounced</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
