import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { useStateValue } from "../../context/StateProvider";
import { db } from "../../firebase";
import { toast } from "react-toastify";
import { Checks } from "phosphor-react";
import Modalcard from "../../components/modalcard";
import FeedVideo from "../../components/feedVideo";
import { useRef } from "react";
import {
  Envelope,
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  MapPin,
  Pencil,
  Phone,
  TiktokLogo,
  YoutubeLogo,
} from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import GallaryCardDesign from "../../components/gallery/gallaryCardDesign";
import { InlineWidget } from "react-calendly";

function Grid() {
  const [gallery, setGallery] = useState(null);
  const [reviews, setReviews] = useState([]);
  const [{ user, brand }, dispatch] = useStateValue();
  const [showModal, setShowModal] = useState(false);

  let mode = "light";
  const router = useHistory();
  const { id } = useParams();
  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const docRef = doc(db, `brandAdmin/${user?.email}/gallery/${id}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setGallery(data);
          let fetchedVideos = [];
          async function getVideos(id, type) {
            let headersList = {
              Accept: "*/*",
              Authorization:
                type === "flicks"
                  ? "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf"
                  : "asdlfha-29347239-ASDHPQPINMV-131241234-adfasdfm-QWOQWIEUALS",
            };

            let reqOptions = {
              url:
                type === "flicks"
                  ? `https://flicksapi-zscu3untuq-el.a.run.app/getFlicksById/${id}`
                  : `https://reviewapi-zscu3untuq-el.a.run.app/getReviewsById/${id}`,
              method: "GET",
              headers: headersList,
            };

            let response = await axios.request(reqOptions);
            response.data.data.type = type;
            return response.data.data;
          }

          async function fetchAllVideos() {
            const promises = data.reviews.map(async (obj) => {
              try {
                const response = await getVideos(obj.id, obj.type);
                fetchedVideos.push(response);
              } catch (error) {
                console.error(
                  `Error fetching video for ID ${obj.id} and type ${obj.type}:`,
                  error
                );
              }
            });

            await Promise.all(promises);
          }
          await fetchAllVideos();
          setReviews(fetchedVideos);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    };

    fetchGallery();
  }, [user?.email, id]);

  const containerRef = useRef(null);
  const scrollUp = () => {
    const container = containerRef.current;
    container.scrollTo({
      top: container.scrollTop - container.clientHeight,
      behavior: "smooth",
    });
  };

  const scrollDown = () => {
    const container = containerRef.current;
    container.scrollTo({
      top: container.scrollTop + container.clientHeight,
      behavior: "smooth",
    });
  };
  async function playVideos(index) {
    setShowModal(true);
  }

  async function copylink(link) {
    navigator.clipboard.writeText(link);
    toast.success("Link has been copied successfully", {
      position: "top-right",
      icon: <Checks size={32} color="green" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
  }
  if (!gallery)
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa fas fa-spinner animate-spin"></i>
      </div>
    );

  return (
    <section className="min-h-screen bg-[#2E3036]">
      {showModal === true && (
        <Modalcard close={setShowModal}>
          <div className="h-[80vh] aspect-[9/16] bg-[#131417] border-[4px] border-black rounded-md">
            <div
              className="w-full h-full rounded overflow-y-auto  snap-y snap-mandatory scrollbar"
              ref={containerRef}
              id="zero-scrollbar"
            >
              <style>
                {`#zero-scrollbar::-webkit-scrollbar {
                        display: none;
                      }
                        #zero-scrollbar {
                        scrollbar-width: none;
                        -ms-overflow-style: none;
                        }
                     `}
              </style>
              {reviews?.map((review, index) => (
                <FeedVideo
                  key={index}
                  data={review}
                  scrollUp={scrollUp}
                  scrollDown={scrollDown}
                  from="gallery"
                />
              ))}
            </div>
          </div>
        </Modalcard>
      )}
      <div className="h-24 w-full bg-gradient-to-b from-transparent to-[#202020] flex items-center justify-between px-5">
        <div className=""></div>
        <div className="flex items-center gap-3">
          {gallery?.published === true ? (
            <p className="text-[#A5A5A5]">
              You can share the link for gallary.
            </p>
          ) : (
            <p className="text-[#A5A5A5]">
              Customize this gallery to get shareable link.
            </p>
          )}{" "}
        </div>
        <div className="flex items-center gap-3">
          {gallery?.published === true ? (
            <button
              className="bg-[#63D876] h-[50px] px-12 text-white rounded-full tracking-wide"
              onClick={() =>
                copylink(
                  `https://myrevue.app/gallery/${brand?.name
                    ?.toLowerCase()
                    ?.replaceAll(" ", "-")}/${gallery?.title
                    ?.toLowerCase()
                    ?.replaceAll(" ", "-")}/${id}`
                )
              }
            >
              Share Link
            </button>
          ) : (
            // <PublishButton
            //   gallery={{
            //     ...gallery,
            //     docId: id,
            //     ref: "brandAdmin/" + user?.email + "/gallery/" + id,
            //   }}
            // />
            <button
              className="bg-[#93A8F4] h-[50px] px-12 text-white rounded-full tracking-wide flex items-center gap-5"
              onClick={() =>
                router.push("/admin/gallery/editGalleryWebsite/" + id)
              }
            >
              <Pencil size={22} color="white" /> Customize
            </button>
          )}
        </div>
      </div>
      {/* <>
        <div className="pt-10 text-white">
          <h1 className="text-[#D9D9D9] text-4xl text-center  tracking-wide">{gallery.title}</h1>
          <p className="text-[#D9D9D9] text-xl text-center tracking-wide mt-2">{gallery.description}</p>
        </div>
        <div className="w-full mt-10 pb-10">
          <div className="flex flex-wrap gap-10 justify-center">
            {reviews.map((rev, index) => (
              <GridCard data={rev} key={index} playVideos={playVideos} index={index} />
            ))}
          </div>
        </div>
      </> */}
      <div className="flex-1 mx-5 mt-10 bg-white overflow-y-auto overflow-x-hidden rounded-lg mb-10">
        {/* navigation  */}
        {(gallery?.customization?.navigation?.logo ||
          gallery?.customization?.navigationPages.length > 0) && (
          <div className="m-3 bg-black bg-opacity-80 h-[70px] rounded-lg flex justify-between items-center px-5 ">
            <div className="h-12">
              <img
                src={gallery?.customization?.navigation?.logo}
                alt=""
                className="h-full w-fit"
              />
            </div>
            <div className="flex items-center gap-5">
              {gallery?.customization?.navigationPages?.map((page, index) => (
                <a
                  href={page?.url}
                  key={index}
                  rel="noreferrer"
                  target="_blank"
                >
                  <button className="text-[#808080] hover:text-white">
                    {page?.name}
                  </button>
                </a>
              ))}
            </div>
            <div className="w-20"></div>
          </div>
        )}
        {/* navigation  */}
        {/* Hero section  */}
        <div
          className={`pt-10 mx-3 draggable rounded-lg ${
            gallery?.customization?.navigation?.headerType === "title" ||
            !gallery?.customization
              ? ""
              : "h-72 flex flex-col justify-center bg-no-repeat bg-cover"
          }`}
          style={{
            backgroundImage: `url('${gallery?.customization?.navigation?.headerImage}')`,
          }}
        >
          <h1
            className={`text-4xl text-center  tracking-wide`}
            style={{
              color:
                gallery?.customization?.navigation.heroTextColor ?? "black",
            }}
          >
            {gallery.title}
          </h1>
          <p
            className={`text-xl text-center tracking-wide mt-2`}
            style={{
              color:
                gallery?.customization?.navigation.heroTextColor ?? "black",
            }}
          >
            {gallery.description}
          </p>
        </div>
        {/* Hero section  */}
        {/* Gallery Cards  */}
        <div>
          <GallaryCardDesign
            design={gallery?.design}
            selected={reviews}
            mode={mode}
          />
        </div>
        {/* Gallery Cards  */}
        {/* Calendly  */}
        {gallery?.customization?.calendlyLink && (
          <>
            <InlineWidget
              url={gallery?.customization?.calendlyLink}
              styles={{
                height: "100%",
                width: "100%",
                marginTop: 20,
                marginBottom: 20,
              }}
            />
          </>
        )}
        {/* Calendly  */}
        {/* youtube  */}
        {gallery?.customization?.youtubeId && (
          <div className="w-full max-w-[900px] mx-auto my-5 draggable">
            <iframe
              className="w-full aspect-video rounded-lg"
              src={`https://www.youtube.com/embed/${gallery?.customization?.youtubeId}`}
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        )}
        {/* youtube  */}
        {/* forms  */}
        {gallery?.customization?.forms && (
          <div>
            <iframe
              src={`${gallery?.customization?.forms}?embedded=true`}
              id="thin-scrollbar"
              title={"googleForms"}
              className="w-full aspect-video my-5"
            ></iframe>
          </div>
        )}
        {/* forms  */}
        {/* maps  */}
        {gallery?.customization?.maps && (
          <div className="grid grid-cols-2 w-full my-5 px-5 draggable">
            <div className="flex items-center ">
              <p className="text-black text-5xl font-semibold">
                Explore Our Location: Find Us Easily on the Map
              </p>
            </div>
            <iframe
              src={gallery?.customization?.maps}
              title="Google Maps"
              className=" w-full max-w-[800px] aspect-video rounded-lg"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        )}
        {/* maps  */}
        {/* footer  */}
        {gallery?.customization?.contactDetails?.phone ||
        gallery?.customization?.contactDetails?.email ||
        gallery?.customization?.contactDetails?.address ? (
          <div
            className={`${
              mode === "light"
                ? "bg-white text-black"
                : "bg-[#383838] text-white"
            } border-t w-full mt-5 py-3 px-5 flex items-start justify-between`}
          >
            <div className="">
              {gallery?.customization?.contactDetails?.phone && (
                <div className="flex items-center gap-2">
                  <Phone size={18} color="currentcolor" />
                  {gallery?.customization?.contactDetails?.phone}
                </div>
              )}
              {gallery?.customization?.contactDetails?.email && (
                <div className="flex items-center gap-2">
                  <Envelope size={18} color="currentcolor" />
                  {gallery?.customization?.contactDetails?.email}
                </div>
              )}
            </div>
            <div className="flex flex-col items-end">
              {gallery?.customization?.contactDetails?.address && (
                <div className="flex items-center gap-2">
                  <MapPin size={18} color="currentcolor" />
                  {gallery?.customization?.contactDetails?.address}
                </div>
              )}
              <div className="flex items-center ">
                {gallery?.customization?.socialLinks?.facebook && (
                  <a
                    href={gallery?.customization?.socialLinks?.facebook}
                    rel="noreferrer"
                    target="_blank"
                    className=""
                  >
                    <button className="ml-2">
                      <FacebookLogo weight="fill" size={26} color="black" />
                    </button>
                  </a>
                )}
                {gallery?.customization?.socialLinks?.instagram && (
                  <a
                    href={gallery?.customization?.socialLinks?.instagram}
                    rel="noreferrer"
                    target="_blank"
                    className=""
                  >
                    <button className="ml-2">
                      <InstagramLogo weight="fill" size={26} color="black" />
                    </button>
                  </a>
                )}
                {gallery?.customization?.socialLinks?.linkedin && (
                  <a
                    href={gallery?.customization?.socialLinks?.linkedin}
                    rel="noreferrer"
                    target="_blank"
                    className=""
                  >
                    <button className="ml-2">
                      <LinkedinLogo weight="fill" size={26} color="black" />
                    </button>
                  </a>
                )}
                {gallery?.customization?.socialLinks?.tiktok && (
                  <a
                    href={gallery?.customization?.socialLinks?.tiktok}
                    rel="noreferrer"
                    target="_blank"
                    className=""
                  >
                    <button className="ml-2">
                      <TiktokLogo weight="fill" size={26} color="black" />
                    </button>
                  </a>
                )}
                {gallery?.customization?.socialLinks?.youtube && (
                  <a
                    href={gallery?.customization?.socialLinks?.youtube}
                    rel="noreferrer"
                    target="_blank"
                    className=""
                  >
                    <button className="ml-2">
                      <YoutubeLogo weight="fill" size={26} color="black" />
                    </button>
                  </a>
                )}
              </div>
            </div>
          </div>
        ) : null}
        {/* footer  */}
      </div>
    </section>
  );
}

export default Grid;
