import {
  doc,
  getDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { nanoid } from "nanoid";
import { sendAmplitudeData } from "../../amplitude";
import { useStateValue } from "../../context/StateProvider";

function ViewFaqsubmission() {
  const [{ user, brand }, dispatch] = useStateValue();
  const [loading, setloading] = useState(true);
  const [subdata, setsubdata] = useState({});
  const { id, subid } = useParams();
  const [formdetails, setformdetails] = useState({})
  const navigate = useHistory();

  useEffect(() => {
    if (!user) {
      navigate.replace("/login")
    }
    getDoc(doc(db, "faqforms/" + id + "/submissions/" + subid)).then((res) => {
      setloading(true);
      setsubdata(res.data());
    }).catch(error => console.log(error));
    getDoc(doc(db, "faqforms", id)).then(res => {
      setformdetails(res.data())
      setloading(false)
    }).catch(error => console.log(error))


    sendAmplitudeData("view submission details", {
      campaign: id,
      submission: subid,
    });
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [showHtml, setShowHtml] = useState(true);
  const [videoProgress, setVideoProgress] = useState(0);



  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowHtml(false);
    }, 3000);
    console.log('showHtml:', showHtml); // add this line
    return () => clearTimeout(timeout);
  }, [showHtml]);



  const handleVideoEnd = () => {
    setCurrentIndex((currentIndex + 1) % (subdata.reviews.length || 1));
    console.log('currentIndex:', currentIndex); // add this line
    setShowHtml(true);
  };



  return (
    <div className=" text-white min-h-screen">
      <div className="text-2xl pb-5 font-medium text-center pt-5">⚡️ We autogenerated a multi-scene video for this response:</div>
      <div className="rounded-md mx-5 p-5 grid grid-cols-5 bg-[#131313]">
        <div className=" h-full w-full flex items-center justify-center flex-col col-span-2">
          <div className="pb-5">
            <iframe title="faq" height={613} width={345} src={`/embed/faqplayer/${id}/${subid}`} className="rounded-xl" />
          </div>
        </div>
        <div className="col-span-3">
          <div className="w-full mb-5 bg-[#202125] h-fit rounded-xl">
            <div className=" w-full px-2 h-10 flex items-center bg-[#343232] pl-3 rounded-t-xl">
              User info:
            </div>
            <div className="p-4">
              <p className="tracking-wide">Name: {subdata?.user_info?.name}</p>
              <p className="tracking-wide">Id: {subdata?.user_info?.id}</p>
              <p className="tracking-wide">
                Email/Phone: {subdata?.user_info?.email ?? subdata?.user_info?.phone}
              </p>
              <p className="tracking-wide">tag: {subdata?.user_info?.tag}</p>
            </div>
          </div>
          <div className="p-5 gap-5 flex flex-col items-center">
            <p className="font-work text-center">Integration Code</p>
            <iframe
              title="faqplayer"
              src={`https://carbon.now.sh/embed?bg=rgba%2874%2C144%2C226%2C1%29&t=night-owl&wt=none&l=htmlmixed&width=826.75&ds=false&dsyoff=20px&dsblur=68px&wc=true&wa=false&pv=0px&ph=0px&ln=true&fl=1&fm=Fira+Code&fs=13.5px&lh=170%25&si=false&es=2x&wm=false&code=%253C%21DOCTYPE%2520html%253E%250A%253Chtml%2520lang%253D%2522en%2522%253E%250A%253Chead%253E%250A%2520%2520%253Cmeta%2520charset%253D%2522UTF-8%2522%2520%252F%253E%250A%2520%2520%253Cmeta%2520http-equiv%253D%2522X-UA-Compatible%2522%2520content%253D%2522IE%253Dedge%2522%2520%252F%253E%250A%2520%2520%253Cmeta%2520name%253D%2522viewport%2522%2520content%253D%2522width%253Ddevice-width%252C%2520initial-scale%253D1.0%2522%2520%252F%253E%250A%2520%2520%253Ctitle%253EFAQ%2520player%253C%252Ftitle%253E%250A%253C%252Fhead%253E%250A%253Cbody%253E%250A%2520%2520%253Ciframe%2520%250A%2520%2520%2520%2520src%253D%2522https%253A%252F%252F${window?.location.host}%252Fembed%252Ffaqplayer%252F${id}%252F${subid}%2522%250A%2520%2520%2520%2520title%253D%2522faqplayer%2522%250A%2520%2520%2520%2520height%253D%2522532%2522%250A%2520%2520%2520%2520width%253D%2522300%2522%250A%2520%2520%2520style%253D%2522%2520border-radius%253A%25200.75rem%2522%250A%2520%2520%253E%2509%250A%2520%2520%253C%252Fiframe%253E%250A%253C%252Fbody%253E%250A%253C%252Fhtml%253E`}
              style={{ width: "100%", height: 510, border: 0, overflow: "auto", }}
              sandbox="allow-scripts allow-same-origin"
            >
            </iframe>
          </div>
        </div>
      </div>
      <div className="w-full p-5 ">
        <div className="w-full flex flex-wrap justify-center gap-10">
          {subdata?.reviews?.map((data, index) => (
            <div className="w-64 bg-[#202125] rounded-xl flex flex-col justify-between" key={index}>
              <p className="px-3 py-3">{data?.question}</p>
              <div className="">
                <video src={data?.reviewUrl} className="w-64 aspect-[9/16] rounded-b-xl" controls controlsList="nodownload noplaybackrate" disablePictureInPicture></video>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ViewFaqsubmission;
