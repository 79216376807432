import React from "react";
import {
  SquaresFour,
  ChatTeardropDots,
  SignOut,
  Gear,
  Barcode,
  Gift,
  MonitorPlay,
  DownloadSimple,
  CreditCard,
} from "phosphor-react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router";
import { auth } from "../firebase";
import Home from "../pages/home";
import Settings from "../pages/settings";
import Reviews from "../pages/reviews";
import Experiences from "../pages/experiences";
import Products from "../pages/products";
import Assets from "../pages/assets/assets";
import Viewproduct from "../pages/view/viewproduct";
import Viewreview from "../pages/view/viewreview";
import Createcoupon from "../pages/create/createcoupon";
import Createproduct from "../pages/create/createproduct";
import { useEffect } from "react";
import Search from "../pages/search";
import Support from "../pages/support";
import Viewcoupon from "../pages/view/viewcoupon";
import { useStateValue } from "../context/StateProvider";
import Billings from "../pages/billings";
import FourZeroFour from "../pages/404";
import Downloads from "../pages/downloads";
import axios from "axios";
import Analytics from "../pages/analytics";
import Requestcampaign from "../pages/requestcampaign";
import {
  ArrowLineUpRight,
  FilmReel,
  IdentificationBadge,
  Images,
  Plugs,
  VideoCamera,
} from "@phosphor-icons/react";
import InHouseRequest from "../pages/inhouserequest";
import Apidocs from "../pages/apidocs";
import ImportVideos from "../pages/importVideos";
import Flicks from "../pages/flicks";
import ViewFlick from "../pages/view/viewflick";
import Import from "../pages/import/import";
import Emails from "../pages/emails";
import CreateEmail from "../pages/createEmail";
import Badges from "../pages/badges";
import CreateEmailTemplate from "../pages/createEmailTemplate";
import EditEmailTemplate from "../pages/EditEmailTemplate";
import ViewEmaIlCampaign from "../pages/viewEmaIlCampaign";
import RequestcampaignDraft from "../pages/requestcampaignDraft";
import SlideShow from "../pages/gallary/SlideShow";
import Carousals from "../pages/gallary/Carousals";
import Lists from "../pages/gallary/Lists";
import Grid from "../pages/gallary/Grids";
import Gallery from "../pages/Gallery";
import CreateGallery from "../pages/CreateGallary";
import EditGalleryWebsite from "../pages/EditGalleryWebsite";

const Layout = () => {
  const [{ brand, user }, dispatch] = useStateValue();
  const location = useLocation();
  const router = useHistory();
  const { path } = useRouteMatch();
  const nav_active =
    "font-light flex items-center gap-x-3 text-lg h-10 my-2 px-5 text-white cursor-pointer bg-gradient-to-r from-one via-[#91A8ED50] to-transparent";
  const nav_passive =
    "flex items-center gap-x-3 text-lg h-10 my-2 px-5 text-[#55565a] cursor-pointer hover:bg-gradient-to-r from-one via-[#91A8ED50] to-transparent hover:text-white";

  useEffect(() => {
    // if (!user) {
    //   router.replace("/login");
    // }
    if (!brand || !user) {
      return router.replace("/login");
    }
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/6374d342b0d6371309cf5560/1gi05ifoh";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();

    var title = `${brand?.name} - MyRevue`;
    var metaTitle = `${brand?.name} - MyRevue`;
    var metaDescription = ``;
    var metaKeyword = ``;

    var ogUrl = `https://brand.myrevue.app/collector`;
    var ogtype = `website`;
    var ogtitle = `${brand?.name} - MyRevue`;
    var ogdescription = ``;
    var ogimage = ``;

    var twittercard = `summary_large_image`;
    var twitterdomain = `brand.myrevue.app`;
    var twitterurl = `https://brand.myrevue.app/collector`;
    var twittertitle = `${brand?.name} - MyRevue`;
    var twitterdescription = ``;
    var twitterimage = ``;

    document.title = title;
    document.getElementsByTagName("meta")["title"].content = metaTitle;
    document.getElementsByTagName("meta")["description"].content =
      metaDescription;
    document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

    document.querySelector(`meta[property="og:url"]`).content = ogUrl;
    document.querySelector(`meta[property="og:type"]`).content = ogtype;
    document.querySelector(`meta[property="og:title"]`).content = ogtitle;
    document.querySelector(`meta[property="og:description"]`).content =
      ogdescription;
    document.querySelector(`meta[property="og:image"]`).content = ogimage;

    document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
    document.querySelector(`meta[property="twitter:domain"]`).content =
      twitterdomain;
    document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
    document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
    document.querySelector(`meta[name="twitter:description"]`).content =
      twitterdescription;
    document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
  }, [location.pathname]);

  useEffect(() => {
    async function getBrandProducts() {
      let headersList = {
        Accept: "*/*",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      };

      let reqOptions = {
        url:
          "https://adminapi-zscu3untuq-el.a.run.app/getBrandProducts/" +
          brand?.id,
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      sessionStorage.setItem("products", JSON.stringify(response.data));
      dispatch({ type: "SET_PRODUCTS", products: response.data });
    }
    async function getBrandReviews() {
      let headersList = {
        Accept: "*/*",
        Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
      };

      let reqOptions = {
        url:
          "https://adminapi-zscu3untuq-el.a.run.app/getBrandReviews/" +
          brand?.id,
        method: "GET",
        headers: headersList,
      };

      let response = await axios.request(reqOptions);
      sessionStorage.setItem("reviews", JSON.stringify(response.data));
      dispatch({ type: "SET_REVIEW", reviews: response.data });
    }
    async function getBrandFlicks() {
      const headersList = {
        Accept: "*/*",
        Authorization: "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g",
      };

      let page = 0;
      let allFlicks = [];

      while (true) {
        const reqOptions = {
          url: `https://brandapi-zscu3untuq-el.a.run.app/getBrandFlicks/${brand?.id}/${page}`,
          method: "GET",
          headers: headersList,
        };

        const response = await axios.request(reqOptions);
        const flicks = response.data.data;

        if (flicks.length === 0) {
          break; // Exit the loop if no more data is returned
        }

        allFlicks = allFlicks.concat(flicks);
        page++;
      }

      // Store the final result in session storage and dispatch
      sessionStorage.setItem("flicks", JSON.stringify(allFlicks));
      dispatch({ type: "SET_FLICKS", flicks: allFlicks });
    }
    getBrandProducts();
    getBrandReviews();
    getBrandFlicks();
  }, [user]);

  async function signout() {
    try {
      await auth.signOut();
      dispatch({ type: "DELETE_USER" });
      dispatch({ type: "SET_NOTIFICATIONS", notifications: [] });
      sessionStorage.clear();
      router.replace("/");
      return;
    } catch (error) {
      // Handle sign out error
      console.error(error);
    }
    return;
  }
  const handleMaximize = () => {
    if (window.Tawk_API) {
      window.Tawk_API.maximize();
    }
  };
  useEffect(() => {
    if (window.Tawk_API) {
      window.Tawk_API.visitor = {
        email: user?.email,
        brandName: user?.brandName,
      };
    }
  }, []);
  return (
    <div className="flex h-screen w-full bg-[#2D3037] bg-cover bg-[url('https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fpattern.png?alt=media&token=aa300ab1-5153-469c-a710-b2b9192b98c2')] ">
      <div className="h-full thin-scrollbar bg-bg_highlight w-[240px] pt-8 flex flex-col justify-between overflow-y-auto">
        <div className="">
          <div className="flex items-center justify-center">
            <img
              crossOrigin="anonymous"
              src={require("../assets/logo/MyRevue Logo elite new.svg").default}
              alt=""
              className="h-10 transform scale-150"
            />
          </div>
          <div className="pt-8 pb-3">
            <div
              onClick={() => router.push("/admin/home")}
              className={
                location.pathname.includes("/home") ? nav_active : nav_passive
              }
            >
              <i className="fas fa-home"></i>
              <p className="">Home</p>
            </div>
            <div
              onClick={() => router.push("/admin/dashboard")}
              className={
                location.pathname === "/admin/dashboard"
                  ? nav_active
                  : nav_passive
              }
            >
              <SquaresFour size={26} />
              <p className="">Dashboard</p>
            </div>
            <div
              onClick={() => router.push("/engagement")}
              className={
                location.pathname.includes("/assets") ? nav_active : nav_passive
              }
            >
              <Gift size={26} />
              <p className="">Engagement</p>
            </div>
            <div
              onClick={() => router.push("/admin/badges")}
              className={
                location.pathname.includes("/badges") ? nav_active : nav_passive
              }
            >
              <IdentificationBadge size={26} />
              <p className="">Badges</p>
            </div>
            <div
              onClick={() => router.push("/admin/reviews")}
              className={
                location.pathname.includes("/reviews")
                  ? nav_active
                  : nav_passive
              }
            >
              <MonitorPlay size={26} />
              <p className="">Reviews</p>
            </div>
            <div
              onClick={() => router.push("/admin/flicks")}
              className={
                location.pathname.includes("/flicks") ? nav_active : nav_passive
              }
            >
              <FilmReel size={26} />
              <p className="">Flicks</p>
            </div>
            <div
              onClick={() => router.push("/admin/gallery")}
              className={
                location.pathname.includes("/gallery")
                  ? nav_active
                  : nav_passive
              }
            >
              <Images size={26} />
              <p className="">Gallery</p>
            </div>
            <div
              onClick={() => router.push("/admin/products")}
              className={
                location.pathname.includes("/products")
                  ? nav_active
                  : nav_passive
              }
            >
              <Barcode size={26} />
              <p className="">Products</p>
            </div>
            {/* <div
              onClick={() => router.push("/studio")}
              className={
                location.pathname.includes("/studio") ? nav_active : nav_passive
              }
            >
              <Gift size={26} />
              <p className="">Studio</p>
            </div> */}
            <div
              onClick={() => router.push("/admin/downloads")}
              className={
                location.pathname.includes("/downloads")
                  ? nav_active
                  : nav_passive
              }
            >
              <DownloadSimple size={26} />
              <p className="">Downloads</p>
            </div>

            <div
              onClick={() => router.push("/admin/apidocs")}
              className={
                location.pathname.includes("/apidocs")
                  ? nav_active
                  : nav_passive
              }
              a
            >
              <Plugs size={26} />
              <p className="">Integration</p>
            </div>
            <div
              onClick={() => router.push("/admin/emails")}
              className={
                location.pathname.includes("/emails") ? nav_active : nav_passive
              }
            >
              <ArrowLineUpRight size={26} />
              <p className="">Emails</p>
            </div>
            <div
              onClick={() => router.push("/admin/inhouserequest")}
              className={
                location.pathname.includes("/inhouserequest")
                  ? nav_active
                  : nav_passive
              }
            >
              <VideoCamera size={26} />
              <p className="">Get A Video</p>
            </div>

            {/* <div
              onClick={() => router.push("/admin/importVideos")}
              className={
                location.pathname.includes("/importVideos")
                  ? nav_active
                  : nav_passive
              }
            >
              <ArrowLineUpRight size={26} />
              <p className="">Import Videos</p>
            </div> */}
            <div
              onClick={() => router.push("/admin/import")}
              className={
                location.pathname.includes("/import") ? nav_active : nav_passive
              }
            >
              <ArrowLineUpRight size={26} />
              <p className="">Import</p>
            </div>
          </div>

          <div className="">
            <div
              onClick={() => handleMaximize()}
              className={
                location.pathname.includes("/support")
                  ? nav_active
                  : nav_passive
              }
            >
              <ChatTeardropDots size={26} />
              <p className="">Support</p>
            </div>
            <div
              onClick={() => router.push("/admin/settings")}
              className={
                location.pathname.includes("/settings")
                  ? nav_active
                  : nav_passive
              }
            >
              <Gear size={26} />
              <p className="">Settings</p>
            </div>
            <div
              onClick={() => router.push("/admin/billing")}
              className={
                location.pathname.includes("/billing")
                  ? nav_active
                  : nav_passive
              }
            >
              <CreditCard size={26} />
              <p className="">Billing</p>
            </div>
          </div>
        </div>
        <div className="">
          {(!user?.subscription ||
            user?.subscription?.subtype === "Freemium") && (
            <div className="m-5 p-3 rounded-md bg-[#1b1d29] h-40 relative overflow-hidden cursor-pointer">
              <div className="">
                <p className="text-white text-2xl font-JetBrains font-extralight">
                  Upgrade to pro
                </p>
                <img
                  src={require("../assets/icons/longarrow.svg").default}
                  alt=""
                  crossOrigin="anonymous"
                  className="mt-3"
                />
              </div>
              <img
                src={require("../assets/images/astronaut.png")}
                alt=""
                crossOrigin="anonymous"
                className="transform scale-75 -mt-20 ml-[3rem]"
              />
            </div>
          )}
          <div className={nav_passive} onClick={() => signout()}>
            <SignOut size={26} />
            <p className="">Sign Out</p>
          </div>
        </div>
      </div>
      <div className="w-full flex-1 h-screen overflow-y-auto ">
        <Switch>
          <Route path={`${path}/billing`} exact component={Billings} />
          <Route path={`${path}/settings`} exact component={Settings} />
          <Route path={`${path}/emails`} exact component={Emails} />
          <Route path={`${path}/emails/create`} exact component={CreateEmail} />
          <Route
            path={`${path}/emails/createemailtemplate`}
            exact
            component={CreateEmailTemplate}
          />
          <Route
            path={`${path}/emails/editemailtemplate/:id`}
            exact
            component={EditEmailTemplate}
          />
          <Route
            path={`${path}/emails/viewemailcampaign/:id`}
            exact
            component={ViewEmaIlCampaign}
          />
          <Route path={`${path}/reviews`} exact component={Reviews} />
          <Route path={`${path}/experiences`} exact component={Experiences} />
          <Route path={`${path}/flicks`} exact component={Flicks} />
          <Route path={`${path}/products`} exact component={Products} />
          <Route path={`${path}/assets`} exact component={Assets} />
          <Route path={`${path}/downloads`} exact component={Downloads} />
          <Route path={`${path}/home`} exact component={Analytics} />
          <Route path={`${path}/import`} exact component={Import} />
          <Route path={`${path}/createcoupon`} exact component={Createcoupon} />
          <Route
            path={`${path}/createproduct`}
            exact
            component={Createproduct}
          />
          <Route path={`${path}/apidocs`} exact component={Apidocs} />
          <Route path={`${path}/support`} exact component={Support} />
          <Route path={`${path}/search/:id`} exact component={Search} />
          <Route path={`${path}/viewreview/:id`} exact component={Viewreview} />
          <Route path={`${path}/viewflick/:id`} exact component={ViewFlick} />
          <Route
            path={`${path}/viewproduct/:id`}
            exact
            component={Viewproduct}
          />
          <Route path={`${path}/viewcoupon/:id`} exact component={Viewcoupon} />
          <Route
            path={`${path}/inhouserequest`}
            exact
            component={InHouseRequest}
          />
          <Route
            path={`${path}/requestcampaign`}
            exact
            component={Requestcampaign}
          />
          <Route
            path={`${path}/requestcampaign/draft/:draftId`}
            exact
            component={RequestcampaignDraft}
          />
          <Route path={`${path}/importVideos`} exact component={ImportVideos} />
          <Route path={`${path}/badges`} exact component={Badges} />
          <Route path={`${path}/dashboard`} exact component={Home} />
          {/* <Route path={`${path}/requestcampaign/:id"`} component={ViewRequestCampaign}/> */}
          <Route path={path} exact component={Analytics} />
          <Route
            path={path + "/viewgallery/SlideShow/:id"}
            exact
            component={SlideShow}
          />
          <Route path={path + "/viewgallery/Grid/:id"} exact component={Grid} />
          <Route
            path={path + "/viewgallery/List/:id"}
            exact
            component={Lists}
          />
          <Route
            path={path + "/viewgallery/Carousals/:id"}
            exact
            component={Carousals}
          />
          <Route
            path={path + "/gallery/create"}
            exact
            component={CreateGallery}
          />
          <Route
            path={path + "/gallery/editGalleryWebsite/:id"}
            exact
            component={EditGalleryWebsite}
          />
          <Route path={path + "/gallery"} exact component={Gallery} />
          <Route path={`${path}/*`} component={FourZeroFour} />
        </Switch>
      </div>
    </div>
  );
};

export default Layout;
