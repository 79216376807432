import { doc, getDoc, updateDoc } from "firebase/firestore";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router";
import ExportVideo from "../components/ExportVideo";
import LogoSelectorForVideo from "../components/LogoSelectorForVideo";
import UserDetailsVideo from "../components/UserDetailsVideo";
import VideoResize from "../components/VideoResize";
import VideoTrimSelector from "../components/VideoTrimSelector";
import { db } from "../firebase";
import logo from "../assets/images/review.png";
import AWN from "awesome-notifications";
import axios from "axios";
import strings from "../assets/Strings";
import { useRef } from "react";

function VideoEditorPage() {
  const [mode, setMode] = useState(null);
  const [review, setReview] = useState({});
  const [loading, setLoading] = useState(true);
  const [timer, setTimer] = useState({ start: 0, end: 0 });
  const [actualsize, setActualSize] = useState(null);
  const [brandLogo, setbrandLogo] = useState(null);
  const [title, setTitle] = useState(null);
  const [format, setFormat] = useState(null);
  const { subid, id } = useParams();
  const [working, setWorking] = useState(false);
  let videoPlayer = useRef();
  let containerRef = useRef();

  const formRef = "forms/" + id + "/submissions/" + subid;

  useEffect(() => {
    getDoc(doc(db, formRef)).then((res) => {
      setReview(res.data());
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (videoPlayer && videoPlayer.current) {
      const currentVideo = videoPlayer.current;
      currentVideo.onloadedmetadata = () => {
        setTimer({ start: 0, end: currentVideo.duration });
        setActualSize({
          height: currentVideo.clientHeight,
          width: currentVideo.clientWidth,
        });
      };
    }
  }, [review]);

  useEffect(() => {
    if (containerRef && containerRef.current && containerRef.current.style) {
      if (format === "square") {
        let height = actualsize.height;
        let width = actualsize.width;

        if (width > height) {
          width = height;
        } else {
          height = width;
        }
        width = Math.round(width);
        height = Math.round(height);
        setActualSize({ ...actualsize, ch: height, cw: width });

        return;
      }
      if (format === "mobile") {
        let height = actualsize.height;
        let width = actualsize.width;

        if (width > height) {
          width = (9 / 16) * height;
        } else {
          height = (16 / 9) * width;
        }
        width = Math.round(width);
        height = Math.round(height);
        setActualSize({ ...actualsize, ch: height, cw: width });

        return;
      }
      if (format === "tablet") {
        var height = actualsize.height;
        var width = actualsize.width;

        if (width > height) {
          width = (10 / 16) * height;
        } else {
          height = (16 / 10) * width;
        }
        width = Math.round(width);
        height = Math.round(height);
        setActualSize({ ...actualsize, ch: height, cw: width });

        return;
      }
      if (format === "laptop") {
        let height = actualsize.height;
        let width = actualsize.width;

        if (width > height) {
          width = (16 / 9) * height;
        } else {
          height = (9 / 16) * width;
        }
        width = Math.round(width);
        height = Math.round(height);
        setActualSize({ ...actualsize, ch: height, cw: width });

        return;
      }
      if (format == null) {
        let height = actualsize.height;
        let width = actualsize.width;
        setActualSize({ ...actualsize, ch: height, cw: width });
      }
    }
  }, [format]);

  const active = "text-white rounded-md w-full p-2 shadow shadow-white";
  const passive = "text-gray-400 rounded-md w-full p-2 ";

  const listentrimChanges = (start, end) => {
    videoPlayer.current.currentTime = start;
    setTimer({ start: start, end: end });
  };

  const handlePause = (e) => {
    const currentTime = Math.floor(e.currentTarget.currentTime);
    if (currentTime === timer.end) {
      e.currentTarget.pause();
    }
  };

  const handlePlay = () => {
    videoPlayer.current.currentTime = timer.start;
    videoPlayer.current.play();
  };

  const handleChangeFormat = (e) => {
    setFormat(e);
    setMode(null);
  };

  const handleChangeLogo = (e) => {
    setbrandLogo(e);
    setMode(null);
  };
  const handleTitle = (e) => {
    setTitle(e);
    setMode(null);
  };

  const handleFinalChanges = async () => {
    if (working) return;
    const data = {
      url: null,
      brandLogo: null,
      titleImage: null,
      start: 0,
      end: 10,
      cropType: null,
      crop: false,
      trim: false,
    };
    data.url = review.reviewUrl;
    data.trim = false;
    data.crop = false;
    data.start = 0;
    data.end = 0;

    if (timer.start !== 0 || timer.end !== videoPlayer.current.duration) {
      data.trim = true;
      data.start = timer.start;
      data.end = timer.end;
    }
    if (format) {
      data.crop = true;
      data.cropType = format;
    }
    if (brandLogo) data.brandLogo = brandLogo;
    if (title) data.titleImage = title;

    if (!data.brandLogo || !data.titleImage) {
      return new AWN().warning("Brand Logo or title Image is missing");
    }
    setWorking(true);
    let notifier = new AWN();
    notifier.async(
      axios.post(strings.apiLink + "processReview", data, {
        headers: { "Content-Type": "application/json" },
      }),
      (response) => {
        setWorking(false);
        console.log(response)
        if (response.status === 200) {
          if (response.data.success) {
            updateDoc(doc(db, "forms/" + id + "/submissions/" + subid), {
              processed: true,
              reviewUrl: response.data.url,
            });
            setReview({
              ...review,
              processed: true,
              reviewUrl: response.data.url,
            });

            resetAll();
            return new AWN().success(
              "review processed and it's ready to export."
            );
          } else {
            return new AWN().warning(response.data.message);
          }
        } else {
          return new AWN().warning(response.message);
        }
      }
    );
  };

  const resetAll = () => {
    setTimer({ start: 0, end: videoPlayer?.current?.duration });
    setFormat(null);
    setbrandLogo(null);
    setTitle(null);
    videoPlayer.current.load();
  };

  if (loading) {
    return (
      <div className="h-96 flex items-center justify-center">
        <i className="fas fa fa-spinner animate-spin"></i>
      </div>
    );
  } else
    return (
      <div className="h-[90vh] flex flex-col">
        <div className="flex-1 flex">
          <div className="w-36 px-3 py-2  shadow-xl shadow-black">
            {format ? (
              <button
                className="text-sm bg-red-500 h-12 text-center w-full my-1 rounded hideOut-normal"
                onClick={() => setFormat(null)}
              >
                Remove Format
              </button>
            ) : null}
            {brandLogo ? (
              <button
                className="bg-red-500 text-sm h-12 text-center w-full my-1 rounded hideOut-normal"
                onClick={() => setbrandLogo(null)}
              >
                Remove Brand Logo
              </button>
            ) : null}
            {title ? (
              <button
                className="bg-red-500 text-sm  h-12 text-center w-full my-1 rounded hideOut-normal"
                onClick={() => setTitle(null)}
              >
                Remove Title
              </button>
            ) : null}

            {timer?.start !== 0 ||
              timer.end !== videoPlayer?.current?.duration ? (
              <button
                className="bg-red-500 text-sm h-12 text-center w-full my-1 rounded hideOut-normal"
                onClick={() =>
                  setTimer({ start: 0, end: videoPlayer?.current?.duration })
                }
              >
                Remove Trim
              </button>
            ) : null}
            {title && brandLogo ? (
              <button
                className="bg-green-500 h-12 text-center w-full my-1 rounded-sm hideOut-normal"
                onClick={handleFinalChanges}
              >
                Apply Changes
              </button>
            ) : null}
          </div>
          <div className="flex-1  flex flex-col items-center justify-center">
            {/* video playing area */}

            <div
              className=" relative shadow-xl shadow-black overflow-hidden block rounded-md transform ease-in-out duration-100"
              ref={containerRef}
              style={{
                width: `${(actualsize?.cw ?? actualsize?.width) + "px"}`,
                height: `${(actualsize?.ch ?? actualsize?.height) + "px"}`,
              }}
            >
              <video
                ref={videoPlayer}
                className={
                  format != null ? `h-[70vh] absolute center` : "h-[70vh]"
                }
                controls
                muted
                src={review?.reviewUrl}
                onTimeUpdate={handlePause}
                onPlay={handlePlay}
                onContextMenu={(e) => e.preventDefault()}
                disablePictureInPicture
                controlsList="nodownload noplaybackrate"
              ></video>
              {brandLogo ? (
                <img
                  src={brandLogo}
                  alt="asdf"
                  className="h-8 hideOut-normal z-10 absolute top-2 left-2"
                />
              ) : null}
              {title ? (
                <img
                  src={title}
                  alt="asdf"
                  className="h-[60px] hideOut-normal z-10 absolute bottom-20 left-4"
                />
              ) : null}
              {review?.processed ? null : (
                <img
                  src={logo}
                  alt="asdf"
                  className="h-8 hideOut-normal z-10 absolute right-2 bottom-2"
                />
              )}
            </div>

            <div className="w-2/3">
              {/* working area */}

              {mode === "trim" ? (
                <VideoTrimSelector
                  duration={videoPlayer.current.duration}
                  handleChange={listentrimChanges}
                />
              ) : null}
              {mode === "format" ? (
                <VideoResize formatType={handleChangeFormat} />
              ) : null}
              {mode === "changeLogo" ? (
                <LogoSelectorForVideo
                  logo={review.LogoLink}
                  setLogo={handleChangeLogo}
                />
              ) : null}
              {mode === "export" ? (
                <ExportVideo
                  src={review.reviewUrl}
                  formId={id}
                  submissionId={subid}
                />
              ) : null}
              {mode === "user" ? (
                <UserDetailsVideo addLines={handleTitle} reviewId={review.id} />
              ) : null}
            </div>
          </div>
          {/* buttons area */}
          <div className="p-2 w-14 rounded-md shadow-xl shadow-black items-center justify-center gap-2 bg-background flex flex-col ">
            <button
              className={mode === "crop" ? active : passive}
              onClick={() => {
                if (!review.processed) setMode("format");
              }}
            >
              <i className="fa-solid py-2 fa-crop-simple fa-lg"></i>
            </button>
            <button
              className={mode === "trim" ? active : passive}
              onClick={() => {
                if (!review.processed) setMode("trim");
              }}
            >
              <i className="fa-solid py-2 fa-hand-scissors fa-lg"></i>
            </button>
            <button
              className={mode === "changeLogo" ? active : passive}
              onClick={() => {
                if (!review.processed) setMode("changeLogo");
              }}
            >
              <i className="fa-solid py-2 fa-image"></i>
            </button>
            <button
              className={mode === "user" ? active : passive}
              onClick={() => {
                if (!review.processed) setMode("user");
              }}
            >
              <i className="fa-sharp fa-solid fa-lg py-2 fa-user"></i>
            </button>

            <button
              className={mode === "export" ? active : passive}
              onClick={() => {
                if (review.processed) setMode("export");
                else return new AWN().warning("review is not ready to export");
              }}
            >
              <i className="fa-solid fa-file-arrow-down py-2 fa-lg"></i>
            </button>
            {mode !== null ? (
              <button onClick={() => setMode(null)} className="hideOut-normal">
                <i className="fas fa-cancel py-2 text-red-500 fa-lg m-4"></i>
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
}

export default VideoEditorPage;
