import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  Timestamp,
  collection,
  addDoc,
} from "firebase/firestore";
import { Chat, ChatDots, Flag, Share, Code } from "phosphor-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Loadingdot from "../../components/loadingdot";
import Sharecard from "../../components/sharecard";
import { db } from "../../firebase";
import { sendAmplitudeData } from "../../amplitude";
import { useStateValue } from "../../context/StateProvider";
import axios from "axios";
import { ArrowLeft } from "@phosphor-icons/react";
import { useHistory } from 'react-router-dom'

export default function ViewFlick() {
  const { id } = useParams();
  const [{ brand }, dispatch] = useStateValue();
  const [loading, setloading] = useState(false);
  const [review, setreview] = useState({});
  const [share, setshare] = useState(false);
  const router = useHistory();

  useEffect(() => {
    fetchreview();
    sendAmplitudeData("viewing review, view review page", {
      reviewid: id,
    });
  }, []);

  async function fetchreview() {
    setloading(true);
    let headersList = {
      "Accept": "*/*",
      "Authorization": "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25"
    }

    let reqOptions = {
      url: "https://adminapi-zscu3untuq-el.a.run.app/getFlick/" + id,
      method: "GET",
      headers: headersList,
    }

    let response = await axios.request(reqOptions);
    let data = response.data;
    setreview(data);
    setloading(false);
  }


  return (
    <div className="flex flex-col h-full">
      <div className="px-5 py-4  border-[#ececec1b] text-white flex items-center text-2xl tracking-wide font-medium">
        <div className="h-12 w-12 flex items-center justify-center cursor-pointer" onClick={() => router.goBack()}>
          <ArrowLeft size={36} color="#ffffff" />
        </div>
        <p className="pl-5 first-letter:uppercase ">{review?.name}</p>
      </div>
      {loading ? (
        <Loadingdot />
      ) : (
        <div className="p-5">
          {share === true ? (
            <Sharecard
              setshare={setshare}
              url={"https://myrevue.app/viewReview/" + review?.type + "/" + review?.id}
              text={`Hey! Check out this review on MyRevue app. Get the best review before buying anything on MyRevue! download the app `}
            />
          ) : null}
          <div className="w-full p-4 bg-bg_highlight rounded-lg flex items-start gap-x-5">
            <div className="w-80 aspect-[9/16] bg-background rounded-lg">
              <video
                src={review?.reviewUrl}
                onClick={(e) =>
                  e.currentTarget.paused ? e.target.play() : e.target.pause()
                }
                className="cursor-pointer rounded-lg w-80 aspect-[9/16] object-cover object-right-top"
              >

              </video>
            </div>
            <div className="bg-bg_highlight w-full flex-1">
              <div className="">
                <div className="text-white mb-3">
                  <p className="font-work">ID</p>
                  <input
                    type="text"
                    defaultValue={review?.id}
                    readOnly
                    className="appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-1 rounded-md text-sm font-work tracking-wide"
                  />
                </div>
                <div className="text-white mb-3">
                  <p className="font-work">Name</p>
                  <input
                    type="text"
                    defaultValue={review?.name}
                    readOnly
                    className="appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-1 rounded-md text-sm font-work tracking-wide"
                  />
                </div>
                <div className="text-white mb-3">
                  <p className="font-work">Product Id</p>
                  <input
                    type="text"
                    defaultValue={review?.product?.id}
                    readOnly
                    className="appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-1 rounded-md text-sm font-work tracking-wide"
                  />
                </div>
                <div className="text-white mb-3">
                  <p className="font-work">Product Name</p>
                  <input
                    type="text"
                    defaultValue={review?.product?.name}
                    readOnly
                    className="appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-1 rounded-md text-sm font-work tracking-wide"
                  />
                </div>
                <div className="text-white mb-3">
                  <p className="font-work">Flick Tag</p>
                  <input
                    type="text"
                    defaultValue={review?.flickTag}
                    readOnly
                    className="appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-1 rounded-md text-sm font-work tracking-wide"
                  />
                </div>
                <div className="text-white mb-3">
                  <p className="font-work">Sub Flick Tag</p>
                  <input
                    type="text"
                    defaultValue={review?.subFlickTag}
                    readOnly
                    className="appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-1 rounded-md text-sm font-work tracking-wide"
                  />
                </div>
                <div className="text-white mb-3">
                  <p className="font-work">Uploaded By</p>
                  <input
                    type="text"
                    defaultValue={review?.user?.name}
                    readOnly
                    className="appearance-none outline-none h-10 w-full bg-background px-3 truncate mt-1 rounded-md text-sm font-work tracking-wide"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
