import { doc, getDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { db } from "../firebase";
import { Link } from "react-router-dom";
import { ShareFat } from "@phosphor-icons/react";

function Cards({ data, setvideoUrl, optionName, setcurrentIndex, currentIndex, setpreviousIndex, isFullScreen }) {
    const screenHeight = window.innerHeight;
    const aspectRatioWidth = (9 / 16) * screenHeight;
    const finalWidth = 0.92 * aspectRatioWidth;
    async function changeVideo() {
        setvideoUrl(data?.reviewUrl);
        setpreviousIndex(currentIndex)
        setcurrentIndex(data?.id)
    }
    return (
        <>
            <style jsx>{`
        .button {
          ${isFullScreen
                    ? `max-width: ${finalWidth}px;`
                    : 'max-width: 92%;'
                }
        }
      `}</style>
            <button onClick={() => changeVideo()} className={`button cursor-pointer w-full h-[50px] bg-[#00000099] rounded-[44px] min-w-[200px] mx-auto text-white flex items-center pl-[16px] pr-[12px] gap-3 border-white border border-opacity-30`}>
                <div className="h-5 w-5 text-xs rounded-full bg-white text-black flex items-center justify-center font-medium">
                    {optionName}
                </div>
                <div className="flex-1 text-left leading-[18px] font-light tracking-wide">
                    <p className="">{data?.question}</p>
                </div>
            </button>
        </>
    )
}



function Faqplayer() {
    const [data, setdata] = useState({});
    const [questions, setquestions] = useState([]);
    const [previousIndex, setpreviousIndex] = useState(null);
    const [currentIndex, setcurrentIndex] = useState(0);
    const [videoUrl, setvideoUrl] = useState('');
    let videoplayer = useRef(null);
    let player = useRef(null);
    const abcarr = ['A', 'B', 'C', 'D', 'E', 'F'];
    const [muted, setMuted] = useState(false);
    const [progress, setProgress] = useState(0);
    const [isFullScreen, setisFullScreen] = useState(false);
    const [indexChanges, setindexChanges] = useState([]);
    const [playbackRate, setplaybackRate] = useState(0);
    const { campaignid, id } = useParams();
    const [form, setform] = useState({});
    useEffect(() => {

        async function getFaqData() {
            await getDoc(doc(db, "faqforms/" + campaignid + "/submissions/" + id)).then((result) => {
                setdata(result?.data());
                setquestions(result?.data()?.reviews?.slice(1))
                setvideoUrl(result?.data()?.reviews?.[0]?.reviewUrl);
            }).catch(err => {
                console.log(err)
            })
        }
        async function getFaqform() {
            await getDoc(doc(db, "faqforms/" + campaignid)).then((result) => {
                setform(result?.data());
            }).catch(err => {
                console.log(err)
            })
        }
        Promise.all([getFaqform(), getFaqData()])
    }, []);

    useEffect(() => {
        if (indexChanges.length > 0 && currentIndex === 0) {
            setindexChanges([]);
            return
        }
        setindexChanges(prevIndexs => [...prevIndexs, currentIndex])
        // Perform any additional actions when currentIndex changes
    }, [currentIndex]);

    useEffect(() => {
        // Access the video element through the ref
        const video = videoplayer.current;

        // Update the playbackRate
        if (playbackRate === 0) {
            video.playbackRate = 1; // Set the desired playbackRate value
        }
        else if (playbackRate === 1) {
            video.playbackRate = 1.25; // Set the desired playbackRate value
        }
        else if (playbackRate === 2) {
            video.playbackRate = 1.5; // Set the desired playbackRate value
        }
        else if (playbackRate === 3) {
            video.playbackRate = 2; // Set the desired playbackRate value
        }
    }, [playbackRate]);

    const handleTimeUpdate = () => {
        const video = videoplayer.current;
        const currentTime = video.currentTime;
        const duration = video.duration;
        const percentage = (currentTime / duration) * 100;
        setProgress(percentage);
    };

    async function videoTimeToStart() {
        const video = videoplayer.current;
        video.currentTime = 0;
    }

    async function back() {
        if (previousIndex === null) {
            return
        }
        const current = currentIndex;
        const previous = previousIndex;
        setpreviousIndex(current)
        setcurrentIndex(previous)
        setvideoUrl(data?.reviews?.[previous]?.reviewUrl);
        videoTimeToStart()
    }

    const handleFullscreen = () => {
        const element = player.current;

        if (element.requestFullscreen) {
            element.requestFullscreen();
            setisFullScreen(true);
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
            setisFullScreen(true);
        } else if (element.webkitRequestFullscreen) {
            element.webkitRequestFullscreen();
            setisFullScreen(true);
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
            setisFullScreen(true);
        }
    };

    const exitFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
            setisFullScreen(false);
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
            setisFullScreen(false);
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
            setisFullScreen(false);
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
            setisFullScreen(false);
        }
    };

    return (
        <div className={`${isFullScreen ? "h-full aspect-[9/16]" : "h-[100vh] aspect-[9/16]  border-4 border-blue-700 "}   rounded-2xl relative  `} ref={player} >
            <div className="absolute top-0 w-full rounded-2xl flex gap-5 items-center justify-between z-30 min-h-[50px] px-3 bg-gradient-to-b from-[#12121250]  to-transparent">
                <div className="space-x-3 flex items-center">
                    {previousIndex === null ? null : <button className="" onClick={() => back()}>
                        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer"><path d="M8.2 1.6 1.8 8l6.4 6.4M2.6 8H17" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                    </button>}
                    <button className="" onClick={() => videoTimeToStart()}>
                        <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="cursor-pointer"><path d="M8.665 16.795a1.07 1.07 0 0 1-.793.193 8.174 8.174 0 0 1-3.362-1.33 8.148 8.148 0 0 1-1.384-1.194A8.064 8.064 0 0 1 2.044 13a8.147 8.147 0 0 1-.816-6.196 8.115 8.115 0 0 1 1.411-2.86 8.124 8.124 0 0 1 2.396-2.098 8.147 8.147 0 0 1 6.196-.816 8.116 8.116 0 0 1 2.86 1.411 8.124 8.124 0 0 1 2.099 2.396 8.145 8.145 0 0 1 .522 7.08l-1.751-1.471c.15-.68.228-1.62.135-2.32a6.072 6.072 0 0 0-2.304-3.993 6.036 6.036 0 0 0-2.114-1.039 6.015 6.015 0 0 0-4.572.603c-.707.41-1.302.94-1.77 1.546a6.036 6.036 0 0 0-1.04 2.114A6.016 6.016 0 0 0 4.7 13.015c.306.332.65.626 1.017.877a6.007 6.007 0 0 0 2.493.982 1.069 1.069 0 0 1 .889 1.217c-.047.29-.205.538-.426.696l-.007.008Z" fill="#fff"></path><path fillRule="evenodd" clipRule="evenodd" d="M12.707 8.6c.194 1.926.383 3.847.572 5.771 1.925-.193 3.846-.386 5.771-.575-2.114-1.732-4.228-3.464-6.346-5.195h.003Z" fill="#fff"></path></svg>
                    </button>
                </div>
                <div className="w-full flex-1 relative">
                    <div className={`h-1 bg-white w-[${progress}%] absolute z-40 rounded-full`}></div>
                    <div className="h-1 bg-white w-full bg-opacity-20 rounded-full"></div>
                </div>
                <div className="flex gap-x-3 items-center">
                    <button className="" onClick={() => { videoplayer.current.muted = !videoplayer.current.muted; setMuted(videoplayer.current.muted) }}>
                        {muted === true ?
                            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.6875 7L17.3438 5.375C17.5312 5.1875 17.5312 4.84375 17.3438 4.65625C17.1562 4.46875 16.8125 4.46875 16.625 4.65625L15 6.3125L13.3438 4.65625C13.1562 4.46875 12.8125 4.46875 12.625 4.65625C12.4375 4.84375 12.4375 5.1875 12.625 5.375L14.2812 7L12.625 8.65625C12.4375 8.84375 12.4375 9.1875 12.625 9.375C12.8125 9.5625 13.1562 9.5625 13.3438 9.375L15 7.71875L16.625 9.375C16.8125 9.5625 17.1562 9.5625 17.3438 9.375C17.5312 9.1875 17.5312 8.84375 17.3438 8.65625L15.6875 7ZM9 0C8.75 0 8.5 0.0625 8.3125 0.25L4.09375 4H1.5C0.65625 4 0 4.6875 0 5.5V8.46875C0 9.28125 0.65625 9.9375 1.5 9.9375H4.09375L8.3125 13.6875C8.5 13.8438 8.75 13.9375 9 13.9375C9.65625 13.9375 9.96875 13.375 9.96875 12.9688V1C9.96875 0.59375 9.65625 0 9 0ZM8.96875 13L4.46875 9H1.5C1.21875 9 1 8.75 1 8.5V5.5C1 5.21875 1.21875 5 1.5 5H4.46875L8.9375 1.03125L8.96875 13Z" fill="white"></path></svg>
                            :
                            <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.40625 0.09375C9.25 0.03125 9.125 0 9 0C8.75 0 8.5 0.09375 8.3125 0.28125L4.09375 4.03125H1.5C0.65625 4.03125 0 4.6875 0 5.53125V8.53125C0 9.34375 0.65625 10 1.5 10H4.09375L8.3125 13.75C8.5 13.9375 8.75 14 9 14C9.125 14 9.25 14 9.40625 13.9375C9.75 13.7812 10 13.4062 10 13V1.03125C10 0.625 9.75 0.25 9.40625 0.09375ZM8.96875 13L4.78125 9.28125L4.46875 9H1.5C1.21875 9 1 8.78125 1 8.53125V5.53125C1 5.25 1.21875 5.03125 1.5 5.03125H4.46875L4.78125 4.75L8.96875 1.03125C8.96875 1.03125 8.96875 1.03125 9 1.03125L8.96875 13ZM14.3438 2.09375C14.125 1.9375 13.8125 2 13.6562 2.25C13.5 2.46875 13.5625 2.78125 13.7812 2.9375C15.1562 3.84375 16 5.375 16 7C16 8.65625 15.1562 10.1875 13.7812 11.0938C13.5625 11.25 13.5 11.5625 13.6562 11.7812C13.75 11.9375 13.9062 12 14.0625 12C14.1562 12 14.25 12 14.3438 11.9375C16 10.8125 17 8.96875 17 7C17 5.03125 16 3.21875 14.3438 2.09375ZM12.6875 4.59375C12.4688 4.4375 12.1562 4.5 12 4.75C11.8438 4.96875 11.9062 5.28125 12.125 5.4375C12.6562 5.78125 13 6.375 13 7C13 7.65625 12.6562 8.25 12.125 8.59375C11.9062 8.75 11.8438 9.0625 12 9.28125C12.0938 9.4375 12.25 9.5 12.4062 9.5C12.5 9.5 12.5938 9.5 12.6875 9.4375C13.5 8.875 14 7.96875 14 7C14 6.0625 13.5 5.15625 12.6875 4.59375Z" fill="white"></path></svg>
                        }
                    </button>
                    <button className="" onClick={() => isFullScreen === true ? exitFullscreen() : handleFullscreen()}>
                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M4.5 0H0.5C0.21875 0 0 0.25 0 0.5V4.5C0 4.78125 0.21875 5 0.5 5C0.75 5 1 4.78125 1 4.5V1H4.5C4.75 1 5 0.78125 5 0.5C5 0.25 4.75 0 4.5 0ZM4.5 13H1V9.5C1 9.25 0.75 9 0.5 9C0.21875 9 0 9.25 0 9.5V13.5C0 13.7812 0.21875 14 0.5 14H4.5C4.75 14 5 13.7812 5 13.5C5 13.25 4.75 13 4.5 13ZM13.5 9C13.2188 9 13 9.25 13 9.5V13H9.5C9.21875 13 9 13.25 9 13.5C9 13.7812 9.21875 14 9.5 14H13.5C13.75 14 14 13.7812 14 13.5V9.5C14 9.25 13.75 9 13.5 9ZM13.5 0H9.5C9.21875 0 9 0.25 9 0.5C9 0.78125 9.21875 1 9.5 1H13V4.5C13 4.78125 13.2188 5 13.5 5C13.75 5 14 4.78125 14 4.5V0.5C14 0.25 13.75 0 13.5 0Z" fill="white"></path></svg>
                    </button>
                    <button className="bg-white bg-opacity-50 py-[6px] px-3 rounded-lg text-white" onClick={() => setplaybackRate(rate => rate === 3 ? 0 : rate + 1)}>
                        {playbackRate === 0 && "1X"}
                        {playbackRate === 1 && "1.25X"}
                        {playbackRate === 2 && "1.5X"}
                        {playbackRate === 3 && "2X"}
                    </button>
                </div>
            </div>
            <div className="absolute bottom-0 w-full rounded-2xl flex flex-col justify-end items-center z-20">
                <div className="space-y-3 flex flex-col items-center w-full pb-5">
                    {currentIndex !== 0 && <>
                        {!form?.button ? null : <a href={form?.button?.url} target="_blank" rel="noreferrer" className="w-full">
                            <button className={`button cursor-pointer w-full h-[50px] bg-[#00000099] rounded-[44px] min-w-[200px] mx-auto text-white flex items-center pl-[16px] pr-[12px] gap-3 border-white border border-opacity-30`}>

                                <div className="flex-1 text-left leading-[18px] font-light tracking-wide">
                                    <p className="">{form?.button?.text}</p>
                                </div>
                                <div className="h-9 w-9 flex items-center justify-center font-medium">
                                    <ShareFat size={24} color="white" />
                                </div>
                            </button>
                        </a>}
                    </>}
                    {questions?.filter(val => val.id !== currentIndex)?.map((q, index) => (
                        <Cards key={index} data={q} optionName={abcarr[index]} isFullScreen={isFullScreen} setvideoUrl={setvideoUrl} setcurrentIndex={setcurrentIndex} currentIndex={currentIndex} setpreviousIndex={setpreviousIndex} />
                    ))}
                </div>
                <div className="bg-gradient-to-b from-transparent via-[#12121250]  to-[#121212] h-10 w-full flex items-center justify-center gap-3 -mt-3 font-work rounded-b-lg">
                    Powered by
                    <a href="https://brand.myrevue.app" target='_blank' className="" rel="noreferrer">
                        <img loading="lazy" src={require("../assets/logo/MyRevue Logo elite new.svg").default} alt="logo" className="h-[42px] w-auto" />
                    </a>
                </div>
            </div>
            <video
                ref={videoplayer}
                src={videoUrl}
                autoPlay
                poster={data?.thumbnailurl}
                controls={false}
                onTimeUpdate={handleTimeUpdate}
                className={isFullScreen ? "h-full aspect-[9/16] mx-auto" : "h-full w-full rounded-xl object-cover"}
                onClick={(e) => e.currentTarget.paused ? e.target.play() : e.target.pause()}
            />
        </div>
    );
}


export default Faqplayer