import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { Check, ShieldSlash, Star, VideoCameraSlash } from 'phosphor-react';
import { AmazonLogo, Article, Chat, Checks, DotsThreeVertical, FacebookLogo, InstagramLogo, Note, Package, SnapchatLogo, ThumbsDown, ThumbsUp, Truck, TwitterLogo, UserPlus, VideoCamera, X, YoutubeLogo } from '@phosphor-icons/react';
import Modalcard from '../../components/modalcard';
import axios from 'axios';
import { useStateValue } from '../../context/StateProvider';
import { toast } from 'react-toastify';

export default function ViewRequestCampaign() {
    const [{ brand, user }, dispatch] = useStateValue();
    const { id } = useParams();
    const [campaign, setcampaign] = useState(JSON.parse(sessionStorage.getItem("inhouserequest")));
    const [loading, setloading] = useState(false);
    const [tab, settab] = useState(1);
    const [notes, setnotes] = useState(JSON.parse(sessionStorage.getItem("inhouserequest"))?.notes ?? []);
    const [campaignApplication, setcampaignApplication] = useState([]);
    const [campaignContent, setcampaignContent] = useState([]);
    const [applicationModal, setapplicationModal] = useState(false);
    const [applicationDetails, setapplicationDetails] = useState({});
    const [modalTab, setmodalTab] = useState(0);
    const [selectedContent, setselectedContent] = useState({});
    const [changesRef, setchangesRef] = useState({
        comments: "",
        changes: ""
    })
    const [productDeliveryTab, setproductDeliveryTab] = useState(0);
    const [reviewModal, setReviewModal] = useState(false);
    const [shipmentModal, setshipmentModal] = useState(false);
    const [shippmentDetails, setshippmentDetails] = useState({
        withTracking: null,
        trackingNumber: "",
        shippingCompany: "",
        orderId: "",
        shippingDate: ""
    });
    const [selectedCount, setselectedCount] = useState(0);

    const videoTypes = ['', 'Video Ad', 'Unboxing Video', 'Tutorial Video', 'Get The Look Video', 'Haul Video', '360 Showcase Video', 'Testimonial Video', 'Store Visit Video'];
    const durationsec = [0, 15, 30, 60];
    const videoAd = ['', 'Facebook', 'Instagram', 'Twitter', 'Amazon', 'YouTube', 'Snapchat']
    const videoTypesDescription = ['', 'Best for ads on Facebook, Instagram, YouTube, etc.', 'Opening of new product and showcasing its content and features.', 'Record how to use or step by step instructions video.', 'Showcases how to recreate a specific style or look.', 'Showcasing and reviewing a collection of products they have purchased.', 'Show your product from every angle.', 'Highlights your product benefits.', 'Record a walkin video in brand store.'];
    const demoVideos = [{
        basic: "",
        premium: ""
    },
    {
        basic: "",
        premium: ""
    },
    {
        basic: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finhousedemovideos%2Funboxing%2FVideo%202.mp4?alt=media&token=c251602a-522f-4aea-b5b5-1535b2ad19a9",
        premium: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finhousedemovideos%2Funboxing%2FUnboxing%20Video%201.mp4?alt=media&token=3b5fb0b3-56be-4b92-ad5e-3e65999f1ed6"
    },
    {
        basic: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finhousedemovideos%2Ftutorial%2Fcostmetic%20makeup1.mp4?alt=media&token=2cc77824-7fe2-407b-a4c5-81c5f129a1b9",
        premium: "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Finhousedemovideos%2Ftutorial%2FTutorial.mp4?alt=media&token=c1043b77-5b31-483e-b7f7-1b0e40a00c55"
    },
    {
        basic: "",
        premium: ""
    },
    {
        basic: "",
        premium: ""
    },
    {
        basic: "",
        premium: ""
    }, {
        basic: "",
        premium: ""
    }, {
        basic: "",
        premium: ""
    }]

    const router = useHistory();
    useEffect(() => {
        async function getCampaignApplications() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
            }

            let reqOptions = {
                url: `https://brandadminapi-zscu3untuq-el.a.run.app/getAllInterestedUser/${campaign?._id}`,
                method: "GET",
                headers: headersList,
            }
            let count = 0;
            const response = await axios.request(reqOptions).then((res) => res?.data);
            response?.data.forEach(async (application, index) => {
                if (application?._id === "644b319587819b8047c616bc") {
                    application.user.dob = "2007-08-04"
                }

                async function checkGender() {
                    if (campaign?.creatorCriteria?.gender === "any") {
                        return true
                    }
                    else if (campaign?.creatorCriteria?.gender === "male") {
                        if (application?.user?.gender === "male") {
                            return true
                        }
                        else {
                            return false
                        }
                    }
                    else if (campaign?.creatorCriteria?.gender === "female") {
                        if (application?.user?.gender === "female") {
                            return true
                        }
                        else {
                            return false
                        }
                    }
                }



                async function checkLanguages(array1, array2) {
                    for (let i = 0; i < array2.length; i++) {
                        if (array1.includes(array2[i])) {
                            return true;
                        }
                    }
                    return false;
                }



                const checkAge = await checkAgeGroup(campaign?.creatorCriteria?.age, application?.user?.dob)
                const checkUserGender = await checkGender();
                const checkUserLanguage = await checkLanguages(campaign?.creatorCriteria?.language, application?.user?.languages);

                const isEligible = checkAge && checkUserGender && checkUserLanguage;
                application.isEligible = isEligible;
            });
            response?.data?.forEach((application, index) => {
                if (application?.status !== 'pending') {
                    count += 1;
                }
            })
            setcampaignApplication(response?.data ?? []);
            setselectedContent(0);
            console.log(response?.data)
        }
        async function getCampaignContent() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
            }

            let reqOptions = {
                url: `https://brandadminapi-zscu3untuq-el.a.run.app/getHouseContent/${campaign?._id}`,
                method: "GET",
                headers: headersList,
            }

            const response = await axios.request(reqOptions).then((res) => res?.data?.data);
            setcampaignContent(response ?? []);
        }

        getCampaignApplications();
        getCampaignContent();
        // getcampaign();
    }, [id]);

    async function checkAgeGroup(ageGroup, dob) {
        const dobYear = new Date(dob).getFullYear();
        const now = new Date();
        const age = now.getFullYear() - dobYear;
        for (let i = 0; i < ageGroup.length; i++) {
            const ageRangeSplit = ageGroup[i].split("-");
            if (ageRangeSplit.length === 1) {
                if (age === parseInt(ageRangeSplit[0])) {
                    return true;
                }
            } else if (ageRangeSplit.length === 2) {
                if (parseInt(ageRangeSplit[0]) <= age && age <= parseInt(ageRangeSplit[1])) {
                    return true;
                }
            }
        }
        return false;
    }
    async function openApplicationAnswer(application) {
        setapplicationModal(true);
        setapplicationDetails({ flicks: application?.flicksMade, reviews: application?.reviewsMade, user: application?.user });
    }

    async function selectUserApplication(application) {
        if (application?.isEligible === false || !application?.isEligible) {
            toast.warning(`This application doesn't meet the eligibilty criteria.`, {
                position: "top-right",
                // theme: "dark",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return;
        }
        if (application?.status === 'Ready') {
            toast.warning(`User is already selected`, {
                position: "top-right",
                // theme: "dark",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return;
        }
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
        };

        let bodyContent = {
            userId: application?.user?.id,
            status: 'Ready'
        }

        let reqOptions = {
            url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${id}`,
            method: "PUT",
            headers: headersList,
            data: bodyContent
        };

        await axios.request(reqOptions).then(response => {
            if (response?.data?.success === true) {
                setcampaignApplication(prevData => {
                    return prevData.map(obj => {
                        if (obj._id === application?._id) {
                            return { ...obj, status: "Ready" };
                        } else {
                            return obj;
                        }
                    });
                });
            }
        });
    }

    async function changeShipmentStatus(application, status) {
        if (status === "Ready") {
            if (shippmentDetails?.withTracking === null) {
                toast.warning(`Please select using shipment number or without shipment number`, {
                    position: "top-right",
                    // theme: "dark",
                    icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                    bodyClassName: "text-sm tracking-wide font-work"
                });
                return;
            }

            if (shippmentDetails?.withTracking === true) {
                if (shippmentDetails?.orderId.length < 1 || shippmentDetails?.shippingCompany.length < 3 || shippmentDetails?.shippingDate.length < 5 || shippmentDetails?.trackingNumber.length < 5) {
                    toast.warning(`Please fill all details`, {
                        position: "top-right",
                        // theme: "dark",
                        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                        bodyClassName: "text-sm tracking-wide font-work"
                    });
                    return;
                }
            }
        }

        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
        };

        if (status === "shipped") {
            let bodyContent = {
                userId: application?.user?.id,
                status: "Ready",
                shipmentDetails: {
                    shipmentStatus: status,
                    shipmentTrackingNumber: shippmentDetails?.withTracking === false ? "N/A" : shippmentDetails?.trackingNumber,
                    shipmentCompany: shippmentDetails?.withTracking === false ? "N/A" : shippmentDetails?.shippingCompany,
                    orderId: shippmentDetails?.withTracking === false ? "N/A" : shippmentDetails?.orderId,
                    shipmentDate: shippmentDetails?.withTracking === false ? "N/A" : shippmentDetails?.shippingDate,
                }
            }
            let reqOptions = {
                url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${id}`,
                method: "PUT",
                headers: headersList,
                data: bodyContent
            };
            await axios.request(reqOptions).then(response => {
                if (response?.data?.success === true) {
                    setcampaignApplication(prevData => {
                        return prevData.map(obj => {
                            if (obj._id === application?._id) {
                                return { ...obj, shipmentDetails: bodyContent.shipmentDetails };
                            } else {
                                return obj;
                            }
                        });
                    });
                }
            });
        }
        if (status === "delivered") {
            let bodyContent = {
                userId: application?.user?.id,
                status: "Ready",
                "shipmentDetails.shipmentStatus": status,
            }
            let reqOptions = {
                url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${id}`,
                method: "PUT",
                headers: headersList,
                data: bodyContent
            };
            await axios.request(reqOptions).then(response => {
                if (response?.data?.success === true) {
                    setcampaignApplication(prevData => {
                        return prevData.map(obj => {
                            if (obj._id === application?._id) {
                                return {
                                    ...obj, shipmentDetails: {
                                        ...obj.shipmentDetails, shipmentStatus: status
                                    },
                                };
                            } else {
                                return obj;
                            }
                        });
                    });
                }
            });
        }

        setshippmentDetails({
            withTracking: null,
            trackingNumber: "",
            shippingCompany: "",
            orderId: "",
            shippingDate: ""
        });
        setshipmentModal(false);
        setapplicationDetails({})
    }
    async function approveContent(application) {
        const aprovedvideos = campaignContent.filter(val => val?.status === "Completed")
        if (application?.status === 'Completed') {
            toast.warning('Content is already approved', {
                position: "top-right",
                // theme: "dark",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });

            return
        }
        if (aprovedvideos.length === campaign?.videos) {
            toast.warning('You have already approved maximum no of videos', {
                position: "top-right",
                // theme: "dark",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return
        }
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
        };

        let bodyContent = {
            userId: application?.user?.id,
            status: 'Completed'
        }

        let reqOptions = {
            url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${id}`,
            method: "PUT",
            headers: headersList,
            data: bodyContent
        };
        await axios.request(reqOptions).then(response => {
            if (response?.data?.success === true) {
                setcampaignContent(prevData => {
                    return prevData.map(obj => {
                        if (obj._id === application?._id) {
                            return { ...obj, status: "Completed" };
                        } else {
                            return obj;
                        }
                    });
                });
            }
        });
    }
    async function rejectContent(application) {
        if (application?.status === 'Rejected') {
            toast.warning('Content is already rejected', {
                position: "top-right",
                // theme: "dark",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return
        }

        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
        };

        let bodyContent = {
            userId: application?.user?.id,
            status: 'Rejected'
        }

        let reqOptions = {
            url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${id}`,
            method: "PUT",
            headers: headersList,
            data: bodyContent
        };
        await axios.request(reqOptions).then(response => {
            if (response?.data?.success === true) {
                setcampaignContent(prevData => {
                    return prevData.map(obj => {
                        if (obj._id === application?._id) {
                            return { ...obj, status: "Rejected" };
                        } else {
                            return obj;
                        }
                    });
                });
            }
        });
    }
    async function requestEditContent() {
        if (selectedContent?.status === "Completed" || selectedContent?.status === "Rejected") {
            toast.warning(`Cannot request for edit as content is already ${selectedContent?.status === "Completed" ? "Approved" : "Rejected"}`, {
                position: "top-right",
                // theme: "dark",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return
        }

        if (changesRef?.changes.length < 5) {
            toast.warning(`Please select a changes title`, {
                position: "top-right",
                // theme: "dark",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return;
        }
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
        };

        let bodyContent = {
            userId: selectedContent?.user?.id,
            changesTitle: changesRef?.changes,
            changesComment: changesRef?.comments ?? null,
            status: "Ready"
        }

        let reqOptions = {
            url: `https://brandadminapi-zscu3untuq-el.a.run.app/updateUserStatus/${id}`,
            method: "PUT",
            headers: headersList,
            data: bodyContent
        };
        await axios.request(reqOptions).then(response => {
            if (response?.data?.success === true) {
                setcampaignContent(prevData => {
                    return prevData.map(obj => {
                        if (obj._id === selectedContent?._id) {
                            return { ...obj, changesTitle: changesRef?.changes, changesComment: changesRef?.comments ?? null };
                        } else {
                            return obj;
                        }
                    });
                });
                setapplicationModal(false);
                setapplicationDetails({});
            }
        });
    }
    async function formateShippingDate(value) {
        const date = new Date(value);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = String(date.getFullYear());
        const formattedDate = `${day}/${month}/${year}`;
        setshippmentDetails({
            ...shippmentDetails,
            shippingDate: formattedDate
        })
    }



    if (loading === true) {
        return (<div className="flex items-center justify-center h-[90vh]">
            <p className="text-2xl font-work">Loading ...</p>
        </div>)
    }

    return (
        <div className='w-full'>
            <>
                {reviewModal === true ?
                    <Modalcard close={setReviewModal}>
                        <video
                            onKeyDown={(event) => {
                                if (event.button === 2) {
                                    event.preventDefault();
                                }
                            }}
                            onContextMenu={e => e.preventDefault()}
                            src={applicationDetails?.reviewUrl ?? applicationDetails?.url}
                            onClick={(event) => event.currentTarget?.paused ? event.currentTarget.play() : event.currentTarget.pause()}
                            className='aspect-[9/16] rounded-md mx-auto w-[320px] z-10 appearance-none outline-none'
                            controls={false}
                            controlsList='nodownload noplaybackrate'
                            disablePictureInPicture>
                        </video>
                    </Modalcard>
                    : null}
                {shipmentModal === true ?
                    <Modalcard close={setshipmentModal}>
                        <div className="w-[380px] p-5 bg-[#2e313e]  overflow-y-auto rounded-md" id="thin-scrollbar">
                            <div className="flex items-center justify-between">
                                <p className="font-work tracking-wide text-lg">Provide shipping details</p>
                                <button className="" onClick={() => setshipmentModal(false)}>
                                    <X size={28} color='white' />
                                </button>
                            </div>
                            <div className="py-5">
                                <div className="pb-3">
                                    <p className="font-medium tracking-wide">
                                        Shipping to {selectedContent?.user?.name}
                                    </p>
                                </div>
                                <div className="my-3">
                                    <label className='flex items-center gap-5 font-work'>
                                        <input type="radio" name="answer" value="yes" onChange={(event) => event.currentTarget.checked === true ? setshippmentDetails({ ...shippmentDetails, withTracking: true }) : setshippmentDetails({ ...shippmentDetails, withTracking: false })} />
                                        Using a shipment number
                                    </label>
                                </div>
                                <div className="my-3">
                                    <label className='flex items-center gap-5 font-work'>
                                        <input type="radio" name="answer" value="no" onChange={(event) => event.currentTarget.checked === true ? setshippmentDetails({ ...shippmentDetails, withTracking: false }) : setshippmentDetails({ ...shippmentDetails, withTracking: true })} />
                                        Without a shipment number
                                    </label>
                                </div>
                            </div>
                            <div className="">
                                <p className="font-work tracking-wide text-sm text-[#adadad]">/* Details not required in case of product type is Digital */</p>
                                <div className="my-3">
                                    <p className="font-work tracking-wide">Tracking number</p>
                                    <input type="text" onChange={(event) => setshippmentDetails({ ...shippmentDetails, trackingNumber: event.target.value })} className=" mt-2 w-full h-10 text-sm rounded-md border-[2px] appearance-none outline-none px-3 bg-transparent border-[#9d9d9d]" />
                                </div>
                                <div className="my-3">
                                    <p className="font-work tracking-wide">Shipping Company</p>
                                    <input type="text" onChange={(event) => setshippmentDetails({ ...shippmentDetails, shippingCompany: event.target.value })} className=" mt-2 w-full h-10 text-sm rounded-md border-[2px] appearance-none outline-none px-3 bg-transparent border-[#9d9d9d]" />
                                </div>
                                <div className="my-3">
                                    <p className="font-work tracking-wide">Order ID</p>
                                    <input type="text" onChange={(event) => setshippmentDetails({ ...shippmentDetails, orderId: event.target.value })} className=" mt-2 w-full h-10 text-sm rounded-md border-[2px] appearance-none outline-none px-3 bg-transparent border-[#9d9d9d]" />
                                </div>
                                <div className="my-3">
                                    <p className="font-work tracking-wide">Shipping Date</p>
                                    <input type="date" onChange={(event) => formateShippingDate(event.target.value)} className=" mt-2 w-full h-10 text-sm rounded-md border-[2px] appearance-none outline-none px-3 bg-transparent border-[#9d9d9d]" />
                                </div>
                                <div className="flex justify-between mt-8">
                                    <button className="border-2  border-[#0cf3ff] rounded-full px-5 py-2 text-[#0cf3ff]">Cancel</button>
                                    <button onClick={() => changeShipmentStatus(selectedContent, "shipped")} className="bg-[#0cccd6] rounded-full px-8 py-2 text-white">Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modalcard>
                    : null}
            </>

            <>
                <div className=" flex gap-5 m-5 pb-4 border-b border-[#757575]">
                    <div className="cursor-pointer" onClick={() => settab(1)}>
                        <div className={`text-md text-center mt-2 tracking-wide text-[${tab === 1 ? '#0cf3ff' : 'rgba(255,255,255,0.6)'}] font-medium font-work pb-1 flex gap-x-2 items-center`}><Article size={26} color="currentcolor" />Brief</div>
                        <div className={`h-[2px] w-full rounded-xl ${tab === 1 ? 'bg-[#0cf3ff]' : 'bg-transparent'}`}></div>
                    </div>

                    <div className="cursor-pointer" onClick={() => settab(2)}>
                        <div className={`text-md text-center mt-2 tracking-wide text-[${tab === 2 ? '#0cf3ff' : 'rgba(255,255,255,0.6)'}] font-medium font-work pb-1 flex gap-x-2 items-center`}><Note size={26} color="currentcolor" />Notes</div>
                        <div className={`h-[2px] w-full rounded-xl ${tab === 2 ? 'bg-[#0cf3ff]' : 'bg-transparent'}`}></div>
                    </div>
                    <div className="cursor-pointer" onClick={() => settab(3)}>
                        <div className={`text-md text-center mt-2 tracking-wide text-[${tab === 3 ? '#0cf3ff' : 'rgba(255,255,255,0.6)'}] font-medium font-work pb-1 flex gap-x-2 items-center`}><UserPlus size={26} color="currentcolor" />Applications</div>
                        <div className={`h-[2px] w-full rounded-xl ${tab === 3 ? 'bg-[#0cf3ff]' : 'bg-transparent'}`}></div>
                    </div>
                    <div className="cursor-pointer" onClick={() => settab(4)}>
                        <div className={`text-md text-center mt-2 tracking-wide text-[${tab === 4 ? '#0cf3ff' : 'rgba(255,255,255,0.6)'}] font-medium font-work pb-1 flex gap-x-2 items-center`}><VideoCamera size={26} color="currentcolor" />Product Delivery</div>
                        <div className={`h-[2px] w-full rounded-xl ${tab === 4 ? 'bg-[#0cf3ff]' : 'bg-transparent'}`}></div>
                    </div>
                    <div className="cursor-pointer" onClick={() => settab(5)}>
                        <div className={`text-md text-center mt-2 tracking-wide text-[${tab === 5 ? '#0cf3ff' : 'rgba(255,255,255,0.6)'}] font-medium font-work pb-1 flex gap-x-2 items-center`}><VideoCamera size={26} color="currentcolor" />Content</div>
                        <div className={`h-[2px] w-full rounded-xl ${tab === 5 ? 'bg-[#0cf3ff]' : 'bg-transparent'}`}></div>
                    </div>

                </div>
            </>
            {tab === 1 && <>
                <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                    <p className="text-lg tracking-wide font-work ">Is your product physical or digital?</p>
                    <div className="flex items-center mt-3 gap-5 font-work">
                        <div className={`bg-[#23252d] cursor-pointer h-[122px] w-[200px] shadow-gray-600 border-4 ${campaign?.productType === "Physical" ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md flex flex-col items-center justify-center gap-1`}>
                            <div className="">
                                <svg
                                    width="40"
                                    height="40"
                                    fill={`${campaign?.productType === "Physical" ? "#0cf3ff" : "#ffffff"}`}

                                    className="bui-icon"
                                    viewBox="0 0 24 24"
                                >
                                    <path fill="none" d="M0 0H24V24H0z"></path>
                                    <path d="M18 6h-2c0-2.21-1.79-4-4-4S8 3.79 8 6H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-6-2c1.1 0 2 .9 2 2h-4c0-1.1.9-2 2-2zm6 16H6V8h2v2c0 .55.45 1 1 1s1-.45 1-1V8h4v2c0 .55.45 1 1 1s1-.45 1-1V8h2v12z"></path>
                                </svg>
                            </div>
                            <div className="font-work">Physical</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[122px] w-[200px] shadow-gray-600 border-4 ${campaign?.productType === "Digital" ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md flex flex-col items-center justify-center gap-1`}>
                            <div className="">
                                <svg
                                    width="40"
                                    height="40"
                                    fill={`${campaign?.productType === "Digital" ? "#0cf3ff" : "#ffffff"}`}

                                    className="bui-icon"
                                    viewBox="0 0 24 24"
                                >
                                    <path d="M4 6h18V4H4c-1.1 0-2 .9-2 2v11H0v3h14v-3H4V6zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1zm-1 9h-4v-7h4v7z"></path>
                                </svg>
                            </div>
                            <div className="font-work">Digital</div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                    <p className="text-lg tracking-wide font-work pb-3">Video type</p>
                    <div className="flex flex-wrap gap-5">
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[1] ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="text-[#0cf3ff] font-medium text-lg font-work">{videoTypes[1]}</div>
                            <div className="text-[13px] font-work">{videoTypesDescription[8]}</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[2] ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="text-[#0cf3ff] font-medium text-lg font-work">{videoTypes[2]}</div>
                            <div className="text-[13px] font-work">{videoTypesDescription[8]}</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[3] ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="text-[#0cf3ff] font-medium text-lg font-work">{videoTypes[3]}</div>
                            <div className="text-[13px] font-work">{videoTypesDescription[8]}</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[4] ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="text-[#0cf3ff] font-medium text-lg font-work">{videoTypes[4]}</div>
                            <div className="text-[13px] font-work">{videoTypesDescription[8]}</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[5] ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="text-[#0cf3ff] font-medium text-lg font-work">{videoTypes[5]}</div>
                            <div className="text-[13px] font-work">{videoTypesDescription[8]}</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[6] ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="text-[#0cf3ff] font-medium text-lg font-work">{videoTypes[6]}</div>
                            <div className="text-[13px] font-work">{videoTypesDescription[8]}</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[7] ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="text-[#0cf3ff] font-medium text-lg font-work">{videoTypes[7]}</div>
                            <div className="text-[13px] font-work">{videoTypesDescription[8]}</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoType === videoTypes[8] ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="text-[#0cf3ff] font-medium text-lg font-work">{videoTypes[8]}</div>
                            <div className="text-[13px] font-work">{videoTypesDescription[8]}</div>
                        </div>
                    </div>
                </div>
                <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                    <p className="text-lg tracking-wide font-work pb-3">Video quality</p>
                    <div className="flex flex-wrap gap-5">
                        <div className={`bg-[#23252d] cursor-pointer h-[358px] w-[312px] shadow-gray-600 border-4 ${campaign?.videoQuality === "Basic Video Quality" ? "border-[#10d4de]" : "border-transparent hover:border-[#10d4de] shadow-sm"} rounded-md flex flex-col`}>
                            <div className="text-[#10d4de] font-medium text-lg font-work h-[285px] w-full">
                                <video src={campaign?.videoType === "Unboxing Video" ? demoVideos[2]?.basic : demoVideos[3]?.basic} className='h-full w-full object-cover object-center' />
                            </div>
                            <div className="font-work p-3 flex-1 h-full w-full ">
                                <button className="h-full w-full rounded-full border-2 border-[#3803D3] bg-[#F0E6FF] flex items-center justify-center gap-4 text-[#6200ee]">
                                    {campaign?.videoQuality === "Basic Video Quality" && <Check size={28} color='#6200ee' />}
                                    <p className="">Basic - ₹500</p>
                                </button>
                            </div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[358px] w-[312px] shadow-gray-600 border-4 ${campaign?.videoQuality !== "Basic Video Quality" ? "border-[#10d4de]" : "border-transparent hover:border-[#10d4de] shadow-sm"} rounded-md flex flex-col`}>
                            <div className="text-[#10d4de] font-medium text-lg font-work h-[285px] w-full">
                                <video src={campaign?.videoQuality !== "Unboxing Video" ? demoVideos[2]?.premium : demoVideos[3]?.premium} className='h-full w-full object-cover object-center' />
                            </div>
                            <div className="font-work p-3 flex-1 h-full w-full ">
                                <button className="h-full w-full rounded-full border-2 border-[#3803D3] bg-[#F0E6FF] flex items-center justify-center gap-4 text-[#6200ee]">
                                    {campaign?.videoQuality !== "Basic Video Quality" && <Star weight='fill' size={22} color='#6200ee' />}
                                    <p className="">Premium - ₹1000</p>
                                </button>
                            </div>
                        </div>
                        <div className="bg-[#333640] p-3 rounded-md flex-1 w-full">
                            <p className="font-work my-3"><span className="font-medium ">Basic quality</span> is a home environment video. It’s simpler and usually shot on a smartphone.</p>
                            <p className="font-work my-3"><span className="font-medium font-work">Premium quality</span> videos are produced by a selection of more experienced creators with home studios. Expect more attention to detail: better lighting, solid color backdrops.</p>
                            <p className="font-work my-3"><span className="font-medium underline underline-offset-2 text-[#0cf3ff]">Learn more</span> about Basic vs Premium quality.</p>
                        </div>
                    </div>
                </div>
                <div className="border-b mt-10 m-5 border-[#555555]"></div>
                <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                    <p className="text-lg tracking-wide font-work pb-3">Video duration</p>
                    <div className="flex flex-wrap gap-5">
                        <div className={` p-4 bg-[#23252d] cursor-pointer h-[200px] w-[173px] shadow-gray-600 border-4 ${campaign?.videoDuration === 15 ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md flex flex-col`}>
                            <div className="flex items-center justify-center">
                                <svg
                                    width="32"
                                    height="32"
                                    stroke={campaign?.videoDuration === 15 ? "#0cf3ff" : "#ffffff"}

                                    className="bui-icon"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        fill=""
                                        strokeWidth="4"
                                        d="M30 16c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2s14 6.268 14 14z"
                                    ></path>
                                    <path fill={campaign?.videoDuration === 15 ? "#0cf3ff" : "#ffffff"} d="M26 16A10 10 0 0016 6v10h10z"></path>
                                </svg>
                            </div>
                            <div className="text-center font-work mt-3">15 seconds</div>
                            <div className={`text-center font-work mt-4 text-3xl font-medium ${campaign?.videoDuration === 15 ? "text-[#0cf3ff]" : "text-white"}`}>₹{campaign?.videoQuality === 15 ? 1000 : 1700}</div>
                            <div className="text-center font-work mt-3">₹{campaign?.videoQuality === 15 ? 66.66 : 113.33} / sec</div>
                        </div>
                        <div className={`p-4 bg-[#23252d] cursor-pointer h-[200px] w-[173px] shadow-gray-600 border-4 ${campaign?.videoDuration === 30 ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md flex flex-col`}>
                            <div className="flex items-center justify-center">
                                <svg
                                    width="32"
                                    height="32"
                                    stroke={campaign?.videoDuration === 30 ? "#0cf3ff" : "#ffffff"}

                                    className="bui-icon"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        fill="none"
                                        strokeWidth="4"
                                        d="M30 16c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2s14 6.268 14 14z"
                                    ></path>
                                    <path fill={campaign?.videoDuration === 30 ? "#0cf3ff" : "#ffffff"} d="M16 26a10 10 0 000-20v20z"></path>
                                </svg>
                            </div>
                            <div className="text-center font-work mt-3">30 seconds</div>
                            <div className={`text-center font-work mt-4 text-3xl font-medium ${campaign?.videoDuration === 30 ? "text-[#0cf3ff]" : "text-white"}`}>₹{campaign?.videoQuality === 30 ? 1250 : 2000}</div>
                            <div className="text-center font-work mt-3">₹{campaign?.videoQuality === 30 ? 41.66 : 66.66} / sec</div>
                        </div>
                        <div className={`p-4 bg-[#23252d] cursor-pointer h-[200px] w-[173px] shadow-gray-600 border-4 ${campaign?.videoDuration === 60 ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md flex flex-col`}>
                            <div className="flex items-center justify-center">
                                <svg
                                    width="32"
                                    height="32"
                                    fill={campaign?.videoDuration === 60 ? "#0cf3ff" : "#ffffff"}

                                    className="bui-icon"
                                    viewBox="0 0 32 32"
                                >
                                    <path
                                        fill="none"
                                        stroke={campaign?.videoDuration === 60 ? "#0cf3ff" : "#ffffff"}
                                        strokeWidth="4"
                                        d="M30 16c0 7.732-6.268 14-14 14S2 23.732 2 16 8.268 2 16 2s14 6.268 14 14z"
                                    ></path>
                                    <circle cx="16" cy="16" r="10"></circle>
                                </svg>
                            </div>
                            <div className="text-center font-work mt-3">60 seconds</div>
                            <div className={`text-center font-work mt-4 text-3xl font-medium ${campaign?.videoDuration === 60 ? "text-[#0cf3ff]" : "text-white"}`}>₹{campaign?.videoQuality === 60 ? 1300 : 2200}</div>
                            <div className="text-center font-work mt-3">₹{campaign?.videoQuality === 60 ? 21.66 : 36.66} / sec</div>
                        </div>
                    </div>
                </div>
                <div className="border-b mt-10 m-5 border-[#555555]"></div>
                <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                    <p className="text-lg tracking-wide font-work pb-3">Where your {campaign?.videoType} will be displayed</p>
                    <div className="flex flex-wrap gap-5">
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "Facebook" ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="flex items-center justify-center mb-5">
                                <FacebookLogo size={52} color={campaign?.adDisplayed === "Facebook" ? "#0cf3ff" : "#ffffff"} weight='fill' />
                            </div>
                            <div className="text-[13px] font-work text-center">Facebook</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "Instagram" ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="flex items-center justify-center mb-5">
                                <InstagramLogo size={52} color={campaign?.adDisplayed === "Instagram" ? "#0cf3ff" : "#ffffff"} weight='fill' />
                            </div>
                            <div className="text-[13px] font-work text-center">Instagram</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "Twitter" ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="flex items-center justify-center mb-5">
                                <TwitterLogo size={52} color={campaign?.adDisplayed === "Twitter" ? "#0cf3ff" : "#ffffff"} weight='fill' />
                            </div>
                            <div className="text-[13px] font-work text-center">Twitter</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "Amazon" ? "border-[#0cf3ff]" : "border-transparent shadow-sm"} rounded-md p-4`}>
                            <div className="flex items-center justify-center mb-5">
                                <AmazonLogo size={52} color={campaign?.adDisplayed === "Amazon" ? "#0cf3ff" : "#ffffff"} weight='fill' />
                            </div>
                            <div className="text-[13px] font-work text-center">Amazon</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "YouTube" ? "border-[#0cf3ff]" : "border-transparent  shadow-sm"} rounded-md p-4`}>
                            <div className="flex items-center justify-center mb-5">
                                <YoutubeLogo size={52} color={campaign?.adDisplayed === "YouTube" ? "#0cf3ff" : "#ffffff"} weight='fill' />
                            </div>
                            <div className="text-[13px] font-work text-center">YouTube</div>
                        </div>
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.adDisplayed === "Snapchat" ? "border-[#0cf3ff]" : "border-transparent  shadow-sm"} rounded-md p-4`}>
                            <div className="flex items-center justify-center mb-5">
                                <SnapchatLogo size={52} color={campaign?.adDisplayed === "Snapchat" ? "#0cf3ff" : "#ffffff"} weight='fill' />
                            </div>
                            <div className="text-[13px] font-work text-center">Snapchat</div>
                        </div>
                    </div>
                </div>
                <div className="border-b mt-10 m-5 border-[#555555]"></div>
                <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                    <p className="text-lg tracking-wide font-work pb-3">Choose video format</p>
                    <div className="flex flex-wrap gap-5">
                        <div className={`bg-[#23252d] cursor-pointer h-[132px] w-[200px] shadow-gray-600 border-4 ${campaign?.videoFormat === "9:16" ? "border-[#0cf3ff]" : "border-transparent  shadow-sm"} rounded-md p-4`}>
                            <div className="flex items-center justify-center mb-3">
                                <div className={`h-12 aspect-[10/16] border-4 border-[${campaign?.videoFormat === "9:16" ? "#0cf3ff" : "#ffffff"}]`}></div>
                            </div>
                            <div className="text-[13px] font-work text-center">9:16</div>
                            <div className="text-[12px] font-work text-center">Portrait</div>
                        </div>

                    </div>
                </div>
                <div className="border-b mt-10 m-5 border-[#555555]"></div>
                <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                    <div className="w-full flex items-start justify-between">
                        <div className="">
                            <p className="text-lg tracking-wide font-work pb-3">Photos</p>
                            <p className="text-lg font-work pb-3">4 authentic photos of your product from each creator</p>
                            <button className="h-12 w-fit px-8 rounded-full border-2 border-[#3803D3] bg-[#F0E6FF] flex items-center justify-center gap-4 text-[#6200ee]">
                                {campaign?.photos === true && <Check size={28} color='#6200ee' />}
                                <p className=""> {campaign?.photos === true ? "Added" : "Add"} - ₹400  / (₹100 per photo)</p>
                            </button>
                        </div>
                        <div className="flex gap-5 w-[390px]">
                            <div className="h-[185px]  aspect-square">
                                <img src="https://static.billo.app/public/content-examples/content_examples.png" alt="" className="h-[185px] aspect-square object-cover" />
                            </div>
                            <div className="">
                                <p className="tracking-wide font-work pb-3">Upgrade your product listing or ads with authentic stills of your product.</p>
                                <p className="tracking-wide font-work pb-3 underline underline-offset-2 text-[#0cf3ff]">See examples</p>
                            </div>
                        </div>
                    </div>
                    {campaign?.photos === true && <div className="w-full">
                        <textarea className='h-32 bg-[#23252D] w-full mt-5 rounded-md p-3 font-work' defaultValue={campaign?.photosDescription} disabled maxLength={300} />
                        <div className="flex justify-between">
                            <p className="text-xs tracking-wide font-work text-[#b7b7b7]">Edit to your preference</p>
                            <p className="text-xs tracking-wide font-work text-[#b7b7b7]">{campaign?.photosDescription?.length}/300</p>
                        </div>
                    </div>}
                </div>
                <div className="border-b mt-10 m-5 border-[#555555]"></div>
                <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                    <p className="text-lg tracking-wide font-work pb-3">Creator criteria</p>
                    <div className="">
                        <p className="tracking-wide font-work pb-1">Gender</p>
                        <select className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' disabled>
                            <option value="">{campaign?.creatorCriteria?.gender.charAt(0).toUpperCase() + campaign?.creatorCriteria?.gender.slice(1)}</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="any">Any</option>
                        </select>
                    </div>
                    <div className="mt-5">
                        <p className="tracking-wide font-work pb-1">Language</p>
                        <div className="flex gap-5">
                            <div className="">
                                <input type="checkbox" disabled id="english" name="english" defaultChecked={campaign?.creatorCriteria?.language.includes('English')} />
                                <label htmlFor="english" className='ml-2'>English</label>
                            </div>
                            <div className="">
                                <input type="checkbox" disabled id="hindi" name="hindi" defaultChecked={campaign?.creatorCriteria?.language.includes('Hindi')} />
                                <label htmlFor="hindi" className='ml-2'>Hindi</label>
                            </div>
                            <div className="">
                                <input type="checkbox" disabled id="bengali" name="bengali" defaultChecked={campaign?.creatorCriteria?.language.includes('Bengali')} />
                                <label htmlFor="bengali" className='ml-2'>Bengali</label>
                            </div>
                            <div className="">
                                <input type="checkbox" disabled id="tamil" name="tamil" defaultChecked={campaign?.creatorCriteria?.language.includes('Tamil')} />
                                <label htmlFor="tamil" className='ml-2'>Tamil</label>
                            </div>
                            <div className="">
                                <input type="checkbox" disabled id="telgu" name="telgu" defaultChecked={campaign?.creatorCriteria?.language.includes('Telgu')} />
                                <label htmlFor="telgu" className='ml-2'>Telgu</label>
                            </div>
                            <div className="">
                                <input type="checkbox" disabled id="gujrati" name="gujrati" defaultChecked={campaign?.creatorCriteria?.language.includes('Gujrati')} />
                                <label htmlFor="gujrati" className='ml-2'>Gujrati</label>
                            </div>
                            <div className="">
                                <input type="checkbox" disabled id="marathi" name="marathi" defaultChecked={campaign?.creatorCriteria?.language.includes('Marathi')} />
                                <label htmlFor="marathi" className='ml-2'>Marathi</label>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <p className="tracking-wide font-work pb-1">Age</p>
                        <div className="flex gap-5">
                            <div className="">
                                <input type="checkbox" disabled name="18-24" defaultChecked={campaign?.creatorCriteria?.age?.includes("18-24")} />
                                <label htmlFor="18-24" className='ml-2'>18-24</label>
                            </div>
                            <div className="">
                                <input type="checkbox" disabled name="25-34" defaultChecked={campaign?.creatorCriteria?.age?.includes("25-34")} />
                                <label htmlFor="25-34" className='ml-2'>25-34</label>
                            </div>
                            <div className="">
                                <input type="checkbox" disabled name="35-44" defaultChecked={campaign?.creatorCriteria?.age?.includes("35-44")} />
                                <label htmlFor="35-44" className='ml-2'>35-44</label>
                            </div>
                            <div className="">
                                <input type="checkbox" disabled name="44+" defaultChecked={campaign?.creatorCriteria?.age?.includes("44+")} />
                                <label htmlFor="44+" className='ml-2'>44+</label>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5">
                        <p className="tracking-wide font-work pb-1">Creator preference</p>
                        <input type="text" readOnly className="h-10 w-full appearance-none outline-none rounded-md bg-[#23252D] px-3 tex-sm placeholder:text-sm" placeholder='Looking for anything specific? Describe creator qualities (e.g. has a pet / can film in a car)' />
                    </div>
                </div>
                <div className="border-b mt-10 m-5 border-[#555555]"></div>
                <div className="bg-[#131418] m-5 rounded-md mt-10 p-5 mb-8">
                    <p className="text-lg tracking-wide font-work pb-3">Number of videos</p>
                    <div className="flex w-full gap-5">
                        <div className="">
                            <div className={`bg-[#23252d] cursor-pointer h-[152px] w-[200px] shadow-gray-600 shadow-sm rounded-md p-4`}>
                                <div className="text-center py-1 text-xs tracking-wide">
                                    How many videos you'd like to order?
                                </div>
                                <div className="text-[#0cf3ff] text-2xl text-center font-medium mt-1">{campaign?.videos} videos</div>
                                <div className="font-work flex items-center justify-center gap-5 mt-2">
                                    <div className="h-10 w-10 rounded-full bg-[#0cf3ff] flex items-center justify-center text-3xl font-light cursor-pointer select-none">-</div>
                                    <div className="h-10 w-10 rounded-full bg-[#0cf3ff] flex items-center justify-center text-3xl font-light cursor-pointer select-none" >+</div>
                                </div>
                            </div>
                        </div>
                        <div className="bg-[#333640] p-3 rounded-md flex-1 w-full">
                            <p className="font-work">Order will be carried out by <span className="text-[#0cf3ff]">{campaign?.videos} creators</span></p>
                            <p className="my-2 text-sm  tracking-wide font-work font-medium">Your product shipping</p>
                            <p className="my-2 text-sm  tracking-wide font-work">Note that you <span className="underline underline-offset-2">will have to send one product to each creator producing the video (as a giveaway).</span></p>
                            <p className="my-2 text-sm  tracking-wide font-work">The faster you ship products to approved creators, the faster you can expect to receive the final videos.</p>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-2 bg-[#131418] m-5 rounded-md mt-5 p-5">

                    <div className="">
                        <p className="text-lg tracking-wide font-work pb-3">Product Info</p>
                        <div className="">
                            <p className="tracking-wide font-work pb-1">Product Type</p>
                            <select disabled className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide'>
                                <option value="">{campaign?.productDetails?.productType.charAt(0).toUpperCase() + campaign?.productDetails?.productType.slice(1)}</option>
                                <option value="Giveaway">Giveaway</option>
                                <option value="Pre Owned">Pre Owned</option>
                            </select>
                        </div>
                        <>
                            <div className="mt-5">
                                <p className="tracking-wide font-work pb-1">Product Name</p>
                                <input type='text' readOnly defaultValue={campaign?.productDetails?.productName} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="mt-5">
                                <p className="tracking-wide font-work pb-1">Product (SKU Code)</p>
                                <input type='text' readOnly defaultValue={campaign?.productDetails?.productSKU} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="mt-5">
                                <p className="tracking-wide font-work pb-1">Product Link</p>
                                <input type='text' readOnly defaultValue={campaign?.productDetails?.productLink} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="mt-5">
                                <p className="tracking-wide font-work pb-1">Product Value</p>
                                <input type='text' readOnly defaultValue={campaign?.productDetails?.productValue} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            {campaign?.productDetails?.productType === 'Giveaway' && <div className="mt-5">
                                <p className="tracking-wide font-work pb-1">Estimated Delivery Time</p>
                                <input type='text' readOnly defaultValue={campaign?.productDetails?.estimatedDeliveryTime} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>}
                            <div className="mt-5">
                                <p className="tracking-wide font-work pb-1">Script/ Guidelines</p>
                                <textarea type='text' readOnly defaultValue={campaign?.productDetails?.script} placeholder='' className='w-[312px] bg-[#23252D] h-32 pt-2 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                            <div className="mt-5">
                                <p className="tracking-wide font-work pb-1">Reference Video (Link)</p>
                                <input type='text' readOnly defaultValue={campaign?.productDetails.referenceVideo} placeholder='' className='w-[312px] bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' />
                            </div>
                        </>
                    </div>
                    <div className="">
                        <p className="tracking-wide font-work pb-1">Product Image</p>
                        <div className="h-52 aspect-square bg-[#23252D] relative flex flex-col items-center justify-center rounded-md">
                            <div className="h-full w-full">
                                <img src={campaign?.productDetails?.productImage} alt="productimage" className="h-full w-full rounded-md object-cover" />
                            </div>
                        </div>
                    </div>

                </div>

                <div className="bg-[#131418] mx-5 h-16 sticky bottom-0 flex justify-end border-t border-[#474747]">
                    <div className="flex items-center mr-5">
                        <div className="">
                            <p className="font-work">{campaign?.videos} videos x ₹{campaign?.perVideoAmount}</p>
                            <p className="font-work -mt-1">Total: ₹{campaign?.totalAmount}</p>
                        </div>
                    </div>
                </div>

            </>
            }

            {tab === 2 && <>
                <div className="bg-[#131418] m-5 rounded-md mt-10 p-5">
                    <p className="text-lg tracking-wide font-work pb-5">Notes</p>
                    {notes?.map((note, index) => (
                        <div className="flex items-center justify-between py-2" key={index}>
                            <p className="" >{index + 1}: {note}</p>
                        </div>
                    ))}
                </div>
            </>}
            {tab === 3 && <>
                {applicationModal === true && <>
                    <Modalcard close={setapplicationModal}>
                        <div className="w-[900px] bg-[#2e313e] h-[80vh] overflow-y-auto rounded-md" id="thin-scrollbar">
                            <div className="h-16 border-b bg-[#2e313e] border-[#83838380] flex items-center justify-between gap-5 px-10 sticky top-0 z-10">
                                <div className="flex gap-5">
                                    <button onClick={() => setmodalTab(0)} className="border-b-2 border-[#4611ea]">Content</button>
                                </div>
                            </div>
                            {modalTab === 0 && <>
                                <div className="mt-5">
                                    <p className="ml-10 font-work text-lg underline underline-offset-2">Flicks</p>
                                    <div className="py-5 flex flex-wrap gap-5 p-5 justify-center" >
                                        {applicationDetails?.flicks?.map((review, index) => (
                                            <div className="w-64 aspect-[9/16] rounded-md bg-white" key={index}>
                                                <video controls={false} onKeyDown={(event) => {
                                                    if (event.button === 2) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                    onContextMenu={e => e.preventDefault()}
                                                    src={review?.reviewUrl} onClick={(event) => event.currentTarget?.paused ? event.currentTarget.play() : event.currentTarget.pause()} poster={review?.thumbnail} className='h-full w-full rounded-md cursor-pointer'></video>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <p className="ml-10 font-work text-lg underline underline-offset-2">Reviews</p>
                                    <div className="py-5 flex flex-wrap gap-5 p-5 justify-center" >
                                        {applicationDetails?.reviews?.map((review, index) => (
                                            <div className="w-64 aspect-[9/16] rounded-md bg-white" key={index}>
                                                <video controls={false} onKeyDown={(event) => {
                                                    if (event.button === 2) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                    onContextMenu={e => e.preventDefault()}
                                                    src={review?.reviewUrl} onClick={(event) => event.currentTarget?.paused ? event.currentTarget.play() : event.currentTarget.pause()} poster={review?.thumbnail} className='h-full w-full rounded-md cursor-pointer'></video>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </>}

                        </div>
                    </Modalcard>
                </>}
                <div className="flex flex-wrap gap-5 m-5">
                    {campaignApplication?.filter(val => {
                        return val
                    })?.map((application, index) => (<div key={application._id} className="w-[270px] bg-[#131418] border border-[#545454] rounded-md" >
                        <div className='flex items-start gap-3 p-5'>
                            <div className="h-12 w-12 rounded-full flex items-center justify-center bg-[#23252D]">
                                {application?.user?.imageUrl !== null ?
                                    <img src={application?.user?.imageUrl} alt="user_image" className="h-full w-full rounded-full" />
                                    :
                                    <p className="">{(application?.user?.name && application?.user?.name.split(" ").map((n) => n[0].toUpperCase()).join("")) || ''}</p>
                                }
                            </div>
                            <div className="">
                                <p className="">{application?.user?.name}</p>
                                <div className="text-xs tracking-wide mt-1 bg-[#23252D] py-1 px-3 rounded-md w-fit">{campaign?.videoType}</div>
                            </div>
                        </div>
                        <div className="px-5">
                            {application?.reviewsMade.length === 0 ? <div className="aspect-[9/16] rounded-md mx-auto w-[180px] bg-[#23252D] flex items-center justify-center cursor-pointer">
                                <VideoCameraSlash size={28} color="#ffffff" />
                            </div> :
                                <img onClick={() => { setapplicationDetails(application?.reviewsMade[0]); setReviewModal(true) }} src={application?.reviewsMade[0].thumbnail} className='aspect-[9/16] rounded-md mx-auto w-[180px]' alt='' />}
                        </div>
                        <div className="p-5 flex justify-center items-center gap-5">
                            <div className="">
                                <button className="h-12 w-12 rounded-full bg-[#23252D] flex items-center justify-center" onClick={() => {
                                    if (application?.isEligible === true) {
                                        toast.warning(`Creator has met eligibilty requirements.`, {
                                            position: "top-right",
                                            // theme: "dark",
                                            icon: <Checks size={32} color="green" weight="bold" />,
                                            bodyClassName: "text-sm tracking-wide font-work"
                                        });
                                    }
                                    else {
                                        toast.warning(`Creator has not met eligibilty requirements.`, {
                                            position: "top-right",
                                            // theme: "dark",
                                            icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                                            bodyClassName: "text-sm tracking-wide font-work"
                                        });
                                    }
                                }}>
                                    {application?.isEligible === true ? <ThumbsUp size={25} color='#998EE8' /> : <ThumbsDown size={25} color='red' />}
                                </button>
                                <p className="text-xs tracking-wide mt-1">  {application?.isEligible === true ? "Eligible" : "Ineligible"}</p>
                            </div>
                            <div className="">
                                <button onClick={() => openApplicationAnswer(application)} className="h-12 w-12 rounded-full bg-[#23252D] flex items-center justify-center">
                                    <Chat size={25} color='#998EE8' />
                                </button>
                                <p className="text-xs tracking-wide mt-1">Answers</p>
                            </div>
                            <div className='flex flex-col items-center'>
                                <button onClick={() => selectUserApplication(application)} className="h-12 w-12 rounded-full bg-[#23252D] flex items-center justify-center" >
                                    {application?.status !== 'pending' ? <Checks size={25} color='#998EE8' /> : <Check size={25} color='#998EE8' />}
                                </button>
                                <p className="text-xs tracking-wide mt-1">{application?.status !== 'pending' ? "Selected" : "Select"}</p>
                            </div>
                        </div>
                    </div>))}
                    {campaignApplication.length === 0 && <div className='h-full flex-1 flex items-center justify-center'>
                        <p className="">Creators haven't submitted any applications yet.</p>
                    </div>}
                </div>
            </>}
            {tab === 4 && <>
                <div className="flex gap-5 m-5">
                    <button onClick={() => setproductDeliveryTab(0)} className={`py-2 px-5 rounded-full ${productDeliveryTab === 0 ? "bg-[#2793b379]" : ""} border-2 border-[#14ced8] text-sm appearance-none outline-none tracking-wide`}>Due to Ship</button>
                    <button onClick={() => setproductDeliveryTab(1)} className={`py-2 px-5 rounded-full ${productDeliveryTab === 1 ? "bg-[#2793b379]" : ""} border-2 border-[#14ced8] text-sm appearance-none outline-none tracking-wide`}>Shipped</button>
                    <button onClick={() => setproductDeliveryTab(2)} className={`py-2 px-5 rounded-full ${productDeliveryTab === 2 ? "bg-[#2793b379]" : ""} border-2 border-[#14ced8] text-sm appearance-none outline-none tracking-wide`}>Delivered</button>
                    <button onClick={() => setproductDeliveryTab(3)} className={`py-2 px-5 rounded-full ${productDeliveryTab === 3 ? "bg-[#2793b379]" : ""} border-2 border-[#14ced8] text-sm appearance-none outline-none tracking-wide`}>All Shipment</button>
                </div>
                <div className="mx-5 mt-10">
                    <p className="text-xl tracking-wide font-work pb-5">{campaign?.productDetails?.productName}</p>
                </div>
                <div className="relative block m-5">
                    <div className="relative">
                        <div className="overflow-x-auto min-h-[0.01%]">
                            <table className="rounded-md w-full max-w-full border-collapse border-spacing-0 table indent-[initial] m-0">
                                <thead className="bg-[#27252c] rounded-md">
                                    <tr>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            #
                                        </th>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Name
                                        </th>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Status
                                        </th>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Address
                                        </th>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-left cursor-pointer">
                                            Tracking
                                        </th>
                                        <th className=" font-normal border-b-0 p-[15px] text-[#ffffff] whitespace-nowrap align-bottom leading-[1.42857143] text-center cursor-pointer">
                                            Details
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="table-row-group align-middle border-inherit bg-[#131418] ">
                                    {campaignApplication?.filter(val => {
                                        if (val.status !== "pending") {
                                            return val
                                        }
                                    }).filter(val => {
                                        if (productDeliveryTab === 0) {
                                            if (!val?.shipmentDetails) {
                                                return val
                                            }
                                        }
                                        else if (productDeliveryTab === 1) {
                                            if (val?.shipmentDetails?.shipmentStatus === "shipped") {
                                                return val
                                            }
                                        }
                                        else if (productDeliveryTab === 2) {
                                            if (val?.shipmentDetails?.shipmentStatus === "delivered") {
                                                return val
                                            }
                                        }
                                        else if (productDeliveryTab === 3) {
                                            return val
                                        }
                                    }).map((application, index) => (
                                        <tr key={index}>
                                            <td className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer">
                                                {index + 1}
                                            </td>
                                            <td className=" flex items-center  gap-5 font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer">
                                                <img src={application?.user?.imageUrl} alt={application?.user?.name} className="h-14 w-14 rounded-full" />    {application?.user?.name}
                                            </td>
                                            <td className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer">
                                                {!application?.shipmentDetails && <p className="bg-[#20a4ab] py-1 px-4 w-fit text-xs tracking-wide text-white ">Due To Ship</p>}
                                                {application?.shipmentDetails?.shipmentStatus === "shipped" && <p className="bg-[#20a4ab] py-1 px-4 w-fit text-xs tracking-wide text-white ">Shipped</p>}
                                                {application?.shipmentDetails?.shipmentStatus === "delivered" && <p className="bg-[#20a4ab] py-1 px-4 w-fit text-xs tracking-wide text-white ">Delivered</p>}
                                            </td>
                                            <td className="font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer" style={{ overflow: "hidden" }}>
                                                <p className="" style={{ wordWrap: "break-word", maxWidth: 320, whiteSpace: "normal" }}>{application?.user?.address}</p>
                                            </td>
                                            <td className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer">
                                                {application?.shipmentDetails?.shipmentTrackingNumber ?? "-"}
                                            </td>
                                            <td className=" font-normal border-b-0 p-[15px] text-[#808080] whitespace-nowrap align-center leading-[1.42857143] text-left cursor-pointer">
                                                {!application?.shipmentDetails && <button onClick={() => { setshipmentModal(true); setselectedContent(application) }} className="bg-[#20a4ab] py-2 rounded-full mx-auto px-4 w-fit text-[13px] tracking-wide text-white flex items-center gap-3"><Truck size={20} color="#ffffff" />Mark as shipped</button>}
                                                {application?.shipmentDetails?.shipmentStatus === "shipped" && <button onClick={() => changeShipmentStatus(application, "delivered")} className="bg-[#20a4ab] py-2 rounded-full px-4 w-fit text-[14px] tracking-wide font-work mx-auto text-white flex items-center gap-3"><Package size={20} color="#ffffff" />Mark as delivered</button>}
                                                {application?.shipmentDetails?.shipmentStatus === "delivered" && <button className="bg-[#20a4ab] py-2 rounded-full px-4 w-fit text-[13px] tracking-wide text-white mx-auto">Delivered</button>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {campaignApplication?.filter(val => {
                                if (val.status !== "pending") {
                                    return val
                                }
                            }).filter(val => {
                                if (productDeliveryTab === 0) {
                                    if (!val?.shipmentDetails) {
                                        return val
                                    }
                                }
                                else if (productDeliveryTab === 1) {
                                    if (val?.shipmentDetails?.shipmentStatus === "shipped") {
                                        return val
                                    }
                                }
                                else if (productDeliveryTab === 2) {
                                    if (val?.shipmentDetails?.shipmentStatus === "delivered") {
                                        return val
                                    }
                                }
                                else if (productDeliveryTab === 3) {
                                    return val
                                }
                            }).length === 0 && <div className='h-20 bg-[#131418] w-full font-work flex gap-5 items-center justify-center'>
                                    {productDeliveryTab === 0 && <> <Truck size={32} color='white' />  <p>No task requiring delivery</p></>}
                                    {productDeliveryTab === 1 && <> <Package size={32} color='white' />  <p>Product has not been shipped to any users</p></>}
                                    {productDeliveryTab === 2 && <> <Package size={32} color='white' />  <p>Product has not been delivered to any users</p></>}
                                </div>}
                        </div>
                    </div>
                </div>

            </>}
            {tab === 5 && <>
                {applicationModal === true && <>
                    <Modalcard close={setapplicationModal}>
                        <div className="w-[900px] bg-[#2e313e] h-[80vh] overflow-y-auto rounded-md" id="thin-scrollbar">
                            <div className="h-16 border-b bg-[#2e313e] border-[#83838380] flex items-center justify-between gap-5 px-10 sticky top-0 z-10">
                                <div className="flex gap-5">
                                    <button className="border-b-2 border-[#4611ea]">Request Edit</button>
                                </div>
                            </div>
                            <div className="flex m-5 gap-5">
                                <div className="w-96">
                                    <video onKeyDown={(event) => {
                                        if (event.button === 2) {
                                            event.preventDefault();
                                        }
                                    }}
                                        onContextMenu={e => e.preventDefault()}
                                        controls={false} src={selectedContent?.url} onClick={(event) => event.currentTarget?.paused ? event.currentTarget.play() : event.currentTarget.pause()} className='aspect-[9/16] rounded-md  w-[250px]'></video>
                                </div>
                                <div className="w-full gap-5">
                                    <p className="font-work text-lg pb-3">Request edits</p>
                                    <div className="my-3">
                                        <p className="font-work pb-1">Changes #1</p>
                                        <select type='text' onChange={(event) => setchangesRef({ ...changesRef, changes: event.target.value })} className='w-full bg-[#23252D] h-10 appearance-none outline-none rounded-md px-3 text-sm tracking-wide' >
                                            <option value="">Please Select</option>
                                            <option value="Fix aspect ratio to fit the Brief">Fix aspect ratio to fit the Brief</option>
                                            <option value="Fix duration to fit the Brief">Fix duration to fit the Brief</option>
                                            <option value="Videos needs to look more natural">Videos needs to look more natural </option>
                                            <option value="Videos need more energy">Videos need more energy</option>
                                            <option value="Remove filters or effects">Remove filters or effects</option>
                                            <option value="Remove voice over/music">Remove voice over/music</option>
                                            <option value="Incorrect pronounciation">Incorrect pronounciation</option>
                                            <option value="Video/audio quality issue">Video/audio quality issue</option>
                                            <option value="Product not shown">Product not shown</option>
                                            <option value="Inaccurate Scene">Inaccurate Scene</option>
                                            <option value="Incorrect mention">Incorrect mention</option>
                                            <option value="Film it differently">Film it differently</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                    <div className="my-3">
                                        <p className="font-work pb-1">Comments (Optional)</p>
                                        <textarea type='text' onChange={(event) => setchangesRef({ ...changesRef, comments: event.target.value })} placeholder='' defaultValue={selectedContent?.video?.comments} className='w-full bg-[#23252D] h-32 appearance-none outline-none rounded-md p-3 text-sm tracking-wide' />
                                    </div>
                                    <div className="flex justify-between">
                                        <button onClick={() => { setselectedContent(''); setapplicationModal({}) }} className="border-2  border-[#0cf3ff] rounded-full px-8 py-2 text-[#0cf3ff]">Cancel</button>
                                        <button onClick={() => requestEditContent()} className="bg-[#0cf3ff] rounded-full px-8 py-2 text-white">Send Request</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modalcard>
                </>}
                <div className="flex flex-wrap m-5 gap-5">
                    {campaignContent?.map((application, index) => (<div key={index} className="w-[270px] bg-[#131418] border border-gray-400 rounded-md relative">
                        <div className="p-3 flex justify-end">
                            {(application?.changesTitle && application?.status === "In Review") && <div className="py-1 px-3 w-fit bg-[#4611ea] text-xs">Asked for changes</div>}
                            {application?.status === "Completed" && <div className="py-1 px-3 w-fit bg-[#4611ea] text-xs">Approved</div>}
                            {application?.status === "Rejected" && <div className="py-1 px-3 w-fit bg-[#ea1111] text-xs">Rejected</div>}
                        </div>
                        <div className='flex items-start gap-3 px-5 pb-5'>
                            <div className="h-12 w-12 rounded-full flex items-center justify-center bg-[#23252D]">
                                {application?.user?.imageUrl !== null ?
                                    <img src={application?.user?.imageUrl} alt="user_image" className="h-full w-full rounded-full" />
                                    :
                                    <p className="">{(application?.user?.name && application?.user?.name.split(" ").map((n) => n[0].toUpperCase()).join("")) || ''}</p>
                                }
                            </div>
                            <div className="">
                                <p className="">{application?.user?.name}</p>
                                <div className="text-xs tracking-wide mt-1 bg-[#23252D] py-1 px-3 rounded-md w-fit">{campaign?.videoType}</div>
                            </div>
                        </div>
                        <div className="px-5">
                            <div onClick={() => { setapplicationDetails(application); setReviewModal(true) }} className='aspect-[9/16] rounded-md mx-auto w-[180px] cursor-pointer'>
                                <video onKeyDown={(event) => {
                                    if (event.button === 2) {
                                        event.preventDefault();
                                    }
                                }}
                                    onContextMenu={e => e.preventDefault()}
                                    controls={false} src={application?.url} className='h-full w-full'></video>
                            </div>
                        </div>
                        <div className="p-5 flex justify-center items-center gap-5">
                            <div className="flex flex-col items-center">
                                <button className="h-12 w-12 rounded-full bg-[#23252D] flex items-center justify-center" onClick={() => { setselectedContent(application); setapplicationModal(true) }}>
                                    <DotsThreeVertical size={25} color='#998EE8' />
                                </button>
                                <p className="text-xs tracking-wide mt-1">
                                    More
                                </p>
                            </div>
                            {application?.status !== "Completed" && <div className="flex flex-col items-center" onClick={() => rejectContent(application)}>
                                <button className="h-12 w-12 rounded-full bg-[#23252D] flex items-center justify-center" >
                                    <X size={25} color='#ff0000' />
                                </button>
                                <p className="text-xs tracking-wide mt-1">
                                    {application?.status === "Ready" && "Reject"}
                                    {application?.status === "In Review" && "Reject"}
                                    {application?.status === "Rejected" && "Rejected"}
                                </p>
                            </div>}
                            {application?.status !== "Rejected" && <div className='flex flex-col items-center' onClick={() => approveContent(application)}>
                                <button className="h-12 w-12 rounded-full bg-[#23252D] flex items-center justify-center" >
                                    <Check size={25} color='#998EE8' />
                                </button>
                                <p className="text-xs tracking-wide mt-1">
                                    {application?.status === "Ready" && "Approve"}
                                    {application?.status === "In Review" && "Approve"}
                                    {application?.status === "Completed" && "Approved"}
                                </p>
                            </div>}
                        </div>
                    </div>))}
                    {campaignContent.length === 0 && <div className='h-full flex-1 flex items-center justify-center'>
                        <p className="">Creators haven't submitted content yet.</p>
                    </div>}
                </div>
            </>}
        </div>
    )
}
