import { CaretDown } from "@phosphor-icons/react";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import FeedVideo from "../components/feedVideo";
import { useRef } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { Link } from "react-router-dom";

export default function Feed() {
    const { id } = useParams();
    const [feed, setfeed] = useState([]);
    const [productInfo, setproductInfo] = useState({})
    const [descriptionActive, setDescriptionActive] = useState(false);
    let apiResponses = [];
    useEffect(() => {
        async function getFeed() {
            try {
                await getDoc(doc(db, "shoppableVideos", id)).then(res => {
                    if (res.exists) {
                        fetchAllData(res.data().selected)
                    }
                }).catch(err => console.log(err))
            } catch (error) {
                console.log(error);
            }
        }
        getFeed();
        fetchProduct()
    }, [id]);
    async function fetchAllData(arr) {
        const promises = arr.map(obj => getReviewsFlicks(obj));
        const responses = await Promise.all(promises);
        apiResponses.push(...responses);
        setfeed(apiResponses)
    }
    async function fetchProduct() {
        let headersList = {
            Accept: "*/*",
            Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        };

        let reqOptions = {
            url: "https://adminapi-zscu3untuq-el.a.run.app/getProduct/" + id,
            method: "GET",
            headers: headersList,
        };

        let response = await axios.request(reqOptions);
        let data = response.data;
        setproductInfo(data)
    }
    async function getReviewsFlicks(docu) {
        if (docu.type !== "import") {
            let headersList = {
                "Accept": "*/*",
                "Authorization": docu.type === 'review' ? "asdlfha-29347239-ASDHPQPINMV-131241234-adfasdfm-QWOQWIEUALS" : "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf"
            }

            let flickReqOptions = {
                url: `https://flicksapi-zscu3untuq-el.a.run.app/getFlicksById/${docu.id}`,
                method: "GET",
                headers: headersList,
            }
            let reqOptions = {
                url: `https://reviewapi-zscu3untuq-el.a.run.app/getReviewsById/${docu.id}`,
                method: "GET",
                headers: headersList,
            }
            let response = await axios.request(docu.type === "review" ? reqOptions : flickReqOptions);
            let data = response.data.data;
            return data;
        }
        else {
            const data = await getDoc(doc(db, "importVideos", docu.id)).then(document => document.data());
            return data;
        }
    }

    const containerRef = useRef(null)
    const scrollUp = () => {
        const container = containerRef.current;
        container.scrollTo({
            top: container.scrollTop - container.clientHeight,
            behavior: 'smooth',
        });
    };

    const scrollDown = () => {
        const container = containerRef.current;
        container.scrollTo({
            top: container.scrollTop + container.clientHeight,
            behavior: 'smooth',
        });
    };

    return (
        <div className="bg-white min-h-screen flex">
            <div className="h-screen w-full flex-1 border-r">
                <div className="w-full h-screen overflow-y-auto  snap-y snap-mandatory scrollbar" ref={containerRef} id="zero-scrollbar">
                    <style>{`
        #zero-scrollbar::-webkit-scrollbar {
          display: none;
        }
        #zero-scrollbar {
          scrollbar-width: none;
          -ms-overflow-style: none;
        }
      `}</style>
                    {feed?.map((review, index) => (
                        <FeedVideo key={index} productInfo={productInfo} data={review} scrollUp={scrollUp} scrollDown={scrollDown} />
                    ))}
                </div>
            </div>
            <div className="h-screen max-w-[25%] w-full py-10 p-5 space-y-5 hidden md:block overflow-y-auto" id="thin-scrollbar">
                <div className="">
                    <img src={productInfo?.imageUrl} alt="product_image" className="w-full max-w-[75%] aspect-square border mx-auto rounded-md" />
                </div>
                <div className="">
                    <p className="text-black font-medium tracking-wide text-2xl font-work first-letter:uppercase">{productInfo?.name}</p>
                </div>
                {productInfo?.affiliateLink && (
                    <div className="">
                        <a href={productInfo?.affiliateLink} rel="noreferrer noopener" target="_blank" className="">
                            <button className="bg-[#090A0B] py-[10px] w-full font-work rounded-md">Buy Now</button>
                        </a>
                    </div>
                )}
                <div className="border-b ">
                    <div className="flex justify-between pb-3 items-center cursor-pointer select-none" onClick={() => setDescriptionActive(!descriptionActive)}>
                        <p className="text-black text-lg font-work">Description</p>
                        <CaretDown color="black" size={28} />
                    </div>
                    {descriptionActive === true && <div className="">
                        <p className="text-black">{productInfo?.description}</p>
                    </div>
                    }
                </div>

            </div>
        </div>
    );
}
