import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { sendAmplitudeData } from "../amplitude";

function CollectorFeed() {
  const [feedItems, setfeed] = useState([]);

  useEffect(() => {
    sendAmplitudeData("feed opened");
  }, []);

  return (
    <div className="px-20 py-10 min-h-screen">
      {/* <div className="flex items-center justify-center">
        <div className="relative bg-[#131418] h-10 w-72  flex items-center justify-center gap-3 px-3 rounded-lg">
          <input type="text" className="w-full h-full bg-transparent appearance-none outline-none flex-1" placeholder="Search feed" />
          <MagnifyingGlass size={28} color="#f7f7f7" className="" />
        </div>
      </div> */}
      <div className="flex  justify-between items-center">
        <p className="text-3xl mb-10">Feed</p>
        <Link to="feed/create">
          <button className="bg-[#8ea9fa] h-10 px-8 cursor-pointer select-none text-white rounded">
            <i className="fas fa-plus mx-2"></i>Create A Feed
          </button>
        </Link>
      </div>
      {feedItems.length === 0 ? (
        <div className="h-96 flex items-center justify-center flex-col">
          <p className=" m-2">your feed is empty create one</p>
          <Link to="feed/create">
            <button className="bg-[#8ea9fa] h-10 px-8 cursor-pointer select-none text-white rounded">
              <i className="fas fa-plus mx-2"></i>Create A feed
            </button>
          </Link>
        </div>
      ) : (
        <div className="flex flex-wrap gap-5 m-2 text-center">
          {feedItems.map((feed, index) => (
            <div
              key={"feed" + index}
              className="rounded w-56   p-2 hover:shadow-md border border-black cursor-pointer"
            >
              <Link to={"/collector/viewfeed/" + feed.design + "/" + feed.id}>
                <div className="relative grid grid-cols-2 shadow shadow-white my-2">
                  <div className="absolute h-full w-full flex items-center justify-center bg-black bg-opacity-60">
                    <p className=" text-lg">{feed.design} View</p>
                  </div>
                  <img
                    src={
                      feed?.reviews[0]?.thumbnail ?? "https://i.pravatar.cc/300"
                    }
                    alt=""
                    className="h-24 m-1 rounded"
                  />
                  <img
                    src={
                      feed?.reviews[1]?.thumbnail ?? "https://i.pravatar.cc/300"
                    }
                    alt=""
                    className="h-24 m-1 rounded"
                  />
                  <img
                    src={
                      feed?.reviews[2]?.thumbnail ?? "https://i.pravatar.cc/300"
                    }
                    alt=""
                    className="h-24 m-1 rounded"
                  />
                  <img
                    src={
                      feed?.reviews[3]?.thumbnail ?? "https://i.pravatar.cc/300"
                    }
                    alt=""
                    className="h-24 m-1 rounded"
                  />
                </div>
              </Link>
              <p className="text-sm">{feed.title}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CollectorFeed;
