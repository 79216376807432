import React, { useEffect, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import Cstats from "./view/Cstats";
import { useStateValue } from "../context/StateProvider";
import { sendAmplitudeData } from "../amplitude";
import { Eye, Hexagon, Plus, ShieldSlash } from "@phosphor-icons/react";
import { toast } from "react-toastify";

function Cdashboard() {
  const [forms, setforms] = useState([]);
  const [loading, setloading] = useState(true);
  const [{ user }, dispatch] = useStateValue();

  const { path } = useRouteMatch();
  const router = useHistory();

  useEffect(() => {
    sendAmplitudeData("campaign dashboard page opened");

    const q = query(
      collection(db, "forms"),
      where("brandemail", "==", user?.email)
    );

    getDocs(q).then((res) => {
      const formsData = res.docs.map((doc) => doc.data());

      // Retrieve the unique product IDs
      const uniqueProductIds = [
        ...new Set(formsData.map((form) => form.prodid)),
      ];

      // Count the number of forms per product ID
      const formsCountPerProductId = uniqueProductIds.map((prodid) => ({
        prodid,
        count: formsData.filter((form) => form.prodid === prodid).length,
      }));

      const uniqueFormsData = formsCountPerProductId.map((formCount) => {
        const matchingForm = formsData.find(
          (form) => form.prodid === formCount.prodid
        );
        return {
          ...matchingForm,
          campaigns: formCount.count,
        };
      });

      setforms(uniqueFormsData);
      setloading(false);
    });
  }, []);

  async function changeRoute() {
    let limit = user?.limit?.campaignLimit ?? 1;
    if ((user?.campaigns ?? forms?.length) >= limit) {
      toast.warning("Campaign limit exceeded, Please upgrade your plan.", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="red" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    router.push(path + "/chooseTemplate");
  }

  if (loading)
    return (
      <div className=" min-h-screen -mt-16 relative flex items-center justify-center overflow-y-auto bg-[#323336] py-16 px-20">
        <p className="">Gettings your forms ready</p>
      </div>
    );

  if (forms.length === 0) {
    return (
      <div className=" min-h-screen -mt-16 relative overflow-y-auto py-16 px-20 ">
        <div className="">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Ffaq%20initial%20login%20image.png?alt=media&token=e461dbfd-68e5-44c8-aeea-69a539e20f54"
            alt="rightImage"
            className="absolute right-0 bottom-0 max-h-[80vh]"
          />
        </div>
        <div className="pt-28">
          <p className="text-6xl font-semibold">Welcome</p>
          <div className="mt-8">
            <p className="tracking-wider font-medium text-xl mb-1">
              What is campaign ?
            </p>
            <p className="tracking-wider font-light">
              Start by creating a video collector. This will let you remotely
              gather videos from any device just by sharing a link.
            </p>
          </div>
          <div className="my-8">
            <button
              className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
              onClick={() => changeRoute()}
            >
              <Plus size={22} color="white" /> Create Campaign
            </button>
          </div>
        </div>
        <Cstats />
      </div>
    );
  } else
    return (
      <div className=" min-h-screen">
        <div className="px-10 py-5">
          <div className="w-full lg:max-w-[70%] mx-auto grid grid-cols-3 gap-5">
            <div className="w-full text-center h-auto py-5 p-3 my-2 border-2 rounded-xl border-white">
              <p className="mx-auto font-medium tracking-wider text-lg">
                Campaigns used
              </p>
              <div className="mt-5 text-3xl">
                <span className="">
                  {user?.campaigns ?? 0} / <span className="">{user?.limit?.campaignLimit ?? 1}</span>
                </span>
              </div>
            </div>
            <div className="w-full text-center h-auto py-5 p-3 my-2 border-2 rounded-xl border-white">
              <p className="mx-auto font-medium tracking-wider text-lg">
                Downloads used
              </p>
              <div className="mt-5  text-3xl">
                <span className="">
                  {user?.downloads ?? 0} / <span className=""> {user?.limit?.downloadLimit === 5000 ? "Unlimited" : user?.limit?.downloadLimit ?? 3}</span>
                </span>
              </div>
            </div>
            <div className="w-full text-center h-auto py-5 p-3 my-2 border-2 rounded-xl border-white">
              <p className="mx-auto font-medium tracking-wider text-lg">
                Comments used
              </p>
              <div className="mt-5  text-3xl">
                <span className="">
                  {user?.comments ?? 0}/ <span className="">{user?.limit?.commentLimit ?? 0}</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between p-10">
          <div className="">
            <p className="text-3xl font-medium">Your Campaigns</p>
            <p className="tracking-wide">
              Use campaigns to collect testimonials from your users.
            </p>
          </div>
          <div className="">
            <button
              className="bg-[#93A8F4] w-fit px-4 py-3 rounded-md appearance-none outline-none hover:bg-[#4611ea] flex items-center justify-center gap-4"
              onClick={() => changeRoute()}
            >
              <Plus size={22} color="white" /> Create Campaign
            </button>
          </div>
        </div>

        <div className="grid  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 w-full p-5 gap-x-6 gap-y-16 pb-10 ">
          {forms?.map((form, index) => (
            <div
              className=" cursor-pointer w-full bg-[#232323] h-[173px] rounded-3xl relative flex flex-col justify-between"
              key={index}
              onClick={() =>
                router.push(path + "/view-product-campaigns/" + form?.prodid)
              }
            >
              <div className="">
                <div className="flex items-center p-3 gap-3">
                  <img
                    src={form?.productimage}
                    alt="thumbnail"
                    className="h-[85px] aspect-square rounded-lg -mt-8"
                  />
                  <div className="">
                    {/* <p className="text-sm text-[#D9D9D9] tracking-wide">Campaign Id: <span className="text-white"> {form?.id}</span></p> */}
                  </div>
                </div>
                <div className="flex justify-between items-center px-3">
                  <div className="">
                    <div className="flex items-center gap-3">
                      <p className="text-sm text-[#D9D9D9] font-work tracking-wide">
                        Product ID
                      </p>
                      <p className="font-work">{form?.prodid}</p>
                    </div>
                    <div className="flex items-center gap-3">
                      <p className="text-sm text-[#D9D9D9] font-work tracking-wide">Campaigns</p>
                      <p className="font-work">{form?.campaigns}</p>
                    </div>
                  </div>
                  <div className="flex flex-col items-center">
                    <div className="h-6 w-6 flex items-center justify-center relative">
                      <Hexagon size={22} weight="light" />
                      <Eye size={10} className="absolute" weight="bold" />
                    </div>
                    <p className="text-[8px] tracking-wide">View</p>
                  </div>
                </div>
              </div>
              <div className="h-20 overflow-clip text-sm font-light tracking-wide py-2 px-3 bg-[#2C2C2C] text-[#D9D9D9] rounded-b-3xl">
                {form?.productname}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}

export default Cdashboard;
