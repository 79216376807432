import React, { useEffect, useState } from "react";
import { getEngagementAnalytics } from "../common/functions";
import { useStateValue } from "../context/StateProvider";
import BarChart from "./BarChart";

function EngagementAnalytics() {
  const [records, setRecords] = useState(null);
  const [base, setbase] = useState("week");
  const [{ brand }] = useStateValue();

  useEffect(() => {
    if (brand.id)
      getEngagementAnalytics(brand.id, base).then((res) => {
        var data = {};
        console.log(res);
        for (const rec of res) {
          data[rec._id] = rec.count;
        }
        setRecords(data);
      });
    else console.log("no id found to call engagement analytics");
  }, [base]);
  console.log(records);
  if (!records)
    return (
      <div className="p-5 h-screen flex items-center flex-col justify-center transform duration-300">
        <p className="pt-10 text-5xl font-bold">Analytics</p>
        <p className=" py-5">
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries, but also the leap into electronic typesetting,
          remaining essentially unchanged. It was popularised in the 1960s with
          the release of Letraset sheets containing
        </p>
        <p className="">
          loading... <i className="fas fa fa-spinner animate-spin"></i>{" "}
        </p>
      </div>
    );
  else
    return (
      <div className="p-5 transform duration-300">
        <div className="h-32 flex items-center">
          <p className=" text-5xl font-bold flex-1">Analytics</p>

          <div className="flex items-center justify-end">
            <button
              onClick={() => setbase("week")}
              className={
                base === "week"
                  ? "rounded-l px-4 py-3 bg-black "
                  : "rounded-l px-4 py-3 bg-black bg-opacity-20"
              }
            >
              This Week
            </button>
            <button
              onClick={() => setbase("month")}
              className={
                base === "month"
                  ? " rounded-r px-4 py-3 bg-black"
                  : "rounded-r px-4 py-3 bg-black bg-opacity-20"
              }
            >
              This Month
            </button>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-5 justify-center items-start">
          <div className="h-96 bg-[#0000009c] p-3 rounded">
            <p className=" py-3 text-xl font-bold">Campaigns</p>
            <BarChart
              records={records}
              filters={[
                "open_campaign",
                "campaign_login_success",
                "user_campaign_submission",
              ]}
              base={base}
            />
          </div>
          <div className=" bg-[#0000009c] p-3 rounded h-96">
            <p className=" py-3 text-xl font-bold">Quiz</p>
            <BarChart
              records={records}
              filters={[
                "open_quiz",
                "quiz_login_success",
                "user_quiz_complete",
              ]}
              base={base}
            />
          </div>
          <div className="bg-[#0000009c] p-3 rounded h-96">
            <p className=" py-3 text-xl font-bold">FAQ</p>
            <BarChart
              records={records}
              filters={["open_faq", "faq_login_success", "faq_login_success"]}
              base={base}
            />
          </div>
          <div className="bg-[#0000009c] p-3 rounded h-96">
            <p className=" py-3 text-xl font-bold">Challenge</p>
            <BarChart
              records={records}
              filters={[
                "open_challenge",
                "challenge_login_success",
                "user_challenge_submited",
              ]}
              base={base}
            />
          </div>
        </div>
      </div>
    );
}

export default EngagementAnalytics;
