import React from "react";

export default function DashboardCard({ text, value }) {
  return (
    <div className="bg-[#131418] shadow-sm shadow-gray-600 h-40 w-full rounded-md flex flex-col justify-between p-5 text-white">
      <p className="font-extralight text-5xl text-[#9a9999]">{value}</p>
      <div className="">
        <p className="font-light text-sm tracking-wider">{text}</p>
      </div>
    </div>
  );
}
