import React from 'react'
import NotLoginHeader from './layouts/NotLoginHeader'
import Notloginfooter from './layouts/Notloginfooter'
import NotLoginHeaderNew from './layouts/NotLoginHeaderNew'
import NotloginfooterNew from './layouts/NotloginfooterNew'

export default function Cancellationpolicy() {
    return (
        <div className="bg-[#101010] ">
            <NotLoginHeaderNew />
            <div className="p-10">
                <p className="text-center text-lg uppercase tracking-wide">Cancellation Policy for MyRevue App</p>
                <p className="py-2 font-work tracking-wide">At MyRevue, we understand that sometimes, brands may need to cancel their subscription . Therefore, we have developed the following cancellation policy to ensure a fair and transparent process for all our brands: </p>
                <p className="py-2 font-work tracking-wide">Subscription Cancellation Brands can cancel their subscription at any time by dropping mail on connect@myrevue.app . Once the cancellation is processed, the user will no longer be billed for the subscription. However, please note that we do not offer refunds for any unused portion of the subscription.</p>
                <p className="py-2 font-work tracking-wide">By using the brand.myrevue.app, brands acknowledge and agree to this cancellation policy. If you have any questions or concerns about the policy, please contact our customer support team.</p>
            </div>
            <NotloginfooterNew />
        </div>
    )
}
