import { Check, Pencil, Trash } from '@phosphor-icons/react'
import moment from 'moment/moment'
import React, { useEffect, useRef, useState } from 'react'
import { toPng } from 'html-to-image';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../firebase';
import { useStateValue } from '../context/StateProvider';
import { useHistory } from 'react-router-dom';

export default function CreateEmailTemplate() {
    const [{ brand, user }, dispatch] = useStateValue();
    const router = useHistory();
    const scriptLoaded = useRef(false);
    const [templateName, setTemplateName] = useState(`New Template - ${moment().format("DD-MM-YYYY")}`);

    useEffect(() => {
        if (!scriptLoaded.current) {
            console.log("Loading script...");

            const script = document.createElement('script');
            script.src = 'https://editor.unlayer.com/embed.js';
            script.async = true;

            script.onload = () => {
                scriptLoaded.current = true; // Set the flag to true after loading
                console.log("Script loaded");
                window.unlayer.init({
                    id: 'editor',
                    projectId: 180217,
                });
            };

            script.onerror = () => {
                console.error("Failed to load script");
            };

            document.body.appendChild(script);
        }
    }, [scriptLoaded]);

    async function exportHtmlJson() {
        try {
            const data = await new Promise((resolve, reject) => {
                window.unlayer.exportHtml((data) => {
                    if (data) {
                        resolve(data);
                    } else {
                        reject(new Error("Failed to save design"));
                    }
                });
            });
            const document = {
                dateAdded: Date.now(),
                status: true,
                templateName: templateName,
                design: JSON.stringify(data.design),
                html: data.html,
                email: user?.email
            }
            addDoc(collection(db, "brandEmailTemplates"), document).then(res => {
                router.push("/admin/emails?tab=templates")
            })
        } catch (error) {
            console.error(error);
        }
    }
    return (
        <div className=''>
            <div className="h-[60px] w-full bg-[#131418] flex justify-between px-5">
                <div className="flex items-center gap-2 w-full flex-1">
                    <Pencil size={22} color='white' />
                    <input type="text" value={templateName} onChange={(event) => setTemplateName(event.target.value)} className="bg-transparent w-full appearance-none outline-none truncate max-w-[500px]" />
                </div>
                <div className="flex items-center gap-3 p-3">
                    <button className="h-full aspect-square bg-[#3d3d3d] rounded-md flex items-center justify-center">
                        <Trash size={18} color='red' />
                    </button>
                    <button onClick={() => exportHtmlJson()} className="h-full w-fit px-5 gap-2 bg-[#3d3d3d] rounded-md flex items-center justify-center">
                        <Check size={18} color='white' />
                        Save template
                    </button>
                </div>
            </div>
            <div id="editor" className='h-[calc(100vh-60px)]'></div>
        </div>
    )
}
