import { List, X } from "phosphor-react";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useStateValue } from "../context/StateProvider";

function NotLoginHeader() {
  const [{ user, }, dispatch] = useStateValue();
  const [navactive, setnavactive] = useState(false)
  return (
    <>

      <nav className="px-[20px] bg-[#121212]">
        <div className="flex justify-between items-center py-3">
          <div className="flex items-center gap-x-7">
            <Link to="/">
              <img
                src={require("../assets/logo/MyRevue Logo elite new.svg").default}
                alt="logo"
                className="h-[40px]  transform scale-150 ml-10"
              />
            </Link>
          </div>

          <div className="lg:flex items-center gap-x-6 text-white hidden">
            <Link to="/whyvideoreviews">Why Video Reviews ?</Link>
            <Link to="/plans">Pricing</Link>
            <Link to="/login">
              <button className="px-5 py-[7px]">
                Log in
              </button>
            </Link>
            <Link to="/signup">
              <button className="graident_border px-5 py-[7px] gradient-border">
                Sign up for free
              </button>
            </Link>
          </div>
          <div className="block lg:hidden">
            {navactive === false ?
              <button className="h-10 w-10" onClick={() => setnavactive(true)}>
                <List size={40} color="#ffffff" />
              </button>
              :
              <button className="h-10 w-10" onClick={() => setnavactive(false)}>
                <X size={40} color="#ffffff" />
              </button>
            }
          </div>
        </div>
      </nav>
      {navactive === true ?
        <div className="bg-[#121212] w-full border-t border-[#80808040]">
          <Link to="/whyvideoreviews">
            <button className="px-5 hover:bg-[#232323] w-full h-11">
              Why Video Reviews ?
            </button>
          </Link>
          <Link to="/plans">
            <button className="px-5 hover:bg-[#232323] w-full h-11">
              Pricing
            </button>
          </Link>
          <Link to="/login">
            <button className="px-5 hover:bg-[#232323] w-full h-11">
              Log in
            </button>
          </Link>
          <div className="flex justify-center py-2">
            <Link to="/signup">
              <button className="graident_border px-10 py-[7px] gradient-border">
                Sign up for free
              </button>
            </Link>
          </div>
        </div>
        :
        null}
    </>
  );
}

export default NotLoginHeader;
