import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { useRef } from "react";
import AWN from "awesome-notifications";
import { useHistory } from "react-router";
import { sendAmplitudeData } from "../amplitude";
import { Link } from "react-router-dom";
import strings from "../assets/Strings";
import { useStateValue } from "../context/StateProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { ShieldSlash } from "@phosphor-icons/react";
function CreateFeed() {
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [design, setDesign] = useState(null);
  const [{ user, brand, reviews, products }, dispatch] = useStateValue();
  const [filters, setfilters] = useState({
    dateFrom: "",
    dateTo: "",
    rating: "",
    product: "",
    sorting: "",
    sub_Cat: ""
  });
  const [subcats, setsubcats] = useState([]);
  let titleRef = useRef();
  let descRef = useRef();
  let router = useHistory();

  const active =
    "cursor-pointer hover:scale-105 transform duration-100  rounded-sm border-4 border-[#8ea9fa] border-opacity-80";
  const passive =
    "cursor-pointer hover:scale-105 transform duration-100  rounded-sm border-4 border-[#8ea9fa] border-opacity-0";

  useEffect(() => {
    sendAmplitudeData("create feed page opened");
    let subcat = [];
    reviews.forEach((rev, index) => {
      subcat.push(rev?.subcategory)
    });
    setsubcats([...new Set(subcat)])

    // async function fetchApprovedContents() {
    //   let headersList = {
    //     "Accept": "*/*",
    //     "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
    //   }

    //   let reqOptions = {
    //     url: `https://brandadminapi-zscu3untuq-el.a.run.app/getHouseContentsCompleted/${brand?.id}`,
    //     method: "GET",
    //     headers: headersList,
    //   }

    //   let response = await axios.request(reqOptions);
    //   console.log(response.data.data);

    // }
    // fetchApprovedContents();
    setLoading(false)
  }, []);
  const handleSelectClick = (e) => {
    if (selected.includes(e.id)) {
      setSelected(selected.filter((r) => r !== e.id));
    } else {
      setSelected([...selected, e.id]);
    }
  };


  const generatefeed = () => {
    if (selected.length < 1) {
      toast.warning("Please select atleast 1 review", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work"
      });
      return
    }

    var temp = [];
    for (const rev of reviews) {
      if (selected.includes(rev.id)) {
        let data = {};
        data.title = rev?.name;
        data.description = rev?.description;
        data.reviewUrl = rev?.reviewUrl;
        data.thumbnail = rev?.thumbnail;
        data.name = rev?.user?.name;
        data.userImage = rev?.user?.imageUrl ?? "https://ui-avatars.com/api/?name=" + rev?.user?.name;
        data.userId = rev?.user?.id;
        data.subcat = rev?.subcategory
        data.id = rev?.id;
        temp.push(data);
      }
    }
    let data = {
      date: Date.now(),
      reviews: temp,
      brandId: brand?.id,
      brandEmail: user?.email
    };



    try {
      addDoc(
        collection(db, "feed"),
        data
      ).then((res) => {
        new AWN().success("feed creted successfully.");
        router.push("/collector/feed");
      });
    } catch (e) {
      toast.warning(e, {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work"
      });
      return;
    }
  };


  function filtersdate(days) {

    const today = Date.now();

    const DaysAgoTimestamp = today - (days * 24 * 60 * 60 * 1000);

    if (!days) {
      setfilters({ ...filters, dateFrom: "", dateTo: "" })
      return
    }
    setfilters({ ...filters, dateFrom: DaysAgoTimestamp, dateTo: today });
  }


  if (loading)
    return (
      <div className="min-h-screen flex flex-col items-center justify-center">
        <i className="fas fa-spinner fa m-2"></i>
        <p className="">Loading...</p>
      </div>
    );
  else if (reviews.length === 0) {
    return (
      <div className="h-[70vh] flex flex-col items-center justify-center text-center">
        <p className="mb-4">
          You have no reviews yet to create a feed. Please create some
          Campaign and collect some reviews.
        </p>
        <Link to="/collector/chooseTemplate">
          <button className="bg-[#8ea9fa] h-10 px-8 cursor-pointer select-none text-white rounded">
            <i className="fas fa-plus mx-2"></i>Create A Campagin
          </button>
        </Link>
      </div>
    );
  } else
    return (
      <div className="min-h-screen flex gap-10 p-10">
        {step === 0 && <div className="h-screen w-96 bg-[#202020] rounded-md">
          <div className="h-12 w-full flex items-center px-5 border-b border-[#80808040]">
            Filters
          </div>
          <div className="mt-5 px-5 space-y-5">
            <div className="">
              <p className="">Date added</p>
              <select className="text-white w-full h-8 mt-2 px-2 text-sm tracking-wide rounded-md appearance-none outline-none bg-[#494949]" onChange={(event) => filtersdate(event.target.value)}>
                <option value="">Please Select</option>
                <option value="7">Last 7 days</option>
                <option value="14">Last 14 days</option>
                <option value="31">Last 1 Month</option>
              </select>
            </div>
            <div className="">
              <p className="">Rating</p>
              <select className="text-white w-full h-8 mt-2 px-2 text-sm tracking-wide rounded-md appearance-none outline-none bg-[#494949]" onChange={(event) => setfilters({ ...filters, rating: event.target.value })}>

                <option value="">Please Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </select>
            </div>
            <div className="">
              <p className="">Product</p>
              <select className="text-white w-full h-8 mt-2 px-2 text-sm tracking-wide rounded-md appearance-none outline-none bg-[#494949]" onChange={(event) => setfilters({ ...filters, product: event.target.value })}>
                <option value="" >Please Select</option>
                {products?.map((product, index) => (
                  <option value={product?.id} key={index}>{product?.name}</option>
                ))}
              </select>
            </div>
            <div className="">
              <p className="">Categories</p>
              <select className="text-white w-full h-8 mt-2 px-2 text-sm tracking-wide rounded-md appearance-none outline-none bg-[#494949]" onChange={(event) => setfilters({ ...filters, sub_Cat: event.target.value })}>
                <option value="" >Please Select</option>
                {subcats?.map((subcat, index) => (
                  <option value={subcat} key={index}>{subcat}</option>
                ))}
              </select>
            </div>
            <div className="">
              <p className="">Sorting</p>
              <select className="text-white w-full h-8 mt-2 px-2 text-sm tracking-wide rounded-md appearance-none outline-none bg-[#494949]" onChange={(event) => setfilters({ ...filters, sorting: event.target.value })}>
                <option value="">Please Select</option>
                <option value="desc">Oldtest to newest</option>
                <option value="asc">Newest to oldtest</option>
              </select>
            </div>
          </div>
        </div>}
        <div
          className={step === 0 ? "hideOut w-full flex-1 rounded-md px-3" : "hideIn"}
        >
          <div className="shadow-lg shadow-black rounded-md  w-full  p-3 mb-3">
            <p className="my-3 text-lg font-semibold">
              Select from MyRevue App Reviews
            </p>
            <div className="flex flex-wrap gap-3 items-center">
              {reviews.filter(val => {
                if (filters.rating === "") {
                  return val
                }
                else if ((val.rating >= filters.rating) && (val.rating <= filters.rating)) {
                  return val
                }
              }).filter(val => {
                if (filters.product === "") {
                  return val
                }
                else if ((filters.product === val?.product?.id)) {
                  return val
                }
              }).filter(val => {
                if (filters.sub_Cat === "") {
                  return val
                }
                else if ((filters.sub_Cat === val?.subcategory)) {
                  return val
                }
              }).sort((a, b) => {
                if (filters.sorting === "") {

                }
                else if (filters.sorting === "asc") {
                  return new Date(b.dateAdded) - new Date(a.dateAdded);
                }
                else if (filters.sorting === "desc") {
                  return new Date(a.shortDate) - new Date(b.shortDate);
                }
              }).filter((item) => {
                if (filters.dateFrom !== "") {
                  let date = new Date(item.shortDate);
                  return date >= new Date(filters.dateFrom) && date <= new Date(filters.dateTo);
                }
                else {
                  return item
                }
              }).map((webr, index) => (
                <div
                  key={"webreview" + index}
                  onClick={() => handleSelectClick(webr)}
                  className={selected.includes(webr.id) ? active : passive}
                >
                  <img
                    src={webr.thumbnail ?? "https://i.pravatar.cc/150"}
                    alt="nopreview_available"
                    className="h-52 aspect-[9/16] object-cover"
                  />
                </div>
              ))}
            </div>
          </div>
          <div className=" text-center">
            <button
              className="bg-[#8ea9fa] px-3 py-2 rounded shadow-sm"
              onClick={generatefeed}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
}

export default CreateFeed;
