import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { db } from "../../firebase";
import { sendAmplitudeData } from "../../amplitude";
import Modalcard from "../../components/modalcard";
import { useStateValue } from "../../context/StateProvider";
import { ShareFat, Pencil, FileCsv, Copy } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";
import QRCodeStyling from "qr-code-styling";

function ViewcampaignFaq() {
  const router = useHistory();
  const [{ user, reviews }, dispatch] = useStateValue();
  const [forms, setforms] = useState([]);
  const [form, setform] = useState({});
  const [loading, setloading] = useState(true);
  const { id } = useParams();
  const [copied, setcopied] = useState(false);
  const [forms_search_text, setforms_search_text] = useState("");
  const [tab, settab] = useState(0);
  const [custombutton, setcustombutton] = useState(false);
  const [buttondetails, setbuttondetails] = useState({
    text: null,
    url: null,
  });

  useEffect(() => {
    if (!user) {
      return router.replace("/login");
    }
    const q = query(collection(db, "faqforms/" + id + "/submissions"));
    getDoc(doc(db, "faqforms/" + id)).then((res) => {
      setform(res.data());
      console.log(res?.data()?.button?.text ?? null);
      setbuttondetails({
        text: res?.data()?.button?.text ?? null,
        url: res?.data()?.button?.url ?? null,
      });
    });

    getDocs(q).then((res) => {
      setforms(res.docs.map((e) => ({ ...e.data(), id: e.id })));
      setloading(false);
    });

    sendAmplitudeData("viewing campaign details", { campaign: id });
  }, []);

  const copy = () => {
    navigator?.clipboard?.writeText(
      window.location.host + "/faquserView/" + id
    );
    setcopied(true);
  };
  const csvString = [
    [
      "Campaign Name",
      "Campaign Url",
      "User Name",
      "User ID",
      "User Email",
      "User Phone",
      form?.questions?.map((question, index) => `Q: ${question?.question}`),
      "Status",
    ],
    ...forms?.map((item, index) => [
      form.campaign_name,
      `https://brand.myrevue.app/faquserView/${item.id}`,
      item?.user_info?.name,
      item?.user_info?.id,
      item?.user_info?.email,
      item?.user_info?.phone,
      item?.reviews?.map((question, index) => question?.reviewUrl),
      item?.substatus,
    ]),
  ]
    ?.map((e) => e.join(","))
    .join("\n");

  async function exportcsv() {
    const data = `data:,${csvString}`;
    const filename = `${form?.campaign_name || form?.productname}.csv`;
    const aTag = document.createElement("a");

    aTag.href = data;
    aTag.download = filename;
    aTag.click();
  }

  async function addCustomButton() {
    if (!buttondetails.text) {
      toast.warning("Please fill all details", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    if (!buttondetails.url) {
      toast.warning("Please fill all details", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
      return;
    }
    await updateDoc(doc(db, "faqforms/" + id), {
      button: {
        ...buttondetails,
      },
    }).then((res) => {
      console.log("updated");
      // setbuttondetails({
      //   text: null,
      //   url: null
      // });
      setcustombutton(false);
    });
  }
  const qrCode = new QRCodeStyling({
    width: 1000,
    height: 1000,
    image:
      "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fmyrevue%20only%20logo.png?alt=media&token=d1d344f7-ce12-44c1-826d-3963af17ad94",
    data: "http://brand.myrevue.app/faquserview/" + id,
    margin: 20,
    qrOptions: { typeNumber: "0", mode: "Byte", errorCorrectionLevel: "Q" },
    imageOptions: { hideBackgroundDots: true, imageSize: 0.4, margin: 0 },
    dotsOptions: { type: "extra-rounded", color: "#000000" },
    backgroundOptions: { color: "#ffffff" },
    dotsOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#6a1a4c",
        color2: "#6a1a4c",
        rotation: "0",
      },
    },
    cornersSquareOptions: { type: "extra-rounded", color: "#ff005c" },
    cornersSquareOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    cornersDotOptions: { type: "", color: "#ff005c" },
    cornersDotOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#000000",
        color2: "#000000",
        rotation: "0",
      },
    },
    backgroundOptionsHelper: {
      colorType: { single: true, gradient: false },
      gradient: {
        linear: true,
        radial: false,
        color1: "#ffffff",
        color2: "#ffffff",
        rotation: "0",
      },
    },
  });

  async function downloadQRCode() {
    qrCode.download({
      name: id,
      extension: "png",
    });
  }
  return (
    <div className=" min-h-screen text-white scroll-smooth ">
      {custombutton === true && (
        <Modalcard close={setcustombutton}>
          <div className="h-96 w-full md:w-[500px] bg-[#ffffff] p-4">
            <div className="">
              <p className="text-center text-black text-lg">
                Custom button details
              </p>
            </div>
            <div className="mt-5">
              <p className="text-black">Button Text</p>
              <input
                type="text"
                onChange={(event) =>
                  setbuttondetails({
                    ...buttondetails,
                    text: event.target.value,
                  })
                }
                defaultValue={buttondetails?.text}
                className="w-full h-10 border placeholder:text-sm font-work px-3 text-black"
                placeholder="ex: Product Name"
              />
            </div>
            <div className="mt-5">
              <p className="text-black">Redirect Link</p>
              <input
                type="text"
                onChange={(event) =>
                  setbuttondetails({
                    ...buttondetails,
                    url: event.target.value,
                  })
                }
                defaultValue={buttondetails?.url}
                className="w-full h-10 border placeholder:text-sm font-work px-3 text-black"
                placeholder="ex: https://brand.myrevue.app"
              />
            </div>

            <div className="mt-5">
              <p className="text-black mb-2">Button Preview</p>
              <a
                href={buttondetails?.url ?? "https://brand.myrevue.app"}
                target="_blank"
                rel="noreferrer"
                className=""
              >
                <button
                  className={`button cursor-pointer w-full h-[50px] bg-[#00000099] rounded-[44px] min-w-[200px] mx-auto text-white flex items-center pl-[16px] pr-[12px] gap-3 border-white border border-opacity-30`}
                >
                  <div className="flex-1 text-left leading-[18px] font-light tracking-wide">
                    <p className="">{buttondetails?.text ?? "Preview"}</p>
                  </div>
                  <div className="h-9 w-9 flex items-center justify-center font-medium">
                    <ShareFat size={24} color="white" />
                  </div>
                </button>
              </a>
            </div>
            <div className="mt-5">
              <button
                className="w-full h-10 border placeholder:text-sm font-work px-3 hover:text-black hover:bg-white bg-black text-white"
                onClick={() => addCustomButton()}
              >
                Submit
              </button>
            </div>
          </div>
        </Modalcard>
      )}
      <div className="flex items-center justify-between p-10 ">
        {/* <div className="bg-[#383A3F] h-20  rounded-lg drop-shadow-lg w-full flex-1 flex items-center px-5">
          <div className="flex gap-x-6 items-center w-full">
            <p className="font-light tracking-wider">Shareable link:</p>
            <input
              type="text"
              className="bg-transparent flex-1 w-full font-light tracking-wider"
              defaultValue={window?.location?.host + "/faquserview/" + id}
              onFocus={() => copy()}
            />
            {copied === true && (
              <button className="bg-green-500 bg-opacity-80 w-32 py-2 rounded outline-none appearance-none">
                ! Copied
              </button>
            )}
          </div>

        </div> */}
        <div className="flex-1 flex items-center justify-between gap-10">
          {form?.productimage && (
            <div className="rounded-md h-28 w-28">
              <img
                src={form?.productimage}
                alt="product_image"
                className="rounded-md"
              />
            </div>
          )}
          <div className="bg-[#3B3D42] h-auto rounded-lg drop-shadow-lg w-full flex-1 flex flex-col justify-center items-center px-5 ml-10 gap-3 py-5">
            <p className="text-center text-[#D9D9D9] tracking-wide">
              Shareable link:
            </p>
            <div className="flex items-center gap-5">
              <div className="items-center bg-[#2E3036] w-fit flex gap-10 rounded-full pl-10">
                <p>{window?.location?.host + "/faquserview/" + id}</p>
                <button
                  className="hover:bg-green-500 bg-opacity-80 p-2 m-1 rounded-full outline-none appearance-none"
                  onClick={() => copy()}
                >
                  <Copy size={28} color="white" />
                </button>
              </div>
              <button
                className="bg-[#2E3036] hover:bg-blue-500 bg-opacity-80 p-2 m-1 rounded-full outline-none appearance-none flex items-center gap-3 px-5"
                onClick={() => downloadQRCode()}
              >
                Download QR Code
                <Copy size={28} color="white" />
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <p className="">Gettings your submissions ready </p>
        </div>
      ) : (
        <div className="flex justify-between items-start  px-10">
          <div className="flex-1 w-full mr-10">
            <div className="w-full  grid grid-cols-5">
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${
                  tab === 0
                    ? "border-b border-[#D9D9D9] text-white"
                    : "bg-transparent text-[#808080]"
                }`}
                onClick={() => {
                  settab(0);
                  setforms_search_text("");
                }}
              >
                All ({forms.length})
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${
                  tab === 1
                    ? "border-b border-[#D9D9D9] text-white"
                    : "bg-transparent text-[#808080]"
                }`}
                onClick={() => {
                  settab(1);
                  setforms_search_text("pending");
                }}
              >
                Pending (
                {
                  forms.filter((val) => {
                    if (val.substatus === "pending" || !val?.substatus) {
                      return val;
                    }
                  }).length
                }
                )
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${
                  tab === 2
                    ? "border-b border-[#D9D9D9] text-white"
                    : "bg-transparent text-[#808080]"
                }`}
                onClick={() => {
                  settab(2);
                  setforms_search_text("In Review");
                }}
              >
                In Review (
                {
                  forms.filter((val) => {
                    if (val.substatus === "In Review") {
                      return val;
                    }
                  }).length
                }
                )
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${
                  tab === 3
                    ? "border-b border-[#D9D9D9] text-white"
                    : "bg-transparent text-[#808080]"
                }`}
                onClick={() => {
                  settab(3);
                  setforms_search_text("published");
                }}
              >
                Published (
                {
                  forms.filter((val) => {
                    if (val.substatus === "published") {
                      return val;
                    }
                  }).length
                }
                )
              </button>
              <button
                className={`w-full h-10  appearance-none outline-none tracking-wide transition-all ${
                  tab === 4
                    ? "border-b border-[#D9D9D9] text-white"
                    : "bg-transparent text-[#808080]"
                }`}
                onClick={() => {
                  settab(4);
                  setforms_search_text("rejected");
                }}
              >
                Rejected (
                {
                  forms.filter((val) => {
                    if (val.substatus === "rejected") {
                      return val;
                    }
                  }).length
                }
                )
              </button>
            </div>
            <div className="flex flex-1  flex-wrap gap-8 mt-10">
              {forms
                .filter((val) => {
                  if (forms_search_text === "") {
                    return val;
                  } else if (
                    val?.substatus
                      ?.toLowerCase()
                      .includes(forms_search_text?.toLowerCase()) ||
                    !val?.substatus
                  ) {
                    return val;
                  }
                })
                .map((doc, index) => (
                  <Link
                    to={"/engagement/view-faqsubmission/" + id + "/" + doc.id}
                    key={index}
                  >
                    <div
                      key={index}
                      className="bg-[#383A3F] aspect-[12/16] w-60  rounded-lg drop-shadow-lg relative flex  justify-center"
                    >
                      <div className="h-full w-full  bg-[#42454B] absolute rounded-lg  shadow-2xl bg-opacity-0">
                        <div className="right-0 float-right m-2 tracking-wide ">
                          {(doc?.substatus === "pending" ||
                            !doc?.substatus) && (
                            <div className="bg-[#dea513]  px-5 py-1 rounded">
                              Pending
                            </div>
                          )}
                          {doc?.substatus === "In Review" && (
                            <div className="bg-orange-500  px-5 py-1 rounded">
                              {doc?.substatus}
                            </div>
                          )}
                          {doc?.substatus === "rejected" && (
                            <div className="bg-red-500  px-5 py-1 rounded">
                              {doc?.substatus}
                            </div>
                          )}
                          {doc?.substatus === "published" && (
                            <div className="bg-green-500  px-5 py- rounded">
                              {doc?.substatus}
                            </div>
                          )}
                        </div>
                        <div className="shadow-top bottom-0 absolute rounded-b-lg pb-2 bg-gradient-to-b from-transparent via-[#000000ba] to-[#000000] w-full">
                          <p className="px-1 text-xs tracking-wider font-light">
                            Campaign Name :{" "}
                            {doc?.campaign_info?.campaign_name ??
                              form?.campaign_name}
                          </p>
                          <p className="px-1 text-xs tracking-wider font-light">
                            Reviewer : {doc?.user_info?.name}
                          </p>
                          {/* <p className="px-1 text-xs tracking-wider font-light">
                            Type : {doc?.reviewUrl !== null ? "Video" : "Text"}
                          </p> */}
                        </div>
                      </div>
                      <div className="aspect-[12/16] w-60">
                        {!doc?.thumbnailurl ? (
                          <video
                            src={doc?.reviews[0]?.reviewUrl}
                            className="object-cover h-full w-full rounded-lg"
                          ></video>
                        ) : (
                          <img
                            src={doc?.thumbnailurl}
                            className="object-cover h-full w-full rounded-lg"
                            alt="thumbnail"
                          ></img>
                        )}
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
          </div>
          <div className="w-96 bg-[#383A3F] p-5 rounded-md mb-10">
            <div className="flex flex-col items-center justify-center pb-5 gap-3">
              <button
                className="bg-[#2E3036] hover:bg-[#151516] flex items-center gap-8 h-12 rounded-full px-5 w-fit"
                onClick={() => setcustombutton(true)}
              >
                <Pencil size={28} color="#ffffff" />
                {!form?.button ? "Add" : "Edit"} Custom Faq Button
              </button>
              <button
                className="bg-[#438B4E] hover:bg-[#25da40]  flex items-center gap-8 h-12 rounded-full px-5 w-fit"
                onClick={() => exportcsv()}
              >
                <FileCsv size={28} color="#ffffff" />
                Generate CSV
              </button>
            </div>

            <p className="text-center font-light tracking-wider text-lg w-full border-b pb-2 border-[#9b9b9b] mb-5">
              Campaign Details
            </p>
            <p className="font-light tracking-wide">
              Campaign Name : {form.campaign_name}
            </p>
            <p className="font-light tracking-wide">Campaign id : {form.id}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default ViewcampaignFaq;
