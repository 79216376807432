import React, { useEffect, useState } from "react";
import { MagnifyingGlass, Bell, ArrowElbowRight, X } from "phosphor-react";
import DashboardCard from "../components/dashboardCard";
import { Link, useHistory } from "react-router-dom";
import { collection, getDocs, limit, orderBy, query, } from "firebase/firestore";
import { db } from "../firebase";
import Sidemodal from "../components/sidemodal";
import { useStateValue } from "../context/StateProvider";
import AnalyticsCard from "../components/analyticsCard";
import { ArrowRight, ShieldSlash, VideoCamera } from "@phosphor-icons/react";
import { toast } from "react-toastify";
import axios from "axios";
import Modalcard from "../components/modalcard";

export default function Home() {
  const [{ user, products, brand, reviews, notifications, flicks }, dispatch] = useStateValue();
  const [most_reviews, setmost_reviews] = useState([]);
  const [top_rated, settop_rated] = useState([]);
  const [topProduct, setTopProduct] = useState([]);
  const [notification, setnotification] = useState(false);

  const [querytext, setquerytext] = useState("");
  const [viewsData, setviewsData] = useState([])
  const [flicksData, setFlicksData] = useState([])
  const [reviewsData, setReviewsData] = useState([])
  const [learnModal, setlearnModal] = useState(false);
  const [youtubeModal, setyoutubeModal] = useState(false);
  const [youtubeLink, setyoutubeLink] = useState(null);

  const [totalViews, setTotalViews] = useState(0);


  const router = useHistory();

  useEffect(() => {
    const views_arr = [];

    reviews?.map((r) => views_arr.push(r.views));
    const mixedSum = (arr = []) => {
      let sum = 0;
      for (let i = 0; i < arr.length; i++) {
        const el = arr[i];
        sum += +el;
      }
      return sum;
    };
    dispatch({ type: "SET_BRAND", brand: { ...brand, views: mixedSum(views_arr) } })
    if (products) {
      let b = Array.from(products);
      setTopProduct(b.sort((a, b) => a.views - b.views));
      settop_rated(b.sort((a, b) => a.rating - b.rating));
      setmost_reviews(b.sort((a, b) => b.reviews - a.reviews));
    }
  }, [user]);

  useEffect(() => {
    Promise.all([getnotifications()])
  }, [user]);

  useEffect(() => {
    async function getReviewsData() {
      try {
        let headersList = {
          "Accept": "*/*",
          "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
        }

        let reqOptions = {
          url: `https://brandadminapi-zscu3untuq-el.a.run.app/getPastWeekFlicksCount/${brand?.id}`,
          method: "GET",
          headers: headersList,
        }

        let response = await axios.request(reqOptions);
        const croppedData = response?.data?.data?.map((item) => {
          const date = new Date(item._id);
          const croppedDate = date.toLocaleDateString(undefined, {
            month: "short",
            day: "numeric",
          });
          return {
            date: croppedDate,
            count: item.count,
          };
        });
        croppedData.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        });
        setFlicksData(croppedData);
      } catch (error) {
        toast.warning(error, {
          position: "top-right",
          icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
          bodyClassName: "text-sm tracking-wide font-work"
        });
      }
    }
    async function getFlicksData() {
      try {
        let headersList = {
          "Accept": "*/*",
          "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
        }

        let reqOptions = {
          url: `https://brandadminapi-zscu3untuq-el.a.run.app/getPastWeekReviewsCount/${brand?.id}`,
          method: "GET",
          headers: headersList,
        }

        let response = await axios.request(reqOptions);
        const croppedData = response.data.data.map((item) => {
          const date = new Date(item._id);
          const croppedDate = date.toLocaleDateString(undefined, {
            month: "short",
            day: "numeric",
          });
          return {
            date: croppedDate,
            count: item.count,
          };
        });
        croppedData.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        });
        setReviewsData(croppedData);
      } catch (error) {
        toast.warning(error, {
          position: "top-right",
          icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
          bodyClassName: "text-sm tracking-wide font-work"
        });
      }
    }
    async function getViewData() {
      try {
        let headersList = {
          "Accept": "*/*",
          "Authorization": "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN"
        }

        let reqOptions = {
          url: `https://brandadminapi-zscu3untuq-el.a.run.app/getPastWeekViewsCount/${brand?.id}`,
          method: "GET",
          headers: headersList,
        }

        let response = await axios.request(reqOptions);
        setTotalViews(response.data.data.totalViews)
        const croppedData = response?.data?.data?.graph.map((item) => {
          const date = new Date(item._id);
          const croppedDate = date.toLocaleDateString(undefined, {
            month: "short",
            day: "numeric",
          });
          return {
            date: croppedDate,
            count: item.count,
          };
        });
        croppedData.sort((a, b) => {
          const dateA = new Date(a.date);
          const dateB = new Date(b.date);
          return dateA - dateB;
        });
        setviewsData(croppedData);
      } catch (error) {
        toast.warning(error, {
          position: "top-right",
          icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
          bodyClassName: "text-sm tracking-wide font-work"
        });
      }
    }

    Promise.all([getReviewsData(), getFlicksData(), getViewData()])

  }, [brand?.id])


  async function getnotifications() {
    if (notifications !== null) {
      return;
    }
    const notifications_query = query(
      collection(db, `brandAdmin/${user?.email}/notification`),
      orderBy("time", "desc"),
      limit(50)
    );
    await getDocs(notifications_query).then((res) =>
      dispatch({ type: "SET_NOTIFICATIONS", notifications: res.docs.map((doc) => doc.data()) })
    ).catch(err => console.log(err))

    return true;
  }
  var viewsOptions = {
    series: [
      {
        name: 'Views Count',
        data: viewsData.map((views) => views.count),
      },
    ],

    options: {
      chart: {
        type: 'area',
        toolbar: {
          show: false, // Hide the toolbar (which includes zoom in, zoom out, etc.)
        },
      },
      colors: ["#5b478f",],
      stroke: {
        curve: 'smooth', // Use 'straight' curve instead of 'smooth'
        width: 3, // Set the line width to 2
        show: true, // Show the stroke (border) above the graph points
        colors: ['#855CF8'],
      },
      xaxis: {
        categories: viewsData.map((views) => views.date),
      },
      markers: {
        show: false, // Hide the data point markers
      },
      legend: {
        show: false, // Hide the legend
      },
      tooltip: {
        theme: 'dark', // Set the tooltip theme to dark
        style: {
          background: '#000000', // Change the background color to black
          color: '#ffffff', // Change the text color to white
        },
      },
      dataLabels: {
        enabled: false, // Disable the data labels
      },
      grid: {
        show: false
      }
    }
  }

  var reviewsOptions = {
    series: [
      {
        name: 'Reviews Posted',
        data: reviewsData.map((views) => views.count),
      },
    ],

    options: {
      chart: {
        type: 'area',
        toolbar: {
          show: false, // Hide the toolbar (which includes zoom in, zoom out, etc.)
        },
      },
      colors: ["#5b478f",],
      stroke: {
        curve: 'smooth', // Use 'straight' curve instead of 'smooth'
        width: 3, // Set the line width to 2
        show: true, // Show the stroke (border) above the graph points
        colors: ['#855CF8'],
      },
      xaxis: {
        categories: reviewsData.map((views) => views.date),
      },
      markers: {
        show: false, // Hide the data point markers
      },
      legend: {
        show: false, // Hide the legend
      },
      tooltip: {
        theme: 'dark', // Set the tooltip theme to dark
        style: {
          background: '#000000', // Change the background color to black
          color: '#ffffff', // Change the text color to white
        },
      },
      dataLabels: {
        enabled: false, // Disable the data labels
      },
      grid: {
        show: false
      }
    }
  }
  var flicksOptions = {
    series: [
      {
        name: 'FLicks Posted',
        data: flicksData.map((views) => views.count),
      },
    ],

    options: {
      chart: {
        type: 'area',
        toolbar: {
          show: false, // Hide the toolbar (which includes zoom in, zoom out, etc.)
        },
      },
      colors: ["#5b478f",],
      stroke: {
        curve: 'smooth', // Use 'straight' curve instead of 'smooth'
        width: 3, // Set the line width to 2
        show: true, // Show the stroke (border) above the graph points
        colors: ['#855CF8'],
      },
      xaxis: {
        categories: flicksData.map((views) => views.date),
      },
      markers: {
        show: false, // Hide the data point markers
      },
      legend: {
        show: false, // Hide the legend
      },
      tooltip: {
        theme: 'dark', // Set the tooltip theme to dark
        style: {
          background: '#000000', // Change the background color to black
          color: '#ffffff', // Change the text color to white
        },
      },
      dataLabels: {
        enabled: false, // Disable the data labels
      },
      grid: {
        show: false
      }
    }
  }
  async function closeyoutubemodal() {
    setyoutubeLink(null)
    setyoutubeModal(false)
  }

  return (
    <div className="p-5">
      {youtubeModal === true &&
        <Modalcard close={closeyoutubemodal} zindex={"z-[150]"}>
          <div className="h-[70vh] aspect-video relative">
            <div className="absolute right-0 top-0 -mx-16">
              <button onClick={() => closeyoutubemodal()} >
                <X size={32} color="white" />
              </button>
            </div>
            <iframe
              src={`https://www.youtube.com/embed/${youtubeLink}`}
              className="h-full w-full"
              title="YouTube video player"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; "
              allowFullScreen
              allowTransparency
            ></iframe>
          </div>
        </Modalcard>
      }
      {notification === true ? (
        <Sidemodal close={setnotification}>
          <div className="h-screen w-96 bg-[#1f2024]  ">
            <div className="h-14 border-b w-full border-[#ececec30] flex items-center justify-between pl-5 pr-1 text-2xl text-white font-extralight tracking-wider">
              Notifications
              <button
                onClick={() => setnotification(false)}
                className="h-12 w-12 hover:bg-background flex items-center justify-center"
              >
                <X size={24} color="white" />
              </button>
            </div>
            {notifications?.map((notification, index) => (
              <div
                className="h-[4.5rem] w-full hover:bg-[#131418] px-2 flex items-center my-1 cursor-pointer"
                key={index}
              >
                <div className="flex items-start gap-x-3">
                  <img
                    src={notification.imageUrl}
                    alt=""
                    crossOrigin="anonymous"
                    className="h-14"
                  />
                  <p className="text-white text-xs tracking-wide">
                    {notification?.message}
                  </p>
                </div>
              </div>
            ))}
            {notifications?.length === 0 && (
              <div className="flex items-center justify-center h-full w-full text-[#808080]">
                No new notifications available
              </div>
            )}
          </div>
        </Sidemodal>
      ) : null}
      {learnModal === true ? (
        <Sidemodal close={setlearnModal}>
          <div className="h-screen w-[500px] bg-[#1f2024]  ">
            <div className="h-14 border-b w-full border-[#ececec30] flex items-center justify-between pl-5 pr-1 text-2xl text-white font-extralight tracking-wider">
              Video Guides
              <button
                onClick={() => setlearnModal(false)}
                className="h-12 w-12 hover:bg-background flex items-center justify-center"
              >
                <X size={24} color="white" />
              </button>
            </div>
            <div className=" space-y-3 w-full text-[#ffffff] font-work p-3">

              <div className="w-full flex gap-3 hover:bg-[#131418] cursor-pointer group" onClick={() => { setyoutubeModal(true); setyoutubeLink(`umprSgFtBW0`) }} >
                <div className="w-40 aspect-video p-2" >
                  <img src="https://img.youtube.com/vi/umprSgFtBW0/maxresdefault.jpg" alt="1" className="w-full aspect-video rounded" />
                </div>
                <div className="w-full flex-1 p-2">
                  <p className=" font-work text-sm tracking-wide">How To Update Your Brand Logo, Cover Image & Apply For Verification On MyRevue Elite</p>
                  <div className="flex justify-between">
                    <p className="text-[#808080] font-work">1 min</p>
                    <ArrowRight size={20} color="#808080" className="group-hover:block hidden" />
                  </div>
                </div>
              </div>
              <div className="w-full flex gap-3 hover:bg-[#131418] cursor-pointer group" onClick={() => { setyoutubeModal(true); setyoutubeLink(`kedJke7YJj8`) }} >
                <div className="w-40 aspect-video p-2">
                  <img src="https://img.youtube.com/vi/kedJke7YJj8/maxresdefault.jpg" alt="1" className="w-full aspect-video rounded" />
                </div>
                <div className="w-full flex-1 p-2">
                  <p className=" font-work text-sm tracking-wide">Create Your First Campaign With MyRevue Elite</p>
                  <div className="flex justify-between">
                    <p className="text-[#808080] font-work"> 6 min</p>
                    <ArrowRight size={20} color="#808080" className="group-hover:block hidden" />
                  </div>
                </div>
              </div>
              <div className="w-full flex gap-3 hover:bg-[#131418] cursor-pointer group" onClick={() => { setyoutubeModal(true); setyoutubeLink(`-rQTRNH_17g`) }} >
                <div className="w-40 aspect-video p-2">
                  <img src="https://img.youtube.com/vi/-rQTRNH_17g/maxresdefault.jpg" alt="1" className="w-full aspect-video rounded" />
                </div>
                <div className="w-full flex-1 p-2">
                  <p className=" font-work text-sm tracking-wide">How To Get A Video On MyRevue Elite</p>
                  <div className="flex justify-between">
                    <p className="text-[#808080] font-work">7 min</p>
                    <ArrowRight size={20} color="#808080" className="group-hover:block hidden" />
                  </div>
                </div>
              </div>
              <div className="w-full flex gap-3 hover:bg-[#131418] cursor-pointer group" onClick={() => { setyoutubeModal(true); setyoutubeLink(`_KiikLmcNYA`) }} >
                <div className="w-40 aspect-video p-2">
                  <img src="https://img.youtube.com/vi/_KiikLmcNYA/maxresdefault.jpg" alt="1" className="w-full aspect-video rounded" />
                </div>
                <div className="w-full flex-1 p-2">
                  <p className=" font-work text-sm tracking-wide">How To Approve Applications Of Creators On MyRevue Elite</p>
                  <div className="flex justify-between">
                    <p className="text-[#808080] font-work">3 min</p>
                    <ArrowRight size={20} color="#808080" className="group-hover:block hidden" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Sidemodal>
      ) : null}
      <div className="flex items-center justify-between pb-5">
        <div className="relative h-10 w-80 flex items-center">
          <div className="absolute right-3">
            <MagnifyingGlass size={24} color="white" />
          </div>
          <form onSubmit={() => router.push(`/admin/search/${querytext}`)}>
            <input
              type="text"
              onChange={(e) => setquerytext(e.target.value)}
              className="bg-bg_highlight h-10 pr-12 rounded w-80 appearance-none outline-none pl-3 text-white placeholder:text-[#606165] font-light placeholder:font-normal text-sm tracking-wide"
              placeholder="Search products"
            />
          </form>
        </div>
        <div className="flex items-center divide-x-[2px] divide-[#282a2d]">
          <div className="">
            <div className="mx-3 h-10 w-52">
              <Link
                to={{
                  pathname: `https://www.myrevue.app/brand/${brand?.name?.replaceAll(" ", "-")}/${brand?.id}`,
                }}
                target="_blank"
              >
                <button className="h-10 w-52 rounded flex justify-between items-center px-3 border-[2px] border-[#707378]">
                  <p className="w-44 truncate text-white">
                    https://myrevue.app/brand/{brand?.name?.replaceAll(" ", "-").replaceAll("&", "%26").replaceAll(",", "%2C")}/{brand?.id}
                  </p>
                  <ArrowElbowRight size={24} color="white" />
                </button>
              </Link>
            </div>
          </div>
          <div className="">
            <div className="mx-3 h-10 w-fit">
              <button onClick={() => setlearnModal(true)} className="h-10 w-fit rounded flex justify-between items-center px-3 border-[2px] border-[#707378]">
                <VideoCamera size={24} color="white" />
                <p className="w-fit px-2 truncate text-white">
                  Video Guides
                </p>
              </button>
            </div>
          </div>
          <div className="">
            <div
              onClick={() => setnotification(true)}
              className="h-10 w-10 ml-3 mr-3 flex items-center justify-center text-[#606165] hover:text-white cursor-pointer"
            >
              <Bell size={30} color="white" />
            </div>
          </div>
          <div className="">
            <Link to="/admin/settings">
              <div className="bg-black ml-3 mr-3 h-10 w-10 rounded-full flex items-center justify-center">
                <img
                  src={
                    brand?.imageUrl ??
                    "https://ui-avatars.com/api/?name=" + brand?.name
                  }
                  alt=""
                  crossOrigin="anonymous"
                  className="h-full w-full rounded-full"
                />
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="my-5 grid grid-cols-4  grid-flow-row gap-5">
        <Link to={"/admin/reviews"}>
          <DashboardCard
            value={reviews?.length}
            text="Total reviews collected"
          />
        </Link>
        <DashboardCard
          value={user?.campaigns ?? 0}
          text="Total reviews from campaign"
        />


        <Link to={"/admin/reviews"}>
          <DashboardCard
            value={reviews?.length}
            text="Total reviews from app"
          />
        </Link>
        <Link to={"/admin/experiences"}>
          <DashboardCard
            value={0}
            text="Total experiences"
          />
        </Link>
        <AnalyticsCard options={reviewsOptions} value={brand?.reviews} text="Total reviews published" />
        <AnalyticsCard options={viewsOptions} value={totalViews} text="Total views" />
        <AnalyticsCard options={flicksOptions}
          value={flicks?.length}
          text="Total flicks"
        />
        <Link to={"/admin/products"}>
          <DashboardCard value={products?.length} text="Total products" />
        </Link>

        <DashboardCard
          value={brand?.subscribes ?? 0}
          text="Total subscribers"
        />
        <div className="cols-span-2"></div>


      </div>

      <div className="grid grid-cols-3 mt-10 gap-5">
        <div
          className="w-full bg-[#131418] rounded-md text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600"
          id="thin-scrollbar"
        >
          <p className="sticky top-0 bg-[#131418] text-lg tracking-normal border-b border-[#939393] px-5 py-3 mb-3 text-[#939393]">
            Top products
          </p>
          <ul className="">
            {topProduct?.slice(0, 20)?.map((product, index) => (
              <li
                onClick={() => router.push("/admin/viewproduct/" + product?.id)}
                className="flex items-start gap-x-5 my-3 hover:bg-background px-5 py-2 cursor-pointer"
                key={index}
              >
                <div className="flex items-center gap-x-3">
                  <p className="text-lg w-5">{index + 1}:</p>
                  <img
                    src={product?.imageUrl}
                    crossOrigin="anonymous"
                    alt=""
                    className="h-12 w-12 rounded"
                  />
                </div>
                <p className="text-base text-left tracking-wide  flex-1">
                  {product?.name}
                </p>
              </li>
            ))}
          </ul>
        </div>

        <div
          className="w-full bg-[#131418] rounded-md  text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600"
          id="thin-scrollbar"
        >
          <p className="sticky top-0 bg-[#131418] text-lg tracking-normal border-b border-[#939393] px-5 py-3 mb-3 text-[#939393]">
            Top rated products
          </p>
          <ul className="">
            {top_rated?.slice(0, 20)?.map((product, index) => (
              <li
                onClick={() => router.push("/admin/viewproduct/" + product?.id)}
                className="flex items-start gap-x-5 my-3 hover:bg-background px-5 py-2 cursor-pointer"
                key={index}
              >
                <div className="flex items-center gap-x-3">
                  <p className="text-lg w-5">{index + 1}:</p>
                  <img
                    crossOrigin="anonymous"
                    src={product?.imageUrl}
                    alt=""
                    className="h-12 w-12 rounded"
                  />
                </div>
                <p className="text-base text-left tracking-wide  flex-1">
                  {product?.name}
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div
          className="w-full bg-[#131418] rounded-md  text-white font-light max-h-96 overflow-y-auto shadow-sm shadow-gray-600"
          id="thin-scrollbar"
        >
          <p className="sticky top-0 bg-[#131418] text-lg tracking-normal border-b border-[#939393] px-5 py-3 mb-3 text-[#939393]">
            Most reviewed product
          </p>
          <ul className="">
            {most_reviews?.slice(0, 20)?.map((product, index) => (
              <li
                onClick={() => router.push("/admin/viewproduct/" + product?.id)}
                className="flex items-start gap-x-5 my-3 hover:bg-background px-5 py-2 cursor-pointer"
                key={index}
              >
                <div className="flex items-center gap-x-3">
                  <p className="text-lg w-5">{index + 1}:</p>
                  <img
                    src={product?.imageUrl}
                    alt=""
                    crossOrigin="anonymous"
                    className="h-12 w-12 rounded"
                  />
                </div>
                <p className="text-base text-left tracking-wide  flex-1">
                  {product?.name}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
