import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  increment,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../firebase";
import AWN from "awesome-notifications";
import { nanoid } from "nanoid";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
  uploadString,
} from "firebase/storage";
import {
  getAuth,
  GoogleAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import ReactStars from "react-rating-stars-component";
import { sendAmplitudeData } from "../../amplitude";
import { useRef } from "react";
import Userviewfileupload from "../../components/Userviewfileupload";
import axios from "axios";
import { toast } from "react-toastify";
import { ShieldSlash } from "phosphor-react";
import { CircleNotch } from "@phosphor-icons/react";
import { logEngagementHook } from "../../common/functions";

function MUserView() {
  const [cstep, setStep] = useState(0);
  const { form } = useParams();
  const [device, setdevice] = useState("");
  //   const router = useHistory();
  const [loading, setloading] = useState(true);
  const [userRsp, setuserRsp] = useState({});
  const [loaded, setloaded] = useState(false);
  const [formData, setformData] = useState({});
  const [product_details, setproduct_details] = useState({});
  const [product_attributes, setproduct_attributes] = useState([]);
  const [uploading, setuploading] = useState(false);
  const [review_info, setreview_info] = useState({});
  const [progress_video, setprogress_video] = useState(0);
  const [user, setuser] = useState({});
  const [user_details, setuser_details] = useState({});
  const [phone_modal, setphone_modal] = useState(false);
  const [phone_number, setphone_number] = useState("");
  const [countrycode, setcountrycode] = useState(91);
  const [getotp, setgetotp] = useState(false);
  const [otp, setotp] = useState("");
  const [valid, setValid] = useState(true);
  const [create_user_phone, setcreate_user_phone] = useState(false);
  const [user_info, setuser_info] = useState({});
  const [error, seterror] = useState({});
  const [otp_verifying, setotp_verifying] = useState(false);
  var user_id = `user_${nanoid(8)}`;
  const [video_id, setvideo_id] = useState(nanoid(20));
  const [thumbnailurl, setthumbnailurl] = useState("");
  const [review_type, setreview_type] = useState("");
  const [textreview, settextreview] = useState("");
  const [review_images, setreview_images] = useState([]);
  const [displayReviews, setdisplayReviews] = useState([]);
  const [reviewsfetch, setreviewsfetch] = useState([]);

  useEffect(() => {
    if (loaded) return;
    setloaded(true);
    getDoc(doc(db, "forms", form)).then((res) => {
      if (!res.exists()) {
        setloading(false);
        setValid(false);
      } else {
        const data = res.data();
        setuserRsp({ ...data, id: res.id });
        setreviewsfetch(data?.reviews || []);
        setformData(data);
        setloading(false);
        getproduct(data.prodid);
        // console.log(data)

        var title = `${data?.campaign_name}`;
        var metaTitle = `${data?.campaign_name}`;
        var metaDescription = `${data?.welcomeMessage}`;
        var metaKeyword = `Brands - MyRevue, Video testimonials, Review collector, ${data?.productname}`;

        var ogUrl = `https://brand.myrevue.app/userView/${form}`;
        var ogtype = `website`;
        var ogtitle = `${data?.campaign_name} `;
        var ogdescription = `${data?.welcomeMessage} `;
        var ogimage = `${data?.productimage} `;

        var twittercard = `summary_large_image`;
        var twitterdomain = `brand.myrevue.app`;
        var twitterurl = `https://brand.myrevue.app/userView/${form}`;
        var twittertitle = `${data?.campaign_name}`;
        var twitterdescription = `${data?.welcomeMessage}`;
        var twitterimage = `${data?.productimage}`;

        document.title = title;
        document.getElementsByTagName("meta")["title"].content = metaTitle;
        document.getElementsByTagName("meta")["description"].content =
          metaDescription;
        document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

        document.querySelector(`meta[property="og:url"]`).content = ogUrl;
        document.querySelector(`meta[property="og:type"]`).content = ogtype;
        document.querySelector(`meta[property="og:title"]`).content = ogtitle;
        document.querySelector(`meta[property="og:description"]`).content =
          ogdescription;
        document.querySelector(`meta[property="og:image"]`).content = ogimage;

        document.querySelector(`meta[name="twitter:card"]`).content =
          twittercard;
        document.querySelector(`meta[property="twitter:domain"]`).content =
          twitterdomain;
        document.querySelector(`meta[property="twitter:url"]`).content =
          twitterurl;
        document.querySelector(`meta[name="twitter:title"]`).content =
          twittertitle;
        document.querySelector(`meta[name="twitter:description"]`).content =
          twitterdescription;
        document.querySelector(`meta[name="twitter:image"]`).content =
          twitterimage;
        sendAmplitudeData("campaign link opened", { campaign: form });
        if (
          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
            navigator.userAgent
          )
        ) {
          setdevice("Mobile");
          logEngagementHook({
            campaignId: form,
            date: Date.now(),
            platform: "mobile",
            action: "open_campaign",
            brand: data.bpsId,
          });
        } else {
          setdevice("Desktop");
          logEngagementHook({
            campaignId: form,
            date: Date.now(),
            platform: "Desktop",
            action: "open_campaign",
            brand: data.bpsId,
          });
        }
      }
    });
  }, []);

  useEffect(() => {
    if (reviewsfetch.length > 0) {
      let a = [];
      reviewsfetch.forEach(async (rev, index) => {
        let headersList = {
          Accept: "*/*",
          Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
        };

        let reqOptions = {
          url: "https://adminapi-zscu3untuq-el.a.run.app/getReview/" + rev,
          method: "GET",
          headers: headersList,
        };

        let response = await axios.request(reqOptions);
        let data = response.data;
        a.push(data);
      });
      setdisplayReviews(a);
    }
  }, [formData]);

  async function getproduct(id) {
    let headersList = {
      Accept: "*/*",
      Authorization: "ADMMIN-a234u20jf-ad34fdh456-2345gfsf25",
    };

    let reqOptions = {
      url: "https://adminapi-zscu3untuq-el.a.run.app/getProduct/" + id,
      method: "GET",
      headers: headersList,
    };

    let response = await axios.request(reqOptions);
    let data = response.data;
    console.log(data);
    if (!data) {
      toast.warning("Product information not available", {
        position: "top-right",
        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
    }
    setproduct_details(data);
    setproduct_attributes(Object.keys(data?.attributes ?? {}));
  }

  const submitAnswer = async () => {
    let answered = 0;
    let not_answered = 0;

    userRsp.questions.forEach((question) => {
      if (question.hasOwnProperty("answer")) {
        answered = answered + 1;
      } else {
        not_answered = not_answered + 1;
      }
    });

    if (not_answered > 0) {
      return new AWN().alert("Please answer to all questions");
    }
    console.log("answered:" + answered);
    userRsp.answered = true;
    userRsp.substatus = "pending";
    userRsp?.askQuestionBefore === true && review_type === "video"
      ? setStep(2)
      : setStep(4);
  };

  const signinByGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        setuser({
          email: user.email,
          displayName: user.displayName,
          photoURL: user.photoURL,
        });
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: device,
          brand: formData.bpsId,
          action: "campaign_login_success",
        });
        let headersList = {
          Accept: "*/*",
          Authorization:
            "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
          "Content-Type": "application/json",
        };

        let bodyContent = {
          name: user.displayName,
          id: user_id,
          tag: `@${user_id}`,
          email: user.email,
          followers: 0,
          following: 0,
          reviews: 0,
          views: 0,
          flicks: 0,
        };

        let reqOptions = {
          url: "https://userapi-zscu3untuq-el.a.run.app/createUser",
          method: "PUT",
          headers: headersList,
          data: bodyContent,
        };

        let response = await axios.request(reqOptions);
        console.log(response.data);
        if (response.data?.success === false) {
          toast.warning("Something went wrong", {
            position: "top-right",
            icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
            bodyClassName: "text-sm tracking-wide font-work",
          });
          return;
        }
        setuser_details(response?.data?.user);
        await checkuserresponse(user.email, user.phoneNumber).then((length) => {
          console.log(length);
          if (formData?.multipleResponse === false && length > 0) {
            toast.warning(
              "You cannot submit multiple responses in this campaign.",
              {
                position: "top-right",
                icon: <ShieldSlash size={32} color="red" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work",
              }
            );
            setStep(0);
            return;
          } else {
            setStep(2);
            return;
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const video_upload = async (e) => {
    var image = e.target.files[0];
    var type = image.type.split("/")[1];
    const formats = ["mp4", "mkv", "mov", "avi"];

    if (!formats.includes(type)) {
      alert("Please upload a video file");
      return;
    }
    await handleFileUpload(e);
    const storage = getStorage();
    const storageRef = ref(storage, "formReview/" + video_id + ".mp4");
    const uploadTask = uploadBytesResumable(storageRef, image);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        setuploading(true);
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        // console.log(progress)
        setprogress_video(progress);
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setuserRsp({
            ...userRsp,
            reviewUrl: downloadURL,
          });
          setuploading(false);
        });
      }
    );
  };

  const submit_review = async () => {
    if (review_type === "video") {
      if (!review_info.review_name) {
        return new AWN().alert("Please fill review title");
      }
    }
    if (!review_info?.review_description) {
      return new AWN().alert("Please fill description");
    }
    if (!review_info.attributes) {
      return new AWN().alert("Please rate all attributes");
    }
    if (review_info.attributes) {
      if (
        Object.keys(review_info?.attributes).length <
        Object.keys(product_attributes).length
      ) {
        return new AWN().alert("Please rate all attributes");
      }
    }
    const attribute_arr = Object.values(review_info?.attributes);
    const mixedSum = (arr = []) => {
      let sum = 0;
      for (let i = 0; i < arr.length; i++) {
        const el = arr[i];
        sum += +el;
      }
      return sum;
    };
    const average_rating = (mixedSum(attribute_arr) / 5).toFixed(2);
    review_info.average_rating = average_rating;

    let newdoc = {
      thumbnail: thumbnailurl,
      review_info: review_info,
      campaign_info: {
        campaign_name: userRsp?.campaign_name,
        productname: userRsp?.productname,
        prodid: userRsp?.prodid,
        catId: userRsp?.catId,
        productimage: userRsp?.productimage,
        subcategory: userRsp?.subcategory,
      },
      user_info: {
        name: user_details.name,
        tag: user_details.tag,
        token: user_details.token ?? null,
        id: user_details.id,
        image: user_details.imageUrl ?? null,
        email: user_details.email ?? null,
        phone: user_details.phone ?? null,
      },
      reviewUrl: userRsp.reviewUrl || null,
      review_type: review_type,
      text_review: textreview,
      questions: userRsp.questions,
      substatus: "pending",
      dateAdded: Date.now(),
      images: review_images,
    };

    let questions = JSON.parse(JSON.stringify(userRsp.questions));
    for (const quest of questions) {
      if (quest[quest["answer"]]) {
        quest[quest["answer"]] += 1;
      } else {
        quest[quest["answer"]] = 1;
      }
      delete quest["answer"];
      delete quest["option_choosed"];
    }

    let updates = {
      questions: questions,
      submissions: increment(1),
      textReviews: review_type === "text" ? increment(1) : increment(0),
      videoReviews: review_type === "video" ? increment(1) : increment(0),
    };
    await updateDoc(doc(db, "forms/" + userRsp?.id), updates).then((res) =>
      console.log("done")
    );
    addDoc(
      collection(db, "forms/" + userRsp?.id + "/submissions"),
      newdoc
    ).then(() => {
      new AWN().success("Submitted successFully");
      logEngagementHook({
        campaignId: form,
        date: Date.now(),
        platform: device,
        brand: formData.bpsId,
        action: "user_campaign_submission",
      });
      setStep(5);
    });
  };

  function getphoneotp() {
    let regex = /^[6-9][0-9]{9}/i;
    if (!regex.test(phone_number)) {
      return new AWN().warning("Please enter valid phone number");
    }

    const auth = getAuth();

    const phonenumber = "+" + countrycode + phone_number;

    if (phonenumber.length >= 12) {
      setgetotp(true);
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow signInWithPhoneNumber.
            // onSignInSubmit();
          },
        },
        auth
      );
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phonenumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }
  const verifyOTP = () => {
    let regex = /\d{6}/i;
    if (!regex.test(otp)) {
      return new AWN().alert("Please enter valid otp");
    }
    let confirmationResult = window.confirmationResult;
    confirmationResult
      .confirm(otp)
      .then(async (result) => {
        const user = result.user;
        logEngagementHook({
          campaignId: form,
          date: Date.now(),
          platform: device,
          brand: formData.bpsId,
          action: "campaign_login_success",
        });
        setuser({
          phone: user?.phoneNumber,
          displayName: null,
          photoURL: null,
        });
        var q = query(
          collection(db, "user"),
          where("phone", "==", user?.phoneNumber),
          limit(1)
        );
        var users = await getDocs(q).then((res) =>
          res.docs.map((doc) => doc.data())
        );

        if (users.length === 0) {
          q = query(
            collection(db, "user"),
            where("id", "==", user_id),
            limit(1)
          );
          users = await getDocs(q).then((res) =>
            res.docs.map((doc) => doc.data())
          );
        }

        if (users?.length === 0) {
          setcreate_user_phone(true);
          setotp_verifying(false);
        } else {
          setotp_verifying(false);
          setuser_details(users[0]);
          setStep(2);
        }
      })
      .catch((error) => {
        console.log(error);
        new AWN().alert("Sign In error. Please try again.");
        window.location.reload(true);
      });
  };

  async function create_user() {
    if (user_info?.name.length < 4) {
      return new AWN().alert("Please enter your full name");
    }
    var u_data = {
      about: "",
      achievement: null,
      address: null,
      categories: [],
      coerImage: null,
      dob: null,
      email: null,
      favorite: null,
      filter: [],
      followers: 0,
      following: null,
      gender: null,
      id: user_id,
      imageUrl: null,
      joinDate: Date.now(),
      languages: ["english"],
      name: user_info?.name,
      phone: "+91" + phone_number,
      points: 0,
      qrCodeUrl: null,
      rating: null,
      refBy: null,
      refCode: null,
      reviews: 0,
      savedReviews: null,
      socialMedia: null,
      tag: `@${user_id}`,
      token: "",
      verified: null,
    };
    setDoc(doc(db, "user", user_id), u_data)
      .then(() => {
        setuser_details(u_data);
        setStep(2);
      })
      .catch((error) => console.log(error));
  }

  const generateVideoThumbnail = (file) => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");

      // this is important
      video.autoplay = true;
      video.muted = true;
      video.src = URL.createObjectURL(file);

      video.onloadeddata = () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        video.pause();
        return resolve(canvas.toDataURL("image/png"));
      };
    });
  };

  async function checkuserresponse(email, phone) {
    const subQueryEmail = query(
      collection(db, `forms/${form}/submissions`),
      where("user_info.email", "==", email)
    );
    const subQueryPhone = query(
      collection(db, `forms/${form}/submissions`),
      where("user_info.phone", "==", phone)
    );

    const emailDocs = await getDocs(subQueryEmail);
    const emailLength = emailDocs.docs.length;
    console.log(emailLength);
    return emailLength;
  }

  const handleFileUpload = async (e) => {
    const thumbnail_snap = await generateVideoThumbnail(e.target.files[0]);

    const storage = getStorage();
    const storageRef = ref(storage, "formReview/" + video_id + ".jpg");

    uploadString(storageRef, thumbnail_snap, "data_url").then((snapshot) => {
      getDownloadURL(storageRef).then((downloadURL) => {
        setthumbnailurl(downloadURL);
      });
    });
  };
  async function gobackfromquestion() {
    if (review_type === "text") {
      if (userRsp?.askQuestionBefore === true) {
        setreview_type("");
        setStep(2);
      } else {
        setreview_type("");
        setStep(2);
      }
    } else {
      if (userRsp?.askQuestionBefore === true) {
        setreview_type("");
        setStep(2);
      } else {
        setStep(2);
      }
    }
  }

  async function gobackfromreview() {
    if (review_type === "text") {
      if (userRsp?.askQuestionBefore === true) {
        setStep(3);
      } else {
        setStep(3);
      }
    } else {
      if (userRsp?.askQuestionBefore === true) {
        setStep(2);
      } else {
        setStep(3);
      }
    }
  }
  if (!valid)
    return (
      <div className="h-screen w-full  bg-[#101010] md:p-10">
        <div
          className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
          style={{ backgroundColor: "#02638F" }}
        >
          <div
            className="overflow-y-auto h-full  relative flex flex-col justify-between "
            id="thin-scrollbar"
          >
            <div className="pt-10 px-5   flex items-center justify-center">
              <div className="bg-white h-16 py-2 overflow-hidden px-8 rounded-xl ">
                <img
                  src={
                    formData.LogoLink ??
                    "https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                  }
                  alt=""
                  className="h-full mx-auto "
                />
              </div>
            </div>
            <div className="">
              <p className="text-center font-work">
                This isn't a valid campaign <br /> please check link
              </p>
            </div>
            <div className=""></div>
          </div>
        </div>
      </div>
    );

  if (loading) {
    return (
      <div className="h-screen w-full  bg-[#101010] md:p-10">
        <div
          className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
          style={{ backgroundColor: "#02638F" }}
        >
          <div
            className="overflow-y-auto h-full  relative flex items-center justify-center"
            id="thin-scrollbar"
          >
            <span className="animate-spin">
              <CircleNotch size={46} color="white" />
            </span>
          </div>
        </div>
      </div>
    );
  } else
    return (
      <div className="h-screen w-full  bg-[#101010] md:p-10">
        <div
          className="h-screen w-full md:w-fit md:h-full  md:aspect-[9/18] overflow-y-auto mx-auto md:rounded-lg md:ring-4 md:ring-white relative"
          style={{ backgroundColor: "#02638F" }}
        >
          <div
            className="overflow-y-auto h-full  relative flex flex-col justify-between "
            id="thin-scrollbar"
          >
            {cstep === 0 ? (
              <div className="">
                <div className="p-10 flex items-center justify-center">
                  <div className="bg-white h-16 overflow-hidden px-8 rounded-xl flex items-center justify-center ">
                    <img
                      src={
                        formData.LogoLink ??
                        "https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                      }
                      alt=""
                      className="h-16 mx-auto "
                    />
                  </div>
                </div>
                <div className="px-10">
                  <div className="">
                    <p className="font-medium tracking-wider text-center">
                      {formData?.campaign_name || formData?.parentName}
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center my-5">
                    <div>
                      {formData?.spokeLink ? (
                        <img
                          src={formData?.spokeLink}
                          alt=""
                          className="h-20 w-20 mx-auto rounded-full object-cover my-2"
                        />
                      ) : (
                        <div className="bg-white h-20 w-20 rounded-full text-pink-500 flex items-center justify-center text-3xl">
                          {formData?.name
                            ?.split(" ")[0]
                            .substring(0, 2)
                            .toUpperCase()}
                        </div>
                      )}
                    </div>
                    <div className="mt-3">
                      <p className="text-center font-medium">{formData.name}</p>
                      <p className="text-center font-light font-sans opacity-50">
                        {formData.designation}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border-l-[3px] border-red-500 mx-10 mt-12">
                  <p className="px-2 tracking-wide">
                    {formData.welcomeMessage}
                  </p>
                </div>
                <div className="mx-10 flex flex-col items-center mt-12">
                  <p className="text-lg font-medium">How it Works</p>
                  <div className="flex gap-x-2 mt-10">
                    <img
                      src="https://vocalvideo.com/assets/icons/record-blurb-mobile-ddb01ba6be7d6a634b3af138233feacb91bcdaa39f496d14eede0627ab2c573b.svg"
                      alt=""
                      className=""
                    />
                    <p className="">
                      Tell your story by recording a short video clip here.
                      Nothing to download or install.
                    </p>
                  </div>
                </div>
                {/* <div className="mt-8 p-5">
                  <p className="">Reviews</p>
                  {displayReviews?.map((rev, index) => (
                    <video className="w-full aspect-[9/16] rounded-md py-5" poster={rev?.thumbnail} src={rev?.reviewUrl} key={index} onClick={(e) => {
                      e.currentTarget.paused ? e.target.play() : e.target.pause()
                    }} />

                  ))}
                </div> */}
                {/* <div className="mx-auto w-full lg:w-96 md:w-2/3 fixed z-50  px-10 pb-6 pt-12 bottom-0 bg-gradient-to-b from-transparent   via-[#00354C] to-[#003147] ">
                  <button
                    className=" py-4 bg-[#0097A7]  rounded-lg appearance-none outline-none w-full"
                    onClick={() => setStep(1)}
                  >
                    Let's Go
                  </button>
                </div> */}
                <div
                  className="absolute w-full flex flex-col pb-5 justify-end px-5 h-32 bg-gradient-to-b from-transparent via-transparent bottom-0 md:rounded-b-lg"
                  style={{ "--tw-gradient-to": "#00000069" }}
                >
                  <button
                    onClick={() => setStep(1)}
                    className="py-3 rounded-lg appearance-none outline-none w-full"
                    style={{ backgroundColor: "#0097A7" }}
                  >
                    Let's Go
                  </button>
                </div>
              </div>
            ) : null}

            {cstep === 1 ? (
              <div className="">
                {phone_modal === true ? (
                  <div className=" h-full w-full  absolute z-30 p-5 ">
                    <div className="bg-white h-full w-full rounded">
                      <div className="" id="sign-in-button"></div>
                      <div className="h-10 flex items-center justify-between px-3 text-black border-b">
                        <p className="">Sign in with Phone</p>
                        <button
                          className=""
                          onClick={() => setphone_modal(false)}
                        >
                          <i className="fas fa fa-close fa-xl"></i>
                        </button>
                      </div>
                      <div className="h-[93%] w-full flex flex-col items-center justify-between">
                        <div className=""></div>
                        {create_user_phone === true ? (
                          <div className="">
                            <p className="text-black">
                              Please Enter Your Full Name
                            </p>
                            <input
                              type="text"
                              className="border border-black appearance-none outline-none h-10 w-full placeholder:text-[#12121259] px-3 text-black"
                              placeholder="Enter full name"
                              defaultValue=""
                              onChange={(e) =>
                                setuser_info({
                                  ...user_info,
                                  name: e.target.value,
                                })
                              }
                            />
                            <button
                              className={`bg-black ${
                                user_info?.name?.length >= 4
                                  ? "bg-opacity-100"
                                  : "bg-opacity-80"
                              } h-10 w-full mt-5`}
                              onClick={() => create_user()}
                            >
                              Continue
                            </button>
                          </div>
                        ) : (
                          <div className="w-full p-5">
                            <div className="flex gap-5">
                              <input
                                type="number"
                                defaultValue={countrycode}
                                className="w-16 border border-black appearance-none outline-none h-10 placeholder:text-[#12121259] px-3 text-black"
                                placeholder="+91"
                                onChange={(e) => setcountrycode(e.target.value)}
                              />
                              <input
                                type="text"
                                maxLength="10"
                                autocomplete="one-time-code"
                                className="border border-black appearance-none outline-none h-10 w-full flex-1 placeholder:text-[#12121259] px-3 text-black"
                                placeholder="Enter phone number"
                                onChange={(e) =>
                                  setphone_number(e.target.value)
                                }
                              />
                            </div>
                            {getotp === true ? (
                              <input
                                type="text"
                                // maxLength="6"
                                autoComplete="one-time-code"
                                className="border border-black appearance-none outline-none h-10 w-full placeholder:text-[#12121259] px-3 mt-3 text-black"
                                placeholder="Enter OTP"
                                onChange={(e) => setotp(e.target.value)}
                              />
                            ) : null}
                            {getotp === false ? (
                              <button
                                className="bg-black w-full h-10 mt-2"
                                onClick={getphoneotp}
                              >
                                Get OTP
                              </button>
                            ) : null}
                            {/* {getotp === true ? <p onClick={() => getphoneotp()} className="cursor-pointer text-black my-3 text-right text-sm font-medium">Resend OTP</p> : null} */}
                            {getotp === true ? (
                              <button
                                className="bg-black w-full h-10 mt-2"
                                onClick={verifyOTP}
                              >
                                {otp_verifying === true ? (
                                  <div id="loading"></div>
                                ) : (
                                  "Verify OTP"
                                )}
                              </button>
                            ) : null}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="p-6 h-full flex flex-col">
                  <div
                    className="bg-white bg-opacity-10 h-14 w-14 rounded-lg flex items-center justify-center"
                    onClick={() => setStep(0)}
                  >
                    <i className="fas fa-chevron-right transform rotate-180 scale-125 opacity-80"></i>
                  </div>
                  <div className="border-l-[3px] border-red-500 mt-12">
                    <p className="font-medium px-2 opacity-40 tracking-wide">
                      Step 1 of 4
                    </p>
                    <p className="px-2 tracking-wide">
                      Please login/signup to continue
                    </p>
                  </div>
                </div>
                <div
                  className="absolute w-full flex flex-col pb-5 justify-end px-5 h-32 bg-gradient-to-b from-transparent via-transparent bottom-0 md:rounded-b-lg gap-5"
                  style={{ "--tw-gradient-to": "#00000069" }}
                >
                  <button
                    onClick={() => signinByGoogle()}
                    className="bg-white h-10 w-full bottom-10 rounded-md px-4 flex items-center justify-center shadow-md shdaow-[#121212]"
                  >
                    <img
                      src="https://freesvg.org/img/1534129544.png"
                      alt=""
                      className="h-6 w-6 mx-3 "
                    />
                    <p className="text-[#121212] text-sm tracking-wide">
                      Sign in with Google
                    </p>
                  </button>
                  <button
                    onClick={() => setphone_modal(true)}
                    className="bg-white h-10 w-full  bottom-24  rounded-md px-4 flex items-center justify-center shadow-md shdaow-[#121212]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mx-3"
                      viewBox="0 0 512 512"
                    >
                      <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                    </svg>
                    <p className="text-[#121212] text-sm tracking-wide">
                      Sign in with Phone
                    </p>
                  </button>
                </div>
              </div>
            ) : null}

            {cstep === 2 ? (
              <div className="">
                <div className="p-6">
                  <div
                    className="bg-white bg-opacity-10 h-14 w-14 rounded-lg flex items-center justify-center"
                    onClick={() => {
                      setreview_type("");
                      setStep(2);
                    }}
                  >
                    <i className="fas fa-chevron-right transform rotate-180 scale-125 opacity-80"></i>
                  </div>
                </div>
                {uploading === true || userRsp?.reviewUrl ? null : (
                  <div className="border-l-[3px] border-red-500 mt-12 m-6">
                    <p className="font-medium px-2 opacity-40 tracking-wide">
                      Step 2 of 4
                    </p>
                    {review_type === "" && (
                      <>
                        <p className="px-2 tracking-wide text-sm">
                          Share your review either in video or text format
                        </p>
                      </>
                    )}
                    {review_type === "video" && (
                      <>
                        <p className="px-2 tracking-wide text-2xl">
                          Points to cover in this video
                        </p>
                        {formData.points?.map((point, index) => (
                          <div key={index} className="pl-3">
                            <p className="">
                              {index + 1}: {point.question}
                            </p>
                          </div>
                        ))}
                        <p className="pl-3">
                          {formData?.points?.length + 1}: Video must be no
                          longer than 1 min.
                        </p>
                      </>
                    )}
                  </div>
                )}

                <div className="p-6">
                  {uploading ? (
                    <div className="">
                      <div className="">
                        <svg
                          width="24"
                          height="24"
                          className="stroke-current text-white transform scale-[3] mt-10 ml-6"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M11.492 10.172l-2.5 3.064-.737-.677 3.737-4.559 3.753 4.585-.753.665-2.5-3.076v7.826h-1v-7.828zm7.008 9.828h-13c-2.481 0-4.5-2.018-4.5-4.5 0-2.178 1.555-4.038 3.698-4.424l.779-.14.043-.789c.185-3.448 3.031-6.147 6.48-6.147 3.449 0 6.295 2.699 6.478 6.147l.044.789.78.14c2.142.386 3.698 2.246 3.698 4.424 0 2.482-2.019 4.5-4.5 4.5m.978-9.908c-.212-3.951-3.472-7.092-7.478-7.092s-7.267 3.141-7.479 7.092c-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.522-5.408" />
                        </svg>
                      </div>
                      <div className="border-l-[3px] border-red-500 mt-6">
                        <p className="font-medium px-2 opacity-40 tracking-wide">
                          Thank You
                        </p>
                        <p className="px-2 tracking-wide">
                          Hang tight while we save your response.
                        </p>
                      </div>
                    </div>
                  ) : null}
                  {uploading ? (
                    <div className="bg-[rgba(0,0,0,0.3)] h-64 rounded-lg mt-10 flex flex-col items-center justify-center">
                      <div className=" transform scale-150">
                        <div className="dot-fire"></div>
                      </div>
                      <div className="mt-10 text-xl tracking-wide font-medium flex flex-col items-center justify-center">
                        <p className="">Uploading ...</p>
                        <p className="">{progress_video}%</p>
                      </div>
                    </div>
                  ) : null}
                  {userRsp.reviewUrl ? (
                    <div>
                      <div className=" flex items-center justify-center rounded-lg mt-5">
                        <video
                          src={userRsp?.reviewUrl}
                          autoPlay
                          controls
                          className="rounded-lg w-full"
                        ></video>
                      </div>
                      <div className="">
                        <button
                          className="mt-4 py-4 bg-[#0097A7] my-4 rounded-lg appearance-none outline-none w-full"
                          onClick={() =>
                            userRsp?.askQuestionBefore === true
                              ? setStep(4)
                              : setStep(3)
                          }
                        >
                          Continue
                        </button>
                        {device === "Mobile" ? (
                          <>
                            <p className="mx-auto text-center">Or</p>
                            <div className="relative mt-4 ">
                              <input
                                type="file"
                                accept=".mp4"
                                capture="camera"
                                className="absolute my-4 w-full border py-3 opacity-0"
                                onChange={(e) => video_upload(e)}
                              />
                              <button className="py-4 bg-[#0097A7]  rounded-lg appearance-none outline-none w-full">
                                <i className="fas fa-video mr-5"></i> Record
                                Video
                              </button>
                            </div>
                          </>
                        ) : null}

                        <div className="relative mt-4 ">
                          <input
                            type="file"
                            accept=".mp4"
                            // capture="camera"
                            className="absolute my-4 w-full border py-3 opacity-0"
                            onChange={(e) => video_upload(e)}
                          />
                          <button className="py-4 bg-[#12121265]  rounded-lg appearance-none outline-none w-full">
                            Re - Upload
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {uploading === true || userRsp?.reviewUrl ? null : (
                  <div className="absolute bottom-0 w-full ">
                    <p className="text-center font-medium text-sm tracking-wide pb-5">
                      How would you like to respond
                    </p>
                    <div className="w-full  relative px-5">
                      {review_type === "" && (
                        <div className="py-5 space-y-5">
                          <button
                            className="h-10 w-full bg-[#0097A7] rounded-md"
                            onClick={() => {
                              setreview_type("text");
                              setStep(cstep + 1);
                            }}
                          >
                            Write a review
                          </button>
                          <button
                            className="h-10 w-full bg-[#0097A7] rounded-md"
                            onClick={() => {
                              setreview_type("video");
                              userRsp?.askQuestionBefore === true && setStep(3);
                            }}
                          >
                            Upload/Record a video
                          </button>
                        </div>
                      )}
                      {review_type === "video" && (
                        <>
                          {device === "Mobile" ? (
                            <>
                              <div className="relative mt-4 ">
                                <input
                                  type="file"
                                  accept="video/*"
                                  capture="camera"
                                  maxLength="120"
                                  className="absolute h-full w-full border opacity-0 rounded-lg"
                                  onChange={(e) => video_upload(e)}
                                />
                                <button className=" py-3 bg-[#0097A7] rounded-lg appearance-none outline-none w-full">
                                  <i className="fas fa-video mr-5"></i> Record
                                  Video
                                </button>
                              </div>
                              <p className="mx-auto text-center my-2">Or</p>
                            </>
                          ) : null}
                          <div className="relative mb-5">
                            <input
                              type="file"
                              accept=".mp4"
                              className="absolute  w-full border h-full rounded-lg opacity-0"
                              onChange={(e) => video_upload(e)}
                            />
                            <button
                              className="py-3 bg-[#0097A7] rounded-lg appearance-none outline-none w-full"
                              // onClick={() => setvideorecord_modal(true)}
                            >
                              <i className="fas fa-video mr-5"></i> Upload Video
                            </button>
                          </div>
                        </>
                      )}
                      {/* {review_type === 'text' && <>
                    <div className="p-5">
                      <p className="text-white text-opacity-50 font-light tracking-wide text-sm pb-2">Please write a review in more than 150 characters</p>
                      <textarea onChange={(event) => settextreview(event.target.value)} className="h-40 w-full border border-white border-opacity-20 rounded-md p-2 outline-none appearance-none text-black"></textarea>
                      <button
                        className=" py-3 bg-[#0097A7] rounded-lg appearance-none outline-none w-full"

                        onClick={() => submittextreview()}>
                        Submit
                      </button>
                    </div>

                  </>} */}
                    </div>
                  </div>
                )}
              </div>
            ) : null}
            {cstep === 3 ? (
              <div className="">
                <div className="px-6 pt-6">
                  <div
                    className="bg-white bg-opacity-10 h-14 w-14 rounded-lg flex items-center justify-center cursor-pointer "
                    onClick={() => gobackfromquestion()}
                    // onClick={() => { userRsp?.askQuestionBefore === true  ? (review_type('text') ? (setStep(2), setreview_type('')) : setStep(2)) : setStep(2) }}
                  >
                    <i className="fas fa-chevron-right transform rotate-180 scale-125 opacity-80"></i>
                  </div>
                </div>
                <div className="pb-32 px-6">
                  {userRsp.questions?.map((quest, index) => (
                    <div
                      key={index}
                      className="px-10 py-6 my-5 bg-[rgba(0,0,0,0.3)]  w-full rounded-lg"
                    >
                      <p className="text-white text-opacity-70 text-2xl font-light font-sans mb-5">
                        {quest.id}: {quest.question}
                      </p>
                      {quest.opt1 ? (
                        <div className="flex items-start w-full my-2">
                          <input
                            type="radio"
                            id={"opt1" + index}
                            name={quest.id}
                            onChange={(e) => {
                              quest.answer = e.target.value;
                              quest.option_choosed = 1;
                            }}
                            value={quest.opt1}
                            defaultChecked={
                              quest.answer === quest.opt1 ? true : false
                            }
                            className="w-8 h-8"
                          />
                          <label
                            className="pl-2 w-full flex-1 -mt-1"
                            htmlFor={"opt1" + index}
                          >
                            {quest.opt1}
                          </label>{" "}
                          <br />
                        </div>
                      ) : null}
                      {quest.opt2 ? (
                        <div className="flex items-start w-full my-2">
                          <input
                            type="radio"
                            id={"opt2" + index}
                            name={quest.id}
                            onChange={(e) => {
                              quest.answer = e.target.value;
                              quest.option_choosed = 2;
                            }}
                            value={quest.opt2}
                            defaultChecked={
                              quest.answer === quest.opt2 ? true : false
                            }
                          />
                          <label
                            className="pl-2 w-full flex-1 -mt-1"
                            htmlFor={"opt2" + index}
                          >
                            {quest.opt2}
                          </label>{" "}
                          <br />
                        </div>
                      ) : null}
                      {quest.opt3 ? (
                        <div className="flex items-start w-full my-2">
                          <input
                            type="radio"
                            id={"opt3" + index}
                            name={quest.id}
                            onChange={(e) => {
                              quest.answer = e.target.value;
                              quest.option_choosed = 3;
                            }}
                            value={quest.opt3}
                            defaultChecked={
                              quest.answer === quest.opt3 ? true : false
                            }
                          />
                          <label
                            className="pl-2 w-full flex-1 -mt-1"
                            htmlFor={"opt3" + index}
                          >
                            {quest.opt3}
                          </label>{" "}
                          <br />
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="absolute w-full flex flex-col pb-5 justify-end px-5 h-32 bg-gradient-to-b from-transparent via-transparent bottom-0 md:rounded-b-lg">
                  <button
                    className="mt-4 py-4 bg-[#0097A7] rounded-lg appearance-none outline-none w-full"
                    onClick={() => submitAnswer()}
                  >
                    Continue
                  </button>
                </div>
              </div>
            ) : null}
            {cstep === 4 ? (
              <div className="p-6 ">
                <div
                  className="bg-white bg-opacity-10 h-14 w-14 rounded-lg flex items-center justify-center"
                  onClick={() => gobackfromreview()}
                >
                  <i className="fas fa-chevron-right transform rotate-180 scale-125 opacity-80"></i>
                </div>
                <div className="">
                  <p className=" font-light text-2xl text-center my-5">
                    Please enter review details
                  </p>
                </div>
                <div className="">
                  {review_type === "video" && (
                    <div className="">
                      <p className="text-sm tracking-wide">
                        Review Title{" "}
                        <span className="text-red-500 text-lg">*</span>
                      </p>
                      <input
                        type="text"
                        onChange={(event) =>
                          setreview_info({
                            ...review_info,
                            review_name: event.target.value,
                          })
                        }
                        maxLength={30}
                        className="h-10 bg-[#00000059] mt-1 w-full appearance-none outline-none px-3 rounded-md text-sm text-[#bbbbbb]"
                        placeholder="enter title of review"
                      />
                      {error?.review_name ? (
                        <p className="text-red-500 text-sm tracking-wider font-medium pl-1">
                          Please add review title
                        </p>
                      ) : null}
                    </div>
                  )}
                  <div className="mt-3">
                    <p className="text-sm tracking-wide">
                      Review Description
                      <span className="text-red-500 text-lg"> *</span>
                    </p>
                    <textarea
                      type="text"
                      onChange={(event) =>
                        setreview_info({
                          ...review_info,
                          review_description: event.target.value,
                        })
                      }
                      maxLength={50}
                      className="h-28 bg-[#00000059] mt-1 w-full appearance-none outline-none p-3 rounded-md text-sm text-[#bbbbbb] "
                      placeholder={
                        review_type === "text"
                          ? "write a review"
                          : "enter review description upto 50 letters"
                      }
                    ></textarea>
                    {error?.review_description === true ? (
                      <p className="text-red-500 text-sm tracking-wider font-medium pl-1">
                        Please add description for review
                      </p>
                    ) : null}
                  </div>
                  <div className="mt-5">
                    <p className="text-sm tracking-wide font-medium text-center">
                      How would you rate this product
                    </p>
                    <p className="text-sm tracking-wide text-center">
                      Rate this out of 5 stars{" "}
                      <span className="text-red-500 text-lg">*</span>
                    </p>
                    {error?.attributes ? (
                      <p className="text-red-500 text-sm tracking-wider font-medium pl-1">
                        Please add rating for all attributes
                      </p>
                    ) : null}
                    <div className="grid grid-cols-2 grid-flow-row justify-between mt-4">
                      {product_attributes?.map((att, index) => (
                        <div
                          className={`${
                            index % 2 === 0
                              ? "w-full grid items-end justify-start my-3"
                              : "w-full grid items-end justify-center my-3"
                          }`}
                          key={index}
                        >
                          <p className="text-center">{att}</p>
                          <ReactStars
                            size={25}
                            count={5}
                            isHalf={false}
                            value={0}
                            color="white"
                            activeColor="#FF7D58"
                            onChange={(newValue) =>
                              setreview_info({
                                ...review_info,
                                attributes: {
                                  ...review_info.attributes,
                                  [att]: newValue,
                                },
                              })
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  {review_type === "text" && (
                    <div className="mt-5">
                      <Userviewfileupload
                        setimages={setreview_images}
                        images={review_images}
                      />
                    </div>
                  )}
                  <div className="mt-6">
                    <button
                      className=" py-2 bg-[#0097A7]  rounded-lg appearance-none outline-none w-full"
                      onClick={() => submit_review()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
            {cstep === 5 ? (
              <div className="p-6 ">
                <div className=" flex items-center justify-center">
                  <div className="bg-white h-16 overflow-clip w-44 rounded-xl flex items-center justify-center p-6">
                    <img
                      src={
                        formData.LogoLink ??
                        "https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                      }
                      alt=""
                      className=" "
                    />
                  </div>
                </div>
                <div className="mt-12 flex flex-col justify-center">
                  <div className="border-l-[3px] border-red-500 ">
                    <p className="px-2 text-3xl font-medium">
                      🎉 {formData?.headLine}
                    </p>
                    <p className="px-2 text-xl font-light font-sans tracking-wide mt-3">
                      {formData?.thankYouMessageRef}
                    </p>
                  </div>
                  <div className="mt-8 flex  items-center gap-x-3">
                    <div>
                      {formData?.spokeLink ? (
                        <img
                          src={formData?.spokeLink}
                          alt=""
                          className="h-12 w-12 mx-auto rounded-full object-cover my-2"
                        />
                      ) : (
                        <div className="bg-white h-12 w-12 rounded-full text-pink-500 flex items-center justify-center text-xl">
                          {formData?.name
                            ?.split(" ")[0]
                            .substring(0, 2)
                            .toUpperCase()}
                        </div>
                      )}
                    </div>
                    <div className="">
                      <p className="font-medium">{formData.name}</p>
                      <p className="opacity-40">{formData.designation}</p>
                    </div>
                  </div>
                </div>
                <div className=" flex items-center justify-end mt-16">
                  <div className="w-[410px] bg-white  flex flex-col rounded-2xl p-10 items-center justify-center">
                    <p className="text-lg font-sans tracking-widest uppercase text-black ">
                      POWERED BY
                    </p>
                    <img
                      src="https://static.wixstatic.com/media/4d4444_441d76e982064799887042198e1ed26c~mv2.png/v1/fill/w_380,h_120,al_c,q_85,usm_0.66_1.00_0.01/LogoMakr-04UpJi.webp"
                      alt=""
                      className="h-14 my-3"
                    />
                    <p className="text-black mt-3 italic text-center">
                      <span className="text-pink-500 font-medium  underline underline-offset-2 not-italic">
                        MyRevue
                      </span>{" "}
                      is first indian platform for reviews content. Stream and
                      publish reviews and get assured cashbacks.
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
}

export default MUserView;
