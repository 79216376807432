import React from "react";
import { useEffect } from "react";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";
import { useHistory, useParams } from "react-router";
import { useState } from "react";
import AWN from "awesome-notifications";
import { sendAmplitudeData } from "../amplitude";
import { useStateValue } from "../context/StateProvider";
import { CaretLeft } from "@phosphor-icons/react";
function ViewTemplate() {
  const router = useHistory();
  const { templateId } = useParams();
  const [templates, setTemplate] = useState([]);
  const [selected, setSelected] = useState(null);
  const [{ brand }, dispatch] = useStateValue();

  useEffect(() => {
    const tq = query(collection(db, "templates/" + templateId + "/templates"))
    getDocs(tq).then((res) => {
      let b = res.docs.map((b) => {
        let temp = b.data();

        let points = temp.points ?? [];
        let question = temp.questions ?? [];

        points = points.map((p) => p.replaceAll("Company Name", brand?.name));
        question = question.map((p) => {
          p.question = p.question.replaceAll("Company Name", brand?.name);
          return p;
        });
        temp.points = points;
        temp.question = question;
        return temp;
      });
      setTemplate(b);
    });

    sendAmplitudeData("viewing  question templates", {
      template: templateId,
    });
  }, []);

  const handleChooseTemplate = () => {
    var tempq = [];
    var tempp = [];
    tempq = selected.questions?.map((qu, index) => ({
      id: index + 1,
      question: qu.question,
      opt1: qu.options[0],
      opt2: qu.options[1],
      opt3: qu.options[2] ?? null,
      // saved: true,
    }));
    sessionStorage.setItem("questions", JSON.stringify(tempq));
    dispatch({ type: "SET_QUESTIONS", question: tempq })




    // setQuestionAtom(tempq);
    tempp = selected.points?.map((po, index) => ({
      id: index + 1,
      question: po,
      // saved: true,
    }));




    // setPointsAtom(tempq ?? []);
    sessionStorage.setItem("points", JSON.stringify(tempp ?? []));
    dispatch({ type: "SET_POINTS", point: tempp ?? [] })
    new AWN().success("template selected");
    router.push("/collector/createCampaign");
  };

  if (templates.length === 0) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fas fa fa-spinner animate-spin"></i>
      </div>
    );
  }
  if (selected) {
    return (
      <div className="px-20 pb-10">
        <div className="flex items-center justify-between py-5">
          <div
            onClick={() => setSelected(null)}
            className="flex text-white items-center cursor-pointer"
          >
            <CaretLeft size={20} color="white" />
            <p className="">Go Back</p>
          </div>
          <h1 className="text-2xl first-letter:uppercase  font-medium tracking-wide"> {templateId.replaceAll("-", " ")} {">"} {selected.title}{" "}</h1>
        </div>
        <div className="flex px-10">
          <img
            src={selected?.imageUrl}
            alt=""
            className="flex-1 object-cover object-center h-[180px] w-full m-5 rounded-md"
          />
          <div className="m-5 flex-1 ">
            <p className="text-xl font-medium tracking-wide ">{selected.title}</p>
            <p className="mt-4 font-light tracking-wide">
              {selected?.description}
            </p>
          </div>
        </div>
        <div className="mt-6 grid grid-cols-2 text-white gap-10 mx-auto lg:w-2/3">
          <div className="bg-[#1212124D] h-fit w-full p-3 rounded-lg">
            <p className="text-center mb-4 font-medium text-lg pt-4">
              Questions
            </p>
            <ul>
              {selected?.questions?.map((quest, index) => (
                <li key={"quest" + index} className="py-2">
                  Q{index + 1}. {quest.question}
                  <ul className="ml-5 list-disc">
                    {quest.options?.map((opt, index) => (
                      <li key={"opt" + index}>{opt}</li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-[#1212124D] h-fit w-full p-3 rounded-lg">
            <p className="text mb-2 font-medium text-lg p-2">Points</p>
            <ul className="list-disc ml-8">
              {selected?.points?.map((p, index) => (
                <li
                  key={index + "points"}
                  className=" font-light tracking-wide"
                >
                  {p}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className=" flex justify-center mt-6">
          <button
            className="py-[10px] px-12 rounded-lg mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
            onClick={handleChooseTemplate}
          >
            Choose this template
          </button>
        </div>
      </div>
    );
  } else
    return (
      <div className=" min-h-screen flex-1 w-full px-20">
        <div className="flex items-center justify-between py-5">
          <div
            onClick={() => router.goBack()}
            className="flex text-white items-center cursor-pointer"
          >
            <CaretLeft size={20} color="white" />
            <p className="">Go Back</p>
          </div>
          <h1 className="text-2xl first-letter:uppercase  font-medium tracking-wide">{templateId.replaceAll("-", " ")}</h1>
        </div>
        <div className="my-3 flex flex-wrap gap-5 justify-center">
          {templates.map((temp, index) => (
            <div
              key={index + "templates"}
              onClick={() => setSelected(temp)}
              className="cursor-pointer bg-[#202020] p-2 w-full max-w-[300px] h-[350px] text-white rounded-xl flex flex-col justify-between"
            >
              <div className="w-full">
                <div className="w-full mb-2 h-[124px]">
                  <img
                    src={temp?.imageUrl}
                    alt="thumbnails"
                    className=" object-cover rounded-t-lg h-full w-full"
                  />
                </div>
                <div className="">
                  <p className=" mb-2 text-lg font-medium text-center text-[#D9D9D9]">{temp.title}</p>
                  <p className="font-light text-sm tracking-wide text-center text-[#D9D9D9] mt-2">
                    {temp.description}
                  </p>
                </div>
              </div>
              <div className="flex justify-between">
                <p className="font-medium tracking-wide">{temp?.questions?.length ?? 0} Questions</p>
                <p className="text-[#EA335F] font-medium tracking-wide">{temp?.points?.length ?? 0} Points</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
}

export default ViewTemplate;
