import React, { useState } from "react";
import NotLoginHeader from "../layouts/NotLoginHeader";
import { sendAmplitudeData } from "../amplitude";
import { useEffect } from "react";
import { Check, CircleWavyQuestion } from "phosphor-react";
import Notloginfooter from "../layouts/Notloginfooter";
import { useHistory } from "react-router";
import NotLoginHeaderNew from "../layouts/NotLoginHeaderNew";
import NotloginfooterNew from "../layouts/NotloginfooterNew";

function ChoosePlan() {
  const [monthly, setmonthly] = useState(true);
  const router = useHistory();
  useEffect(() => {
    sendAmplitudeData("pricing page opened");
    var title = `Pricing & Plans | MyRevue`;
    var metaTitle = `Pricing & Plans | MyRevue`;
    var metaDescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var metaKeyword = `Brands - MyRevue, Video testimonials, Review collector, Senja, Vocal Video, testimonial tool, lead generation software free trial, zapier zap, marketing lead gen, lead generation it companies, video testimonial page design, b2b lead software, lead generation app, testimonial write up,client testimonials page, lead gen platform, short testimonial examples, lead finding software`;

    var ogUrl = `https://brand.myrevue.app/login`;
    var ogtype = `website`;
    var ogtitle = `Pricing & Plans | MyRevue`;
    var ogdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var ogimage = ``;

    var twittercard = `summary_large_image`;
    var twitterdomain = `brand.myrevue.app`;
    var twitterurl = `https://brand.myrevue.app/login`;
    var twittertitle = `Pricing & Plans | MyRevue`;
    var twitterdescription = `Professional video testimonials,without breaking the bank. No matter how hard you try, it's never easy to get a video testimonial.`;
    var twitterimage = ``;

    document.title = title;
    document.getElementsByTagName("meta")["title"].content = metaTitle;
    document.getElementsByTagName("meta")["description"].content = metaDescription;
    document.getElementsByTagName("meta")["keywords"].content = metaKeyword;

    document.querySelector(`meta[property="og:url"]`).content = ogUrl;
    document.querySelector(`meta[property="og:type"]`).content = ogtype;
    document.querySelector(`meta[property="og:title"]`).content = ogtitle;
    document.querySelector(`meta[property="og:description"]`).content = ogdescription;
    document.querySelector(`meta[property="og:image"]`).content = ogimage;

    document.querySelector(`meta[name="twitter:card"]`).content = twittercard;
    document.querySelector(`meta[property="twitter:domain"]`).content = twitterdomain;
    document.querySelector(`meta[property="twitter:url"]`).content = twitterurl;
    document.querySelector(`meta[name="twitter:title"]`).content = twittertitle;
    document.querySelector(`meta[name="twitter:description"]`).content = twitterdescription;
    document.querySelector(`meta[name="twitter:image"]`).content = twitterimage;
  }, []);

  return (
    // <div className="min-h-screen ">
    //   <NotLoginHeader />

    //   <section className="w-full max-w-[500px] mx-auto pt-10">
    //     <p className="text-center text-xl font-light">
    //       Our paid plans which come with publishing, video downloads, and no
    //       watermark
    //     </p>
    //   </section>

    //   <section className="mt-4 text-center">
    //     <div className="grid grid-cols-2 w-[340px] mx-auto">
    //       <div
    //         className={`w-full cursor-pointer h-11 flex items-center justify-center ${monthly === true
    //           ? "bg-white text-black first:rounded-l-md"
    //           : "border border-white first:rounded-l-md"
    //           }`}
    //         onClick={() => setmonthly(true)}
    //       >
    //         Billed monthly
    //       </div>
    //       <div
    //         className={`w-full cursor-pointer h-11 flex items-center justify-center ${monthly === false
    //           ? "bg-white text-black last:rounded-r-md"
    //           : "border border-white last:rounded-r-md"
    //           }`}
    //         onClick={() => setmonthly(false)}
    //       >
    //         Billed annually
    //       </div>
    //     </div>

    //     {/* <div className="grid grid-cols-2 w-[340px] mx-auto">
    //       <div className={`w-full cursor-pointer ${monthly === true ? "bg-white text-black first:rounded-l-md last:rounded-r-md" : "text-white"} h-11 flex items-center justify-center `} onClick={() => setmonthly(true)}>
    //         Billed monthly
    //       </div>
    //       <div className={`w-full cursor-pointer ${monthly === true ? "text-white" : "bg-white text-black first:rounded-l-md last:rounded-r-md"}  h-11 flex items-center justify-center border border-white `} onClick={() => setmonthly(false)}>
    //         Billed annually
    //       </div>
    //     </div> */}
    //   </section>

    //   <section className="flex mt-10 pb-10 flex-wrap items-center justify-center gap-10">
    //     <div className="bg-[#1212124D] border p-6 w-full max-w-[310px] rounded-[20px] mt-8 h-max">
    //       <p className="text-center text-xl pb-4">Freemium</p>
    //       <p className=" text-center">
    //         <span className="text-2xl">$0/</span>
    //         {monthly === true ? "month" : "year"} for 1 users
    //       </p>
    //       <ul className="space-y-1 mt-5 font-light tracking-wide">
    //         <li className="flex gap-x-2 items-center">
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 32 32"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    //               fill="#03A300"
    //             />
    //             <path
    //               d="M21.5 13L14.1625 20L10.5 16.5"
    //               stroke="white"
    //               stroke-width="3"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //           Publish Unlimited Reviews
    //         </li>
    //         <li className="flex gap-x-2 items-center">
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 32 32"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    //               fill="#03A300"
    //             />
    //             <path
    //               d="M21.5 13L14.1625 20L10.5 16.5"
    //               stroke="white"
    //               stroke-width="3"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //           Download Upto 3 Videos
    //         </li>
    //         <li className="flex gap-x-2 items-center">
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 32 32"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    //               fill="#03A300"
    //             />
    //             <path
    //               d="M21.5 13L14.1625 20L10.5 16.5"
    //               stroke="white"
    //               stroke-width="3"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //           1 Campaign
    //         </li>
    //         <li className="flex gap-x-2 items-center">
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 32 32"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    //               fill="#03A300"
    //             />
    //             <path
    //               d="M21.5 13L14.1625 20L10.5 16.5"
    //               stroke="white"
    //               stroke-width="3"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //           1 User
    //         </li>
    //       </ul>
    //     </div>
    //     <div className="bg-[#FFFFFF0D] p-6  w-full max-w-[310px] rounded-[20px] mt-8 border border-white relative h-max">
    //       <div className="absolute z-50 top-0 left-0 -m-5 bg-[#FFFFFF2D] rounded-md ">
    //         <div className="border border-white w-max px-5 py-2 rounded-md backdrop-blur-sm ">
    //           Popular
    //         </div>
    //       </div>
    //       <p className="text-center text-xl pb-4">Basic</p>
    //       <p className="text-center  font-medium b-2">
    //         Everything in Free, plus...
    //       </p>
    //       <p className="text-center">
    //         <span className="text-2xl">
    //           ${monthly === true ? "999" : "9999"}/
    //         </span>
    //         {monthly === true ? "month" : "year"} for 3 users
    //       </p>
    //       <ul className="space-y-1 mt-5 font-light tracking-wide">
    //         <li className="flex gap-x-2 items-center">
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 32 32"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    //               fill="#03A300"
    //             />
    //             <path
    //               d="M21.5 13L14.1625 20L10.5 16.5"
    //               stroke="white"
    //               stroke-width="3"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //           Download upto 50 Videos
    //         </li>
    //         <li className="flex gap-x-2 items-center">
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 32 32"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    //               fill="#03A300"
    //             />
    //             <path
    //               d="M21.5 13L14.1625 20L10.5 16.5"
    //               stroke="white"
    //               stroke-width="3"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //           3 Campaigns
    //         </li>
    //         <li className="flex gap-x-2 items-center">
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 32 32"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    //               fill="#03A300"
    //             />
    //             <path
    //               d="M21.5 13L14.1625 20L10.5 16.5"
    //               stroke="white"
    //               stroke-width="3"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //           3 Users
    //         </li>
    //         <li className="flex gap-x-2 items-center">
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 32 32"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    //               fill="#03A300"
    //             />
    //             <path
    //               d="M21.5 13L14.1625 20L10.5 16.5"
    //               stroke="white"
    //               stroke-width="3"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //           Api Integration
    //         </li>
    //       </ul>
    //     </div>
    //     <div className="bg-[#1212124D] p-6 border w-full max-w-[310px] rounded-[20px] mt-8 h-max">
    //       <p className="text-center text-xl font-medium pb-4">Standard</p>
    //       <p className="text-center pb-2">Everything in Basic, plus...</p>
    //       <p className="text-center">
    //         <span className="text-2xl">
    //           ${monthly === true ? "2499" : "24999"}/
    //         </span>
    //         {monthly === true ? "month" : "year"} for 5 users
    //       </p>
    //       <ul className="space-y-1 mt-5 font-light tracking-wide">
    //         <li className="flex gap-x-2 items-center">
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 32 32"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    //               fill="#03A300"
    //             />
    //             <path
    //               d="M21.5 13L14.1625 20L10.5 16.5"
    //               stroke="white"
    //               stroke-width="3"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //           Download Unlimited
    //         </li>
    //         <li className="flex gap-x-2 items-center">
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 32 32"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    //               fill="#03A300"
    //             />
    //             <path
    //               d="M21.5 13L14.1625 20L10.5 16.5"
    //               stroke="white"
    //               stroke-width="3"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //           Upto 10 Campaigns
    //         </li>
    //         <li className="flex gap-x-2 items-center">
    //           <svg
    //             width="22"
    //             height="22"
    //             viewBox="0 0 32 32"
    //             fill="none"
    //             xmlns="http://www.w3.org/2000/svg"
    //           >
    //             <path
    //               d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
    //               fill="#03A300"
    //             />
    //             <path
    //               d="M21.5 13L14.1625 20L10.5 16.5"
    //               stroke="white"
    //               stroke-width="3"
    //               stroke-linecap="round"
    //               stroke-linejoin="round"
    //             />
    //           </svg>
    //           5 Users
    //         </li>
    //       </ul>
    //     </div>
    //   </section>
    //   <section className="p-5 overflow-x-auto">
    //     <table className="w-[700px] lg:w-[1050px] overflow-x-auto mx-auto text-center text-sm tableplans">
    //       <thead className="">
    //         <tr>
    //           <th className=" border border-[#858585] h-16 p-2 bg-[#2d3037] font-medium tracking-wide">
    //             Plans
    //           </th>
    //           <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 bg-[#17847E] font-medium tracking-wide">
    //             Freemium
    //           </th>
    //           <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 bg-[#0C8A9D] font-medium tracking-wide">
    //             Basic
    //           </th>
    //           <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 bg-[#3961A1] font-medium tracking-wide">
    //             Standard
    //           </th>
    //           <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 bg-[#705CA7] font-medium tracking-wide">
    //             Curated
    //           </th>
    //         </tr>
    //       </thead>
    //       <tbody className="tbody">
    //         {/* <tr className="">
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             Monthly
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             0 Rs
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             999 Rs /Month
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             2499 Rs /Month
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             5000 Rs / Month Onwards
    //           </td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             Annually
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             0 Rs
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             10000 Rs / Year, Billed Annually
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             22000 Rs / Year, Billed Annually
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //         </tr> */}
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Features
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip="Get a video collector and turn the power of reviews into more
    //             traffic, increased sales, and strong customer loyalty.. Simply
    //             share Link/ QR code with your customers and get their video
    //             reviews."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>

    //           <td className="font-light border border-[#858585] h-10 p-2 ">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 ">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 ">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>

    //           <td className="font-light border border-[#858585] p-2">
    //             For larger businesses with a need for the most advanced features
    //             in a bespoke package.
    //           </td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Campaigns
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip="No of campaigns can be created"
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 text-xl">
    //             1
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 text-xl">
    //             3
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 text-xl">
    //             10
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             On Request
    //           </td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Flag Detection Tool
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip="Flag the fake reviews by our detection tool."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             A review solution tailored to your needs and delivered with
    //             top-tier customer support.
    //           </td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Remote hassle free Review Collection
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip="Remotely collect reviews from your customers through any device."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             Throughout your experience, a dedicated Customer Support Team
    //             will work with you every step of the way. With an understanding
    //             of your unique business drivers and objectives, they’ll help you
    //             create and implement a review strategy that grows your brand and
    //             your bottom line.
    //           </td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Users
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip="Get a no of ID login to Customer Dashboard."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 text-xl">
    //             1
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 text-xl">
    //             3
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 text-xl">
    //             5
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             Work directly with our success managers, implementation experts
    //             and support engineers to grow your brand trust quicker.
    //           </td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Acknowledgements
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip="No. of Comment Credits to acknowledge customer reviews"
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //           <td className="font-light border border-[#858585] h-10 p-2 text-xl">
    //             100
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 text-xl">
    //             100
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             With our artificial intelligence doing the heavy lifting, dive
    //             deep into your customer feedback to see trends and insights
    //             within your reviews.
    //           </td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Publish
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip="Share unlimited reviews on your Social Media Handles and Website through easily configurable APIs with Brand and MyRevue watermark."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             Showcase Product Reviews in ads and on your website to make
    //             buying decisions easier for prospects considering your products.
    //           </td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Video Storage
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip="Review storage for upto 14 days for Unpublished and Draft videos on MyRevue Customer Dashboard."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Download published videos and raw response footage
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip="Download upto no of videos per month and share on various platforms."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 text-xl">
    //             3
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 text-xl">
    //             50
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2 text-xl">
    //             Unlimited
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Customized Gallery
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip="Get your personlized website and showcase your video reviews as
    //             testimonials to your prospective customers."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Dedicated Support Manager
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip="Get your personlized website and showcase your video reviews as
    //             testimonials to your prospective customers."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               API Integration
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip=" Improve your brand value by access to our entire service review
    //             widget library to showcase your star rating and review content
    //             across your site."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Customized Brand Page
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip=" Customize your profile page with your own branding, promotions
    //             and company info on MyRevue app to fuel organic growth and free
    //             referral traffic to your site."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //         </tr>
    //         <tr className="">
    //           <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
    //             <div className="h-full w-full flex items-center justify-between ">
    //               Incentives &amp; Rewards
    //               <button
    //                 aria-label="questionmark"
    //                 className="h-8 w-8 flex items-center justify-center"
    //                 data-tooltip=" Create coupon codes with a click of a button to provide special
    //             discounts to customers giving reviews and make them happy."
    //               >
    //                 <CircleWavyQuestion size={22} color="white" />
    //               </button>
    //             </div>
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //           <td className="font-light border border-[#858585] h-10 p-2">
    //             <Check size={32} color="#ffffff" className="mx-auto" />
    //           </td>
    //           <td className="font-light border border-[#858585] h-10 p-2"></td>
    //         </tr>
    //       </tbody>
    //     </table>
    //   </section>
    //   <section className='lg:p-16  p-5 bg-gradient-to-r from-[#222323] via-[#222323] to-[#33164D]'>
    //     <h3 className="text-xl sm:text-3xl sm:leading-tight md:text-4xl md:leading-tight tracking-wide">Create testimonial videos faster and easier.</h3>
    //     <p className="text-[#ffffffe6] pt-2 tracking-wide">No credit card required <span className='block sm:hidden'></span><span className="text-white underline underline-offset-8 cursor-pointer" onClick={() => router.push("/plans")}> • See plans & pricing →</span></p>
    //     <a href="/signup" className="btn btn-secondary mt-6 max-w-fit"><span className="px-8">Get started.</span></a>
    //   </section>
    //   <Notloginfooter />
    // </div>
    <div className="h-screen overflow-y-auto bg-[#101010]">
      <NotLoginHeaderNew />
      <div className="md:my-10  px-5 md:px-10 mx-auto xl:px-24 2xl:px-32">
        <h1 className="text-[28px] md:text-[36px] lg:text-[40px] text-center leading-tight font-semibold tracking-wide">Plans and Pricing</h1>
        <p className="text-[#8E8B8B] text-center tracking-wide mt-3 max-w-[440px] mx-auto">
          Our paid plans which come with publishing, video downloads, and no watermark.
        </p>
        <div className="grid grid-cols-2 w-[245px] mx-auto mt-5 bg-[#2B2F37] rounded-full">
          <button
            onClick={() => setmonthly(true)}
            className={`py-2 w-full rounded-full ${monthly === true ? "bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF]" : "bg-transparent"
              } `}>
            Monthly
          </button>
          <button
            onClick={() => setmonthly(false)}
            className={`py-2 w-full rounded-full ${monthly === false ? "bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF]" : "bg-transparent"
              } `}>
            Yearly
          </button>
        </div>

        <section className="flex mt-10 pb-10 flex-wrap items-center justify-center gap-10">
          <div className="bg-[#2B2F37]  p-6 w-full max-w-[310px] rounded-[20px] mt-8 h-max">
            <p className="text-center text-xl pb-4 text-[#F92147] font-semibold tracking-wide">Freemium</p>
            <p className=" text-center text-2xl">
              <span className="">$0/</span>
              {monthly === true ? "Month" : "Year"}
            </p>
            <ul className="space-y-1 mt-5 font-light tracking-wide">
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />
                Publish Unlimited Reviews
              </li>
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />
                Download Upto 3 Videos
              </li>
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />1 Campaign
              </li>
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />1 User
              </li>
            </ul>
            <div className="w-full flex items-center justify-center">
              <button
                className="py-2 px-12 rounded-full w-fit  mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                onClick={() => router.push("/signup")}>
                Get Started
              </button>
            </div>
          </div>
          <div className="bg-[#2B2F37] p-6  w-full max-w-[310px] rounded-[20px] mt-8 relative h-max">
            <div className="absolute z-50 top-0 left-0 -m-5 bg-[#2B2F37] rounded-md ">
              <div className="border border-white w-max px-5 py-2 rounded-md backdrop-blur-sm ">Popular</div>
            </div>
            <p className="text-center text-xl pb-4 text-[#F92147] font-semibold tracking-wide">Basic</p>
            <p className=" text-center text-2xl">
              <span className="">${monthly === true ? "12.99" : "122.99"}/</span>
              {monthly === true ? "Month" : "Year"}
            </p>
            <ul className="space-y-1 mt-5 font-light tracking-wide">
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />
                Everything in Free, plus...
              </li>
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />
                Download upto 50 Videos
              </li>
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />25 Campaigns
              </li>
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />3 Users
              </li>
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />
                Api Integration
              </li>
            </ul>
            <div className="w-full flex items-center justify-center">
              <button
                className="py-2 px-12 rounded-full w-fit  mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                onClick={() => router.push("/signup")}>
                Get Started
              </button>
            </div>
          </div>
          <div className="bg-[#2B2F37] p-6  w-full max-w-[310px] rounded-[20px] mt-8 h-max">
            <p className="text-center text-xl pb-4 text-[#F92147] font-semibold tracking-wide">Standard</p>
            <p className="text-center text-2xl">
              <span className="">${monthly === true ? "30.99" : "305.99"}/</span>
              {monthly === true ? "month" : "Year"}
            </p>
            <ul className="space-y-1 mt-5 font-light tracking-wide">
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />
                Everything in Basic, plus...
              </li>
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />
                Download Unlimited
              </li>
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />
                Upto 50 Campaigns
              </li>
              <li className="flex gap-x-2 items-center">
                <Check size={26} color="green" weight="bold" />5 Users
              </li>
            </ul>
            <div className="w-full flex items-center justify-center">
              <button
                className="py-2 px-12 rounded-full w-fit  mt-5 bg-gradient-to-br from-[#F92147] via-[#C934A2] to-[#9747FF] transition-all hover:from-transparent hover:via-transparent hover:to-transparent signup-btn-hover"
                onClick={() => router.push("/signup")}>
                Get Started
              </button>
            </div>
          </div>
        </section>
        <section className="p-5 overflow-x-auto pt-16 -mt-10">
          <table className="w-[700px] lg:w-[1050px] overflow-x-auto mx-auto text-center text-sm tableplans">
            <thead className="">
              <tr className="bg-[#2B2F37]">
                <th className=" border border-[#858585] h-16 p-2 font-medium tracking-wide">Plans</th>
                <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 font-medium tracking-wide">Freemium</th>
                <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 font-medium tracking-wide">Basic</th>
                <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 font-medium tracking-wide">Standard</th>
                <th className=" border border-[#858585] h-16 sticky -top-1 z-10 p-2 font-medium tracking-wide">Curated</th>
              </tr>
            </thead>
            <tbody className="tbody">
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Features
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center z-50"
                      data-tooltip="Get a video collector and turn the power of reviews into more
                traffic, increased sales, and strong customer loyalty.. Simply
                share Link/ QR code with your customers and get their video
                reviews.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>

                <td className="font-light border border-[#858585] h-10 p-2 ">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2 ">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2 ">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>

                <td className="font-light border border-[#858585] p-2">
                  For larger businesses with a need for the most advanced features in a bespoke package.
                </td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Campaigns
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip="No of campaigns can be created">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2 text-xl">1</td>
                <td className="font-light border border-[#858585] h-10 p-2 text-xl">25</td>
                <td className="font-light border border-[#858585] h-10 p-2 text-xl">50</td>
                <td className="font-light border border-[#858585] h-10 p-2">On Request</td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Flag Detection Tool
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip="Flag the fake reviews by our detection tool.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  A review solution tailored to your needs and delivered with top-tier customer support.
                </td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Remote hassle free Review Collection
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip="Remotely collect reviews from your customers through any device.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  Throughout your experience, a dedicated Customer Support Team will work with you every step of the way. With an
                  understanding of your unique business drivers and objectives, they’ll help you create and implement a review strategy that
                  grows your brand and your bottom line.
                </td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Users
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip="Get a no of ID login to Customer Dashboard.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2 text-xl">1</td>
                <td className="font-light border border-[#858585] h-10 p-2 text-xl">3</td>
                <td className="font-light border border-[#858585] h-10 p-2 text-xl">5</td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  Work directly with our success managers, implementation experts and support engineers to grow your brand trust quicker.
                </td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Acknowledgements
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip="No. of Comment Credits to acknowledge customer reviews">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
                <td className="font-light border border-[#858585] h-10 p-2 text-xl">25</td>
                <td className="font-light border border-[#858585] h-10 p-2 text-xl">100</td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  With our artificial intelligence doing the heavy lifting, dive deep into your customer feedback to see trends and insights
                  within your reviews.
                </td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Publish
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip="Share unlimited reviews on your Social Media Handles and Website through easily configurable APIs with Brand and MyRevue watermark.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  Showcase Product Reviews in ads and on your website to make buying decisions easier for prospects considering your products.
                </td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Video Storage
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip="Review storage for upto 14 days for Unpublished and Draft videos on MyRevue Customer Dashboard.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Download published videos and raw response footage
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip="Download upto no of videos per month and share on various platforms.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2 text-xl">3</td>
                <td className="font-light border border-[#858585] h-10 p-2 text-xl">50</td>
                <td className="font-light border border-[#858585] h-10 p-2 text-xl">Unlimited</td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Customized Gallery
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip="Get your personlized website and showcase your video reviews as testimonials to your prospective customers.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Dedicated Support Manager
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip="Get your personlized website and showcase your video reviews as testimonials to your prospective customers.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    API Integration
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip=" Improve your brand value by access to our entire service review widget library to showcase your star rating and review content across your site.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Customized Brand Page
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip=" Customize your profile page with your own branding, promotions and company info on MyRevue app to fuel organic growth and free referral traffic to your site.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
              </tr>
              <tr className="">
                <td className="font-light text-left border border-[#858585] h-full p-2  pr-5">
                  <div className="h-full w-full flex items-center justify-between ">
                    Incentives &amp; Rewards
                    <button
                      aria-label="questionmark"
                      className="h-8 w-8 flex items-center justify-center"
                      data-tooltip=" Create coupon codes with a click of a button to provide special discounts to customers giving reviews and make them happy.">
                      <CircleWavyQuestion size={22} color="white" />
                    </button>
                  </div>
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
                <td className="font-light border border-[#858585] h-10 p-2">
                  <Check size={32} color="#ffffff" className="mx-auto" />
                </td>
                <td className="font-light border border-[#858585] h-10 p-2"></td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
      <NotloginfooterNew />
    </div>
  );
}

export default ChoosePlan;
