import {
  ArrowArcLeft,
  Checks,
  CircleNotch,
  FileImage,
  Image,
  Pencil,
} from "@phosphor-icons/react";
import {
  Calendar,
  CaretDown,
  CaretUp,
  EnvelopeSimpleOpen,
  FacebookLogo,
  InstagramLogo,
  LinkedinLogo,
  List,
  MapPin,
  Phone,
  ShieldSlash,
  TiktokLogo,
  Trash,
  YoutubeLogo,
} from "@phosphor-icons/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Preview from "../components/gallery/preview";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db, storage } from "../firebase";
import { useEffect } from "react";
import { useStateValue } from "../context/StateProvider";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import strings from "../assets/Strings";

export default function EditGalleryWebsite() {
  const router = useHistory();
  const { id } = useParams();
  const [{ user }, dispatch] = useStateValue();
  const [tabs, setTabs] = useState("insert");
  const [gallary, setGallary] = useState({});
  const [reviews, setReviews] = useState([]);

  const [submitLoading, setSubmitLoading] = useState(false);

  const [headerActive, setHeaderActive] = useState(false);
  const [bodyActive, setBodyActive] = useState(false);
  const [footerActive, setFooterActive] = useState(false);
  const [navigation, setNavigation] = useState({
    logo: null,
    headerType: "title",
    headerImage: null,
    heroTextColor: "#000000",
  });

  const [socialLinksActive, setSocialLinksActive] = useState(false);
  const [socialLinks, setSocialLinks] = useState({
    facebook: null,
    instagram: null,
    linkedin: null,
    tiktok: null,
    youtube: null,
  });
  const [calenderActive, setCalenderActive] = useState(false);
  const [calenderLink, setCalenderLink] = useState(null);

  const [youtubeActive, setYoutubeActive] = useState(false);
  const [youtubeId, setyoutubeId] = useState(null);

  const [mapsActive, setMapsActive] = useState(false);
  const [maps, setMaps] = useState(null);

  const [formsActive, setFormsActive] = useState(false);
  const [forms, setForms] = useState(null);

  const [contactDetailsActive, setContactDetailsActive] = useState(false);

  const [contactDetails, setContactDetails] = useState({
    phone: null,
    email: null,
    address: null,
  });

  const [navigationPages, setNavigationPages] = useState([]);

  useEffect(() => {
    const fetchGallery = async () => {
      try {
        const docRef = doc(db, `brandAdmin/${user?.email}/gallery/${id}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setGallary(data);
          let fetchedVideos = [];
          async function getVideos(id, type) {
            let headersList = {
              Accept: "*/*",
              Authorization:
                type === "flicks"
                  ? "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf"
                  : "asdlfha-29347239-ASDHPQPINMV-131241234-adfasdfm-QWOQWIEUALS",
            };

            let reqOptions = {
              url:
                type === "flicks"
                  ? `https://flicksapi-zscu3untuq-el.a.run.app/getFlicksById/${id}`
                  : `https://reviewapi-zscu3untuq-el.a.run.app/getReviewsById/${id}`,
              method: "GET",
              headers: headersList,
            };

            let response = await axios.request(reqOptions);
            response.data.data.type = type;
            return response.data.data;
          }

          async function fetchAllVideos() {
            const promises = data.reviews.map(async (obj) => {
              try {
                const response = await getVideos(obj.id, obj.type);
                fetchedVideos.push(response);
              } catch (error) {
                console.error(
                  `Error fetching video for ID ${obj.id} and type ${obj.type}:`,
                  error
                );
              }
            });

            await Promise.all(promises);
          }
          await fetchAllVideos();
          setReviews(fetchedVideos);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    };

    fetchGallery();
  }, [id]);

  async function showWarning(text) {
    toast.warning(text, {
      position: "top-right",
      icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
      bodyClassName: "text-sm tracking-wide font-work",
    });
    return;
  }

  async function getBase64(image) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(image);
    });
  }

  async function updateLogo(event) {
    const file = event.target.files[0];
    if (!file) {
      showWarning("Please select an image to upload");
      return;
    }
    if (file) {
      getBase64(file)
        .then((base64String) => {
          setNavigation({ ...navigation, logo: base64String });
          return;
        })
        .catch((error) => {
          showWarning(error);
          return;
        });
    }
  }
  async function updateHeaderImage(event) {
    const file = event.target.files[0];
    if (!file) {
      showWarning("Please select an image to upload");
      return;
    }
    if (file) {
      getBase64(file)
        .then((base64String) => {
          setNavigation({ ...navigation, headerImage: base64String });
          return;
        })
        .catch((error) => {
          showWarning(error);
          return;
        });
    }
  }
  function extractYouTubeId(url) {
    const regExp =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = url.match(regExp);

    if (match && match[1]) {
      return setyoutubeId(match[1]);
    }

    return setyoutubeId(null); // Return null if no match is found
  }
  function extractSrcFromEmbed(embedCode) {
    const regex = /src="([^"]+)"/;
    const match = embedCode.match(regex);

    if (match && match[1]) {
      return setMaps(match[1]);
    }

    return setMaps(null); // Return null if no match is found
  }

  async function addNavigationPage() {
    let updateedNavigationPages = [...navigationPages];
    updateedNavigationPages.push({
      name: "",
      url: "",
    });
    setNavigationPages(updateedNavigationPages);
  }
  async function deletenavigationPages(index) {
    let updatedNavigationPages = [...navigationPages]; // Create a copy of the array
    updatedNavigationPages.splice(index, 1); // Remove the element at the specified index
    setNavigationPages(updatedNavigationPages); // Update the state with the new array
  }
  const handleNavigationPageChange = (index, field, value) => {
    const updatedNavigationPages = [...navigationPages];
    updatedNavigationPages[index][field] = value;
    setNavigationPages(updatedNavigationPages);
  };

  async function uploadFile(base64Data, fileName, contentType) {
    try {
      const storageRef = ref(storage, fileName);
      await uploadString(storageRef, base64Data, "base64", {
        contentType: contentType,
      }); // Change the contentType as needed
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.log(error);
      showWarning("Error in uploading image");
      return null;
    }
  }
  async function publishGallery() {
    setSubmitLoading(true);

    try {
      const updates = {};

      const handleImageUpload = async (imageKey, fileNameTemplate) => {
        if (navigation?.[imageKey]?.includes("data:image") === true) {
          const contentType = navigation[imageKey].split(";")[0].split(":")[1];
          const fileName = `${fileNameTemplate}/${id}/${imageKey}.png`;
          const downloadURL = await uploadFile(
            navigation[imageKey].split(",")[1],
            fileName,
            contentType
          );
          updates[imageKey] = downloadURL;
        }
      };

      await Promise.all([
        handleImageUpload("logo", "galleryImages"),
        handleImageUpload("headerImage", "galleryImages"),
      ]);

      const customization = {
        navigation: navigation,
        navigationPages: navigationPages,
        socialLinks: socialLinks,
        calendlyLink: calenderLink,
        youtubeId: youtubeId,
        maps: maps,
        forms: forms,
        contactDetails: contactDetails,
      };
      customization.navigation.logo = updates?.logo ?? null;
      customization.navigation.headerImage = updates?.headerImage ?? null;

      const finalDocument = {
        ...gallary,
        customization: customization,
        published: true,
      };

      await Promise.all([
        updateDoc(
          doc(db, strings.adminCollection, user?.email, "gallery", id),
          { customization: customization, published: true }
        ),
        setDoc(doc(db, "gallery", id), {
          ...finalDocument,
          docId: id,
          ref: "brandAdmin/" + user?.email + "/gallery/" + id,
        }),
      ]);

      toast.success("Gallery has been customized and published successfully", {
        position: "top-right",
        icon: <Checks size={32} color="green" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });

      // console.log(finalDocument);
      router.push(`/collector/viewgallery/${gallary?.design}/${id}`);
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        icon: <Checks size={32} color="red" weight="bold" />,
        bodyClassName: "text-sm tracking-wide font-work",
      });
    } finally {
      setSubmitLoading(false);
    }
  }

  return (
    <React.Fragment>
      {/* header */}
      <div className="absolute top-0 z-[400] bg-[#181818] h-16 w-full flex items-center justify-between">
        <div className="flex items-center gap-3 text-[#969696]">
          <Pencil color="#969696" size={22} /> Edit Mode{" "}
        </div>
        <div className="flex items-center gap-3">
          <div
            className="flex items-center gap-3 bg-[#0b0b0b] py-2 px-7 rounded-full mr-3 cursor-pointer"
            onClick={() => router.goBack()}
          >
            <ArrowArcLeft size={22} color="white" weight="bold" /> Exit
          </div>
          <div
            className="flex items-center gap-3 bg-[#30d952] py-2 px-7 rounded-full mr-3 cursor-pointer"
            onClick={() => publishGallery()}
          >
            {submitLoading ? (
              <CircleNotch size={22} color="white" className="animate-spin" />
            ) : (
              "Publish"
            )}
          </div>
        </div>
      </div>
      <div className="h-[calc(100vh-64px)] overflow-y-auto flex justify-between">
        {/* preview */}
        <Preview
          gallery={gallary}
          reviews={reviews}
          navigation={navigation}
          pages={navigationPages}
          contactDetails={contactDetails}
          socialLinks={socialLinks}
          maps={maps}
          forms={forms}
          calender={calenderLink}
          youtube={youtubeId}
        />
        {/* sidebar */}
        <div
          className="w-[450px] bg-[#181818] h-[calc(100vh-64px)] overflow-y-auto"
          id="thin-scrollbar"
        >
          <div className="h-16 w-full bg-[#101010] grid grid-cols-2">
            <div
              className="cursor-pointer w-full h-full px-5 flex items-center "
              onClick={() => setTabs("insert")}
            >
              <p
                className={` ${
                  tabs === "insert" && "border-b border-[#808080] "
                } w-full text-center text-xl`}
              >
                Insert
              </p>
            </div>
            <div
              className="cursor-pointer w-full h-full px-5 flex items-center "
              onClick={() => setTabs("themes")}
            >
              <p
                className={` ${
                  tabs === "themes" && "border-b border-[#808080] "
                } w-full text-center text-xl`}
              >
                Themes
              </p>
            </div>
          </div>
          <div className="p-5 space-y-6">
            <div className="border-b border-[#808080]">
              <button
                onClick={() => setHeaderActive(!headerActive)}
                className={`flex justify-between w-full pb-2 ${
                  headerActive === true && "border-b border-[#808080]"
                }`}
              >
                Header{" "}
                {headerActive ? (
                  <CaretUp size={22} color="white" />
                ) : (
                  <CaretDown size={22} color="white" />
                )}
              </button>
              {headerActive && (
                <div className="my-5 space-y-4">
                  <div className="">
                    <p className="">Logo</p>
                    <div className="h-16  min-w-[120px] max-w-fit bg-[#252525] mt-2 rounded-md flex items-center justify-center p-1 text-xl relative">
                      {navigation?.logo === null ? (
                        <p className="">+</p>
                      ) : (
                        <img src={navigation?.logo} alt="" className="h-full" />
                      )}
                      <input
                        type="file"
                        onChange={(event) => updateLogo(event)}
                        className="h-full w-full absolute opacity-0"
                        accept=".png, .jpg,.jpeg"
                      />
                    </div>
                  </div>
                  <div className="">
                    <p className="">Header Type</p>
                    <div className="flex gap-5 mt-2">
                      <div
                        className={
                          navigation.headerType === "large"
                            ? " transform duration-75 bg-[#B0B0B0] border-[#B0B0B0] border text-black cursor-pointer px-4 py-2 rounded-md flex flex-col gap-2 items-center justify-center"
                            : "transform duration-75 cursor-pointer px-4 py-2 border border-[#B0B0B0] rounded-md flex flex-col gap-2 items-center justify-center"
                        }
                        onClick={() =>
                          setNavigation({ ...navigation, headerType: "large" })
                        }
                      >
                        <FileImage
                          size={28}
                          color={
                            navigation.headerType === "large"
                              ? "black"
                              : "#ffffff"
                          }
                        />
                        Large Banner
                      </div>
                      <div
                        className={
                          navigation.headerType === "title"
                            ? " transform duration-75 bg-[#B0B0B0] border-[#B0B0B0] border text-black cursor-pointer px-8 py-2 rounded-md flex flex-col gap-2 items-center justify-center"
                            : "transform duration-75 cursor-pointer px-8 py-2 border border-[#B0B0B0] rounded-md flex flex-col gap-2 items-center justify-center"
                        }
                        onClick={() =>
                          setNavigation({ ...navigation, headerType: "title" })
                        }
                      >
                        <Image
                          size={28}
                          color={
                            navigation.headerType === "title"
                              ? "black"
                              : "#ffffff"
                          }
                        />
                        Title
                      </div>
                    </div>
                    {navigation?.headerType === "large" && (
                      <div className="mt-5">
                        <p className="">Header Banner</p>
                        <div className="h-20 min-w-[150px] max-w-fit bg-[#252525] mt-2 rounded-md flex items-center justify-center p-1 text-xl relative">
                          {navigation?.headerImage === null ? (
                            <p className="">+</p>
                          ) : (
                            <img
                              src={navigation?.headerImage}
                              alt=""
                              className="h-full"
                            />
                          )}
                          <input
                            type="file"
                            onChange={(event) => updateHeaderImage(event)}
                            className="h-full w-full absolute opacity-0"
                            accept=".png, .jpg,.jpeg"
                          />
                        </div>
                      </div>
                    )}
                    <div className="w-fit mt-5">
                      <p className="">Title/Subtitle Color</p>
                      <div className="mt-2 flex items-center gap-3 h-9 rounded-md border w-full focus-within:border-[#D1D1D1] focus-within:ring-[#D1D1D1] bg-transparent px-2 duration-100 focus-within:ring-1">
                        <div className="relative h-6 w-6 self-center">
                          <div className="absolute z-10 h-full w-full">
                            <input
                              type="color"
                              onChange={(event) =>
                                setNavigation({
                                  ...navigation,
                                  heroTextColor: event.target.value,
                                })
                              }
                              className="h-full w-full rounded-full opacity-0"
                              name=""
                              id=""
                            />
                          </div>
                          <button
                            type="button"
                            className="h-full w-full rounded-full border"
                            style={{
                              backgroundColor: navigation?.heroTextColor,
                            }}
                          ></button>
                        </div>
                        <input
                          type="text"
                          value={navigation?.heroTextColor}
                          onChange={(event) =>
                            setNavigation({
                              ...navigation,
                              heroTextColor: event.target.value,
                            })
                          }
                          className="appearance-none outline-none flex-1 border-none uppercase focus:ring-0 w-full bg-transparent placeholder:text-[#acacac]"
                          placeholder="#4E46E5"
                          name=""
                          id=""
                        ></input>
                      </div>
                    </div>
                    <div className="mt-5">
                      <p className="mb-5">Add Button / Pages</p>
                      {navigationPages?.map((page, index) => (
                        <div className="flex items-center gap-3" key={index}>
                          <div className="">{index + 1}:</div>
                          <div className="mb-5 space-y-2 flex-1">
                            <div className="h-11 w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                              <input
                                type="text"
                                value={page?.name}
                                onChange={(event) =>
                                  handleNavigationPageChange(
                                    index,
                                    "name",
                                    event.target.value
                                  )
                                }
                                placeholder="Button Name"
                                className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3"
                              />
                            </div>
                            <div className="flex items-center h-11 w-full gap-3">
                              <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                                <input
                                  type="text"
                                  value={page?.url}
                                  onChange={(event) =>
                                    handleNavigationPageChange(
                                      index,
                                      "url",
                                      event.target.value
                                    )
                                  }
                                  placeholder="Redirect Url"
                                  className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3"
                                />
                              </div>
                              <button
                                onClick={() => deletenavigationPages(index)}
                                className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center"
                              >
                                <Trash color="red" size={30} />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div className="flex justify-center mt-2">
                        <button
                          onClick={() => addNavigationPage()}
                          className="bg-[#2E3036] h-10 px-6 min-w-[150px] text-sm tracking-wide rounded-full"
                        >
                          Add button/page
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="border-b border-[#808080]">
              <button
                onClick={() => setBodyActive(!bodyActive)}
                className={`flex justify-between w-full pb-2 ${
                  bodyActive === true && "border-b border-[#808080]"
                }`}
              >
                Body{" "}
                {bodyActive ? (
                  <CaretUp size={22} color="white" />
                ) : (
                  <CaretDown size={22} color="white" />
                )}
              </button>
              {bodyActive && (
                <div className="my-5 space-y-5 px-5">
                  <div className="border-b border-[#808080]">
                    <button
                      onClick={() => setCalenderActive(!calenderActive)}
                      className={`flex justify-between w-full pb-2 ${
                        calenderActive === true && "border-b border-[#808080]"
                      }`}
                    >
                      <span className="flex items-center gap-2">
                        {" "}
                        <Calendar size={22} color="white" /> Calender{" "}
                      </span>{" "}
                      {calenderActive ? (
                        <CaretUp size={22} color="white" />
                      ) : (
                        <CaretDown size={22} color="white" />
                      )}
                    </button>
                    {calenderActive && (
                      <div className="my-5 space-y-2">
                        <p className="text-sm">Add links from calendly</p>
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) =>
                                setCalenderLink(event.target.value)
                              }
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3 placeholder:text-[#808080] placeholder:text-sm"
                              placeholder="calendly link"
                            />
                          </div>
                          {/* <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                                        <Trash color='red' size={30} />
                                    </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="border-b border-[#808080]">
                    <button
                      onClick={() => setYoutubeActive(!youtubeActive)}
                      className={`flex justify-between w-full pb-2 ${
                        youtubeActive === true && "border-b border-[#808080]"
                      }`}
                    >
                      <span className="flex items-center gap-2">
                        {" "}
                        <YoutubeLogo size={22} color="white" /> Youtube{" "}
                      </span>{" "}
                      {youtubeActive ? (
                        <CaretUp size={22} color="white" />
                      ) : (
                        <CaretDown size={22} color="white" />
                      )}
                    </button>
                    {youtubeActive && (
                      <div className="my-5 space-y-2">
                        <p className="text-sm">Add youtube video link</p>
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) =>
                                extractYouTubeId(event.target.value)
                              }
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3 placeholder:text-[#808080] placeholder:text-sm"
                              placeholder="youtube link"
                            />
                          </div>
                        </div>
                        {youtubeId && (
                          <div className="">
                            <iframe
                              className="w-full aspect-video"
                              src={`https://www.youtube.com/embed/${youtubeId}`}
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowfullscreen
                            ></iframe>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="border-b border-[#808080]">
                    <button
                      onClick={() => setFormsActive(!formsActive)}
                      className={`flex justify-between w-full pb-2 ${
                        formsActive === true && "border-b border-[#808080]"
                      }`}
                    >
                      <span className="flex items-center gap-2">
                        {" "}
                        <List size={22} color="white" /> Forms{" "}
                      </span>{" "}
                      {formsActive ? (
                        <CaretUp size={22} color="white" />
                      ) : (
                        <CaretDown size={22} color="white" />
                      )}
                    </button>
                    {formsActive && (
                      <div className="my-5 space-y-2">
                        <p className="text-sm">Add google forms link</p>
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) => setForms(event.target.value)}
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3 placeholder:text-[#808080] placeholder:text-sm"
                              placeholder="Google forms link"
                            />
                          </div>
                        </div>
                        {forms && (
                          <div className="">
                            <iframe
                              src={`${forms}?embedded=true`}
                              title={"googleForms"}
                              className="w-full aspect-video rounded-md"
                            ></iframe>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="border-b border-[#808080]">
                    <button
                      onClick={() => setMapsActive(!mapsActive)}
                      className={`flex justify-between w-full pb-2 ${
                        mapsActive === true && "border-b border-[#808080]"
                      }`}
                    >
                      <span className="flex items-center gap-2">
                        {" "}
                        <MapPin size={22} color="white" /> Maps{" "}
                      </span>{" "}
                      {mapsActive ? (
                        <CaretUp size={22} color="white" />
                      ) : (
                        <CaretDown size={22} color="white" />
                      )}
                    </button>
                    {mapsActive && (
                      <div className="my-5 space-y-2">
                        <p className="text-sm">Add google maps link</p>
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) =>
                                extractSrcFromEmbed(event.target.value)
                              }
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3 placeholder:text-[#808080] placeholder:text-sm"
                              placeholder="Google maps embed link"
                            />
                          </div>
                        </div>
                        {maps && (
                          <div className="">
                            <iframe
                              src={maps}
                              title="googlemaps"
                              className="w-full aspect-video rounded-md"
                              allowfullscreen=""
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>{" "}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="border-b border-[#808080]">
              <button
                onClick={() => setFooterActive(!footerActive)}
                className={`flex justify-between w-full pb-2 ${
                  footerActive === true && "border-b border-[#808080]"
                }`}
              >
                Footer{" "}
                {footerActive ? (
                  <CaretUp size={22} color="white" />
                ) : (
                  <CaretDown size={22} color="white" />
                )}
              </button>
              {footerActive && (
                <div className="my-5 space-y-5 px-5">
                  <div className="border-b border-[#808080]">
                    <button
                      onClick={() =>
                        setContactDetailsActive(!contactDetailsActive)
                      }
                      className={`flex justify-between w-full pb-2 ${
                        contactDetailsActive === true &&
                        "border-b border-[#808080]"
                      }`}
                    >
                      <span className="flex items-center gap-2">
                        {" "}
                        <MapPin size={22} color="white" /> Contact Details{" "}
                      </span>{" "}
                      {contactDetailsActive ? (
                        <CaretUp size={22} color="white" />
                      ) : (
                        <CaretDown size={22} color="white" />
                      )}
                    </button>
                    {contactDetailsActive && (
                      <div className="my-5 space-y-2">
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                            <Phone color="white" size={30} />
                          </div>
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) =>
                                setContactDetails({
                                  ...contactDetails,
                                  phone: event.target.value,
                                })
                              }
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3"
                            />
                          </div>
                          {/* <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                                        <Trash color='red' size={30} />
                                    </div> */}
                        </div>
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                            <EnvelopeSimpleOpen color="white" size={30} />
                          </div>
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) =>
                                setContactDetails({
                                  ...contactDetails,
                                  email: event.target.value,
                                })
                              }
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3"
                            />
                          </div>
                          {/* <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                                        <Trash color='red' size={30} />
                                    </div> */}
                        </div>
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                            <MapPin color="white" size={30} />
                          </div>
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) =>
                                setContactDetails({
                                  ...contactDetails,
                                  address: event.target.value,
                                })
                              }
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3"
                            />
                          </div>
                          {/* <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                                        <Trash color='red' size={30} />
                                    </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="border-b border-[#808080]">
                    <button
                      onClick={() => setSocialLinksActive(!socialLinksActive)}
                      className={`flex justify-between w-full pb-2 ${
                        socialLinksActive === true &&
                        "border-b border-[#808080]"
                      }`}
                    >
                      <span className="flex items-center gap-2">
                        {" "}
                        <InstagramLogo size={22} color="white" /> Social Links{" "}
                      </span>{" "}
                      {socialLinksActive ? (
                        <CaretUp size={22} color="white" />
                      ) : (
                        <CaretDown size={22} color="white" />
                      )}
                    </button>
                    {socialLinksActive && (
                      <div className="my-5 space-y-2">
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                            <FacebookLogo
                              color="white"
                              weight="fill"
                              size={30}
                            />
                          </div>
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) =>
                                setSocialLinks({
                                  ...socialLinks,
                                  facebook: event.target.value,
                                })
                              }
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3"
                            />
                          </div>
                          {/* <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                                        <Trash color='red' size={30} />
                                    </div> */}
                        </div>
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                            <InstagramLogo color="white" size={30} />
                          </div>
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) =>
                                setSocialLinks({
                                  ...socialLinks,
                                  instagram: event.target.value,
                                })
                              }
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3"
                            />
                          </div>
                          {/* <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                                        <Trash color='red' size={30} />
                                    </div> */}
                        </div>
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                            <LinkedinLogo color="white" size={30} />
                          </div>
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) =>
                                setSocialLinks({
                                  ...socialLinks,
                                  linkedin: event.target.value,
                                })
                              }
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3"
                            />
                          </div>
                          {/* <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                                        <Trash color='red' size={30} />
                                    </div> */}
                        </div>
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                            <TiktokLogo color="white" size={30} />
                          </div>
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) =>
                                setSocialLinks({
                                  ...socialLinks,
                                  tiktok: event.target.value,
                                })
                              }
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3"
                            />
                          </div>
                          {/* <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                                        <Trash color='red' size={30} />
                                    </div> */}
                        </div>
                        <div className="flex items-center h-11 w-full gap-3">
                          <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                            <YoutubeLogo
                              color="white"
                              weight="fill"
                              size={30}
                            />
                          </div>
                          <div className="h-full w-full flex-1 bg-[#383838] rounded-lg flex items-center justify-center">
                            <input
                              type="text"
                              onChange={(event) =>
                                setSocialLinks({
                                  ...socialLinks,
                                  youtube: event.target.value,
                                })
                              }
                              className="h-full w-full rounded-lg bg-transparent appearance-none outline-none px-3"
                            />
                          </div>
                          {/* <div className="h-full aspect-square bg-[#383838] rounded-lg flex items-center justify-center">
                                        <Trash color='red' size={30} />
                                    </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
