import AWN from "awesome-notifications";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytesResumable,
} from "firebase/storage";
import { toBlob, } from "html-to-image";
import React from "react";
import { useState } from "react";

function UserDetailsVideo({ addLines, reviewId }) {
  const [first, setFirst] = useState("");
  const [second, setSecond] = useState("");
  const [loading, setloading] = useState(false);

  const handleUpload = async (e) => {
    //addLines(dataUrl);
    if (first.length < 2) return new AWN().warning("Please add more words");
    if (second.length < 2) return new AWN().warning("Please add more words");
    setloading(true);
    const node = document.getElementById("imageText");
    toBlob(node)
      .then(async (blob) => {
        // upload file to storage
        var storage = getStorage();
        const storageRef = ref(storage, `reviewTitleImage/${reviewId}.jpeg`);
        const uploadTask = await uploadBytesResumable(storageRef, blob)
          .then((res) => res.ref)
          .catch((e) => {
            return new AWN().warning(e);
          });
        const downloadURL = await getDownloadURL(uploadTask);
        setloading(false);
        addLines(downloadURL);
      })
      .catch(function (error) {
        console.error("oops, something went wrong!", error);
        new AWN().warning(error);

        setloading(false);
      });
  };
  if (loading)
    return (
      <div className="absolute hideOut-normal w-full h-full top-0 left-0  bg-black bg-opacity-70 flex items-center  justify-center">
        <i className="fas fa  fa-spinner animate-spin"></i>
      </div>
    );
  else
    return (
      <div className="absolute hideOut-normal w-full h-full top-0 left-0  bg-black bg-opacity-70 flex items-center  justify-center">
        <div className="flex">
          <div className="flex flex-col justify-center items-center">
            <div
              id="imageText"
              style={{
                width: "250px",
                height: "60px",
                borderLeft: "6px solid #e3077d",
                paddingLeft: "10px",
                color: "white",
                textShadow: "1px 1px 1px #000000",
              }}
            >
              <span style={{ fontSize: "20px" }}>{first}</span> <br />
              <span style={{ fontSize: "12px" }}>{second}</span> <br />
            </div>
          </div>
          <div className="flex-1 flex flex-col items-center justify-center">
            <p className="text-xs text-gray-300 m-2">
              Add two small lines each containing not more less than or first
              line - 20 second line - 30 letter.
            </p>
            <p className="text-xs text-gray-300 m-2">
              <span className="text-red-500 text-lg px-1">*</span> These text
              only appear on first 8s of review.
            </p>

            <input
              type="text"
              onChange={(e) => setFirst(e.target.value)}
              placeholder="John Doe"
              maxLength="20"
              className="m-2 appearance-none bg-transparent border border-gray-700  rounded-sm h-12 p-1 w-80"
            />
            <input
              type="text"
              onChange={(e) => setSecond(e.target.value)}
              placeholder="Public Administrator"
              maxLength="30"
              className="m-2 appearance-none bg-transparent border  border-gray-700 rounded-sm h-12 p-1 w-80"
            />
            <button
              className="bg-[#8ea9fa] h-10 px-8 cursor-pointer select-none text-white rounded m-1"
              onClick={handleUpload}
            >
              Add This
            </button>
          </div>
        </div>
      </div>
    );
}

export default UserDetailsVideo;
